import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { selectComponentActions } from '../../constants/shared-constant';
import { FormGroup } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { appConstants } from 'src/app/core/constants/app-constant';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: [ './select.component.scss' ]
})
export class SelectComponent implements OnInit, OnDestroy {
  @Input()
  _select;
  @ViewChild('select', { static: true })
  public select;
  @Input()
  form: FormGroup;
  callBack = () => {
    switch (this._select.action) {
      case selectComponentActions.hide_corresponding_field:
        if (this.form.controls[ this._select.key ].value === this._select.options[ 0 ].value) {
          this.form.controls[ this._select.target[ 1 ] ].disable();
          this.form.controls[ this._select.target[ 1 ] ].patchValue('');
          this.form.controls[ this._select.target[ 1 ] ].setErrors({});
          this.form.controls[ this._select.target[ 0 ] ].enable();
        } else if (this.form.controls[ this._select.key ].value === this._select.options[ 1 ].value) {
          this.form.controls[ this._select.target[ 0 ] ].disable();
          this.form.controls[ this._select.target[ 0 ] ].patchValue('');
          this.form.controls[ this._select.target[ 0 ] ].setErrors({});
          this.form.controls[ this._select.target[ 1 ] ].enable();
        } else if (this.form.controls[ this._select.key ].value === this._select.options[ 2 ].value) {
          this.form.controls[ this._select.target[ 0 ] ].disable();
          this.form.controls[ this._select.target[ 0 ] ].patchValue('');
          this.form.controls[ this._select.target[ 0 ] ].setErrors({});
          this.form.controls[ this._select.target[ 1 ] ].enable();
        }
        break;
      case selectComponentActions.disable_next_field:
        if (this.form.controls[ this._select.key ].value === this._select.options[ 1 ].value) {
          this.form.controls[ this._select.target[ 0 ] ].disable();
          this.form.controls[ this._select.target[ 0 ] ].patchValue('');
          this.form.controls[ this._select.target[ 0 ] ].setErrors({});
        } else if (this.form.controls[ this._select.key ].value === this._select.options[ 0 ].value) {
          this.form.controls[ this._select.target[ 0 ] ].enable();
          this.form.controls[ this._select.target[ 0 ] ].patchValue('');
        }
        break;
    }
  }

  constructor (private dataService: DataService) {
  }

  ngOnInit () {
    if (this._select && this._select.masterDataKey) {
      if (this._select.options && (this._select.options.length === 0 || this._select.includeAllSheet)) {
        if (this._select.includeAllSheet) {
          this._select.options = [ ...[ { value: '', placeholder: 'All' } ], ...appConstants.masterData[ this._select.masterDataKey ] ];
        } else {
          this._select.options = appConstants.masterData[ this._select.masterDataKey ];
        }
      }
    }
    if (this._select.event) {
      this.select[ 'optionSelectionChanges' ].subscribe(() => {
        this.callBack();
      });
    }
  }

  onSelection (obj, key) {
    if (this._select.module === selectComponentActions.search) {
      let chipValue: string;
      for (let i = 0; i < this._select.options.length; i++) {
        if (this._select.options[ i ].value === this.form.value[ key ]) {
          chipValue = this._select.options[ i ].placeholder;
          break;
        }
      }
      this._select[ 'chipOptions' ] = {
        key: this._select.key,
        value: chipValue,
        uiKey: this._select.uiKey,
        apiKey: this._select.apiKey
      };
      this.dataService.setSearchData(obj, this.form.value[ key ], key, this.form.value[ key ]);
    }
  }

  ngOnDestroy () {
    this._select.chipOptions = {};
  }
}
