import { DataService } from 'src/app/shared/services/data/data.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { SharedLocationService } from '../../../services/shared-location.service';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { clusterGeofenceActionStatusEnum, dropPointAddLocationEnum, mapMeta, serviceableAreaGeofenceActionStatusEnum, societyGeofenceActionStatusEnum } from '../../../constants/map-constants';
import MapServiceUtils from './map.service.utils';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  isMapDataLoadInProgress = false;
  private cacheExpirationTimer: any;
  mapdata = { markerPinData: null, geofenceData: null };
  mapdataSubject = new BehaviorSubject<any>(this.mapdata);
  private markerPinData: any;
  private geofenceData: any;
  private geofenceFetchSteps= mapMeta.geofenceTypesEnum.SERVICEABLE_AREA;
  geofenceFeatureSubject = new BehaviorSubject<any>({ action: null, feature: null });
  featureSubject = new BehaviorSubject<any>({ action: null, markerFeature: null, geofenceFeature: null });
  markerActionSubject = new BehaviorSubject<any>({ markerAction: null, markerType: null, location: null });
  mapTabSubject = new BehaviorSubject<any>(-1);
  selectableParentClusterList = [];
  selectedViewAsList = [];
  selectedParentClusterList = [];
  selectedViewAsListReference: string;
  selectedParentClusterListReference: string;
  selectedTab = mapMeta.mapTabs[ 0 ].label;
  mapCenterZoomSubject = new BehaviorSubject<boolean>(false);

  constructor (
    private dataService: DataService,
    private permissionsService: NgxPermissionsService,
    private sharedLocationService: SharedLocationService,
  ) {
    this.getMapData();
    this.setDefaultViewAsList();
    this.setDefaultSelectParentClusterList();
  }

  resetMap() {
    this.resetMapFeatures();
    this.dataService.removeFromLocalStorage(mapMeta.localStorageKeys.HUB_PINS);
    this.dataService.removeFromLocalStorage(mapMeta.localStorageKeys.SERVICEABLE_AREA_PINS);
    this.dataService.removeFromLocalStorage(mapMeta.localStorageKeys.SOCIETY_PINS);
    this.dataService.removeFromLocalStorage(mapMeta.localStorageKeys.DROP_POINT_PINS);
    this.dataService.removeFromLocalStorage(mapMeta.localStorageKeys.GEOFENCE);
    this.dataService.removeFromLocalStorage(mapMeta.localStorageKeys.CACHE_EXPIRATION_DATE);
    this.dataService.removeFromLocalStorage(mapMeta.localStorageKeys.RETRY_COUNT);
    this.dataService.removeFromLocalStorage(mapMeta.localStorageKeys.SELECTABLE_PARENT_CLUSTER);
  }

  resetMapFeatures() {
    this.selectableParentClusterList = [];
    if(this.mapdata.markerPinData && this.mapdata.markerPinData.features) {
      this.mapdata.markerPinData.features = [];
    }
    if(this.mapdata.geofenceData && this.mapdata.geofenceData.features) {
      this.mapdata.geofenceData.features = [];
    }
    this.mapdata.markerPinData && this.mapdata.geofenceData && this.mapdataSubject?.next(this.mapdata);
  }

  resetGeofencesFeatures() {
    if(this.mapdata.geofenceData && this.mapdata.geofenceData.features) {
      this.mapdata.geofenceData.features = [];
    }
    this.mapdata.markerPinData && this.mapdata.geofenceData && this.mapdataSubject?.next(this.mapdata);
  }

  getMapData (forceRefresh = false) {
    ///Fetch data from remote db if force refreshed or cache is expired or data doesn't exist in localstorage
    if (forceRefresh || MapServiceUtils.isCacheExpired(this.dataService) || !MapServiceUtils.isDataPresentInCache(this.dataService)) {
      this.isMapDataLoadInProgress = true;
      this.resetMap();

      ///Get makers data from remote api call
      this.getMarkerData(forceRefresh);

      //Get geofence data from remote api call
      this.geofenceFetchSteps = mapMeta.geofenceTypesEnum.SERVICEABLE_AREA;
      this.permissionsService.getPermission(mapMeta.privilage.VIEW_SERVICEABLE_AREA) && this.getGeofenceData(forceRefresh);

    } else {
      ///Get data from local
      const hubsFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.HUB_PINS);
      const serviceableAreaPinFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.SERVICEABLE_AREA_PINS);
      const dropPointFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.DROP_POINT_PINS);
      const societyPinFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.SOCIETY_PINS);
      const geofencesFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.GEOFENCE);
      ///Create map data
      this.markerPinData = MapServiceUtils.createMarkerArcGisJson(hubsFeatures || [], serviceableAreaPinFeatures || [], dropPointFeatures || [], societyPinFeatures || []);
      this.geofenceData = MapServiceUtils.createGeofenceArcGisJson(geofencesFeatures);
      this.createMapData();
      this.mapdataSubject.next(this.mapdata);
      ///Start expiration timer
      const expirationDuration = moment(this.dataService.getLocalStorage(mapMeta.localStorageKeys.CACHE_EXPIRATION_DATE)).diff(moment());
      this.autoReloadCache(expirationDuration);
    }
  }

  createMapData () {
    this.mapdata = {
      markerPinData: this.markerPinData,
      geofenceData: this.geofenceData
    }
    this.mapdataSubject.next(this.mapdata);
  }

  getMarkerData(forceRefresh) {
    const getAndSetMarkerData = (apiUrl, optionsToUrl, featureType, localStorageKey) => {
      this.sharedLocationService.getAllDatabyId(apiUrl, optionsToUrl).subscribe((res: any) => {
        if(res){
          const features = MapServiceUtils.getMarkerFeatures(res, featureType);
          this.dataService.setLocalStorage(localStorageKey, features);
          this.markerPinData.features = [...this.markerPinData.features, ...features ];
          this.mapdata.markerPinData = this.markerPinData ;
          !forceRefresh && this.mapdataSubject.next(this.mapdata);
        }
      });
    };
    this.markerPinData = this.dataService.deepClone(mapMeta.ARC_GIS_INITIAL_PARAMS);
    this.markerPinData.geometryType = mapMeta.esriGeometry.ESRI_GEOMETRY_POINT;
    this.markerPinData.features = [];

    this.permissionsService.getPermission(mapMeta.privilage.VIEW_HUB) && getAndSetMarkerData(AppWebRoutes.HUBS.hubs, { cityId: this.dataService.globalFilter.apiValue.cityId, hideLoader: true }, mapMeta.feature.HUB, mapMeta.localStorageKeys.HUB_PINS);
    this.permissionsService.getPermission(mapMeta.privilage.VIEW_SERVICEABLE_AREA) && getAndSetMarkerData(AppWebRoutes.MAP.serviceableAreaPinAll, { cityId: this.dataService.globalFilter.apiValue.cityId, hideLoader: true }, mapMeta.feature.SERVICEABLE_AREA_PIN, mapMeta.localStorageKeys.SERVICEABLE_AREA_PINS);
    this.permissionsService.getPermission(mapMeta.privilage.VIEW_DROP_POINT) && getAndSetMarkerData(AppWebRoutes.MAP.dropPoints + mapMeta.apiResolve.PARENT_CLUSTER_NAME, { hubIds: [ this.dataService.globalFilter.apiValue.hubId ], hideLoader: true }, mapMeta.feature.DROP_POINT, mapMeta.localStorageKeys.DROP_POINT_PINS);
  }

  getGeofenceData (forceRefresh) {
    this.sharedLocationService.getAllDatabyId(MapServiceUtils.getApiToGetGeofence(this.geofenceFetchSteps), { cityId:  this.dataService.globalFilter.apiValue.cityId, hubIds: [ this.dataService.globalFilter.apiValue.hubId ], hideLoader: true })
      .subscribe((res: any) => {
        ///Create geofence argis feature and update map
        switch (this.geofenceFetchSteps) {
          case mapMeta.geofenceTypesEnum.SERVICEABLE_AREA:
            const geofenceFeatures = MapServiceUtils.getGeofenceFeatures(this.permissionsService, res, this.geofenceFetchSteps);
            this.mapdata.geofenceData = MapServiceUtils.createGeofenceArcGisJson(geofenceFeatures);
            this.geofenceFetchSteps = mapMeta.geofenceTypesEnum.CLUSTER;
            break;
          case mapMeta.geofenceTypesEnum.CLUSTER:
            MapServiceUtils.getClusterGeofenceFeatures(this.permissionsService, this.dataService, this.mapdata, res);
            this.geofenceFetchSteps = this.permissionsService.getPermission(mapMeta.privilage.VIEW_SOCIETY_GEOFENE) ? mapMeta.geofenceTypesEnum.SOCIETY : mapMeta.geofenceTypesEnum.DONE;
            break;
          case mapMeta.geofenceTypesEnum.SOCIETY:
            MapServiceUtils.getSocietyGeofenceFeatures(this.permissionsService, this.mapdata, this.dataService, res);
            this.geofenceFetchSteps = mapMeta.geofenceTypesEnum.DONE;
            break;
        }

        ///Call same function to get data step wise for 0-serviceable area then 1-society then 2-cluster
        if (this.geofenceFetchSteps  === mapMeta.geofenceTypesEnum.DONE) {
          ///Save newly fetched geofence in local storage
          this.dataService.setLocalStorage(mapMeta.localStorageKeys.GEOFENCE, this.mapdata.geofenceData.features);
          this.geofenceData = this.mapdata.geofenceData;
          ///Update expiration date when all data is fetched
          const cacheExpirationDate = moment().add(3, 'hours').format();
          this.autoReloadCache(3*60*60*1000);
          this.dataService.setLocalStorage(mapMeta.localStorageKeys.CACHE_EXPIRATION_DATE, cacheExpirationDate);
          this.isMapDataLoadInProgress = false;
          if(forceRefresh){
            this.selectedViewAsListReference = JSON.stringify(this.permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA) ? mapMeta.viewAsList : mapMeta.viewAsListOps);
            this.updateMapDataOnViewAsChange(this.selectedViewAsList);
            if(this.permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA_LIVE)) {
              this.setDefaultSelectParentClusterList();
              this.updateMapDataOnSelectParentClusterChange(this.selectedParentClusterList);
            }
          } else {
            this.mapdataSubject.next(this.mapdata);
          }
        } else {
          this.getGeofenceData(forceRefresh);
        }
      });
  }

  getGeofenceFeaturesBasedOnSelectedTab (geofenceFeatures) {
    let filteredGeofenceList = [];
    switch (this.selectedTab) {
      case mapMeta.mapTabEnum.ALL:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            (
              ( this.selectedViewAsList.includes(mapMeta.viewAsListLabels.GROWTH_GEOFENCE_DRAFT) &&
                feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.DRAFT]) ||
              ( this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SENT_TO_GROWTH_MANAGER) &&
                feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.REVIEW]) ||
              ( this.selectedViewAsList.includes(mapMeta.viewAsListLabels.GROWTH_GEOFENCE_LIVE) &&
                feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.LIVE]) ||
              ( (this.selectedViewAsList.includes(mapMeta.viewAsListLabels.GROWTH_GEOFENCE_IN_CONSIDERATION) || this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SENT_TO_OPERATION)) &&
                feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.DONE])
            )
          ) ||
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.CLUSTER &&
            (
              ( this.selectedViewAsList.includes(mapMeta.viewAsListLabels.OPS_GEOFENCE_DRAFT) &&
                feature.attributes.status === mapMeta.clusterStatusEnum[clusterGeofenceActionStatusEnum.DRAFT]) ||
              ( this.selectedViewAsList.includes(mapMeta.viewAsListLabels.OPS_GEOFENCE_LIVE) &&
                feature.attributes.status === mapMeta.clusterStatusEnum[clusterGeofenceActionStatusEnum.READY])
            )
          ) ||
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY &&
            (
              ( this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SOCIETY_GEOFENCE_DRAFT) &&
                feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.DRAFT]) ||
              ( this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SENT_TO_GROWTH_MANAGER) &&
                feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.REVIEW]) ||
              ( this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SOCIETY_GEOFENCE_ACTIVE) &&
                feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.LIVE])
            )
          )
        );
        break;
      case mapMeta.mapTabEnum.DRAFT:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.GROWTH_GEOFENCE_DRAFT) &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.DRAFT]) ||
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SOCIETY_GEOFENCE_DRAFT) &&
            feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.DRAFT])
          );
        break;
      case mapMeta.mapTabEnum.IN_REVIEW:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SENT_TO_GROWTH_MANAGER) &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.REVIEW]) ||
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SENT_TO_GROWTH_MANAGER) &&
            feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.REVIEW])
          );
        break;
      case mapMeta.mapTabEnum.SENT:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          ( this.permissionsService.getPermission(mapMeta.privilage.INSERT_SERVICEABLE_AREA) &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SENT_TO_GROWTH_MANAGER) &&
            ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
              feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.REVIEW]) ||
            ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY &&
              feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.REVIEW])
          ) ||
          (
            this.permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA_DONE) &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SENT_TO_GROWTH_MANAGER) &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.DONE]
          )
          );
        break;
      case mapMeta.mapTabEnum.RECONSIDERATION:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
          feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.RECONSIDER]);
        break;
      case mapMeta.mapTabEnum.LIVE:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.GROWTH_GEOFENCE_LIVE) &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.LIVE]) ||
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.CLUSTER &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.OPS_GEOFENCE_LIVE) &&
            feature.attributes.status === mapMeta.clusterStatusEnum[clusterGeofenceActionStatusEnum.READY]) ||
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SOCIETY_GEOFENCE_ACTIVE) &&
            feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.LIVE])
          );
        break;
      case mapMeta.mapTabEnum.IN_CONSIDERATION_GEOFENCE:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.GROWTH_GEOFENCE_IN_CONSIDERATION) &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.DONE])
          );
        break;
      case mapMeta.mapTabEnum.DRAFT_GEOFENCE:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.CLUSTER &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.OPS_GEOFENCE_DRAFT) &&
            feature.attributes.status === mapMeta.clusterStatusEnum[clusterGeofenceActionStatusEnum.DRAFT])
          );
        break;
      case mapMeta.mapTabEnum.LIVE_GEOFENCE:
        filteredGeofenceList = geofenceFeatures.filter(feature =>
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.CLUSTER &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.OPS_GEOFENCE_LIVE) &&
            feature.attributes.status === mapMeta.clusterStatusEnum[clusterGeofenceActionStatusEnum.READY] &&
            ( !this.selectedParentClusterList.length || (this.selectedParentClusterList.length && this.selectedParentClusterList.includes(feature.attributes.clusterId)))
          ) ||
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.SOCIETY_GEOFENCE_ACTIVE) &&
            feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.LIVE]) ||
          ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            this.selectedViewAsList.includes(mapMeta.viewAsListLabels.GROWTH_GEOFENCE_LIVE) &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.LIVE])
          );
        break;
    }
    return filteredGeofenceList;
  }

  updateMapDataOnTabChange (currentSelectedTab) {
    this.selectedTab = currentSelectedTab;
    const geofenceFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.GEOFENCE);
    if(!geofenceFeatures) {
      this.mapdata.markerPinData.features = [];
      this.mapdata.geofenceData.features = [];
      this.mapdataSubject.next(this.mapdata);
      this.getMapData(true);
      return;
    }
    this.mapdata.geofenceData.features = this.getGeofenceFeaturesBasedOnSelectedTab(geofenceFeatures);
    this.mapdataSubject.next(this.mapdata);
  }

  setDefaultViewAsList () {
    const viewAsList = this.permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA) ? mapMeta.viewAsList : mapMeta.viewAsListOps;
    viewAsList.forEach(viewAs => {
      !this.selectedViewAsList.includes(viewAs.resolve) && this.selectedViewAsList.push(viewAs.resolve);
    });
    this.selectedViewAsListReference = JSON.stringify(this.selectedViewAsList);
  }

  setDefaultSelectParentClusterList () {
    this.selectableParentClusterList = this.dataService.getLocalStorage(mapMeta.localStorageKeys.SELECTABLE_PARENT_CLUSTER) ? this.dataService.getLocalStorage(mapMeta.localStorageKeys.SELECTABLE_PARENT_CLUSTER): [];
    this.selectableParentClusterList.forEach(selectableParent => {
      !this.selectedParentClusterList.includes(selectableParent.resolve) && this.selectedParentClusterList.push(selectableParent.resolve);
    });
    this.selectedParentClusterListReference = JSON.stringify(this.selectedParentClusterList);
  }

  updateMapDataOnViewAsChange (changedViewAsList) {
    const geofenceFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.GEOFENCE);
    if(this.selectedViewAsListReference === JSON.stringify(changedViewAsList) || !geofenceFeatures) {
       return;
    }
    this.selectedViewAsListReference = JSON.stringify(changedViewAsList);
    this.selectedViewAsList = changedViewAsList;
    if (changedViewAsList && changedViewAsList.length) {
      this.geofenceData.features = this.getGeofenceFeaturesBasedOnSelectedTab(geofenceFeatures);
      const filteredData = MapServiceUtils.getFilteredMapData(this.selectedViewAsList, this.selectedParentClusterList, this.markerPinData, this.geofenceData);
      this.mapdata = { markerPinData: filteredData.markerPinData, geofenceData: filteredData.geofenceData };
    } else if (changedViewAsList && !changedViewAsList.length) {
      this.mapdata.markerPinData.features = [];
      this.mapdata.geofenceData.features = [];
    } else {
      this.mapdata = { markerPinData: this.markerPinData, geofenceData: this.geofenceData };
    }
    this.mapdataSubject.next(this.mapdata);
  }

  updateMapDataOnSelectParentClusterChange (changedSelectParentClusterList) {
    const geofenceFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.GEOFENCE);
    if(this.selectedParentClusterListReference === JSON.stringify(changedSelectParentClusterList) || !geofenceFeatures) {
       return;
    }
    this.selectedParentClusterListReference = JSON.stringify(changedSelectParentClusterList);
    this.selectedParentClusterList = changedSelectParentClusterList;
    if (changedSelectParentClusterList && changedSelectParentClusterList.length) {
      this.geofenceData.features = this.getGeofenceFeaturesBasedOnSelectedTab(geofenceFeatures);
      const filteredData = MapServiceUtils.getFilteredMapData(this.selectedViewAsList, this.selectedParentClusterList, this.markerPinData, this.geofenceData);
      this.mapdata = { markerPinData: filteredData.markerPinData, geofenceData: filteredData.geofenceData };
    } else if (changedSelectParentClusterList && !changedSelectParentClusterList.length) {
      this.mapdata.markerPinData.features = [];
      this.mapdata.geofenceData.features = [];
    } else {
      this.mapdata = { markerPinData: this.markerPinData, geofenceData: this.geofenceData };
    }
    this.mapdataSubject.next(this.mapdata);
  }

  addDropPointsInLocalCache(dropPointsIds, dropPoints) {
    let pinFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.DROP_POINT_PINS);
    let graphicFeatureList = [];
    dropPointsIds.forEach((dropPointId, index) => {
      const dropPoint = { ...dropPoints[index], ...{id: dropPointId}};
      graphicFeatureList.push(MapServiceUtils.getMarkerFeatureFromJson(dropPoint, mapMeta.feature.DROP_POINT));
    });
    pinFeatures = [...pinFeatures, ...graphicFeatureList];
    this.dataService.setLocalStorage(mapMeta.localStorageKeys.DROP_POINT_PINS, pinFeatures);
    this.markerPinData.features = [...this.markerPinData.features, ...graphicFeatureList];
    this.mapdata.markerPinData = this.markerPinData;
    this.featureSubject.next({ action : mapMeta.featureAction.ADD, markerFeature: graphicFeatureList});
  }

  //Updating marker local data to keep local data refreshed and save remote api calls
  updatePinFeaturesInLocalCache(action, graphic, type, pinLocalStorageKey){
    let pinFeatures = this.dataService.getLocalStorage(pinLocalStorageKey);
    switch (action) {
      case mapMeta.featureAction.ADD:
        const graphicFeature = MapServiceUtils.getMarkerFeatureFromJson(graphic,type);
         if(pinFeatures) {
          pinFeatures.push(graphicFeature)
        } else {
          pinFeatures = [graphicFeature];
        }
        this.markerPinData.features.push(graphicFeature);
        this.mapdata.markerPinData = this.markerPinData;
        break;
     case mapMeta.featureAction.EDIT:
        if (pinFeatures) {
          const graphicFeature = MapServiceUtils.getMarkerFeatureFromJson(graphic,type);
          pinFeatures[ pinFeatures.findIndex(item => item.attributes.id === graphic.id) ] = graphicFeature;
          this.markerPinData.features[ this.markerPinData.features.findIndex(item => item.attributes.id === graphic.id) ] = graphicFeature;
          this.mapdata.markerPinData = this.markerPinData;
        }
        break;
      case mapMeta.featureAction.DELETE:
        if (pinFeatures) {
          pinFeatures.splice(pinFeatures.findIndex(item => item.attributes.id === graphic.id) , 1);
          this.markerActionSubject.next({ markerAction : action, markerType: type, location: dropPointAddLocationEnum.DASHBOARD });
          this.markerPinData.features.splice(this.markerPinData.features.findIndex(item => item.attributes.id === graphic.id) , 1);
          this.mapdata.markerPinData = this.markerPinData;
        }
        break;
    }
    this.dataService.setLocalStorage(pinLocalStorageKey, pinFeatures);
  }

  addClustersInLocalCache(clustersIds, clusterGeofences) {
    let geofenceFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.GEOFENCE);
    let graphicFeatureToAdd = [];
    let graphicFeatureToUpdate = [];
    let graphicFeatureToDelete = [];
    clustersIds.forEach((clusterId, index) => {
      const dataToSend = { ...clusterGeofences[index], ...{id: clusterId}};
      const graphicFeature = MapServiceUtils.getGeofenceFeatureFromJson(clusterId, dataToSend, mapMeta.geofenceTypesEnum.CLUSTER);
      switch (dataToSend.action.toLowerCase()) {
        case mapMeta.featureAction.ADD:
          graphicFeatureToAdd.push(graphicFeature);
          geofenceFeatures.push(graphicFeature);
          this.geofenceData.features.push(graphicFeature);
          break;
        case mapMeta.featureAction.UPDATE:
          graphicFeatureToUpdate.push(graphicFeature);
          if (geofenceFeatures) {
            geofenceFeatures[ geofenceFeatures.findIndex(item => item.attributes.id === graphicFeature.attributes.id && item.attributes.REGION === graphicFeature.attributes.REGION) ] = graphicFeature;
            this.geofenceData.features[ this.geofenceData.features.findIndex(item => item.attributes.id === graphicFeature.attributes.id && item.attributes.REGION === graphicFeature.attributes.REGION) ] = graphicFeature;
          }
          break;
        case mapMeta.featureAction.DELETE:
          graphicFeatureToDelete.push(graphicFeature);
          if (geofenceFeatures) {
            geofenceFeatures.splice(geofenceFeatures.findIndex(item => item.attributes.id === graphicFeature.attributes.id && item.attributes.REGION === graphicFeature.attributes.REGION) , 1);
          }
          break;
      }
    });
    this.dataService.setLocalStorage(mapMeta.localStorageKeys.GEOFENCE, geofenceFeatures);
    this.mapdata.geofenceData = this.geofenceData;
    if(graphicFeatureToUpdate.length) {
      this.geofenceFetchSteps = mapMeta.geofenceTypesEnum.SERVICEABLE_AREA;
      this.resetGeofencesFeatures();
      this.getGeofenceData(true);
    } else {
      this.featureSubject.next({ geofenceFeature: { toAdd: graphicFeatureToAdd, toUpdate: graphicFeatureToUpdate, toDelete: graphicFeatureToDelete }});
    }
  }

  //Updating geofence local data to keep local data refreshed and save remote api calls
  updateGeofenceFeatureInLocalCache(action,graphicFeature){
    let geofenceFeatures = this.dataService.getLocalStorage(mapMeta.localStorageKeys.GEOFENCE);
    switch (action) {
      case mapMeta.featureAction.ADD:
        if(geofenceFeatures) {
          geofenceFeatures.push(graphicFeature)
        } else {
          geofenceFeatures = [graphicFeature];
        }
        break;
     case mapMeta.featureAction.EDIT:
     case mapMeta.featureAction.MOVE:
     case mapMeta.featureAction.PATCH:
        if (geofenceFeatures) {
          geofenceFeatures[ geofenceFeatures.findIndex(item => item.attributes.id === graphicFeature.attributes.id && item.attributes.REGION === graphicFeature.attributes.REGION) ] = graphicFeature;
        }
        break;
      case mapMeta.featureAction.DELETE:
        if (geofenceFeatures) {
          geofenceFeatures.splice(geofenceFeatures.findIndex(item => item.attributes.id === graphicFeature.attributes.id && item.attributes.REGION === graphicFeature.attributes.REGION) , 1);
        }
        break;
    }
    this.geofenceFeatureSubject.next({ action : action, feature: graphicFeature});
    this.mapdata.geofenceData.features = geofenceFeatures;
    this.dataService.setLocalStorage(mapMeta.localStorageKeys.GEOFENCE, geofenceFeatures);
  }

  autoReloadCache (expirationDuration: number) {
    if (this.cacheExpirationTimer) {
      clearTimeout(this.cacheExpirationTimer);
    }
    this.cacheExpirationTimer = null;
    this.cacheExpirationTimer = setTimeout(() => {
      this.getMapData();
    }, expirationDuration);
  }

  resetTimer () {
    if (this.cacheExpirationTimer) {
      clearTimeout(this.cacheExpirationTimer);
    }
    this.cacheExpirationTimer = null;
  }
}
