<form [formGroup]="form">
  <mat-form-field class="multi-chip" appearance="outline" [hintLabel]="_multichip.hintLabel" [ngClass]="{'formdisable': form.controls[ _multichip.key ].disabled }">
    <mat-label>{{ _multichip.placeholder }}
      <span *ngIf="_multichip.validations && _multichip.validations.required"><i class="fa fa-asterisk as-design"></i></span>
    </mat-label>
    <!-- <mat-icon matSuffix>arrow_drop_down</mat-icon> -->
    <mat-chip-list #chipList>
      <mat-chip [ngClass]="{ hidden: _multichip.module === 'search' }" *ngFor="let option of dataService.initDataForMultiChip[_multichip.key]" [selectable]="selectable"
        [disabled]="_multichip.disabled ? _multichip.disabled : false" [removable]="removable" (removed)="remove(option)">
        {{ option }}
        <mat-icon class="material-icons-outlined" matChipRemove *ngIf="removable">close</mat-icon>
      </mat-chip>

      <input #multiChipInput matInput [formControlName]="_multichip.key" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputAddOnBlur]="addOnBlur"
        [required]="_multichip.validations && _multichip.validations.required" />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of _multichip.filteredOptions | async" [value]="option[_multichip.apiKey]" (onSelectionChange)="
          onSelectionChangeMultiChip(
            option,
            option[_multichip.uiKey],
            _multichip.key,
            option[_multichip.apiKey]
          )
        ">
        {{ option[_multichip.uiKey] }} {{_multichip.multichipApiKeyUiRequired? '('+option[_multichip.apiKey]+')' :''}}

      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
<app-error *ngIf="_multichip.searchableDropDown" [form]="form" [_error]="_multichip"></app-error>
