<div class="outer-div">
    <button type="button" class="close-button" mat-icon-button (click)="close()">
        <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
    <h4 *ngIf="images.length">Product Return Images</h4>
    <div class=" images" *ngIf="images.length;else noImages">
        <ng-container>
            <div class="image-container" *ngFor="let image of images">
                <img (click)="imageDetail(image)" class="image-width" [src]="fileUrl + image.pickedImage" onError="this.src='../../../../../assets/img/no_image.jpg';" alt="..." />
            </div>
        </ng-container>
    </div>
    <ng-template #noImages>
        <div class="no-head"></div>
        <div class="no-image">
            <h3>No data found</h3>
        </div>
    </ng-template>
</div>
