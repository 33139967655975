import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApprovalFormView, BdTrackerConstants } from 'src/app/bd-tracker/constants/bd-tracker-constants';
import { FormService } from 'src/app/shared/services/form/form.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: [ './contact-details.component.scss' ]
})
export class ContactDetailsComponent implements OnInit {
  public contactDetailCodeView = BdTrackerConstants.rwaDirectoryContainer.view;
  public contactDetailView: Array<ApprovalFormView>;
  contactDetail = {};
  constructor (
    private formService: FormService,
    public dialogRef: MatDialogRef<ContactDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit (): void {
    this.contactDetail = this.data;
    this.contactDetailView = [];
    this.contactDetailView[ 'page' ] = BdTrackerConstants.pages.contactDetails;
    this.formService.patchValue(this.contactDetailView, this.contactDetailCodeView, this.contactDetail);
  }

  close () {
    this.dialogRef.close();
  }

}
