import { Component, OnInit } from '@angular/core';
import { globalFilter } from '../../constants/app-constant';
import { FormService } from '../../../shared/services/form/form.service';
import { FormGroup } from '../../../../../node_modules/@angular/forms';
import { DataService } from '../../../shared/services/data/data.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-global-filter',
  templateUrl: './global-filter.component.html'
})
export class GlobalFilterComponent implements OnInit {
  public form: FormGroup;
  public globalFilter = globalFilter;
  constructor (private formService: FormService, private dataService: DataService, public dialog: MatDialog) { }

  ngOnInit () {
    this.form = this.formService.createFormGroup(this.globalFilter);
  }

  performAction () {
    if (this.form.valid) {
      this.dialog.closeAll();
    }
  }
}
