import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/data/data.service';
import { appConstants } from '../../constants/app-constant';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [ './home.component.scss' ]
})
export class HomeComponent implements OnInit {
  cardData = [];
  chartData = null;
  chartKeys = [
    {
      id: 'aov',
      title: 'AOV in one week'
    },
    {
      id: 'grossSales',
      title: 'Gross Sales in one week'
    },
    {
      id: 'totalPaidCustomer',
      title: 'Total Paid Customer in one week'
    },
    {
      id: 'numberOfOrder',
      title: 'Number Of Order in one week'
    },
  ];

  constructor (private dataService: DataService) {
    this.dataService.sendSideNavData({ 'title': appConstants.pages.home.label });
  }
  ngOnInit () {
  }
}
