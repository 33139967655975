export const inputComponentActions = {
  lowercase_with_delimiter_underscore: 'lowercase_with_delimiter_underscore',
  coordinates: 'coordinates',
  lower_case: 'lower_case',
  phone_validation: 'phone_validation',
  email_validation: 'email_validation',
  number_validation: 'number_validation',
  search: 'search',
  addition_of_fields: 'addition_of_fields',
  multiplication_of_fields: 'multiplication_of_fields'
};
// Have to move here because it was initiating masterData variables of customerConstant before it was getting from storage or by API. 
export const mbPassLedgerContainer = {
  tableHeader: [
    { header: 'Date', key: 'date' },
    { header: 'Order Id', key: 'orderId' },
    { header: 'Pass Saving', key: 'passSaving' },
    { header: 'Refund', key: 'refund' }
  ],
  metaData: {
    header: 'Mb Pass',
    canEdit: false,
    canDelete: false,
    hidePagination: true,
    showRefresh: false,
    showCreate: false,
    viewPrivilege: 'view_customer'
  },
};
export const autoCompleteComponentActions = {
  auto_update_dependent_list: 'auto_update_dependent_list',
  auto_update_dependent_list_depend: 'auto_update_dependent_list_depend'
};
export const selectComponentActions = { hide_corresponding_field: 'hide_corresponding_field', search: 'search', disable_next_field: 'disable_next_field' };
export const dateComponentActions = { calendar: 'calendar', both: 'both', time: 'time', search: 'search' };
export const radioComponentActions = { hide_corresponding_field: 'hide_corresponding_field' };
export const shared = {
  NA: 'N/A'
};
export const resolveParams = {
  hub: 'hub',
  locations: 'locations',
  vendor: 'vendor'
};

export const formServiceActions = {
  search: 'search',
  date: 'date',
};
export const moduleType = {
  search: 'search',
  crud: 'crud'
};

export const fileSizeGlobal = {
  gif: 5242880, // this byte is equal to 5MB
  defaultSize: 5242880 // this byte is equal to 5MB,
};

export enum CSVConstants {
  CSV_ERROR = 'There are issues with CSV file',
  PLACEHOLDER = 'File Upload'
};

export enum OPEN_SNACKBAR_CLASSES {
  DANGER = 'danger',
  SUCCESS = 'success'
};

export enum ERROR_MESSAGES {
  SCHEDULE_NOT_ALLOWED = 'schedule_not_allowed',
  BIN_ERROR = 'Bin Number Should be 6 digit.',
};

export enum PaymentMethod {
  WALLET = 'WALLET',
  DEBIT_CARD = 'DEBIT_CARD',
  CREDIT_CARD = 'CREDIT_CARD',
  UPI = 'UPI',
  PAY_LATER = 'PAY_LATER',
  NET_BANKING = 'NET_BANKING',
  FOOD_WALLET = 'FOOD_WALLET',
  CRED = 'CRED'
}