<mat-card class="mat-elevation-z card-crud p-0">
  <mat-card-header>
    <mat-card-title *ngIf="!_crud.hideHeader">
      <button class="button-back" *ngIf="_crud?.page?.path" (click)="goBack()" type="button" aria-label="Toggle sidenav" mat-icon-button>
        <mat-icon class="material-icons-outlined arrow-up" aria-label="Side nav toggle icon" matTooltip="Back">keyboard_backspace
        </mat-icon>
      </button>
      <span *ngIf="!_crud.header">{{
        _crud.page && _crud.page.label ? _crud.page.label : ''
        }}</span>
      <span *ngIf="_crud.header">{{ _crud.header ? _crud.header : '' }}</span>
    </mat-card-title>
    <button *ngIf="_crud.download" (click)="downloadCSV(_crud.download)" class="text-nowrap" mat-button color="primary">Download Sample</button>
  </mat-card-header>
  <mat-card-content>
    <div [formGroup]="form" class="padding-top">
      <div class="row">
        <div *ngFor="let crud of _crud" [ngClass]="renderClass()">
          <div [ngSwitch]="crud.type" [formGroup]="form">
            <div *ngSwitchCase="'text'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-input [form]="form" [_input]="crud"></app-input>
            </div>
            <div *ngSwitchCase="'email'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-input [form]="form" [_input]="crud"></app-input>
            </div>
            <div *ngSwitchCase="'password'">
              <app-input [form]="form" [_input]="crud"></app-input>
            </div>
            <div *ngSwitchCase="'number'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-input [form]="form" [_input]="crud"></app-input>
            </div>
            <div *ngSwitchCase="'radio'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-radio [form]="form" [_radio]="crud"></app-radio>
            </div>
            <div *ngSwitchCase="'checkbox'">
              <label>{{ crud.heading ? crud.heading : '' }}</label>
              <app-checkbox [form]="form" [_checkbox]="crud"></app-checkbox>
            </div>
            <div *ngSwitchCase="'date'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-date [form]="form" [_date]="crud" [_crud]="_crud"></app-date>
            </div>
            <div *ngSwitchCase="'textarea'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-textarea [form]="form" [_textarea]="crud"></app-textarea>
            </div>
            <div *ngSwitchCase="'file'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-file [form]="form" [_file]="crud"></app-file>
            </div>
            <div *ngSwitchCase="'media'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-media [schema]="crud" [form]="form" [parentForm]="parentForm"></app-media>
            </div>
            <div *ngSwitchCase="'autocomplete'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-autocomplete (onSelection)="updateSelection($event)" [form]="form" [_autocomplete]="crud" [_search]="_crud"></app-autocomplete>
            </div>
            <div *ngSwitchCase="'multiValue'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-multivalued-input (valueList)="getMultiValue($event)" [label]="crud.key" [hint]="crud.hintLabel" [placeholder]="crud.placeholder" [values]="bin"></app-multivalued-input>
            </div>
            <div *ngSwitchCase="'pasteMultipleInput'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-paste-multiple-input (valueList)="getMultiValue($event)" [label]="crud.key" [hint]="crud.hintLabel" [placeholder]="crud.placeholder" [values]="bin"
                [maxOptionsAllowed]="crud.maxOptionsAllowed"></app-paste-multiple-input>
            </div>
            <div *ngSwitchCase="'google'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-google-map [form]="form" [_google]="crud"></app-google-map>
            </div>
            <div *ngSwitchCase="'select'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-select [form]="form" [_select]="crud"></app-select>
            </div>
            <div *ngSwitchCase="'multichip'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-multi-chip [form]="form" [_multichip]="crud"></app-multi-chip>
            </div>
            <div *ngSwitchCase="'htmlEditor'">
              <label [ngClass]="{ no_pointer: !crud.heading }">{{
                crud.heading ? crud.heading : ''
                }}</label>
              <app-html-editor [form]="form" [_htmlEditor]="crud"></app-html-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
