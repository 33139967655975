import {
  Actions,
  Crud,
  ListInfo,
  MetaData,
  PageInfo,
  Search,
  View
} from '../../shared/interface/interface';
import { AppWebRoutes } from '../../core/constants/app-web-routes';
import { appConstants } from '../../core/constants/app-constant';

interface LocationPage {
  addMarker: PageInfo;
  addDropPoint: PageInfo;
}

interface Container {
  tableHeader?: ListInfo;
  metaData?: MetaData;
  search?: Search;
  crud?: Array<Crud>;
  actions?: Actions;
  view?: Array<View>;
}

interface MAPConstant {
  pages: LocationPage;
  serviceableAreaPinContainer: Container;
  reconsiderationContainer: Container;
  updateServiceableAreaStatusContainer: Container;
  dropPointContainer: Container;
  societyContainer: Container;
  serviceableContainer: Container;
  clusterContainer: Container;
}

export interface MarkerDetail {
  id?: object | string,
  type?: string,
  name?: string,
  ado?: number,
  areaSize?: number,
  longitude?: number,
  latitude?: number,
  bucketSize?: number,
  clusterId?: number
}

export interface Customer {
  id?: number,
  clusterGeofenceId?: number,
  longitude?: number,
  latitude?: number
}

export interface GeofenceAction {
  useAsOpsGeofenceFlag?: boolean,
  createOpsGeofenceFlag?: boolean,
  reconsiderationFlag?: boolean,
  editServiceableAreaStatusFlag?: boolean,
  makeLiveFlag?: boolean,
  enterHouseholdFlag?: boolean,
  editDetailsFlag?: boolean,
  splitFlag?: boolean,
  mergeFlag?: boolean,
  deleteFlag?: boolean,
}

export interface GofenceDetail {
  cityId?: number,
  dateOfDelivery?: string,
  id?: number | string,
  name?: string,
  numberOfHouseholds?: number,
  occupiedHousehold?: number,
  clusterName?: string,
  clusterGeofenceName?: string,
  hubName?: string,
  numberOfHousehold?: number,
  currentDayOrders?: number,
  clusterId?: number | string,
  clusterIdBackup?: number | string,
  deliveryStartDate?: string,
  hubId?:  number | string,
  averageDayOrders?: number,
  totalPaidCustomer?: number,
  totalRegisteredCustomer?: number,
  totalCustomer?: number,
  orderProjectionD0?: number,
  orderProjectionD30?: number,
  orderProjectionSteadyState?: number,
  polygon?: any,
  remark1?: string,
  remark2?: string,
  startDateOfDelivery?: object | string,
  status?: object | string | number,
  tgType?: string,
  type?: object | string,
  active?: object,
  household?: number,
  location?: string,
  typeKey?: string,
  OBJECTID?: object | string | number,
  housingType?: string,
  selectedGeofenceType?: string,
  typeName?: string,
  societyId?: object | string,
  geofenceId?: object | string,
  attributes?: object
}

export interface parentCluster {
  societies: Array<object>,
  droppoint: Array<object>,
  totalCustomer: number,
  totalRegisteredCustomer: number,
  totalPaidCustomer: number,
  averageDayOrders: number,
  currentDayOrders: number,
  loadingTime: number
}

export enum societyTypeEnum { TOWER_BASED = 1, ROW_BASED, LOCALITY }
export enum clusterGeofenceActionStatusEnum { DRAFT, READY, DEACTIVATED }
export enum serviceableAreaGeofenceActionStatusEnum { DRAFT, REVIEW, DONE, LIVE, RECONSIDER }
export enum societyGeofenceActionStatusEnum { DRAFT, REVIEW, LIVE }
export enum dropPointAddLocationEnum { DASHBOARD, CRUD }
export enum mapTabEnumGrowth { ALL, DRAFT, SENT, RECONSIDER, LIVE }
export enum mapTabEnumOps { ALL, IN_CONSIDERATION, DRAFT, LIVE }

export const mapConstant: MAPConstant = {
  pages: {
    addMarker: {
      label: '',
      privilege: 'insert_serviceable_area',
    },
    addDropPoint: {
      label: '',
      privilege: 'update_society',
    },
  },

  serviceableAreaPinContainer: {
    crud: [
      {
        key: 'type',
        placeholder: 'Pin Type',
        type: 'select',
        options: [
          { value: 1, placeholder: 'DC Point' },
          { value: 2, placeholder: 'Placemark' },
        ],
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'Pin Type is required' ],
        isReadOnly: false
      },
      {
        key: 'name',
        placeholder: 'Pin Name',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'Pin name is required' ],
        isReadOnly: false
      },
      {
        key: 'ado',
        placeholder: 'Average Daily order',
        type: 'text',
        validations: {
          pattern: appConstants.numberPattern,
        },
        defaultValue: '',
        validationMessages: [
          'Average Daily order should be number',
        ],
        isReadOnly: false
      },
      {
        key: 'areaSize',
        placeholder: 'Area Size(sq. ft.)',
        type: 'text',
        validations: {
          pattern: appConstants.numberPattern,
        },
        defaultValue: '',
        validationMessages: [
          'Area Size should be number',
        ],
        isReadOnly: false
      },
    ]
  },
  reconsiderationContainer:  {
    crud: [
      {
        key: 'remark1',
        placeholder: 'Remark',
        type: 'textarea',
        defaultValue: '',
        validations: { required: true, maxlength: 55 },
        validationMessages: [ 'Remark is required', 'Maxlength is 55' ],
        isReadOnly: false
      },
    ]
  },
  updateServiceableAreaStatusContainer:  {
    crud: [
       {
        key: 'status',
        placeholder: 'Area Status',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Full Live' }
        ],
        validations: { required: true },
        validationMessages: [ 'Area Status is required' ],
        defaultValue: '',
        isReadOnly: false
      },
    ]
  },
  dropPointContainer: {
    crud: [
      {
        key: 'name',
        placeholder: 'Drop Point Name',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'Drop Point name is required' ],
        isReadOnly: false
      },
      {
        key: 'bucketSize',
        placeholder: 'Bucket Size',
        type: 'text',
        validations: {
          required: true,
          min: 10,
          max: 30,
          pattern: appConstants.numberPattern,
        },
        defaultValue: '10',
        validationMessages: [
          'Bucket Size is required',
          'Minlength is 10',
          'Maxlength is 30',
          'Bucket Size should be number',
        ],
        isReadOnly: false
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.autocomplete,
        chip: true,
        optionsParams: { type: 2 },
        likeSearch: false,
        options: [],
        useTextArea: true,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
        validations: { required: true },
        validationMessages: [ 'Cluster Name is required' ],
      }
    ]
  },
  societyContainer: {
    crud: [
      {
        key: 'societyId',
        placeholder: 'Society',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.SOCIETIES.societies,
        optionsParams: { hasPolygon:0, resolve: 'geofence_map' },
        defaultValue: '',
        validations: { required: true },
        validationMessages: [ 'Society is required' ],
        isReadOnly: false,
        uiKey: 'name',
        apiKey: 'id',
        searchableDropDown: true,
        resolve: 'city',
        chipOptions: {},
        chip: false,
        module: 'crud',
        filterWithHubCity: 'hubId'
      },
      {},
      {}
    ],
  },
  serviceableContainer: {
    crud: [
      {
        key: 'type',
        placeholder: 'Type of House',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Individual House' },
          { value: 2, placeholder: 'High Rises/Society' },
          { value: 3, placeholder: 'Row House' },
          { value: 4, placeholder: 'Mixed Type' },
        ],
        validations: { required: true },
        validationMessages: [ 'Type of House is required' ],
        defaultValue: '',
        isReadOnly: false
      },
      {
        key: 'tgType',
        placeholder: 'Target Group',
        type: 'select',
        options: [
          { value: 'HIG', placeholder: 'HIG' },
          { value: 'MIG', placeholder: 'MIG' },
          { value: 'LIG', placeholder: 'LIG' },
        ],
        validations: { required: true },
        validationMessages: [ 'Target Group is required' ],
        defaultValue: '',
        isReadOnly: false
      },
      {
        key: 'numberOfHousehold',
        placeholder: 'Household Total',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'Household Total is required' ],
        isReadOnly: false
      },
      {
        key: 'occupiedHousehold',
        placeholder: 'Household Occupied',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'Household Occupied is required' ],
        isReadOnly: false
      },
      {
        key: 'orderProjectionD0',
        placeholder: 'ADO Day 0',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'ADO Day 0 is required' ],
        isReadOnly: false
      },
      {
        key: 'orderProjectionD30',
        placeholder: 'ADO Day 30',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'ADO Day 30 is required' ],
        isReadOnly: false
      },
      {
        key: 'orderProjectionSteadyState',
        placeholder: 'ADO Steady State',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'ADO Steady State is required' ],
        isReadOnly: false
      },
       {
        key: 'deliveryStartDate',
        placeholder: 'Suggested Date of Delivery',
        type: 'date',
        datePickerType: 'calendar',
        defaultValue: '',
        isReadOnly: false,
        validations: { required: true },
        validationMessages: [ 'Suggested Date of Delivery is required' ],
      },
      {
        key: 'remark1',
        placeholder: 'Remark 1',
        type: 'textarea',
        defaultValue: '',
        validations: { maxlength: 55 },
        validationMessages: [ 'Maxlength is 55' ],
        isReadOnly: false
      },
      {
        key: 'remark2',
        placeholder: 'Remark 2',
        type: 'textarea',
        defaultValue: '',
        validations: { maxlength: 55 },
        validationMessages: [ 'Maxlength is 55' ],
        isReadOnly: false
      },
    ],
  },
  clusterContainer: {
    crud: [
      {
        key: 'deliveryStartDate',
        placeholder: 'OPS Geofence Date of Delivery',
        type: 'date',
        datePickerType: 'calendar',
        defaultValue: '',
        isReadOnly: false,
        validations: { required: true },
        validationMessages: [ 'OPS Geofence Date of Delivery is required' ],
      },
      {},
      {},
      {},
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.autocomplete,
        chip: true,
        optionsParams: { type: 2 },
        likeSearch: false,
        options: [],
        chipOptions: {},
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
        module: 'crud',
        validations: { required: true },
        validationMessages: [ 'Cluster Name is required' ],
      },
      {},
      {},
      {},
    ],
  }
}

export const mapMeta =
{
  dashboardMapLoadModules: [
    'esri/map',
    "esri/config",
    "esri/graphic",
    "esri/toolbars/draw",
    "esri/toolbars/edit",
    "esri/symbols/SimpleFillSymbol",
    "esri/symbols/SimpleLineSymbol",
    "esri/Color",
    "esri/layers/FeatureLayer",
    "dojo/on",
    "esri/SpatialReference",
    "esri/geometry/webMercatorUtils",
    "esri/renderers/UniqueValueRenderer",
    "esri/geometry/Point",
    "esri/symbols/SimpleMarkerSymbol",
    "dojo/dom-style",
  ],
  crudMapLoadModules: [
    'esri/map',
    "esri/config",
    "esri/graphic",
    "esri/graphicsUtils",
    "esri/geometry/geometryEngine",
    "esri/toolbars/draw",
    "esri/symbols/SimpleFillSymbol",
    "esri/symbols/SimpleLineSymbol",
    "esri/Color",
    "esri/layers/FeatureLayer",
    "esri/layers/GraphicsLayer",
    "esri/layers/KMLLayer",
    "dojo/on",
    "dojo/_base/array",
    "esri/toolbars/edit",
    "esri/SpatialReference",
    "esri/geometry/webMercatorUtils",
    "esri/renderers/UniqueValueRenderer",
    "esri/geometry/Point",
    "esri/geometry/Polygon",
    "esri/symbols/SimpleMarkerSymbol",
    "dojo/dom-style"
  ],
  mapTabs: [
    {
      label: 'All',
      privilege: 'view_map'
    },
    {
      label: 'Draft',
      privilege: 'insert_serviceable_area'
    },
    {
      label: 'In Review',
      privilege: 'update_sa_done'
    },
    {
      label: 'Sent',
      privilege: 'update_serviceable_area'
    },
    {
      label: 'Reconsideration',
      privilege: 'update_serviceable_area'
    },
    {
      label: 'Live',
      privilege: 'update_serviceable_area'
    },
    {
      label: 'In Consideration',
      privilege: 'update_sa_live'
    },
    {
      label: 'In Draft',
      privilege: 'update_sa_live'
    },
    {
      label: 'Live Geofence',
      privilege: 'update_sa_live'
    }
  ],
  mapTabEnum: {
    ALL: 'All',
    DRAFT: 'Draft',
    IN_REVIEW: 'In Review',
    SENT: 'Sent',
    RECONSIDERATION: 'Reconsideration',
    LIVE: 'Live',
    IN_CONSIDERATION_GEOFENCE: 'In Consideration',
    DRAFT_GEOFENCE: 'In Draft',
    LIVE_GEOFENCE: 'Live Geofence',
  },
  mapGeofenceTabs: [
    {
      label: 'Create Geofence',
    },
    {
      label: 'Add Details',
    },
  ],
  viewAsList: [
    {
      label: 'Growth Geofence - Draft',
      resolve: 'Growth Geofence - Draft'
    },
    {
      label: 'Growth Geofence - Live',
      resolve: 'Growth Geofence - Live'
    },
    {
      label: 'Society Geofence - Draft',
      resolve: 'Society Geofence - Draft'
    },
    {
      label: 'Society Geofence - Active',
      resolve: 'Society Geofence - Active'
    },
    {
      label: 'OPS Geofence - Live',
      resolve: 'OPS Geofence - Live'
    },
    // {
    //   label: 'OPS Geofence - Deactivated',
    //   resolve: 'OPS Geofence - Deactivated'
    // },
    {
      label: 'Sent to Growth Manager',
      resolve: 'Sent to Growth Manager'
    },
    {
      label: 'Sent to Operation',
      resolve: 'Sent to Operation'
    },
    {
      label: 'Existing Hub',
      resolve: 'Existing Hub'
    },
    {
      label: 'Other Pins',
      resolve: 'Other Pins'
    },
    {
      label: 'Society Pins',
      resolve: 'Society Pins'
    },
    {
      label: 'Drop Point Pins',
      resolve: 'Drop Point Pins'
    }
  ],
  viewAsListOps: [
    {
      label: 'Growth Geofence - In Consideration',
      resolve: 'Growth Geofence - In Consideration'
    },
    {
      label: 'Growth Geofence - Live',
      resolve: 'Growth Geofence - Live'
    },
    {
      label: 'Society Geofence - Active',
      resolve: 'Society Geofence - Active'
    },
    {
      label: 'OPS Geofence - Live',
      resolve: 'OPS Geofence - Live'
    },
    {
      label: 'OPS Geofence - Deactivated',
      resolve: 'OPS Geofence - Deactivated'
    },
    {
      label: 'OPS Geofence - Draft',
      resolve: 'OPS Geofence - Draft'
    },
    {
      label: 'Existing Hub',
      resolve: 'Existing Hub'
    },
    {
      label: 'Other Pins',
      resolve: 'Other Pins'
    },
    {
      label: 'Society Pins',
      resolve: 'Society Pins'
    },
    {
      label: 'Drop Point Pins',
      resolve: 'Drop Point Pins'
    }
  ],
  headingAndLabels: {
    map: 'Map',
    addNewPlace: 'Add New Geofence',
    clusterDetail: 'Clusters Details',
    createCluster: 'Create Cluster',
    createAndMap:'Create and Map',
    cluster: 'Ops Geofence',
    editDetails: 'Edit Details',
    addDetails: 'Add Details',
    selectGeofenceType: 'What you want to draw?',
    selectedGeofenceDesc: 'What kind of Geofence you want to mark?',
    createDropPoint: 'Create Drop Points',
    markPin: 'Mark a Pin',
    movePin: 'Move a Pin',
    BasemapToggle: 'BasemapToggle',
    splitClass: "col-md-3",
    splitClassFull: "col-md-12",
    canEdit: "You can edit ",
    only: " only",
    saveAsDraft: "Save as Draft?",
    saveAsDraftDes: "This information will be saved to drafts tab.",
    saveAsDraftOpsDes: "This OPS Geofence will be saved to drafts tab.",
    submitGeofenceForReview: "Submit for Review?",
    submitGeofenceForReviewDes: "This information will be sent to Growth Manager for review & approval.",
    saveGeofenceInReview: "Save in Review?",
    saveGeofenceInReviewDes: "This information will be saved in review.",
    submitGeofenceToOps: "Submit To Ops?",
    submitGeofenceToOpsDes: "This information will be sent to Ops Team for review & approval.",
    makeGeofenceLive: "Make it Live?",
    makeGeofenceLiveDes: "This will make it Live.",
    sendForReconsideration: "Send for Reconsidertaion?",
    sendForReconsiderationDes: "This information will be sent for reconsideration.",
    saveAsFinal: "Save As Final?",
    saveAsFinalDes: "This OPS Geofence is already live for customers.",
    editServiceableAreaStatus: 'Edit Serviceable Area Status',
    submitForReconsideration: 'Submit for Reconsideration'
  },
  mapEvents: {
    CLICK: 'click',
    LOAD: 'load',
    DRAW_COMPLETE: 'draw-complete',
    DEACTIVATE: 'deactivate',
    GRAPHIC_MOVE_STOP: 'graphic-move-stop',
    MOUSE_OVER: 'mouse-over',
    MOUSE_OUT: 'mouse-out'
  },
  mapActions: {
    cancel: 'Cancel',
    continue: 'Continue',
    submit: 'Submit',
    saveAsDraft: 'Save as Draft',
    save: 'Save',
    submitToManager: 'Submit to Manager',
    submitToOps: 'Submit to Ops',
    submitForReconsider: 'Submit for Reconsider',
    makeLive: 'Make Live',
    saveAsFinal: 'Save as Final',
  },
  mapType: {
    STREETS: 'streets',
    SATELLITE: 'hybrid'
  },
  featureType: {
    MARKER: 'marker',
    GEOFENCE: 'geofence',
    POLYLINE: 'polyline'
  },
  featureAction: {
    MERGE: 'merge',
    SPLIT: 'split',
    MOVE: 'move',
    EDIT: 'edit',
    PATCH: 'patch',
    UPDATE: 'update',
    ADD: 'add',
    DELETE: 'delete',
  },
  featureActionIdentifier: {
    PARENT: 'parent',
    CHILD: 'child'
  },
  feature: {
    POINT: 'point',
    POLYGON: 'polygon',
    DC_POINT: 'dc_point',
    PLACEMARK: 'placemark',
    SOCIETY_MARKER: 'societyMarker',
    HUB: 'hub',
    SERVICEABLE_AREA_PIN: 'serviceableAreaPin',
    DROP_POINT: 'dropPoint',
    CUSTOMER: 'customer',
    ON_MOVE: 'on_move',
    ON_ADD: 'on_add',
  },
  viewAsListLabels: {
    GROWTH_GEOFENCE_DRAFT: 'Growth Geofence - Draft',
    GROWTH_GEOFENCE_IN_CONSIDERATION: 'Growth Geofence - In Consideration',
    GROWTH_GEOFENCE_LIVE: 'Growth Geofence - Live',
    SOCIETY_GEOFENCE_DRAFT: 'Society Geofence - Draft',
    SOCIETY_GEOFENCE_ACTIVE: 'Society Geofence - Active',
    OPS_GEOFENCE_LIVE: 'OPS Geofence - Live',
    // CHILD_CLUSTER_DEACTIVATED: 'Child Cluster - Deactivated',
    OPS_GEOFENCE_DRAFT: 'OPS Geofence - Draft',
    SENT_TO_GROWTH_MANAGER: 'Sent to Growth Manager',
    SENT_TO_OPERATION: 'Sent to Operation',
    EXISTING_HUBS: 'Existing Hub',
    OTHER_PINS: 'Other Pins',
    SOCIETY_PINS: 'Society Pins',
    DROP_POINT_PINS: 'Drop Point Pins'
  },
  geofenceTypes: [
    {
      key: 'serviceable',
      label: 'Serviceable Area'
    },
    {
      key: 'society',
      label: 'Society'
    }
  ],
  fetureEnum: {
    'serviceable': 1,
    'society': 2,
    'cluster': 3
  },
  serviceableAreaTypeEnum: {
    1: 'Individual Houses',
    2: 'Society',
    3: 'Row Housing',
    4: 'Mix',
  },
  serviceableAreaStatusEnum: {
    0: 'DRAFT',
    1: 'REVIEW',
    2: 'DONE',
    3: 'LIVE',
    4: 'RECONSIDER'
  },
  societyStatusEnum: {
    0: 'DRAFT',
    1: 'REVIEW',
    2: 'LIVE'
  },
  clusterStatusEnum: {
    0: 'DRAFT',
    1: 'READY',
    2: 'DEACTIVATED'
  },
  serviceableAreaApiEnum: {
    0: 'status/draft',
    1: 'status/review',
    2: 'status/done',
    3: 'status/live',
    4: 'status/reconsider'
  },
  societyApiEnum: {
    0: 'geofence-status/draft',
    1: 'geofence-status/review',
    2: 'geofence-status/live'
  },
  clusterCreationOption: {
    USE_AS_OPS_GEOFENCE: 'Use As OPS Geofence',
    CREATE_OPS_GEOFENCE: 'Create OPS Geofence',
    EDIT_OPS_GEOFENCE: 'Edit OPS Geofence',
    SPLIT_OPS_GEOFENCE: 'Split OPS Geofence',
    MERGE_OPS_GEOFENCE: 'Merge OPS Geofence'
  },
  geofenceTypesEnum: {
    SERVICEABLE_AREA: 'serviceable',
    SOCIETY: 'society',
    CLUSTER: 'cluster',
    NEW_CLUSTER: 'newCluster',
    DONE: 'done'
  },
  objectId: {
    SERVICEABLE_OBJECTID: 'SERVICEABLEOBJECTID',
    SOCIETY_OBJECTID: 'SOCIETYOBJECTID',
    CLUSTER_OBJECTID: 'CLUSTEROBJECTID',
    PIN_OBJECTID: 'PINOBJECTID'
  },
  localStorageKeys: {
    HUB_PINS: 'hubPins',
    SERVICEABLE_AREA_PINS: 'serviceableAreaPins',
    SOCIETY_PINS: 'societyPins',
    DROP_POINT_PINS: 'dropPointPins',
    GEOFENCE: 'geofence',
    SELECTABLE_PARENT_CLUSTER: 'selectableParentCluster',
    CACHE_EXPIRATION_DATE: 'cacheExpirationDate',
    RETRY_COUNT: 'retyCount'
  },
  helperInstructions: {
    EDIT_GEOFENCE_INSTRUCTION: 'Click done once you are done editing.',
    DRAW_GEOFENCE_INSTRUCTION: 'Geofence must have at least three vertices.',
    DRAW_GEOFENCE_OVERLAPPING_INSTRUCTION: 'You can\'t create geofence on other OPS Geofence.',
    DROPPOINT_CONTAINS_INSTRUCTION: 'Droppoint should be inside OPS Geofence.',
    EDIT_GEOFENCE_CUSTOMER_AFFECTING_INSTRUCTION: 'Customers are affecting due to this edit.',
    PICK_AND_DROP_INSTRUCTION: 'Pick Geofence and drop.',
    DRAW_LINE_INSTRUCTION: 'Now draw a line to cut the Geofence.',
    DRAW_LINE_ERROR_INSTRUCTION: 'Cutter line must have at least 2 points.',
    MERGING_INSTRUCTION: 'Click done once you are done selecting Child Geofences.',
    MERGING_SELECT_CHILD: 'Select Child Geofences to merge in Parent.',
    MERGING_CRITERIA_INSTRUCTION: 'Select Parent Geofence.',
    MERGING_DISTANCE_VALIDATION_INSTRUCTION: 'Geofence should overlap without any space in between for merging to work',
    SPLIT_INSTRUCTION_RESTRICT: 'You can only split Ops Geofence selected from info popup.',
    MERGE_INSTRUCTION_RESTRICT: 'You can only merge Ops Geofence selected from info popup.',
    SPLIT_INSTRUCTION: 'Click done to split Geofence.',
    SPLIT_ERROR_INSTRUCTION: 'Line didn\'t cross geofence properly.',
    SELECT_FOR_EDIT_INSTRUCTION: 'Select Geofence to Edit.',
    SELECT_FOR_MOVE_INSTRUCTION: 'Select Geofence to Move.',
    SELECT_FOR_SPLIT_INSTRUCTION: 'Select a Geofence to split.',
    HOUSEHOLD_ERROR_INSTRUCTION: 'Occupied Household should be less than Total Household',
    SELECT_GEOFENCE_INSTRUCTION: 'Click done once you are done selecting Geofence.',
  },
  esriGeometry: {
    ESRI_GEOMETRY_POINT: "esriGeometryPoint",
    ESRI_GEOMETRY_POLYGON: "esriGeometryPolygon"
  },
  ARC_GIS_INITIAL_PARAMS: {
    "objectIdFieldName": "OBJECTID",
    "globalIdFieldName": "",
    "geometryType": "esriGeometryPoint",
    "spatialReference": { "wkid": 4326 },
    "fields": [
      { "name": "OBJECTID", "type": "esriFieldTypeOID", "alias": "OBJECTID", "sqlType": "sqlTypeOther", "domain": null, "defaultValue": null },
      { "name": "NAME", "type": "esriFieldTypeString", "alias": "NAME", "sqlType": "sqlTypeOther", "length": 100, "domain": null, "defaultValue": null },
      { "name": "STATUS", "type": "esriFieldTypeString", "alias": "STATUS", "sqlType": "sqlTypeOther", "length": 100, "domain": null, "defaultValue": null },
      { "name": "REGION", "type": "esriFieldTypeString", "alias": "REGION", "sqlType": "sqlTypeOther", "length": 100, "domain": null, "defaultValue": null },
      { "name": "TYPE", "type": "esriFieldTypeString", "alias": "TYPE", "sqlType": "sqlTypeOther", "length": 100, "domain": null, "defaultValue": null }
    ],
    "features": []
  },
  markerIcons: {
    HUB_ICON: "M20 10C20 13.3092 18.3926 16.243 15.9159 18.0632C14.0339 19.4813 11.381 21.7185 10.7712 25.3195C10.7066 25.701 10.3869 25.997 10 25.997C9.61313 25.997 9.29336 25.701 9.22877 25.3195C8.61896 21.7185 5.96608 19.4813 4.08406 18.0632C1.60742 16.243 0 13.3092 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM6 7V6H14V7H6ZM14.5 10V11H14V14H13V11H11V14H6V11H5.5V10L6 7.5H14L14.5 10ZM7 13H10V11H7V13Z",
    DROP_POINT_ICON: "M20 10C20 13.3092 18.3926 16.243 15.9159 18.0632C14.0339 19.4813 11.381 21.7185 10.7712 25.3195C10.7066 25.701 10.3869 25.997 10 25.997C9.61313 25.997 9.29336 25.701 9.22877 25.3195C8.61896 21.7185 5.96608 19.4813 4.08406 18.0632C1.60742 16.243 0 13.3092 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.9504 7H5.39999V10.995H10.9504V7ZM7.93927 12.0789C7.51845 12.0789 7.17616 12.4223 7.17616 12.8444C7.17616 13.2666 7.51852 13.6101 7.93927 13.6101C8.36002 13.6101 8.70238 13.2666 8.70238 12.8444C8.70246 12.4223 8.36009 12.0789 7.93927 12.0789ZM12.3328 12.8472C12.3328 12.4251 12.6751 12.0816 13.0959 12.0816C13.5167 12.0816 13.859 12.4251 13.859 12.8472C13.859 13.2694 13.5167 13.6129 13.0959 13.6129C12.6752 13.6129 12.3328 13.2694 12.3328 12.8472ZM5.39999 11.3176V12.6756H6.86836C6.94974 12.156 7.40047 11.7563 7.93927 11.7563C8.47807 11.7563 8.92873 12.156 9.01018 12.6756H10.9503V11.3176H5.39999ZM12.8493 8.14163H11.2717V12.6755H12.0256C12.1083 12.1575 12.5589 11.759 13.0959 11.759C13.6331 11.759 14.0836 12.1575 14.1663 12.6755H14.7182V10.1323L12.8493 8.14163Z",
    DC_POINT_ICON: "M20 10C20 13.3092 18.3926 16.243 15.9159 18.0632C14.0339 19.4813 11.381 21.7185 10.7712 25.3195C10.7066 25.701 10.3869 25.997 10 25.997C9.61313 25.997 9.29337 25.701 9.22878 25.3195C8.61896 21.7185 5.96608 19.4813 4.08406 18.0632C1.60742 16.243 0 13.3092 0 10C0 4.47716 4.47716 0 10 0C15.5228 0 20 4.47716 20 10ZM11.2 6L11.3867 6.94118H14V11.6471H10.7333L10.5467 10.7059H7.93334V14H7V6H11.2Z",
    PLACEMARK_ICON: "M20 10C20 13.3092 18.3926 16.243 15.9159 18.0632C14.0339 19.4813 11.381 21.7185 10.7712 25.3195C10.7066 25.701 10.3869 25.997 10 25.997C9.61313 25.997 9.29336 25.701 9.22877 25.3195C8.61896 21.7185 5.96608 19.4813 4.08406 18.0632C1.60742 16.243 0 13.3092 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM12.781 14L10 12.2332L7.219 14L7.957 10.67L5.5 8.42947L8.7355 8.14053L10 5L11.2645 8.14053L14.5 8.42947L12.043 10.67L12.781 14Z",
    SOCIETY_MARKER_ICON: "M20 10C20 13.3092 18.3926 16.243 15.9159 18.0632C14.0339 19.4813 11.381 21.7185 10.7712 25.3195C10.7066 25.701 10.3869 25.997 10 25.997C9.61313 25.997 9.29336 25.701 9.22877 25.3195C8.61896 21.7185 5.96608 19.4813 4.08406 18.0632C1.60742 16.243 0 13.3092 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM12.9752 5V12.8261H14V14H6V12.8261H7.02482V5H12.9752ZM11.6263 5.78272H10.4759V6.95662H11.6263V5.78272ZM11.6263 8.13052H10.4759V9.30441H11.6263V8.13052ZM8.17518 9.30441H9.32555V8.13052H8.17518V9.30441ZM8.17518 6.95662H9.32555V5.78272H8.17518V6.95662ZM9.2 10.4783V12.8261H10.8V10.4783H9.2Z",
    ON_MOVE_ICON: "M0 11C0 7.22116 1.31001 3.74829 3.50065 1.01075L4.35485 1.86494C2.37861 4.3807 1.2 7.55271 1.2 11C1.2 14.3443 2.30926 17.4296 4.17992 19.9079L3.32374 20.7641C1.23966 18.0624 0 14.676 0 11ZM28.3188 0.789235L27.4663 1.64175C29.5501 4.19185 30.8 7.44996 30.8 11C30.8 14.4473 29.6214 17.6193 27.6452 20.135L28.4994 20.9892C30.69 18.2517 32 14.7788 32 11C32 7.11848 30.6178 3.55979 28.3188 0.789235ZM3 11C3 8.05014 3.98251 5.32975 5.63813 3.14823L6.49547 4.00556C5.05267 5.96286 4.2 8.38185 4.2 11C4.2 13.5148 4.98667 15.8458 6.32736 17.7605L5.46691 18.6209C3.91499 16.4797 3 13.8467 3 11ZM26.1862 2.92191L25.3315 3.77662C26.8789 5.77276 27.8 8.27881 27.8 11C27.8 13.6181 26.9473 16.0371 25.5046 17.9944L26.3619 18.8517C28.0175 16.6702 29 13.9498 29 11C29 7.94723 27.9478 5.14023 26.1862 2.92191ZM26 11C26 14.3092 24.3926 17.243 21.9159 19.0632C20.0339 20.4813 17.381 22.7185 16.7712 26.3195C16.7066 26.701 16.3869 26.997 16 26.997C15.6131 26.997 15.2934 26.701 15.2288 26.3195C14.619 22.7185 11.9661 20.4813 10.0841 19.0632C7.60741 17.243 6 14.3092 6 11C6 5.47716 10.4771 1 16 1C21.5229 1 26 5.47716 26 11ZM19.5 10.5C19.5 12.433 17.933 14 16 14C14.067 14 12.5 12.433 12.5 10.5C12.5 8.567 14.067 7 16 7C17.933 7 19.5 8.567 19.5 10.5Z",
    ON_ADD_ICON: "M15.9159 18.0632C18.3926 16.243 20 13.3092 20 10C20 4.47717 15.5229 0 10 0C4.47715 0 0 4.47717 0 10C0 13.3092 1.60741 16.243 4.08405 18.0632C5.96608 19.4813 8.61896 21.7185 9.22877 25.3195C9.29337 25.701 9.61314 25.9969 10 25.9969C10.3869 25.9969 10.7066 25.701 10.7712 25.3195C11.381 21.7185 14.0339 19.4813 15.9159 18.0632ZM10 13C11.933 13 13.5 11.433 13.5 9.5C13.5 7.56702 11.933 6 10 6C8.067 6 6.5 7.56702 6.5 9.5C6.5 11.433 8.067 13 10 13Z",
    CUSTOMERS_ICON: "M20 10C20 13.3092 18.3926 16.243 15.9159 18.0632C14.0339 19.4813 11.381 21.7185 10.7712 25.3195C10.7066 25.701 10.3869 25.997 10 25.997C9.61313 25.997 9.29336 25.701 9.22877 25.3195C8.61896 21.7185 5.96608 19.4813 4.08406 18.0632C1.60742 16.243 0 13.3092 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11.0153 8.3899C10.7109 8.59326 10.353 8.7018 9.98699 8.7018C9.49625 8.70108 9.02582 8.50578 8.67885 8.15873C8.33188 7.81167 8.1367 7.34119 8.13609 6.85045C8.13618 6.48439 8.24481 6.12658 8.44825 5.82226C8.65168 5.51795 8.94078 5.28078 9.279 5.14076C9.61722 5.00074 9.98936 4.96415 10.3484 5.03562C10.7074 5.10709 11.0371 5.28341 11.2959 5.54228C11.5547 5.80115 11.731 6.13095 11.8024 6.48998C11.8737 6.849 11.8371 7.22114 11.697 7.55932C11.5569 7.8975 11.3196 8.18654 11.0153 8.3899ZM11.9768 9.36981C12.2768 9.49436 12.5492 9.67703 12.7784 9.90731C13.0083 10.1366 13.1906 10.409 13.3148 10.709C13.439 11.0089 13.5027 11.3305 13.5022 11.6551V13.3701C12.5152 13.766 11.3006 13.9995 9.98699 14C8.67339 14.0005 7.45877 13.766 6.47174 13.3701V11.6533C6.47168 11.3287 6.53557 11.0073 6.65976 10.7074C6.78396 10.4075 6.96601 10.135 7.19554 9.90544C7.42507 9.67591 7.69757 9.49385 7.99747 9.36966C8.29738 9.24546 8.61881 9.18157 8.94341 9.18163H11.0301C11.355 9.18132 11.6767 9.24527 11.9768 9.36981Z",
    DEFAULT_ICON: "M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
  },
  apiResolve : {
    PARENT_CLUSTER_NAME: '?resolve=clusterName'
  },
  privilage: {
    VIEW_HUB: 'view_hub',
    VIEW_SERVICEABLE_AREA: 'view_serviceable_area',
    UPDATE_SERVICEABLE_AREA: 'update_serviceable_area',
    DELETE_SERVICEABLE_AREA: 'delete_serviceable_area',
    INSERT_SERVICEABLE_AREA: 'insert_serviceable_area',
    INSERT_SOCIETY: 'insert_society',
    UPDATE_SERVICEABLE_AREA_DONE: 'update_sa_done',
    UPDATE_SERVICEABLE_AREA_LIVE: 'update_sa_live',
    VIEW_SOCIETY_GEOFENE: 'view_society',
    DELETE_SOCIETY_GEOFENE: 'delete_sg',
    UPDATE_SOCIETY_GEOFENE: 'update_sg',
    VIEW_DROP_POINT: 'view_droppoint',
    UPDATE_DROP_POINT: 'update_droppoint',
    DELETE_DROP_POINT: 'delete_droppoint',
    INSERT_DROP_POINT: 'create_droppoint',
    VIEW_CLUSTER_GEOFENCE: 'view_cluster_geofence',
    UPDATE_CLUSTER_GEOFENCE: 'update_cluster_geofence',
    DELETE_CLUSTER_GEOFENCE: 'delete_cluster_geofence',
    INSERT_CLUSTER_GEOFENCE: 'create_cluster_geofence',
    MERGE_CLUSTER_GEOFENCE: 'merge_cluster',
    UPDATE_HOUSEHOLD : 'update_household'
  }
};
