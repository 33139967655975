import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { inputComponentActions } from '../../constants/shared-constant';
import { appConstants } from '../../../core/constants/app-constant';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: [ './input.component.scss' ]
})
export class InputComponent implements OnInit, OnDestroy {
  @Input()
  _input;
  @Input()
  form: FormGroup;
  @ViewChild('input', { static: true })
  public input: ElementRef;

  constructor (private renderer: Renderer2, private dataService: DataService) { }

  ngOnInit () {
    if (this._input.event) {
      this.renderer.listen(
        this.input.nativeElement,
        this._input.event,
        this.callBack
      );
    }
  }
  callBack = () => {
    switch (this._input.action) {
      case inputComponentActions.lowercase_with_delimiter_underscore:
        if (
          this.form.controls[ this._input.key ].value &&
          this.form.controls[ this._input.key ].value.length > 0
        ) {
          this.form.controls[ this._input.target ].patchValue(
            this.form.controls[ this._input.key ].value
              .toLowerCase()
              .replace(/\s+/g, '_')
          );
        }
        break;
      case inputComponentActions.addition_of_fields:
        if (
          this.form.controls[ this._input.key ].value &&
          Number(this.form.controls[ this._input.key ].value) < 15
        ) {
          this.form.controls[ this._input.key ].patchValue(
            Number(this.form.controls[ this._input.key ].value)
          );
          this.form.controls[ this._input.dependentWith ].patchValue(
            Number(this.form.controls[ this._input.key ].value)
          );
          this.form.controls[ this._input.target ].patchValue(
            this.form.controls[ this._input.key ].value +
            this.form.controls[ this._input.key ].value
          );
        } else {
          this.form.controls[ this._input.key ].patchValue(0);
          this.form.controls[ this._input.dependentWith ].patchValue(0);
          this.form.controls[ this._input.target ].patchValue(0);
        }
        break;
      case inputComponentActions.lower_case:
        if (
          this.form.controls[ this._input.key ].value &&
          this.form.controls[ this._input.key ].value.length > 0
        ) {
          this.form.controls[ this._input.target ].patchValue(
            this.form.controls[ this._input.key ].value.toLowerCase()
          );
        }
        break;
      case inputComponentActions.phone_validation:
        if (
          this.form.controls[ this._input.key ].value.length !== 10 &&
          !appConstants.phonePattern.test(
            this.form.controls[ this._input.key ].value
          )
        ) {
          this.form.controls[ this._input.key ].patchValue('');
        } else {
          this.settingChipValue();
        }
        break;
      case inputComponentActions.email_validation:
        if (
          !appConstants.emailPattern.test(
            this.form.controls[ this._input.key ].value
          )
        ) {
          this.form.controls[ this._input.key ].patchValue('');
        } else {
          this.settingChipValue();
        }
        break;
      case inputComponentActions.number_validation:
        if (
          !appConstants.positiveNonDecimalNumber.test(
            this.form.controls[ this._input.key ].value
          )
        ) {
          this.form.controls[ this._input.key ].patchValue('');
        } else {
          this.settingChipValue();
        }
        break;
      case inputComponentActions.multiplication_of_fields:
        if (
          this.form.controls[ this._input.key ].value &&
          this.form.controls[ this._input.dependentWith ].value
        ) {
          this.form.controls[ this._input.target[ 0 ] ].patchValue(
            +this.form.controls[ this._input.key ].value *
            +this.form.controls[ this._input.dependentWith ].value
          );
        }
        if (this._input.resolve) {
          this.form.controls[ this._input.target[ 1 ] ].patchValue(
            +this.form.controls[ this._input.resolve ].value -
            +this.form.controls[ this._input.key ].value
          );
          if ((+this.form.controls[ this._input.resolve ].value -
            +this.form.controls[ this._input.key ].value) == 0) {
            this.form.controls[ this._input.target[ 2 ] ].clearValidators();
            this.form.controls[ this._input.target[ 2 ] ].updateValueAndValidity();
          } else {
            this.form.controls[ this._input.target[ 2 ] ].setValidators([ Validators.required ]);
            this.form.controls[ this._input.target[ 2 ] ].updateValueAndValidity();

          }
        }
        break;
      default:
        if (
          this._input.module &&
          this._input.module === inputComponentActions.search
        ) {
          this.settingChipValue();
        }
        break;
    }
  };

  settingChipValue () {
    this.dataService.setSearchData(
      this._input,
      this.form.controls[ this._input.key ].value,
      this._input.key,
      this.form.controls[ this._input.key ].value
    );
    this._input.chipOptions = {
      key: this._input.key,
      value: this.form.controls[ this._input.key ].value,
      uiKey: this._input.uiKey,
      apiKey: this._input.apiKey
    };
    this.form.controls[ this._input.key ].patchValue('');
  }

  ngOnDestroy () {
    if (
      this._input &&
      this._input.module &&
      this._input.module === inputComponentActions.search
    ) {
      this._input.chipOptions = {};
      this._input.options = [];
    }
  }
}
