import {
  Actions,
  Crud,
  ListInfo,
  MetaData,
  PageInfo,
  Search,
  View,
} from '../../shared/interface/interface';

import { AppWebRoutes } from '../../core/constants/app-web-routes';
import { appConstants } from '../../core/constants/app-constant';
import { MatTableDataSource } from '@angular/material/table';

export const MAX_EDIT_COUNT_FOR_DAILY_REPORT: number = 2;

export enum CLUSTER_TYPE {
  NORMAL = 1,
  GEOFENCE = 2,
}

interface PPDConstant {
  pages: PPDPage;
  deliveryContainer: Container;
  deliveryTowerContainer: Container;
  deliveryBucketContainer: Container;
  inventoryContainer: Container;
  dailyIncentiveContainer: Container;
  monthlyIncentiveContainer: Container;
  deliverySocietyContainer: Container;
  deliveryDroppointContainer: Container;
  productReturnContainer: Container;
  productReturnRemarksContainer: Container;
  deliveryReportContainer: Container;
  vehicleLoadingContainer: Container;
  clusterVehicleContainer: Container;
  vehiclesContainer: Container;
  uploadClusterVehiclesContainer: Container;
  createEditVehicleContainer: Container;
  clusterVehicleInCSVContainer: Container;
  findProductContainer: Container;
  generateClusterContainer: Container;
  pickIssueContainer: Container;
  packIssueContainer: Container;
  packStatusContainer: Container;
  manPowerUploadContainer: Container;
  featureActivationContainer: Container;
  featureMetaActivationContainer: Container;
  etaClusterContainer: Container;
  genrateOtpContainer: Container;
  clusterTargetContainer: Container;
  clusterTargetUploadContainer: Container;
  milestoneLevelDelayLogsContainer: Container;
  clusterLevelDelayLogsContainer: Container;
  deliveryTargetContainer: Container;
  anfContainer: Container;
  ppsStickerContainer: Container;
  orderRoutingContainer: Container;
  featureContainer: Container;
  featureMetaContainer: Container;
  transportModuleContainer: Container;
  vendorDetailsCrudContainer: Container;
  vehicleDetailsCrudContainer: Container;
  dailyReportContainer: Container;
  consolidatedReportContainer: Container;
  vendorReportContainer: Container;
  vendorVehicleListModuleContainer: Container;
  dailyReportVechileInOutCrudContainer: Container;
  pickingRollbackContainer: Container;
  nonConsumableStickerContainer: Container;
  eWayContainer: Container;
  naStickerContainer: Container;
  naStickerGenerateContainer: Container;
  ppdBannerContainer: Container;
}

interface PPDPage {
  ppd: PageInfo;
  viewPPDDetails: PageInfo;
  addVehicle: PageInfo;
  editVehicle: PageInfo;
  generateCluster: PageInfo;
  editInventory: PageInfo;
  editClusterTarget: PageInfo;
  uploadClusterTarget: PageInfo;
  editProductReturn: PageInfo;
  addProductReturn: PageInfo;
  anf: PageInfo;
  ppsSticker: PageInfo;
  orderRouting: PageInfo;
  featureView: PageInfo;
  transportModule: PageInfo;
  addVendor: PageInfo;
  editVendor: PageInfo;
  dailyReport: PageInfo;
  editInOutVechicleIndailyReport: PageInfo;
  consolidatedReport: PageInfo;
  vendorReport: PageInfo;
  addVendorVehicle: PageInfo;
  editVendorVehicle: PageInfo;
  pickingRollback: PageInfo;
  nonConsumableSticker: PageInfo;
  eway: PageInfo;
  naSticker: PageInfo;
  naStickerGenerate: PageInfo;
  ppdBanner: PageInfo;
  addPpdBanner: PageInfo;
  editPpdBanner: PageInfo;
}

interface Container {
  tableHeader?: ListInfo;
  metaData?: MetaData;
  search?: Search;
  crud?: Array<Crud>;
  actions?: Actions;
  view?: Array<View>;
}

export interface PPDView {
  placeHolder: string;
  value: string;
}

export interface ListViewData {
  metaData?: MetaData;
  tableHeaders?: ListInfo;
  listData?: MatTableDataSource<object>;
  actions?: Actions;
  search?: Search;
  total?: number;
}

export const pickingRollbackAlert = {
  message: 'Please select users emails for rollback',
  title: 'Confirm !!',
  why: 'society',
};

export const ppdConstants: PPDConstant = {
  pages: {
    ppd: { label: 'PPD', path: '/ppd' },
    viewPPDDetails: { label: 'PPD Details', path: '/ppd' },
    editVehicle: {
      label: 'Edit Vehicle',
      path: '/ppd/vehicles',
      privilege: 'update_vehicle',
    },
    generateCluster: {
      label: '',
      path: '/ppd/vehicles',
      privilege: 'view_order',
    },
    orderRouting: {
      label: '',
      path: '/pps-sticker/order-routing',
      privilege: 'view_order',
    },
    addVehicle: {
      label: 'Create Vehicle',
      path: '/ppd/vehicles',
      privilege: 'insert_vehicle',
    },
    editInventory: {
      label: 'Update Inventory',
      path: '/ppd/inventory',
      privilege: 'update_inventory',
    },
    editClusterTarget: {
      label: 'Edit',
      path: '/ppd/supply-chain',
      privilege: 'update_inventory',
    },
    uploadClusterTarget: {
      label: 'Upload Cluster Target',
      path: '/ppd/supply-chain/upload',
      privilege: 'create_cluster_milestone',
    },
    addProductReturn: {
      label: 'Add Product Return',
      path: '/ppd/return',
      privilege: 'view_order',
    },
    editProductReturn: {
      label: 'Return Item Details',
      path: '/ppd/return',
      privilege: 'view_order',
    },
    anf: { label: 'ANF', path: '/ppd/anf' },
    ppsSticker: {
      label: 'PPS Sticker',
      path: '/ppd/pps-sticker',
      privilege: 'view_order',
    },
    featureView: {
      label: 'FEATURE',
      path: '/ppd/feature',
    },
    transportModule: {
      label: 'Transport',
      path: '/ppd/transport',
    },
    addVendor: {
      label: 'Add Vendor Details',
      path: AppWebRoutes.PPD.vendorDetails,
      privilege: 'create_transport_vendor',
    },
    editVendor: {
      label: 'Edit Vendor Details',
      path: AppWebRoutes.PPD.vendorDetails,
      privilege: 'update_transport_vendor',
    },
    dailyReport: {
      label: 'Daily Report',
      path: 'ppd/transport/daily-report',
      privilege: 'view_transport_report',
    },
    editInOutVechicleIndailyReport: {
      label: 'Edit Vendor Vehicle Details',
      path: AppWebRoutes.PPD.editVechileInOutIndailyReport,
      privilege: 'edit_transport_report',
      backUrl: '/ppd/transport/daily-report',
    },
    consolidatedReport: {
      label: 'Generate Consolidated Report',
      path: 'ppd/transport/consolidate-report',
      privilege: 'view_transport_report',
    },
    vendorReport: {
      label: 'Generate Vendor Report',
      path: 'ppd/transport/vendor-report',
      privilege: 'view_transport_report',
    },
    addVendorVehicle: {
      label: 'Add Vendor Vehicle Details',
      path: AppWebRoutes.PPD.vendorVehicles,
      privilege: 'create_transport_vendor_vehicle',
      backUrl: '/ppd/transport/add/',
    },
    editVendorVehicle: {
      label: 'Edit Vendor Vehicle Details',
      path: AppWebRoutes.PPD.vendorVehicles,
      privilege: 'update_transport_vendor_vehicle',
      backUrl: '/ppd/transport/add/',
    },
    pickingRollback: {
      label: 'Picking Rollback',
      path: '/ppd/picking-rollback',
      privilege: 'delete_inventory_out',
    },
    nonConsumableSticker: {
      label: 'Delivery Without Consumable Stickers',
      path: '/pps-sticker/non-consumable-sticker',
      privilege: 'view_order',
    },
    eway: {
      label: 'E waybill',
      path: '/ppd/eway',
      privilege: 'view_order',
    },
    naSticker: {
      label: 'NA Stickers Download',
      path: '/pps-sticker/na-sticker',
      privilege: 'view_order',
    },
    naStickerGenerate: {
      label: 'NA Stickers Generate',
      privilege: 'view_order',
    },
    ppdBanner: {
      label: 'PPD Banner',
      path: 'ppd-banner',
      privilege: 'view_ppd_banner',
    },
    addPpdBanner: {
      label: 'Add PPD Banners',
      path: 'ppd-banner',
      privilege: 'update_ppd_banner',
    },
    editPpdBanner: {
      label: 'Edit PPD Banners',
      path: 'ppd-banner',
      privilege: 'update_ppd_banner',
    },
  },
  findProductContainer: {
    tableHeader: [
      {
        header: 'Order ID',
        key: 'orderId',
        linkId: 'orderId',
        navigateTo: 'order/view',
        whatToDo: 'navigate',
        sortBy: true,
      },
      { header: 'Date', key: 'date', sortBy: true },
      { header: 'Society', key: 'societyName' },
      { header: 'Tower', key: 'addressTower' },
      { header: 'Unit', key: 'unit' },
      {
        header: 'Product',
        key: 'productName',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: appConstants.productCustomString,
        },
        linkId: 'productId',
        navigateTo: 'catalog/hub',
        whatToDo: 'navigate',
      },
      { header: 'Weight', key: 'productWeight' },
      { header: 'QTY', key: 'productQuantity', sortBy: true },
      { header: 'MRP (₹)', key: 'mrp', sortBy: true },
    ],
    metaData: {
      header: 'Products',
      canEdit: false,
      canDelete: false,
      showRefresh: true,
      viewExport: AppWebRoutes.PPD.exportFindProduct,
      viewPrivilege: 'view_order',
    },
    search: [
      {
        key: 'brandId',
        placeholder: 'Brand',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.BRANDS.autocomplete,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
      },
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'date',
        apiKey: 'date',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
      {
        key: 'productId',
        placeholder: 'Product',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CATALOG.productsForHubAutocomplete,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'productName',
        subKey: 'name',
        apiKey: 'productId',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: appConstants.productCustomStringForSearch,
        },
        hubIdRequired: true,
      },
    ],
  },
  productReturnContainer: {
    tableHeader: [
      { header: 'Order Date', key: 'orderDate' },
      {
        header: 'Pickup Date',
        key: 'date',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: (row) => {
            return `${row.date ? row.date : 'NA'}`;
          },
        },
      },
      {
        header: 'Product',
        key: 'productName',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: (row) => {
            if (row.productId) {
              return `${row.productName} (${row.productId})`;
            } else {
              return `NA`;
            }
          },
        },
      },
      {
        header: 'Mrp',
        key: 'mrp',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: (row) => {
            return `${row.mrp ? row.mrp : 'NA'}`;
          },
        },
      },
      {
        header: 'Weight',
        key: 'weight',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: (row) => {
            return `${row.weight ? row.weight : 'NA'}`;
          },
        },
      },
      {
        header: 'Quantity',
        key: 'quantity',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: (row) => {
            return `${row.quantity ? row.quantity : 'NA'}`;
          },
        },
      },
      { header: 'DE Accepted Quantity', key: 'deAcceptQty' },
      { header: 'Hub Accepted Quantity', key: 'hubAcceptQty' },
      { header: 'Usable Quantity', key: 'usableQty' },
      { header: 'Order Id', key: 'orderId' },
      { header: 'Issue Main', key: 'issueMain' },
      {
        header: 'Is Wrong Order',
        key: 'isWrong',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: (row) => {
            return `${row.isWrong ? 'Yes' : 'No'}`;
          },
        },
      },
      { header: 'Customer Id', key: 'customerId' },
      { header: 'Society Name', key: 'societyName' },
      { header: 'Attempt Count', key: 'attemptCount' },
    ],
    actions: [
      {
        icon: 'edit_product_return',
        label: 'Edit Product Return',
        color: 'primary',
        privilege: 'view_order',
        getIcon: function (row) {
          return `${'edit'}`;
        },
      },
    ],
    metaData: {
      header: 'Product Return',
      showCreate: false,
      canEdit: true,
      editRoute: '/ppd/return/edit',
      canDelete: true,
      showRefresh: true,
      viewPrivilege: 'view_order',
    },
    crud: [
      {
        key: 'acceptQty',
        placeholder: 'Accepted Quantity',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.positiveNonDecimalNumber,
        },
        validationMessages: [
          'Accepted Quantity is required',
          'Accepted Quantity is a positive number',
        ],
        module: 'crud',
      },
      {
        key: 'usableQty',
        placeholder: 'Usable Quantity',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.positiveNonDecimalNumber,
        },
        validationMessages: [
          'Usable quantity is required',
          'Usable quantity is a positive number',
        ],
        module: 'crud',
      },
      {
        key: 'productId',
        placeholder: 'Product Name (Weight) (Mrp)',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CATALOG.productsForHubAutocomplete,
        validations: { required: true },
        hubIdRequired: true,
        validationMessages: ['Product is Required'],
        defaultValue: '',
        hideField: false,
        uiKey: 'productName',
        needUIValue: true,
        apiKey: 'productId',
        filterWithHubCity: 'hubId',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: (row) => {
            return `${row.productName} ( ${row.weight} ) ( Rs ${row.mrp} )`;
          },
        },
        module: 'crud',
      },
      {
        key: 'hubId',
        placeholder: 'Hub',
        type: 'text',
        hideField: true,
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Hub is required'],
        isReadOnly: false,
        module: 'crud',
        disabled: true,
      },
    ],
    search: [
      {
        key: 'startDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        defaultValue: [appConstants.getDateByNumberOfDays(0), new Date()],
        dateRangeKey: 'endDate',
        maxDate: new Date(),
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.autocomplete,
        chip: true,
        likeSearch: false,
        options: [],
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
      },
      {
        key: 'productId',
        placeholder: 'Product Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CATALOG.productsForHubAutocomplete,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'productName',
        subKey: 'name',
        apiKey: 'productId',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: appConstants.productCustomStringForSearch,
        },
        hubIdRequired: true,
      },
      {
        key: 'id',
        placeholder: 'Product Id',
        type: 'input',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'idName',
        apiKey: 'id',
        module: 'search',
        event: 'blur',
        hintLabel: 'Only Number Allowed',
        action: 'number_validation',
        defaultValue: '',
      },
      {
        key: 'phoneNo',
        placeholder: 'DE Mobile No',
        type: 'input',
        defaultValue: '',
        module: 'search',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'phoneNo',
        apiKey: 'phoneNo',
        event: 'blur',
        hintLabel: 'Only Number Allowed',
        action: 'number_validation',
      },
      {
        key: 'orderId',
        placeholder: 'OrderId',
        type: 'input',
        defaultValue: '',
        module: 'search',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'orderId',
        apiKey: 'orderId',
        event: 'blur',
        hintLabel: 'Only Number Allowed',
        action: 'number_validation',
      },
      {
        key: 'acceptedBy',
        placeholder: 'Accepted By',
        type: 'input',
        defaultValue: '',
        module: 'search',
        hideField: true,
        uiKey: 'acceptedBy',
        apiKey: 'acceptedBy',
      },
    ],
  },
  productReturnRemarksContainer: {
    tableHeader: [],
    crud: [
      {
        key: 'remarks',
        placeholder: '',
        type: 'radio',
        radios: [
          { value: 'expired', placeholder: 'Expired' },
          { value: 'damaged', placeholder: 'Damaged/Leaked' },
        ],
        validations: { required: true },
        defaultValue: 'expired',
        validationMessages: ['Option Required'],
        isReadOnly: false,
      },
    ],
  },
  manPowerUploadContainer: {
    tableHeader: [
      {
        header: 'Order Date',
        key: 'orderDate',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      { header: 'Hub', key: 'hubId', subkey: 'value', whatToDo: 'subkey' },
      {
        header: 'Cluster Id',
        key: 'clusterId',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Target Delivery',
        key: 'targetDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Full Timer Incentive',
        key: 'incentiveFullAmount',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Part Timer Incentive',
        key: 'incentivePartAmount',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Extra Incentive',
        key: 'extraAmount',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    crud: [
      {
        key: 'hubId',
        placeholder: 'Hub',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.HUBS.hubs,
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Hub is required'],
        isReadOnly: false,
        uiKey: 'name',
        apiKey: 'id',
        searchableDropDown: true,
        filterWithHubCity: 'cityId',
        chipOptions: {},
        chip: false,
        resolve: 'hub',
        module: 'crud',
      },
      {
        key: 'orderDate',
        placeholder: 'Order Date',
        type: 'date',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Order Date is required'],
        datePickerType: 'calendar',
        isReadOnly: false,
      },
      {
        key: 'file',
        placeholder: 'File Upload',
        type: 'file',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['File is required'],
        accept: '.csv',
        isReadOnly: false,
        isEditMode: false,
      },
    ],
    metaData: {
      header: 'List of team cluster Upload Data',
      hidePagination: true,
      hideSearch: true,
      hidelocalSearch: true,
      viewPrivilege: 'upload_incentive',
    },
  },
  generateClusterContainer: {
    crud: [
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        defaultValue: '',
        datePickerType: 'calendar',
        isReadOnly: false,
        validations: { required: true },
        validationMessages: ['Date is required'],
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.clusters,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        uiKey: 'name',
        apiKey: 'id',
        filterWithHubCity: 'hubId',
        validations: { required: true },
        validationMessages: ['Cluster is required'],
      },
    ],
  },
  orderRoutingContainer: {
    crud: [
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        defaultValue: '',
        datePickerType: 'calendar',
        minDate: appConstants.getDateByNumberOfDays(-1),
        isReadOnly: false,
        validations: { required: true },
        validationMessages: ['Date is required'],
      },
      {
        key: 'teamId',
        placeholder: 'Team Name',
        type: 'autocomplete',
        validations: { required: true },
        validationMessages: ['Team is required'],
        apiEndPoint: AppWebRoutes.PPD.orderRouting,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        chipOptions: {},
        autoComplete: true,
        uiKey: 'name',
        module: 'crud',
        apiKey: 'id',
        hubIdRequired: true,
        filterWithHubCity: 'hubId',
      },
    ],
  },
  etaClusterContainer: {
    metaData: {
      confirmation: {
        message: 'Are you sure you want to do this?',
        title: 'Confirmation',
        why: 'update',
      },
    },
    crud: [
      {
        key: 'clusterIds',
        placeholder: 'Cluster Name',
        type: 'multichip',
        apiEndPoint: AppWebRoutes.CLUSTERS.clusters,
        defaultValue: '',
        uiKey: 'name',
        apiKey: 'id',
        searchableDropDown: true,
        chipOptions: {},
        chip: false,
        filterWithHubCity: 'hubId',
        validations: { required: true },
        validationMessages: ['Cluster Name is required'],
      },
      {
        key: 'template',
        placeholder: 'Message template',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.PPD.messageTemplate,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        uiKey: 'name',
        apiKey: 'slug',
        filterWithHubCity: 'hubId',
        validations: { required: true },
        validationMessages: ['Template is required'],
      },
    ],
  },
  pickIssueContainer: {
    tableHeader: [
      {
        header: 'Product Id',
        key: 'productId',
      },
      {
        header: 'Product',
        key: 'productName',
      },
      {
        header: 'Weight',
        key: 'productWeight',
      },
      {
        header: 'MRP',
        key: 'mrp',
      },
      {
        header: 'Team',
        key: 'teamName',
      },
      {
        header: 'Date',
        key: 'date',
      },
      {
        header: 'Required Quantity',
        key: 'quantity',
      },
      {
        header: 'Accept Quantity',
        key: 'acceptedQty',
      },
      {
        header: 'Qty diff',
        key: 'quantityDiff',
      },
    ],
    metaData: {
      header: 'Pick Issues',
      canEdit: false,
      canDelete: false,
      viewExport: AppWebRoutes.PPD.pickIssueExport,
      showRefresh: true,
      viewPrivilege: 'view_order',
    },
    search: [
      {
        key: 'teamId',
        placeholder: 'Team Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.TEAM.all,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
        hubIdRequired: true,
        filterWithHubCity: 'hubId',
      },
      {
        key: 'categoryId',
        placeholder: 'Category',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CATEGORIES.hub,
        uiKey: 'category',
        subKey: 'name',
        module: 'search',
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        action: 'auto_update_dependent_list',
        targetIndex: 2,
        target: 'subCategoryId',
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        dependentOn: 'subCategoryId',
        apiKey: 'categoryId',
        optionsParams: { active: 1 },
        hubIdRequired: true,
        filterWithHubCity: 'hubId',
      },
      {
        key: 'subCategoryId',
        placeholder: 'Subcategory',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.SUBCATEGORIES.hub,
        module: 'search',
        subKey: 'name',
        uiKey: 'subcategory',
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        apiKey: 'id',
        filterWithHubCity: 'hubId',
      },
      {
        key: 'sheet',
        placeholder: 'Sheet',
        type: 'select',
        options: appConstants.masterData.SHEET,
        masterDataKey: 'SHEET',
        module: 'search',
        defaultValue: '1',
        chip: false,
        likeSearch: false,
        searchableDropDown: false,
        chipOptions: {},
        uiKey: 'sheet',
        apiKey: 'sheet',
      },
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'date',
        apiKey: 'date',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
    ],
  },
  packIssueContainer: {
    tableHeader: [
      {
        header: 'Order Id',
        key: 'orderId',
        linkId: 'orderId',
        navigateTo: 'order/view',
        whatToDo: 'navigate',
      },
      {
        header: 'Product Id',
        key: 'productId',
      },
      {
        header: 'Product',
        key: 'name',
      },
      {
        header: 'Weight',
        key: 'weight',
      },
      {
        header: 'Team',
        key: 'teamName',
      },
      {
        header: 'Cluster',
        key: 'clusterName',
      },
      {
        header: 'Quantity',
        key: 'quantity',
      },
      {
        header: 'MRP',
        key: 'mrp',
      },
      {
        header: 'Procure',
        key: 'procure',
      },
      {
        header: 'Issue',
        key: 'issue',
      },
    ],
    metaData: {
      header: 'Packing Issues',
      canEdit: false,
      canDelete: false,
      viewExport: AppWebRoutes.PPD.packingIssueExport,
      showRefresh: true,
      viewPrivilege: 'view_order',
    },
    search: [
      {
        key: 'teamId',
        placeholder: 'Team Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.TEAM.all,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
        module: 'search',
        filterWithHubCity: 'hubId',
      },
      {
        key: 'sheet',
        placeholder: 'Sheet',
        type: 'select',
        options: [
          ...[{ value: '', placeholder: 'All' }],
          ...appConstants.masterData.SHEET,
        ],
        module: 'search',
        chip: false,
        likeSearch: false,
        masterDataKey: 'SHEET',
        includeAllSheet: true,
        searchableDropDown: false,
        chipOptions: {},
        defaultValue: '',
        uiKey: 'sheet',
        apiKey: 'sheet',
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.clusters,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
        module: 'search',
        filterWithHubCity: 'hubId',
      },
      {
        key: 'orderDate',
        placeholder: 'Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'orderDate',
        apiKey: 'orderDate',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
    ],
  },
  packStatusContainer: {
    tableHeader: [
      {
        header: 'Team',
        key: 'teamName',
        sortBy: true,
      },
      {
        header: 'Packed',
        key: 'packed',
      },
      {
        header: 'Remaining',
        key: 'remaining',
      },
      {
        header: 'Total',
        key: 'total',
      },
    ],
    metaData: {
      header: 'Pack Status',
      canEdit: false,
      canDelete: false,
      showRefresh: true,
      hideSearch: true,
      viewPrivilege: 'view_order',
      hidePagination: true,
    },
    search: [
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'date',
        apiKey: 'date',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
    ],
  },
  deliveryContainer: {
    tableHeader: [
      {
        header: 'Cluster',
        key: 'clusterName',
        whatToDo: 'navigateToView',
        linkId: 'clusterId',
        navigateTo: 'ppd/deliverysociety',
      },
      {
        header: 'Total',
        key: 'total',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Delivered',
        key: 'delivered',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Pending',
        key: 'pending',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Avg Delivery',
        key: 'averageDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'First Delivery',
        key: 'firstDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Last Delivery',
        key: 'lastDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    metaData: {
      header: 'Delivery List',
      canEdit: false,
      canDelete: false,
      viewExport: AppWebRoutes.PPD.exportDelivery,
      showRefresh: true,
      viewRoute: 'ppd/deliverysociety',
      viewPrivilege: 'view_order',
    },
    search: [
      {
        key: 'orderDate',
        placeholder: 'Order Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'orderDate',
        apiKey: 'orderDate',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
    ],
  },
  inventoryContainer: {
    tableHeader: [
      {
        header: 'Product',
        key: 'productName',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: function (row) {
            return `${row.productName} (${row.productId})`;
          },
        },
        linkId: 'productId',
        navigateTo: 'catalog/hub',
        whatToDo: 'navigate',
      },
      {
        header: 'Weight',
        key: 'weight',
      },
      {
        header: 'MRP',
        key: 'mrp',
      },
      {
        header: 'Cost Price',
        key: 'costPrice',
      },
      {
        header: 'Post Sale Quantity',
        key: 'postSaleQuantity',
      },
      {
        header: 'SA Count',
        key: 'saCount',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'SA Quantity',
        key: 'saQuantity',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Is Count Mismatched',
        key: 'isSaCountMismatched',
        pipe: 'updateProperty',
        pipeConfig: {
          createCustomString: (row) => {
            return `${row.isSaCountMismatched ? 'Yes' : 'No'}`;
          },
        },
      },
      {
        header: 'MB Quantity',
        key: 'mbQuantity',
      },
      {
        header: 'Aisle',
        key: 'aisle',
      },
      {
        header: 'Rack',
        key: 'rack',
      },
      {
        header: 'Shelf',
        key: 'shelf',
      },
      {
        header: 'Sellable Count',
        key: 'sellableCount',
      },
      {
        header: 'Sellable Damage Count',
        key: 'sellableDamageCount',
      },
      {
        header: 'Audit Count',
        key: 'auditCount',
      },
      {
        header: 'Audit Damage Count',
        key: 'auditDamageCount',
      },
      {
        header: 'Sa Counter Name',
        key: 'saCounterName',
      },
      {
        header: 'Sa Counter Email',
        key: 'saCounterEmail',
      },
      {
        header: 'Sa Audit Name',
        key: 'saAuditorName',
      },
      {
        header: 'Sa Audit Email',
        key: 'saAuditorEmail',
      },
    ],
    metaData: {
      header: 'Inventory List',
      canEdit: false,
      canDelete: false,
      viewExport: AppWebRoutes.PPD.exportInventory,
      showRefresh: true,
      viewPrivilege: 'view_inventory',
      editRoute: '/ppd/inventory/edit',
    },
    search: [
      {
        key: 'productId',
        placeholder: 'Product ID',
        type: 'input',
        event: 'blur',
        chipOptions: {},
        chip: true,
        uiKey: 'name',
        apiKey: 'productId',
        module: 'search',
      },
      {
        key: 'categoryId',
        placeholder: 'Category',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CATEGORIES.autocompleteHubSpecific,
        hubIdRequired: true,
        uiKey: 'name',
        module: 'search',
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        action: 'auto_update_dependent_list',
        targetIndex: 2,
        target: 'subcategoryId',
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        apiKey: 'id',
        dependentOn: 'subcategoryId',
      },
      {
        key: 'subCategoryId',
        placeholder: 'Subcategory',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.SUBCATEGORIES.hub,
        filterWithHubCity: 'hubId',
        module: 'search',
        subKey: 'name',
        uiKey: 'subcategory',
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        apiKey: 'subcategoryId',
      },
      {
        key: 'brandId',
        placeholder: 'Brand',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.BRANDS.autocomplete,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
      },
      {
        key: 'aisleId',
        placeholder: 'Aisle',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CATALOG.aisle,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
      },
      {
        key: 'sheet',
        placeholder: 'Sheet',
        type: 'select',
        options: appConstants.masterData.SHEET,
        module: 'search',
        defaultValue: '1',
        masterDataKey: 'SHEET',
        chip: false,
        likeSearch: false,
        searchableDropDown: false,
        chipOptions: {},
        uiKey: 'sheet',
        apiKey: 'sheet',
      },
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'date',
        apiKey: 'date',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
      {
        key: 'saConfirm',
        placeholder: 'SA Confirm',
        type: 'select',
        options: [
          { value: '', placeholder: 'All' },
          { value: 1, placeholder: 'Yes' },
          { value: 0, placeholder: 'No' },
        ],
        module: 'search',
        chip: false,
        likeSearch: false,
        searchableDropDown: false,
        defaultValue: '',
        uiKey: 'saConfirm',
        apiKey: 'saConfirm',
      },
      {
        key: 'isSaCountMismatched',
        placeholder: 'Is Count Mismatched',
        type: 'select',
        options: [
          { value: '0', placeholder: 'No' },
          { value: '1', placeholder: 'Yes' },
        ],
        defaultValue: '',
        module: 'search',
      },
    ],
    actions: [
      {
        icon: 'edit',
        label: 'Edit',
        color: 'primary',
        privilege: 'update_inventory',
        operation: { id: 'productId', name: 'productName' },
      },
    ],
    crud: [
      {
        key: 'productName',
        placeholder: 'Product Name',
        type: 'text',
        isReadOnly: true,
      },
      {
        key: 'quantity',
        placeholder: 'SA Count',
        type: 'text',
        validations: { required: true, pattern: appConstants.positiveNumber },
        defaultValue: '',
        validationMessages: ['SA Count is required', 'SA Count is number'],
        isReadOnly: false,
      },
      {
        key: 'damagedQuantity',
        placeholder: 'Damaged Quantity',
        type: 'text',
        validations: { required: true, pattern: appConstants.positiveNumber },
        defaultValue: '',
        validationMessages: [
          'Damaged Quantity is required',
          'Damaged Quantity is required',
        ],
        isReadOnly: false,
      },
    ],
  },
  dailyIncentiveContainer: {
    tableHeader: [
      { header: 'Emp Code', key: 'empCode' },
      { header: 'Employee Email', key: 'emailId' },
      {
        header: 'Cluster Name',
        key: 'clusterName',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: function (row) {
            return `${row.clusterName} (${row.clusterId})`;
          },
        },
      },
      { header: 'Order Date', key: 'orderDate' },
      { header: 'OND Refund', key: 'ondCount' },
      { header: 'PD Refund', key: 'pdCount' },
      { header: 'No of Delivery', key: 'deliveryCount' },
      { header: 'Target Delivery', key: 'targetDelivery' },
      { header: 'Total Incentive Amount', key: 'incentiveAmount' },
      // { header: 'Extra Incentive', key: 'extraIncentive' },
    ],
    metaData: {
      header: 'Daily Incentive Report',
      showRefresh: true,
      viewPrivilege: 'view_incentive',
      viewExport: AppWebRoutes.PPD.dailyIncentiveExport,
    },
    search: [
      {
        key: 'orderDate',
        placeholder: 'Order Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'orderDate',
        apiKey: 'orderDate',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
      {
        key: 'empCode',
        placeholder: 'Emp Code search',
        type: 'input',
        event: 'blur',
        chipOptions: {},
        chip: true,
        uiKey: 'name',
        apiKey: 'name',
        module: 'search',
      },
    ],
  },
  monthlyIncentiveContainer: {
    tableHeader: [
      { header: 'Emp Code', key: 'empCode' },
      { header: 'Employee Email', key: 'emailId' },
      { header: 'Month', key: 'month' },
      { header: 'Basic Income', key: 'incentiveAmount' },
      { header: 'Negative Incentive', key: 'negativeIncentive' },
      { header: 'Packing Errors(Hub)', key: 'hubPackingErrors' },
      { header: 'Total Incentive Amount', key: 'totalIncentive' },
      { header: 'Packing Error %', key: 'packingErrorPercentage' },
    ],
    metaData: {
      header: 'Monthly Incentive Report',
      showRefresh: true,
      viewPrivilege: 'view_incentive',
      viewExport: AppWebRoutes.PPD.monthlyIncentiveExport,
    },
    search: [
      // {
      //   key: 'orderDate',
      //   placeholder: 'order Date',
      //   type: 'date',
      //   chip: true,
      //   options: [],
      //   chipOptions: {},
      //   uiKey: 'orderDate',
      //   apiKey: 'orderDate',
      //   module: 'search',
      //   datePickerType: 'calendar',
      //   defaultValue: new Date()
      // },
      {
        key: 'empCode',
        placeholder: 'Emp Code search',
        type: 'input',
        event: 'blur',
        chipOptions: {},
        chip: true,
        uiKey: 'name',
        apiKey: 'name',
        module: 'search',
      },
    ],
  },
  deliveryReportContainer: {
    tableHeader: [
      { header: 'Date', key: 'orderDate' },
      { header: 'Orders', key: 'ordersDelivered' },
      { header: 'Late 6:45', key: 'late0645' },
      { header: 'Late 7', key: 'late7' },
      { header: 'Avg Delivery', key: 'averageDelivery' },
      { header: 'First Delivery', key: 'firstDelivery' },
      { header: 'Last Delivery', key: 'lastDelivery' },
    ],
    metaData: {
      header: 'Delivery Report',
      showRefresh: true,
      viewPrivilege: 'view_order',
    },
    search: [
      {
        key: 'startDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        defaultValue: [appConstants.getDateByNumberOfDays(-7), new Date()],
        dateRangeKey: 'endDate',
        maxDate: new Date(),
      },
    ],
  },
  deliveryTowerContainer: {
    tableHeader: [
      { header: 'Tower name', key: 'addressTower', sortBy: true },
      {
        header: 'Total',
        key: 'total',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Delivered',
        key: 'delivered',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Pending',
        key: 'pending',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Avg Delivery',
        key: 'averageDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'First Delivery',
        key: 'firstDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Last Delivery',
        key: 'lastDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    metaData: {
      header: 'Delivery Tower List',
      canEdit: false,
      canDelete: false,
      showRefresh: true,
      viewExport: AppWebRoutes.PPD.exportDeliveryTower,
      viewPrivilege: 'view_order',
      viewExportParams: { societyId: null },
    },
    search: [
      {
        key: 'startDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        defaultValue: [appConstants.getDateByNumberOfDays(-7), new Date()],
        dateRangeKey: 'endDate',
        maxDate: new Date(),
      },
    ],
  },
  deliveryBucketContainer: {
    tableHeader: [
      {
        header: 'Bucket Number',
        key: 'bucketNumber',
        pipe: 'updateProperty',
        pipeConfig: {
          requiresNewLine: true,
          createCustomString: function (row) {
            return `Bucket ${row.bucketNumber}`;
          },
        },
        sortBy: true,
      },
      {
        header: 'Total',
        key: 'total',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Delivered',
        key: 'delivered',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Pending',
        key: 'pending',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Avg Delivery',
        key: 'averageDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'First Delivery',
        key: 'firstDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Last Delivery',
        key: 'lastDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    metaData: {
      header: 'Delivery Bucket List',
      canEdit: false,
      canDelete: false,
      showRefresh: true,
      viewExport: AppWebRoutes.PPD.exportDeliveryBucket,
      viewPrivilege: 'view_order',
      viewExportParams: { droppointId: null },
    },
    search: [
      {
        key: 'startDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        defaultValue: [appConstants.getDateByNumberOfDays(-7), new Date()],
        dateRangeKey: 'endDate',
        maxDate: new Date(),
      },
    ],
  },
  deliverySocietyContainer: {
    tableHeader: [
      {
        header: 'Society',
        key: 'societyName',
        whatToDo: 'navigateToView',
        linkId: 'societyId',
        navigateTo: 'ppd/deliverytower',
      },
      {
        header: 'Total',
        key: 'total',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Delivered',
        key: 'delivered',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Pending',
        key: 'pending',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Avg Delivery',
        key: 'averageDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'First Delivery',
        key: 'firstDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Last Delivery',
        key: 'lastDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    metaData: {
      header: 'Delivery Society List',
      canEdit: false,
      canDelete: false,
      viewExport: AppWebRoutes.PPD.exportDeliverySociety,
      showRefresh: true,
      viewRoute: 'ppd/deliverytower',
      viewPrivilege: 'view_order',
      viewExportParams: { clusterId: null },
    },
    search: [
      {
        key: 'startDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        defaultValue: [appConstants.getDateByNumberOfDays(-7), new Date()],
        dateRangeKey: 'endDate',
        maxDate: new Date(),
      },
    ],
  },
  deliveryDroppointContainer: {
    tableHeader: [
      {
        header: 'Drop Point',
        key: 'droppoint',
        whatToDo: 'navigateToView',
        linkId: 'droppointId',
        navigateTo: 'ppd/deliverytower',
      },
      {
        header: 'Total',
        key: 'total',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Delivered',
        key: 'delivered',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Pending',
        key: 'pending',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Avg Delivery',
        key: 'averageDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'First Delivery',
        key: 'firstDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Last Delivery',
        key: 'lastDelivery',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    metaData: {
      header: 'Delivery Drop Point List',
      canEdit: false,
      canDelete: false,
      viewExport: AppWebRoutes.PPD.exportDeliveryDroppoint,
      showRefresh: true,
      viewRoute: 'ppd/deliverytower',
      viewPrivilege: 'view_order',
      viewExportParams: { clusterId: null },
    },
    search: [
      {
        key: 'startDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        defaultValue: [appConstants.getDateByNumberOfDays(-7), new Date()],
        dateRangeKey: 'endDate',
        maxDate: new Date(),
      },
    ],
  },
  clusterVehicleInCSVContainer: {
    tableHeader: [
      {
        header: 'Cluster',
        key: 'clusterName',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Vehicle',
        key: 'vehicle',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    metaData: {
      header: 'List of Vehicle in CSV',
      hidePagination: true,
      hidelocalSearch: true,
    },
  },
  clusterVehicleContainer: {
    tableHeader: [
      { header: 'Cluster', key: 'clusterName', sortBy: true },
      { header: 'Vehicle', key: 'vehicle' },
    ],
    metaData: {
      header: 'List of Cluster',
      viewExport: AppWebRoutes.PPD.exportClusterVehicles,
      showRefresh: true,
      viewPrivilege: 'view_order',
    },
  },
  vehicleLoadingContainer: {
    tableHeader: [
      { header: 'Loading ID', key: 'id' },
      { header: 'Cluster', key: 'clusterName' },
      { header: 'Vehicle No', key: 'number' },
      { header: 'Supervisor', key: 'email' },
      { header: 'Loading Time', key: 'loadingTime' },
    ],
    search: [
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.clusters,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
        filterWithHubCity: 'hubId',
      },
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'date',
        apiKey: 'date',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
    ],
    metaData: {
      header: 'List of Cluster',
      viewExport: AppWebRoutes.PPD.VehiclesExportLoading,
      showRefresh: true,
      viewPrivilege: 'view_order',
      canEdit: false,
      canDelete: false,
    },
  },
  vehiclesContainer: {
    tableHeader: [
      { header: 'Number', key: 'number', sortBy: true },
      { header: 'Type', key: 'type', sortBy: true },
      { header: 'Created', key: 'createdOn', sortBy: true },
    ],
    metaData: {
      header: 'List of Vehicles',
      btnText: 'Create Vehicle',
      canEdit: true,
      showCreate: true,
      canDelete: true,
      editRoute: '/ppd/vehicles/edit',
      viewExport: AppWebRoutes.PPD.exportVehicles,
      showRefresh: true,
      delete: {
        url: 'vehicles',
        message: 'Are you sure you want to delete?',
        title: 'Delete Vehicle',
      },
      viewPrivilege: 'view_vehicle',
    },
    actions: [
      { icon: 'edit', label: 'Edit', color: 'accent' },
      {
        icon: 'delete',
        label: 'Delete',
        color: 'accent',
        privilege: 'delete_vehicle',
      },
    ],
  },
  createEditVehicleContainer: {
    tableHeader: [
      { header: 'Cluster', key: 'clusterName' },
      { header: 'Vehicle', key: 'vehicle' },
    ],
    metaData: {
      header: 'Details',
    },
    crud: [
      {
        key: 'number',
        placeholder: 'Number',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Vehicle number is required'],
        isReadOnly: false,
      },
      {
        key: 'typeId',
        placeholder: 'Type',
        apiEndPoint: AppWebRoutes.PPD.vehicleAutocomplete,
        type: 'autocomplete',
        searchableDropDown: true,
        options: [],
        filteredOptions: null,
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
      },
    ],
  },
  uploadClusterVehiclesContainer: {
    metaData: {
      header: 'Upload CSV',
    },
    crud: [
      {
        key: 'file',
        placeholder: 'File Upload',
        type: 'file',
        defaultValue: '',
        apiEndPoint: AppWebRoutes.PPD.uploadClusterVehicle,
        validations: { required: true },
        validationMessages: ['File is required'],
        accept: '.csv',
        isReadOnly: false,
        isEditMode: false,
      },
    ],
  },
  featureMetaActivationContainer: {
    tableHeader: [
      { header: 'Data Key', key: 'dataKey' },
      { header: 'Data Value', key: 'dataValue' },
    ],
    metaData: {
      header: 'Societies',
      mapping: true,
      hidePagination: true,
      hidelocalSearch: true,
      viewPrivilege: 'view_cluster',
      sortOrder: {
        url: 'ppd/features-meta',
        message: '',
        title: 'Update data value',
        why: 'dataValue',
      },
    },
    actions: [
      {
        icon: 'edit',
        label: 'Update data value',
        color: 'accent',
        privilege: 'update_cluster',
      },
    ],
    crud: [
      {
        key: 'max_order',
        placeholder: 'Max order index',
        type: 'text',
      },
      {
        key: 'min_order',
        placeholder: 'Min order index',
        type: 'text',
      },
    ],
  },
  featureActivationContainer: {
    tableHeader: [
      { header: 'Feature ID', key: 'id' },
      { header: 'Feature Name', key: 'name' },
      { header: 'Feature Slug', key: 'slug' },
      { header: 'Activation Scope', key: 'isActive' },
    ],
    crud: [
      {
        key: 'hubId',
        placeholder: 'Hub',
        type: 'text',
        disabled: true,
        validations: { required: true },
        isReadOnly: false,
        uiKey: 'name',
        apiKey: 'id',
      },
      {
        key: 'name',
        placeholder: 'Feature Name',
        type: 'text',
      },
      {
        key: 'activateFor',
        placeholder: 'Activate for Cluster/Society/Team/Hub',
        type: 'select',
        options: [
          { value: 'cluster_id', placeholder: 'Activate for Cluster' },
          { value: 'society_id', placeholder: 'Activate for Society' },
          // { value: 'team_id', placeholder: 'Activate for Team' }
        ],
        module: 'crud',
        defaultValue: '',
      },
    ],
    search: [
      {
        key: 'isActive',
        placeholder: 'Active Status',
        type: 'select',
        options: [
          { value: '1', placeholder: 'Yes' },
          { value: '0', placeholder: 'No' },
        ],
        module: 'search',
        defaultValue: '',
      },
      {
        key: 'name',
        hubIdRequired: true,
        filterWithHubCity: 'hubId',
        placeholder: 'Feature Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.PPD.featureName,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        hintLabel: '',
        module: 'search',
        uiKey: 'name',
        apiKey: 'name',
      },
    ],
    metaData: {
      header: 'Feature Activation',
      canEdit: true,
      editRoute: '/ppd/feature-activation/edit',
      getDialogDataForActiveDeActive: function (isActive) {
        return {
          url: 'ppd/features',
          message:
            'Are you sure you want to ' +
            (isActive ? 'deactivate' : 'activate') +
            ' a user?',
          title: (isActive ? 'Deactivate' : 'Activate') + 'Feature',
          why: 'update',
        };
      },
      confirmation: {
        message: 'Are you sure you want to do this?',
        title: 'Deactivate',
        why: 'update',
      },
      hidePagination: true,
      hidelocalSearch: true,
      viewPrivilege: 'view_feature',
    },
    actions: [
      {
        icon: 'edit',
        label: 'Edit',
        color: 'primary',
        privilege: 'update_feature',
      },
      {
        icon: 'lock_open',
        label: 'Feature Change',
        color: 'accent',
        getIcon: function (row) {
          return `${row.isActive === 1 ? 'lock_open' : 'lock'}`;
        },
        privilege: 'update_feature',
      },
    ],
  },
  genrateOtpContainer: {
    metaData: {},
    crud: [
      {
        key: 'mobile',
        placeholder: 'Phone Number',
        type: 'text',
        validations: {
          required: true,
          pattern: appConstants.phonePattern,
          maxlength: 10,
        },
        validationMessages: [
          'Phone Number is not valid',
          'Phone number must be of MaxLength 10',
        ],
      },
    ],
  },
  clusterTargetContainer: {
    tableHeader: [
      { header: 'Cluster ID/Name', key: 'clusterName' },
      { header: 'Packing Started Ambient', key: 'packingStartTargetAmbient' },
      { header: 'Packing Completed Ambient', key: 'packingEndTargetAmbient' },
      { header: 'Packing Started cold', key: 'packingStartTargetCold' },
      { header: 'Packing Completed cold', key: 'packingEndTargetCold' },
      { header: 'Loaded', key: 'loadingTarget' },
      { header: 'Delivery Started', key: 'deliveryStartTarget' },
    ],
    metaData: {
      header: 'List of Cluster Target',
      btnText: 'Create Cluster Target',
      canEdit: true,
      showCreate: false,
      canDelete: false,
      editRoute: '/ppd/supply-chain/edit',
      viewExport: AppWebRoutes.PPD.clusterTargetExport,
      showRefresh: true,
      viewPrivilege: 'view_vehicle',
    },
    actions: [{ icon: 'edit', label: 'Edit', color: 'accent' }],
    crud: [
      {
        key: 'hubId',
        placeholder: 'Hub',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.HUBS.hubs,
        defaultValue: '',
        isReadOnly: false,
        uiKey: 'name',
        apiKey: 'id',
        searchableDropDown: true,
        filterWithHubCity: 'cityId',
        chipOptions: {},
        chip: false,
        resolve: 'hub',
        module: 'crud',
        disabled: true,
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Id',
        type: 'text',
        isReadOnly: true,
        defaultValue: '',
        validationMessages: ['Cluster Id is required', 'Cluster Id is number'],
        validations: { required: true, pattern: appConstants.positiveNumber },
      },
      {
        key: 'loadingTarget',
        placeholder: 'Loaded Target',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Loaded Target is required'],
        isReadOnly: false,
      },
      {
        key: 'deliveryStartTarget',
        placeholder: 'Delivery Started',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Delivery Started is required'],
        isReadOnly: false,
      },
    ],
  },
  clusterTargetUploadContainer: {
    tableHeader: [
      {
        header: 'Cluster Id',
        key: 'clusterId',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Loading Target',
        key: 'loadingTarget',
        subkey: 'value',
        whatToDo: 'subkey',
      },
      {
        header: 'Delivery Start Target',
        key: 'deliveryStartTarget',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    crud: [
      {
        key: 'hubId',
        placeholder: 'Hub',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.HUBS.hubs,
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Hub is required'],
        isReadOnly: false,
        uiKey: 'name',
        apiKey: 'id',
        searchableDropDown: true,
        filterWithHubCity: 'cityId',
        chipOptions: {},
        chip: false,
        resolve: 'hub',
        module: 'crud',
      },
      {
        key: 'file',
        placeholder: 'File Upload',
        type: 'file',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['File is required'],
        accept: '.csv',
        isReadOnly: false,
        isEditMode: false,
      },
    ],
    metaData: {
      header: 'List of cluster Upload Data',
      hidePagination: true,
      hideSearch: true,
      hidelocalSearch: true,
      viewPrivilege: 'view_cluster_milestone',
      download: 'upload-cluster-target.csv',
    },
  },
  milestoneLevelDelayLogsContainer: {
    tableHeader: [
      { key: 'clusterName', header: 'Cluster' },
      { key: 'date', header: 'Date', sortBy: true },
      {
        key: 'milestone',
        header: 'Milestone',
        pipeConfig: {
          createCustomString: (row) => {
            return appConstants.capitalizeAndRemoveUnderscore(row.milestone);
          },
        },
      },
      { key: 'targetTime', header: 'Target Time' },
      { key: 'actualTime', header: 'Actual Time' },
      { key: 'processDelay', header: 'Process Delay ( in min. )' },
      { key: 'delayReason', header: 'Delay Reason', sortBy: true },
    ],
    metaData: {
      header: 'Milestone Delay Logs',
      removeGlobalFilter: false,
      viewPrivilege: 'view_cluster_milestone',
      hidePagination: false,
      showRefresh: true,
      viewExport: AppWebRoutes.PPD.milestoneDelayExport,
      sortOrder: {
        url: '',
        title: 'Milestone Delay Reason',
        why: 'singleInput',
        placeholder: 'Delay Reason',
      },
    },
    actions: [
      {
        icon: 'list',
        label: 'Input Delay Reason',
        color: 'primary',
        hideAction: true,
        getIcon: function (row) {
          return `${row.delayReason ? '' : 'list'}`;
        },
        privilege: 'create_cluster_milestone',
      },
    ],
    search: [
      {
        key: 'fromDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'fromDate',
        apiKey: 'fromDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        dateRangeKey: 'toDate',
        maxDate: new Date(),
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.autocomplete,
        chip: true,
        likeSearch: false,
        options: [],
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
      },
    ],
  },
  deliveryTargetContainer: {
    tableHeader: [
      { header: 'Cluster', key: 'clusterName' },
      { header: 'Last Milestone', key: 'lastMilestone' },
      { header: 'Next Milestone', key: 'nextMilestone' },
      { header: 'Percentage Completion', key: 'percentageCompletion' },
      { header: 'Target Time', key: 'targetTime' },
      { header: 'Expected Time', key: 'expectedTime' },
      { header: 'Total Delay', key: 'totalDelay' },
      { header: 'Current Process Delay', key: 'currentProcessDelay' },
      { header: 'Delay Reason', key: 'delayReason' },
    ],
    search: [
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'date',
        apiKey: 'date',
        module: 'search',
        datePickerType: 'calendar',
        defaultValue: new Date(),
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.autocomplete,
        chip: true,
        likeSearch: false,
        options: [],
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
      },
      {
        key: 'isReverse',
        placeholder: 'Reverse',
        type: 'select',
        options: [
          { value: '1', placeholder: 'Yes' },
          { value: '0', placeholder: 'No' },
        ],
        module: 'search',
        defaultValue: '',
      },
      {
        key: 'delayed',
        placeholder: 'Delayed',
        type: 'select',
        options: [
          { value: '1', placeholder: 'Yes' },
          { value: '0', placeholder: 'No' },
        ],
        module: 'search',
        defaultValue: '',
      },
    ],
    metaData: {
      header: 'Supply Chain Delivery Target',
      canEdit: false,
      hidePagination: true,
      hidelocalSearch: true,
      viewPrivilege: 'view_cluster_milestone',
    },
    actions: [
      {
        icon: 'list',
        label: 'Milestone Performance',
        openDialogOnClick: true,
        color: 'primary',
        hideAction: true,
        privilege: 'view_cluster_milestone',
      },
    ],
  },
  clusterLevelDelayLogsContainer: {
    tableHeader: [
      { key: 'cluster', header: 'Cluster' },
      { key: 'date', header: 'Date', sortBy: true },
      { key: 'deliveredAt', header: 'Delivered At' },
      { key: 'deliveryDelay', header: 'Delivery Delay ( in min. )' },
      { key: 'milestoneDelayedCount', header: 'Milestones Delayed' },
    ],
    metaData: {
      header: 'Cluster Delay Logs',
      removeGlobalFilter: false,
      viewPrivilege: 'view_cluster_milestone',
      showRefresh: true,
      viewExport: AppWebRoutes.PPD.clusterDelayExport,
      sortOrder: {
        url: '',
        title: 'Milestone Delay Reason',
        why: 'singleInput',
        placeholder: 'Delay Reason',
      },
    },
    actions: [
      {
        icon: 'list',
        label: 'Milestone Performance',
        openDialogOnClick: true,
        color: 'primary',
        hideAction: true,
        privilege: 'view_cluster_milestone',
      },
    ],
    search: [
      {
        key: 'fromDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'fromDate',
        apiKey: 'fromDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        dateRangeKey: 'toDate',
        maxDate: new Date(),
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.autocomplete,
        chip: true,
        likeSearch: false,
        options: [],
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'name',
        apiKey: 'id',
      },
    ],
  },
  anfContainer: {
    tableHeader: [
      { header: 'Cluster Name', key: 'clusterName' },
      {
        header: 'Issue ID',
        key: 'issueId',
        linkId: 'issueId',
        whatToDo: 'externalURL',
        sortBy: true,
      },
      {
        header: 'Customer ID',
        key: 'customerId',
        pipe: 'updateProperty',
        pipeConfig: 'id',
        linkId: 'customerId',
        navigateTo: '/customer/view',
        whatToDo: 'navigate',
        sortBy: true,
      },
      { header: 'Customer Name', key: 'customerName' },
      { header: 'Order Id', key: 'orderId' },
      { header: 'Delivery Status', key: 'statusValue' },
      { header: 'Number of Call attempts', key: 'count' },
      { header: 'Date and Time', key: 'resolvedTime', whatToDo: 'addClass' },
    ],
    metaData: {
      header: 'ANF LIST',
      canEdit: false,
      canDelete: false,
      viewExport: AppWebRoutes.PPD.anfExport,
      showRefresh: true,
      viewPrivilege: 'view_order',
    },
    search: [
      {
        key: 'startDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        defaultValue: [appConstants.getDateByNumberOfDays(0), new Date()],
        dateRangeKey: 'endDate',
      },
    ],
    actions: [
      {
        icon: 'edit',
        label: 'PPD customer navigation',
        color: 'accent',
      },
      {
        icon: 'menu',
        label: 'Audio recording',
        color: 'accent',
      },
    ],
  },
  ppsStickerContainer: {
    tableHeader: [],
    metaData: {
      header: 'PPS Sticker',
      canEdit: false,
      canDelete: false,
      viewExport: AppWebRoutes.PPD.anfExport,
      showRefresh: true,
      viewPrivilege: 'view_order',
    },
    search: [],
    crud: [
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        defaultValue: '',
        datePickerType: 'calendar',
        isReadOnly: false,
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.clusters,
        chip: true,
        likeSearch: false,
        needUIValue: true,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        uiKey: 'name',
        apiKey: 'id',
        filterWithHubCity: 'hubId',
        validations: { required: true },
        defaultValue: '',
        isReadOnly: false,
        validationMessages: ['Cluster is required'],
      },
      {
        key: 'sheet',
        placeholder: 'Sheet Name',
        type: 'select',
        options: [
          { value: '1', placeholder: 'Wet' },
          { value: '2', placeholder: 'Non Wet' },
          { value: '3', placeholder: 'All' },
        ],
        defaultValue: '3',
      },
    ],
  },
  featureContainer: {
    tableHeader: [
      { header: 'Feature ID', key: 'id' },
      { header: 'Feature Name', key: 'name' },
      { header: 'Feature Slug', key: 'slug' },
    ],
    crud: [
      {
        key: 'name',
        placeholder: 'Name',
        type: 'text',
        validations: {
          required: true,
        },
        defaultValue: '',
        validationMessages: ['Name is required'],
        isReadOnly: false,
      },
      {
        key: 'slug',
        placeholder: 'Slug',
        type: 'text',
        validations: {
          required: true,
        },
        defaultValue: '',
        validationMessages: ['Slug is required'],
        isReadOnly: false,
      },
      {
        key: 'dataKey',
        placeholder: 'Data Key',
        type: 'select',
        options: [
          { value: 'cluster_id', placeholder: 'Activate for Cluster' },
          { value: 'society_id', placeholder: 'Activate for Society' },
        ],
        module: 'crud',
        defaultValue: '',
      },
      {
        key: 'dataValue',
        placeholder: 'Data Value',
        type: 'text',
        defaultValue: '',
        isReadOnly: false,
      },
      {
        key: 'isDataUi',
        placeholder: 'Is Data UI',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Yes' },
          { value: 0, placeholder: 'No' },
        ],
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Is Data UI is required'],
        isReadOnly: false,
      },
    ],
    metaData: {
      header: 'Feature',
      canEdit: true,
      btnText: 'Create',
      canDelete: true,
      hidelocalSearch: true,
      createRoute: 'add',
      editRoute: '/ppd/feature/edit',
      confirmation: {
        message: 'Are you sure you want to do this?',
        title: 'Deactivate',
        why: 'update',
      },
      hidePagination: true,
      viewPrivilege: 'view_feature',
      showCreate: true,
    },
    actions: [
      {
        icon: 'edit',
        label: 'Edit',
        color: 'primary',
        privilege: 'update_feature',
      },
    ],
  },
  featureMetaContainer: {
    tableHeader: [
      { header: 'Feature ID', key: 'id' },
      { header: 'Feature Property Id', key: 'featuresPropertyId' },
      { header: 'Data Key', key: 'dataKey' },
      { header: 'Data Value', key: 'dataValue' },
    ],
    crud: [
      {
        key: 'name',
        placeholder: 'Name',
        type: 'text',
        validations: {
          required: true,
        },
        defaultValue: '',
        validationMessages: ['Name is required'],
        isReadOnly: false,
      },
      {
        key: 'slug',
        placeholder: 'Slug',
        type: 'text',
        validations: {
          required: true,
        },
        defaultValue: '',
        validationMessages: ['Slug is required'],
        isReadOnly: false,
      },
      {
        key: 'dataKey',
        placeholder: 'Data Key',
        type: 'text',
        validations: {
          required: true,
        },
        defaultValue: '',
        validationMessages: ['Data Key is required'],
        isReadOnly: false,
      },
      {
        key: 'dataValue',
        placeholder: 'Data Value',
        type: 'text',
        validations: {
          required: true,
        },
        defaultValue: '',
        validationMessages: ['Data Value is required'],
        isReadOnly: false,
      },
      {
        key: 'isDataUi',
        placeholder: 'Is Data UI',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Yes' },
          { value: 0, placeholder: 'No' },
        ],
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Is Data UI is required'],
        isReadOnly: false,
      },
    ],
    metaData: {
      header: 'Feature',
      canEdit: true,
      btnText: 'Create',
      canDelete: true,
      createRoute: 'add',
      editRoute: '/ppd/feature/edit',
      hidePagination: true,
      viewPrivilege: 'view_feature',
      showCreate: true,
    },
  },
  transportModuleContainer: {
    tableHeader: [
      { header: 'Vendor Name', key: 'vendorName' },
      { header: 'Vendor Code', key: 'vendorCode' },
      { header: 'Hub Name', key: 'hubName' },
      { header: 'Work Order Number', key: 'workOrderNumber' },
      { header: 'Work Order Expiry Date', key: 'workExpiryDate' },
      { header: 'Agreed Vehicles', key: 'agreedVehicles' },
      { header: 'Active', key: 'active' },
    ],
    metaData: {
      header: 'List of Vendors',
      btnText: 'Create Vendor',
      canEdit: true,
      showCreate: true,
      canDelete: true,
      editRoute: '/ppd/transport/add',
      viewExport: AppWebRoutes.PPD.exportVendor,
      showRefresh: true,
      createPrivilege: 'create_transport_vendor',
      viewPrivilege: 'view_transport_vendor',
    },
    search: [
      {
        key: 'vendorId',
        placeholder: 'Vendor Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.PPD.vendorNameAutocomplete,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        icon: 'search',
        hintLabel: '',
        uiKey: 'vendorName',
        apiKey: 'id',
        hubIdRequired: true,
      },
      {
        key: 'active',
        placeholder: 'Active',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Yes' },
          { value: 0, placeholder: 'No' },
        ],
        chip: false,
        likeSearch: false,
        searchableDropDown: false,
        defaultValue: '',
        module: 'search',
      },
    ],
    actions: [
      {
        icon: 'edit',
        label: 'Edit',
        color: 'accent',
        privilege: 'update_transport_vendor',
      },
    ],
  },
  vendorVehicleListModuleContainer: {
    tableHeader: [
      { header: 'Vehicle Type', key: 'vehicleType' },
      { header: 'Shift Length', key: 'shiftLength' },
      { header: 'Agreed Vehicles', key: 'agreedVehicles' },
      { header: 'Monthly Fixed Rate', key: 'monthlyFixedRate' },
      { header: 'Km Charge', key: 'kmCharge' },
      { header: 'Bonus Amount', key: 'bonusAmount' },
      { header: 'Penalty Amount', key: 'penaltyAmount' },
      { header: 'Agreed Kms', key: 'agreedKm' },
      { header: 'Extra Km Charge', key: 'extraKmCharge' },
      { header: 'Extra Hour Payment', key: 'extraHourPayment' },
      { header: 'Valid From Date', key: 'validFrom' },
      { header: 'Valid Till Date', key: 'validTo' },
    ],
    metaData: {
      header: 'List of Vendor Vehicle',
      btnText: 'Create Vendor Vehicle',
      canEdit: true,
      showCreate: true,
      canDelete: true,
      hidePagination: true,
      delete: {
        url: AppWebRoutes.PPD.vendorVehicles,
        message: 'Are you sure you want to delete?',
        title: 'Delete Vehicle',
      },
      editRoute: 'ppd/transport/vendor-vehicle',
      showRefresh: true,
      viewPrivilege: 'view_transport_vendor_vehicle',
      createPrivilege: 'create_transport_vendor_vehicle',
      createRoute: 'ppd/transport/vendor-vehicle',
      addNewCreateRoute: true,
    },
    actions: [
      {
        icon: 'edit',
        label: 'Edit',
        color: 'accent',
        privilege: 'update_transport_vendor_vehicle',
      },
      {
        icon: 'delete',
        label: 'Delete',
        color: 'accent',
        privilege: 'delete_transport_vendor_vehicle',
      },
    ],
  },
  vendorDetailsCrudContainer: {
    crud: [
      {
        key: 'vendorName',
        placeholder: 'Vendor Name',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Vendor name is required'],
        isReadOnly: false,
      },
      {
        key: 'vendorCode',
        placeholder: 'Vendor Code',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Vendor code is required'],
        isReadOnly: false,
      },
      {
        key: 'cityId',
        placeholder: 'City',
        type: 'autocomplete',
        validations: { required: true },
        apiEndPoint: AppWebRoutes.CITIES.cities,
        defaultValue: '',
        disabled: false,
        optionsParams: { hubId: undefined },
        validationMessages: ['City is required'],
        isReadOnly: false,
        targetIndex: 3,
        target: 'hubId',
        action: 'auto_update_dependent_list',
        uiKey: 'name',
        apiKey: 'id',
        searchableDropDown: true,
        chipOptions: {},
        chip: false,
        module: 'crud',
      },
      {
        key: 'hubId',
        placeholder: 'Hub Code',
        type: 'autocomplete',
        validations: { required: true },
        apiEndPoint: AppWebRoutes.HUBS.hubs,
        optionsParams: { active: 1 },
        cityIdRequired: true,
        defaultValue: '',
        validationMessages: ['Hub is required'],
        isReadOnly: false,
        dependent: true,
        uiKey: 'name',
        apiKey: 'id',
        module: 'crud',
        searchableDropDown: false,
        chipOptions: {},
        chip: false,
      },
      {
        key: 'serviceLocation',
        placeholder: 'Service Location',
        type: 'text',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Service Location is required'],
        isReadOnly: false,
      },
      {
        key: 'workOrderNumber',
        placeholder: 'Work Order Number',
        type: 'number',
        validations: {
          required: true,
          pattern: appConstants.positiveNonDecimalNumber,
        },
        defaultValue: '',
        validationMessages: [
          'Work Order Number is required',
          'Work Order should be a positive number',
        ],
        isReadOnly: false,
      },
      {
        key: 'workOrderExpiryDate',
        placeholder: 'Work Order Expiry Date',
        type: 'date',
        minDate: appConstants.getDateByNumberOfDays(0),
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Work Order Expiry Date is required'],
        datePickerType: 'calendar',
        isReadOnly: false,
      },
      {
        key: 'active',
        placeholder: 'Active',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Yes' },
          { value: 0, placeholder: 'No' },
        ],
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Active is required'],
        isReadOnly: false,
      },
    ],
  },
  vehicleDetailsCrudContainer: {
    crud: [
      {
        key: 'vehicleType',
        placeholder: 'Choose Vehicle Type',
        type: 'select',
        options: [
          { value: 'Type 1 (Tata Ace)', placeholder: 'Type 1 (Tata Ace)' },
          {
            value: 'Type 2 (Bolero/ Lely land)',
            placeholder: 'Type 2 (Bolero/ Lely land)',
          },
          { value: 'Canter', placeholder: 'Canter' },
          { value: '20th Ft Cold', placeholder: '20th Ft Cold' },
          { value: '24th Ft Cold', placeholder: '24th Ft Cold' },
          { value: 'EV', placeholder: 'EV' },
          { value: 'Carry Pickup Van', placeholder: 'Carry Pickup Van' },
          { value: 'Other - Small', placeholder: 'Other - Small' },
          { value: 'Other - Large', placeholder: 'Other - Large' },
        ],
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Vehicle Type is required'],
        isReadOnly: false,
      },
      {
        key: 'shiftLength',
        placeholder: 'Shift Length',
        type: 'select',
        defaultValue: '',
        options: appConstants.masterData.TRANSPORT_SHIFT_LENGTH,
        masterDataKey: 'TRANSPORT_SHIFT_LENGTH',
        validations: { required: true },
        isReadOnly: false,
        validationMessages: ['Shift Length is required'],
      },
      {
        key: 'agreedVehicles',
        placeholder: 'Agreed Vehicles',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.positiveNonDecimalNumber,
          min: 1,
        },
        validationMessages: [
          'Agreed Vehicles is required',
          'Agreed Vehicles is a positive non decimal number',
          'Agreed Vehicles should greater than 0',
        ],
      },
      {
        key: 'monthlyFixedRate',
        placeholder: 'Monthly Fixed Rate',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
          min: 1,
        },
        validationMessages: [
          'Monthly Fixed Rate is required',
          'Monthly Fixed Rate is a positive number',
          'Monthly Fixed Rate should greater than 0',
        ],
      },
      {
        key: 'kmCharge',
        placeholder: 'Km Charge',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
          min: 1,
        },
        validationMessages: [
          'Km Charge is required',
          'Km Charge is a positive number',
          'Km Charge should greater than 0',
        ],
      },
      {
        key: 'bonusAmount',
        placeholder: 'Bonus Amount',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
        },
        validationMessages: [
          'Bonus Amount is required',
          'Bonus Amount is a positive number',
        ],
      },
      {
        key: 'penaltyAmount',
        placeholder: 'Penalty Amount',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
        },
        validationMessages: [
          'Penalty Amount is required',
          'Penalty Amount is a positive number',
        ],
      },
      {
        key: 'agreedKm',
        placeholder: 'Agreed Kms (Monthly)',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
          min: 1,
        },
        validationMessages: [
          'Agreed Kms (Monthly) is required',
          'Agreed Kms (Monthly) is a positive number',
          'Agreed Kms (Monthly) should greater than 0',
        ],
      },
      {
        key: 'extraKmCharge',
        placeholder: 'Extra Km Charge',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
        },
        validationMessages: [
          'Extra Km Charge is required',
          'Extra Km Charge is a positive number',
        ],
      },
      {
        key: 'extraHourPayment',
        placeholder: 'Extra Hour Payment',
        type: 'number',
        defaultValue: '',
        isReadOnly: false,
        disabled: false,
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
        },
        validationMessages: [
          'Extra Hour Payment is required',
          'Extra Hour Payment is a positive number',
        ],
      },
      {
        key: 'validFrom',
        placeholder: 'Valid From Date',
        type: 'date',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Valid From Date is required'],
        datePickerType: 'calendar',
        isReadOnly: false,
        disabled: false,
        minDate: appConstants.getDateByNumberOfDays(0),
      },
      {
        key: 'validTo',
        placeholder: 'Valid Till Date',
        type: 'date',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Valid Till Date is required'],
        datePickerType: 'calendar',
        isReadOnly: false,
        disabled: false,
        minDate: appConstants.getDateByNumberOfDays(0),
      },
      {
        key: 'id',
        placeholder: 'Vendor Vehicle Id',
        type: 'number',
        defaultValue: '',
        hideField: true,
        isReadOnly: true,
        disabled: true,
      },
    ],
  },
  dailyReportContainer: {
    tableHeader: [
      { header: 'Vendor Name', key: 'vendorName' },
      { header: 'Vehicle Type', key: 'vehicleType' },
      { header: 'Vehicle Number', key: 'vehicleNumber' },
      { header: 'ClusterName', key: 'clusterName' },
      { header: 'Shift Type', key: 'shiftType' },
      { header: 'In KM', key: 'inKm', subkey: 'value', whatToDo: 'subkey' },
      { header: 'Out KM', key: 'outKm', subkey: 'value', whatToDo: 'subkey' },
      {
        header: 'Total Km Run',
        key: 'totalKmRun',
        subkey: 'value',
        whatToDo: 'subkey',
      },
    ],
    metaData: {
      header: 'Daily Report',
      showRefresh: true,
      viewExport: AppWebRoutes.PPD.exportDailyReport,
      viewPrivilege: 'view_transport_report',
      removeGlobalFilter: true,
      editRoute: 'ppd/transport/daily-report-curd',
      canEdit: true,
    },
    search: [
      {
        key: 'startDate',
        placeholder: 'Date Range',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
        dateRange: true,
        dateRangeKey: 'endDate',
        defaultValue: [new Date(), new Date()],
      },
      {
        key: 'hubId',
        placeholder: 'Hub Ids',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.HUBS.hubs,
        optionsParams: { active: 1 },
        defaultValue: '',
        hubIdRequired: false,
        uiKey: 'name',
        apiKey: 'id',
        searchableDropDown: true,
        chipOptions: {},
        chip: false,
        module: 'search',
      },
      {
        key: 'isKmValid',
        placeholder: 'Total Kms Run',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Valid' },
          { value: 0, placeholder: 'In Valid' },
          { value: 2, placeholder: 'All' },
        ],
        chip: false,
        likeSearch: false,
        searchableDropDown: false,
        defaultValue: 2,
        module: 'search',
      },
    ],
    actions: [
      {
        icon: 'edit',
        label: 'Edit In-Out KMs',
        color: 'accent',
        privilege: 'edit_transport_report',
      },
    ],
  },
  dailyReportVechileInOutCrudContainer: {
    crud: [
      {
        key: 'vendorName',
        placeholder: 'Vendor Name',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Vendor name is required'],
        isReadOnly: true,
        disabled: true,
      },
      {
        key: 'vehicleType',
        placeholder: 'Vehicle Type',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Vehicle Type is required'],
        isReadOnly: true,
        disabled: true,
      },
      {
        key: 'vehicleNumber',
        placeholder: 'Vehicle Number',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Vehicle Number is required'],
        isReadOnly: true,
        disabled: true,
      },
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['Date is required'],
        datePickerType: 'calendar',
        isReadOnly: true,
        disabled: true,
      },
      {
        key: 'inKm',
        placeholder: 'In Kms',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
          min: 1,
        },
        validationMessages: [
          'In Kms is required',
          'In Kms is a positive number',
          'In Kms should greater than 0',
        ],
      },
      {
        key: 'outKm',
        placeholder: 'Out Kms',
        type: 'number',
        defaultValue: '',
        validations: {
          required: true,
          pattern: appConstants.decimalNumberPattern,
          min: 1,
        },
        validationMessages: [
          'Out Kms is required',
          'Out Kms is a positive number',
          'Out Kms should greater than 0',
        ],
      },
      {
        key: 'id',
        placeholder: 'Vendor Vehicle Id',
        type: 'number',
        defaultValue: '',
        hideField: true,
        isReadOnly: true,
        disabled: true,
      },
      {
        key: 'transportVehicleStatusId',
        placeholder: 'Transport Vehicle Status Id',
        type: 'number',
        defaultValue: '',
        hideField: true,
        isReadOnly: true,
        disabled: true,
      },
    ],
  },
  consolidatedReportContainer: {
    tableHeader: [],
    crud: [
      {
        key: 'startDate',
        placeholder: 'Start Date',
        type: 'date',
        defaultValue: '',
        target: 'endDate',
        targetIndex: 1,
        validations: { required: true },
        validationMessages: ['Start Date is required'],
        datePickerType: 'both',
        isReadOnly: false,
      },
      {
        key: 'endDate',
        placeholder: 'End Date',
        type: 'date',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['End Date is required'],
        datePickerType: 'both',
        isReadOnly: false,
      },
      {
        key: 'mailId',
        placeholder: 'Enter Email',
        type: 'text',
        defaultValue: '',
        isReadOnly: false,
      },
    ],
  },
  vendorReportContainer: {
    tableHeader: [],
    crud: [
      {
        key: 'vendorName',
        placeholder: 'Vendor Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.PPD.vendorNameAutocomplete,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        hintLabel: '',
        uiKey: 'vendorName',
        apiKey: 'id',
        hubIdRequired: true,
        module: 'crud',
        validations: {
          required: true,
        },
        validationMessages: ['Vendor Name is required'],
      },
      {
        key: 'vendorId',
        placeholder: 'Vendor Code',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.PPD.vendorIdAutocomplete,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        filteredOptions: null,
        chipOptions: {},
        hintLabel: '',
        uiKey: 'vendorCode',
        apiKey: 'id',
        hubIdRequired: true,
        module: 'crud',
        validations: {
          required: true,
        },
        validationMessages: ['Vendor Code is required'],
      },
      {
        key: 'startDate',
        placeholder: 'Start Date',
        type: 'date',
        defaultValue: '',
        target: 'endDate',
        targetIndex: 3,
        validations: { required: true },
        validationMessages: ['Start Date is required'],
        datePickerType: 'both',
        isReadOnly: false,
      },
      {
        key: 'endDate',
        placeholder: 'End Date',
        type: 'date',
        defaultValue: '',
        validations: { required: true },
        validationMessages: ['End Date is required'],
        datePickerType: 'both',
        isReadOnly: false,
      },
      {
        key: 'mailId',
        placeholder: 'Enter Email',
        type: 'text',
        defaultValue: '',
        isReadOnly: false,
      },
    ],
  },
  pickingRollbackContainer: {
    tableHeader: [
      {
        header: 'User mail ID',
        key: 'email',
      },
    ],
    metaData: {
      checkbox: true,
      hidePagination: true,
      hidelocalSearch: true,
      showRefresh: false,
      showCreate: false,
      confirmation: {
        message: 'Do you want to proceed with rollback for selected Mail-ID?',
        title: 'Confirm!',
        why: 'update',
      },
    },
    crud: [
      {
        key: 'teamId',
        placeholder: 'Enter Team Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.PPD.orderRouting,
        chip: true,
        likeSearch: false,
        searchableDropDown: false,
        options: [],
        chipOptions: {},
        autoComplete: true,
        uiKey: 'name',
        module: 'crud',
        apiKey: 'id',
        hubIdRequired: true,
        filterWithHubCity: 'hubId',
        validations: { required: true },
        validationMessages: ['Team Name is required'],
      },
    ],
  },
  nonConsumableStickerContainer: {
    crud: [
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        defaultValue: new Date(),
        datePickerType: 'calendar',
        isReadOnly: false,
        validations: { required: true },
        validationMessages: ['Date is required'],
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.clusters,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        uiKey: 'name',
        apiKey: 'id',
        filterWithHubCity: 'hubId',
        validations: { required: true },
        validationMessages: ['Cluster is required'],
      },
    ],
  },
  eWayContainer: {
    crud: [
      {
        key: 'date',
        placeholder: 'Enter Date',
        type: 'date',
        defaultValue: new Date(),
        validations: { required: true },
        validationMessages: ['Date is required'],
        datePickerType: 'calendar',
        isReadOnly: false,
        minDate: appConstants.getDateByNumberOfDays(-10),
        maxDate: new Date(),
      },
    ],
  },
  naStickerContainer: {
    crud: [
      {
        key: 'date',
        placeholder: 'Date',
        type: 'date',
        defaultValue: new Date(),
        datePickerType: 'calendar',
        isReadOnly: false,
        validations: { required: true },
        validationMessages: ['Date is required'],
        minDate: appConstants.getDateByNumberOfDays(0),
        maxDate: appConstants.getDateByNumberOfDays(1),
      },
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.clusters,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        uiKey: 'name',
        apiKey: 'id',
        filterWithHubCity: 'hubId',
        validations: { required: true },
        validationMessages: ['Cluster is required'],
      },
      {
        key: 'isNa',
        placeholder: 'Is Na Sticker',
        type: 'number',
        hideField: true,
        defaultValue: 1,
        isReadOnly: true,
        disabled: true,
      },
    ],
  },
  naStickerGenerateContainer: {
    crud: [
      {
        key: 'clusterId',
        placeholder: 'Cluster Name',
        type: 'autocomplete',
        apiEndPoint: AppWebRoutes.CLUSTERS.clusters,
        chip: true,
        likeSearch: false,
        searchableDropDown: true,
        options: [],
        chipOptions: {},
        icon: 'search',
        uiKey: 'name',
        apiKey: 'id',
        filterWithHubCity: 'hubId',
        validations: { required: true },
        validationMessages: ['Cluster is required'],
      },
      {
        key: 'isNa',
        placeholder: 'Is Na Sticker',
        type: 'number',
        hideField: true,
        defaultValue: 1,
        isReadOnly: true,
        disabled: true,
      },
    ],
  },
  ppdBannerContainer: {
    tableHeader: [
      { header: 'Id', key: 'id', sortBy: true },
      {
        header: 'Name',
        key: 'name',
      },
      {
        header: 'Active',
        key: 'active',
        pipe: 'updateProperty',
        pipeConfig: {
          createCustomString: function (row) {
            return `${row.active ? 'Yes' : 'No'
              }`;
          },
        },
      },
      {
        header: 'Image',
        key: 'image',
        whatToDo: 'image',
        componentPath: 'ppd-banners/',
      },
      {
        header: 'User Role',
        key: 'userRole',
        pipe: 'updateProperty',
        pipeConfig: {
          createCustomString: function (row) {
            return `${row.userRole ? row.userRole === 1 ? 'Full Time' : 'Part Time' : 'ALL'
              }`;
          },
        },
      },
      { header: 'Start Date', key: 'startDate', sortBy: true },
      { header: 'End Date', key: 'endDate', sortBy: true },
      { header: 'Sort Order', key: 'sortOrder'},
      { header: 'Redirection Link', key: 'redirectionLink'},
      { header: 'Hub Ids', key: 'hubs'},
      { header: 'Group Ids', key: 'groups'},
    ],
    metaData: {
      header: 'Banners List',
      btnText: 'Create Banner',
      canEdit: true,
      canDelete: true,
      createRoute: 'add',
      editRoute: 'ppd/ppd-banner/edit',
      delete: {
        url: 'ppd/promotions/banners',
        message: 'Are you sure you want to delete banner?',
        title: 'Delete banner',
      },
      showCreate: true,
      showRefresh: true,
      viewExport: AppWebRoutes.PPD.export,
      viewPrivilege: 'view_ppd_banner',
      createPrivilege: 'update_ppd_banner',
      getDialogDataForActiveDeActive: function (isActive) {
        return {
          url: 'ppd-banner/',
          message:
            'It will remove all recurring orders of all paid customers for the society' +
            '  ' +
            'It will remove all future orders of all paid customers for the society' +
            ' It will create Freshdesk tickets to close account for all paid customers.' +
            '  ' +
            'Send SMS to all customers notifying society deactivate.' +
            ' ' +
            ' Remove the society from its cluster.' +
            (isActive ? 'deactivate' : 'activate') +
            ' a subcategory?',
          title: (isActive ? 'Deactivating ' : 'Activating') + 'the society will have the following impact:',
          why: 'update'
        };
      },
      confirmation: {
        url: 'societies/deactivate',
        message:
          'It will remove all recurring orders of all paid customers for the society and It will remove all future orders of all paid customers for the society',
        title: 'Deactivating the society will have the following impact:'
      }
    },
    actions: [
      {
        icon: 'edit',
        label: 'Edit',
        color: 'primary',
        privilege: 'update_ppd_banner',
      },
      {
        icon: 'delete',
        label: 'Delete',
        color: 'primary',
        privilege: 'update_ppd_banner',
      },
      {
        icon: 'lock_open',
        privilege: 'update_ppd_banner',
        label: 'ActivatePpdBanner',
        getLabel: function (row) {
          return `${row.active ? 'Deactivate Ppd Banner' : 'Activate Ppd Banner'}`;
        },
        color: 'accent',
        getIcon: function (row) {
          return `${row.active === 1 ? 'lock_open' : 'lock'}`;
        }
      },
    ],
    search: [
      {
        key: 'startDate',
        placeholder: 'Start Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'startDate',
        apiKey: 'startDate',
        module: 'search',
        datePickerType: 'calendar',
      },
      {
        key: 'endDate',
        placeholder: 'End Date',
        type: 'date',
        chip: true,
        options: [],
        chipOptions: {},
        uiKey: 'endDate',
        apiKey: 'endDate',
        module: 'search',
        datePickerType: 'calendar',
      },
      {
        key: 'active',
        placeholder: 'Active',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Yes' },
          { value: 0, placeholder: 'No' },
        ],
        chip: false,
        likeSearch: false,
        searchableDropDown: false,
        defaultValue: '',
        module: 'search',
      },
    ],
    crud: [
      {
        key: 'hubIds',
        placeholder: 'Select Hub',
        type: 'multichip',
        apiEndPoint: AppWebRoutes.HUBS.all,
        extraOptions: [ { name: 'All', id: 0 } ],
        defaultValue: '',
        uiKey: 'name',
        apiKey: 'id',
        searchableDropDown: true,
        chipOptions: {},
        chip: false,
        filterWithHubCity: 'hubddddId',
        hintLabel:'Empty hubId will enable banner to all hubs'
      },
      {
        key: 'name',
        placeholder: 'Name',
        type: 'text',
        validations: { required: true },
        defaultValue: '',
        validationMessages: [ 'PPD Banner Name is required' ],
        isReadOnly: false,
      },
      {
        key: 'redirectionLink',
        placeholder: 'Redirection Link',
        type: 'text',
        defaultValue: '',
        isReadOnly: false,
      },
      {
        key: 'userRole',
        placeholder: 'User role',
        type: 'select',
        options: [
          { value: '', placeholder: 'All' },
          { value: 1, placeholder: 'Full Time' },
          { value: 2, placeholder: 'Part Time' },
        ],
        defaultValue: '',
        hintLabel:'ALL will enable banner to all user role',
        isReadOnly: false,
      },
      {
        key: 'startDate',
        placeholder: 'Start Date',
        type: 'date',
        defaultValue: '',
        validations: { required: true },
        minDate: new Date(),
        validationMessages: [ 'Start Date is required' ],
        datePickerType: 'calendar',
        isReadOnly: false,
      },
      {
        key: 'endDate',
        placeholder: 'End Date',
        type: 'date',
        defaultValue: '',
        minDate: new Date(),
        datePickerType: 'calendar',
        isReadOnly: false,
      },
      {
        key: 'image',
        placeholder: 'File Upload',
        type: 'file',
        defaultValue: '',
        customApiEndPoint: AppWebRoutes.PPD.imageupload,
        accept: '.jpeg,.jpg,.png,.gif',
        validations: { required: true },
        validationMessages: [ 'Image is required' ],
        immediateImageUpload: true,
        isReadOnly: false,
        hintLabel:'Please provide banner image for size 300 * 300 for better look and feel',
      },
      {
        key: 'sortOrder',
        placeholder: 'Sort Order',
        type: 'text',
        validations: {
          required: true,
          pattern: appConstants.positiveNonDecimalNumber,
        },
        defaultValue: '',
        validationMessages: [
          'Sort Order is required',
          'Sort Order is not valid',
        ],
        isReadOnly: false,
      },
      {
        key: 'groupIds',
        placeholder: 'Group',
        type: 'multichip',
        apiEndPoint: AppWebRoutes.GROUPS.autocomplete,
        defaultValue: '',
        isReadOnly: false,
        disabled: false,
        uiKey: 'name',
        apiKey: 'id',
        autoComplete: true,
        hintLabel:'Empty groupId will enable banner to all groups',
      },
      {
        key: 'active',
        placeholder: 'Active',
        type: 'select',
        options: [
          { value: 1, placeholder: 'Yes' },
          { value: 0, placeholder: 'No' },
        ],
        validations: { required: true },
        defaultValue: '',
        validationMessages: ['Active is required'],
        isReadOnly: false,
      },
    ],
  },
};
