import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor (private authService: AuthService, private router: Router, private permissionsService: NgxPermissionsService) {
  }

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin (url: string): boolean {
    if (this.authService.isLoggedIn.value && this.authService.getAuthentication().profile[ 'hubName' ]) {
      if (Object.keys(this.permissionsService.getPermissions()).length === 0) {
        const authenticationData = this.authService.getAuthentication();
        if (authenticationData) {
          this.permissionsService.loadPermissions(authenticationData.profile.privileges);
        }
      }
      return true;
    }
    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;
    // Navigate to the login page with extras
    this.router.navigate([ 'login' ]);
    return false;
  }
}
