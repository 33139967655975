import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/data/data.service';
import { appConstants } from '../../constants/app-constant';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../../shared/custom-validators/must-match.validator';
import { UpdatePasswordService } from './update-password.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AppWebRoutes } from '../../constants/app-web-routes';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  public setPasswordForm: FormGroup;
  public hidePassword = true;
  public userToken: string;
  public isPasswordChanged: boolean;
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private updatePasswordService: UpdatePasswordService
  ) {
    this.dataService.sendSideNavData({
      title: appConstants.pages.updatePassword.label
    });
  }

  ngOnInit() {
    this.userToken = this.route.snapshot.queryParamMap.get(
      this.route.snapshot.queryParamMap.keys[0]
    );

    this.setPasswordForm = this.formBuilder.group(
      {
        password: [
          '',
          Validators.compose([Validators.required, Validators.minLength(4)])
        ],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: MustMatch('password', 'confirmPassword')
      }
    );
  }

  onSetSubmit() {
    if (this.setPasswordForm.invalid) {
      return;
    }
    const requestData = {
      ...this.setPasswordForm.value,
      ...{ token: decodeURIComponent(this.userToken) }
    };

    this.updatePasswordService
      .setNewPassword(requestData)
      .subscribe((success: Response) => {
        if (success === null) {
          this.isPasswordChanged = true;
        }
      });
  }
}
