<div class="counter-section">
  <button mat-stroked-button color="primary" [ngClass]="{'disabled': (this.given == 0) || disabled}" (click)="decrement()">
    <mat-icon>remove</mat-icon>
  </button>
  <div class="counter">
    <span>{{ this.given }}</span>
    <span>
      <span>&nbsp;/ </span>
      <span class="increment">{{this.required}}</span> </span>
  </div>
  <button mat-stroked-button color="primary" [ngClass]="{'disabled':this.given == this.required || disabled}" (click)="increment()">

    <mat-icon>add</mat-icon>
  </button>
</div>
