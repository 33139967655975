import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrim]'
})
export class TrimDirective {
  public inputElement;
  constructor(private el: ElementRef) {
    this.inputElement = this.el.nativeElement;
  }

  @HostListener('change') onChange() {
    this.inputElement.value = this.inputElement.value.trim();
    this.inputElement.dispatchEvent(new Event('input'));
  }
  @HostListener('keyup') onKeyUp() {
    if (this.inputElement.value && this.inputElement.value.length === 1 && this.inputElement.value === ' ') {
      this.inputElement.value = '';
      this.inputElement.dispatchEvent(new Event('input'));
    }
  }

}
