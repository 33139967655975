import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'updateProperty'
})
export class UpdatePropertyPipe implements PipeTransform {
  transform (initialValue, replaceValue) {
    const config = replaceValue.pipeConfig;
    if (replaceValue.type === 'autocomplete') {
      if (config && typeof config === 'object') {
        if ('createCustomString' in config) {
          return config.createCustomString(initialValue);
        } else {
          return config[ initialValue[ replaceValue.key ] ];
        }
      } else {
        if (replaceValue.subkey || replaceValue.subKey) {
          return `${initialValue[ replaceValue.uiKey ][ replaceValue.subkey || replaceValue.subKey ]}`;
        } else {
          return `${initialValue[ replaceValue.uiKey ]}`;
        }
      }
    } else {
      if (config && typeof config === 'object') {
        if ('createCustomString' in config) {
          if (config[ 'requiresMeta' ]) {
            return config.createCustomString(initialValue, replaceValue);
          } else {
            return config.createCustomString(initialValue, replaceValue);
          }
        } else {
          return config[ initialValue[ replaceValue.key ] ];
        }
      } else if (typeof config === 'string' && initialValue[ config ]) {
        if (replaceValue.subkey) {
          return `${initialValue[ replaceValue.key ][ replaceValue.subkey ]} (${initialValue[ config ]})`;
        } else {
          return `${initialValue[ replaceValue.key ]} (${initialValue[ config ]})`;
        }
      } else {
        return initialValue[ replaceValue.key ];
      }
    }
  }
}
