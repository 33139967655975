import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../../shared/services/data/data.service';
import { appConstants } from '../../constants/app-constant';
import { LoginService } from './login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppWebRoutes } from '../../constants/app-web-routes';
import { environment } from '../../../../environments/environment';
declare var require: any;
import { WindowRef } from '../../constants/windowRef';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnInit, OnDestroy {
  public hidePassword = true;
  public loginForm: FormGroup;
  public token;
  toggleCityHubSelector = false;
  showManualOrNot = false;
  showLoaderlogin = false;
  retry = 3;
  constructor (
    private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    public _route: ActivatedRoute,
    private winRef: WindowRef
  ) {
    if (this.phase.phase1 || this.phase.phase2) {
      this.showManualOrNot = true;
    }
    const params = <any>this._route.params;
    this.token = params.value.token;
    if (this.token) {
      this.toggleCityHubSelector = true;
      this.authService.setAuthentication({ token: this.token });
    }
    this.dataService.sendSideNavData({ title: appConstants.pages.login.label });
    this.loginForm = this.formBuilder.group({
      username: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(appConstants.emailPattern)
        ])
      ],
      password: [ '', Validators.compose([ Validators.required ]) ]
    });
  }
  public phase = require('../../constants/phase-constant.json');

  ngOnInit () {
    if (this.authService.isLoggedIn.value) {
      const profileValue = this.authService.getAuthentication();
      if (
        profileValue &&
        profileValue.profile &&
        profileValue.profile[ 'hubName' ]
      ) {
        this.router.navigate([ '' ]);
      } else {
        this.toggleCityHubSelector = true;
      }
    }
    if (this.token) {
      this.showLoaderlogin = true;
      this.getMasterData();
    }
    this.authService.isLoggedIn.subscribe(data => {
      if (data === false) {
        this.toggleCityHubSelector = false;
        this.loginForm.reset();
      }
    });
  }

  signIn () {
    this.showLoaderlogin = true;
    this.loginService
      .login(this.loginForm.value)
      .subscribe((success: Response) => {
        if (success && success[ 'authenticated' ]) {
          this.authService.setAuthentication({ token: success[ 'authToken' ] });
          this.getMasterData();
        }
      });
  }

  profileDetail (callback?) {
    this.loginService.getUserProfile().subscribe((success: Response) => {
      if (success) {
        this.dataService.userProfile = success;
        this.authService.setUserProfile({
          profile: this.dataService.userProfile
        });
        callback && callback();
      }
    });
  }
  getMasterData () {
    this.loginService.getMasterData().subscribe((success: Response) => {
      if (success) {
        const masterDataObject = {};
        Object.keys(success).map(masterObject => {
          masterDataObject[ masterObject ] = [];
          success[ masterObject ][ 'items' ].map(element => {
            masterDataObject[ masterObject ].push({
              value: element.key,
              placeholder: element.value
            });
          });
        });
        this.dataService.removeWFAccountClosureA2BFreezeOption(masterDataObject['WALLET_FREEZE']);
        this.dataService.setLocalStorage(
          environment.localStorageKeys.MASTERDATA,
          masterDataObject
        );
        this.profileDetail(() => {
          this.toggleCityHubSelector = true;
          this.showLoaderlogin = false;
          this.getValueFromFirebase(this.retry);
        });
      }
    });
  }

  getValueFromFirebase (retry: number) {
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings = {
      fetchTimeoutMillis: 60000,
      minimumFetchIntervalMillis: 1
    };
    remoteConfig.fetchAndActivate()
      .then(() => {
        this.getValueFromRemoteConfig('firebase_masterdata', appConstants);
      })
      .catch((error) => {
        if (++retry <= 3) {
          this.getValueFromFirebase(retry);
        }
      });
  }
  // Initializing the remote config, getting data from remote config and setting in app constant provided by parameter.
  getValueFromRemoteConfig (key, keyToSaveData) {
    const remoteConfig = firebase.remoteConfig();
    keyToSaveData[ key ] = JSON.parse(remoteConfig.getValue(key).asString());
    this.dataService.setLocalStorage(
      environment.localStorageKeys.FIREBASE_MASTERDATA,
      keyToSaveData[ key ]
    );
  }
  
  setCityHub () {
    appConstants[ 'menu' ][ 'hubcatalog' ][ 'root' ][
      'submenu'
    ][ 3 ].path = `/catalog/hub/${this.dataService.globalFilter.apiValue.hubId
    }/product`;
    this.authService.setUserProfile(
      {
        profile: this.dataService.getLocalStorage(
          environment.localStorageKeys.USER
        ).profile
      },
      true
    );
  }

  loginWithGoogle () {
    this.winRef
      .nativeWindow.open(
        `${environment.base_url}${AppWebRoutes.AUTH.user}${AppWebRoutes.AUTH.googleLogin
        }`,
        '_self'
      );
  }

  ngOnDestroy () {
    this.dataService.destroyObservable();
  }
}
