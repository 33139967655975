import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../containers/home/home.component';
import { LoginComponent } from '../containers/login/login.component';
import { AuthGuardService } from '../../shared/services/auth-guard/auth-guard.service';
import { PageNotFoundComponent } from '../containers/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from '../containers/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from '../containers/update-password/update-password.component';
import { SettingComponent } from '../containers/app-settings/setting.component';
import { UserNameComponent } from '../containers/user-name/user-name.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'settings',
    component: SettingComponent
  },
  {
    path: 'user-name',
    component: UserNameComponent
  },
  {
    path: 'login/:token',
    component: LoginComponent
  },
  {
    path: 'user',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: '',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'procurement',
    loadChildren: () => import('../../procurement/procurement.module').then(m => m.ProcurementModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'search',
    loadChildren: () => import('../../search/search.module').then(m => m.SearchModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'marketing',
    loadChildren: () => import('../../marketing/marketing.module').then(m => m.MarketingModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'catalog',
    loadChildren: () => import('../../hub-catalog/hub-catalog.module').then(m => m.HubCatalogModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'selfhelp',
    loadChildren: () => import('../../self-help/self-help.module').then(m => m.SelfHelpModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'cims',
    loadChildren: () => import('../../CIMS/cims.module').then(m => m.CimsModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ims',
    loadChildren: () => import('../../ims/ims.module').then(m => m.IMSModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'master',
    loadChildren:
      () => import('../../master-catalog/master-catalog.module').then(m => m.MasterCatalogModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'location',
    loadChildren: () => import('../../location/location.module').then(m => m.LocationModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'customer',
    loadChildren: () => import('../../customer/customer.module').then(m => m.CustomerModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'basket',
    loadChildren: () => import('../../basket/basket.module').then(m => m.BasketModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'order',
    loadChildren: () => import('../../order/order.module').then(m => m.OrderModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ppd',
    loadChildren: () => import('../../ppd/ppd.module').then(m => m.PPDModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'transaction',
    loadChildren: () => import('../../transaction/transaction.module').then(m => m.TransactionModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'common',
    loadChildren: () => import('../../admin-common/admin-common.module').then(m => m.AdminCommonModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'update-password',
    component: UpdatePasswordComponent
  },
  {
    path: 'configuration',
    loadChildren:
      () => import('../../configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'collection',
    loadChildren: () => import('../../collection/collection.module').then(m => m.CollectionModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'wms',
    loadChildren: () => import('../../wms/wms.module').then(m => m.WMSModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'bd-tracker',
    loadChildren: () => import('../../bd-tracker/bd-tracker.module').then(m => m.BdTrackerModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
