import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedCollectionService } from 'src/app/collection/services/collection.service';
import { DataService } from 'src/app/shared/services/data/data.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { MediaExtension, MediaType } from 'src/app/core/constants/app-constant';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: [ './media.component.scss' ]
})
export class MediaComponent implements OnInit {
  files: NgxFileDropEntry[] = [];
  filesToUploadMap = new Map();
  format: any;
  mediaType = MediaType;
  _url: string | ArrayBuffer;
  @Output() onUpload = new EventEmitter();
  public collectionCrud;

  @Input() schema;
  @Input() form: FormGroup;
  @Input() parentForm?: FormGroup;

  constructor (
    public _route: ActivatedRoute,
    private sharedCollectionService: SharedCollectionService,
    private dataService: DataService,
    public permissionsService: NgxPermissionsService,
  ) { }

  ngOnInit () {
    let fileName = this.form.controls[ this.schema.key ].value;
    if (fileName) {
      this.setUrl(fileName);
      this.getFileType(fileName);
    }
    this.setupFormValueChanges();
  }

  setupFormValueChanges() {
    this.form.controls[ this.schema.key ].valueChanges.subscribe(value => {
      this.getFileType(value);
      this.setUrl(value);
    });
  }

  setUrl(filePath) {
    if(filePath) {
      this._url = environment.file_url + 'collection/' + filePath;
    } else {
      this._url = undefined;
    }
  }

  getLocationTypeName() {
    let displayTypeId = (this.parentForm && this.parentForm.value.locationType) || 0;
    let locationTypeName = 'home';
    if (displayTypeId == 1)
      locationTypeName = 'list';
    return locationTypeName;
  }

  public dropped (files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (!this.filesToUploadMap.hasOwnProperty(file.name) &&
            (this.schema.acceptType.split(',').includes(file.name.substr(file.name.lastIndexOf("."))))) {
            this.files = files;
            this.filesToUploadMap.set(file.name, file);
            var reader = new FileReader();
            reader.readAsDataURL(file);
            this.getFileType(file.name);
            reader.onload = (event) => {
              this._url = (<FileReader>event.target).result;
              this.uploadMediaFiles();
            }
          } else {
            this.dataService.openSnackBar(`Only ${this.schema.acceptType} files are allowed`, 'danger');
          }
        });
      }
    }
  }

  getFileType (fileName) {
    if(!fileName) {
      this.format = this.mediaType.VIDEO;
      return;
    }
    let extension = fileName.split('.').pop();
    switch (extension) {
      case MediaExtension.PNG:
      case MediaExtension.JPG:
      case MediaExtension.JPEG:
        this.format = this.mediaType.IMAGE;
        break;
      case MediaExtension.MP4:
        this.format = this.mediaType.VIDEO;
        break;
      case MediaExtension.JSON:
        this.format = this.mediaType.LOTTIE;
        break;
      default:
        this.format = this.mediaType.DOCUMENT;
        break;
    }
  }

  uploadMediaFiles () {
    let locationTypeName = this.getLocationTypeName() || 'home';
    let url = '';
    let apiKey = '';
    if(this.format === this.mediaType.VIDEO) {
      return this.uploadLargeVideoFiles();
    }
    else if (this.format === this.mediaType.IMAGE) {
      url = AppWebRoutes.COLLECTION.imagesUpload;
      apiKey = 'image';
    } else if (this.format === this.mediaType.LOTTIE) {
      url = `${AppWebRoutes.COLLECTION.videoLottieUpload}?type=${locationTypeName}`;
      apiKey = 'video';
    }
    let mediaFileUploaded = 0;
    const totalFilesToUpload = Array.from(this.filesToUploadMap.keys()).length;
    this.filesToUploadMap.forEach((file: File, key: string) => {
      let dataToSendForImageUpload = {
        fileName: file.name,
        file: (this._url as string).split(',')[ 1 ]
      };
      this.sharedCollectionService
        .uploadFile(url, dataToSendForImageUpload, { hideLoader: true })
        .subscribe((success: Response) => {
          if (success && success[ 'fileName' ]) {
            mediaFileUploaded += 1;
            if (mediaFileUploaded === totalFilesToUpload) {
              this.form.controls[ this.schema.key ].patchValue(success[ 'fileName' ]);
            }
          } else {
            this.reset();
          }
        });
    });
  }

  uploadLargeVideoFiles() {
    let mediaFileUploaded = 0;
    const totalFilesToUpload = Array.from(this.filesToUploadMap.keys()).length;
    this.filesToUploadMap.forEach((file: File, key: string) => {
      let fileName = file.name;
      let preSigningUrl = `${AppWebRoutes.COLLECTION.videoLottiePresign}?fileName=${fileName}`;
      
      this.sharedCollectionService
        .getPresigningUrl(preSigningUrl, { hideLoader: true })
        .subscribe((success: any) => {
          if (success) {
            let uploadUrl = success.url;

            let newFileName = success.fileName;
            this.sharedCollectionService
              .uploadLargeVideoFiles(uploadUrl, file, { headers: { 'Content-Type': 'binary/octet-stream'}, hideLoader: true })
              .subscribe((success: Response) => {
                if (success || success === null) {
                  mediaFileUploaded += 1;
                  if (mediaFileUploaded === totalFilesToUpload) {
                    this.form.controls[ this.schema.key ].patchValue(newFileName);
                    this.setUrl(newFileName);
                  }
                } else {
                  this.reset();
                }
              });
          } else {
            this.reset();
          }
        });
    });
  }

  reset () {
    this.files = [];
    this.filesToUploadMap.clear();
    this._url = '';
    this.form.controls[ this.schema.key ].patchValue(null);
  }

  public fileOver (event) { }

  public fileLeave (event) { }
}
