import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/data/data.service';
import { appConstants } from '../../constants/app-constant';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  constructor(public dataService: DataService) {
    this.dataService.sendSideNavData({
      title: appConstants.pages.pageNotFound.label
    });
  } 

  ngOnInit() {}
}
