import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { dateComponentActions } from '../../constants/shared-constant';
import * as moment from 'moment';
@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: [ './date.component.scss' ]
})
export class DateComponent implements OnInit, OnDestroy {
  @Input()
  _date;
  @Input()
  _crud;
  @Input()
  form: FormGroup;
  checking: boolean;
  min;
  max;
  defaultDate

  constructor (private dataService: DataService) { }

  ngOnInit () {
    this.min = this._date.minDate ? new Date(new Date(this._date.minDate).getFullYear(),new Date(this._date.minDate).getMonth(),new Date(this._date.minDate).getDate(), 0, 0, 0) : '';
    this.max = this._date.maxDate ? new Date(new Date(this._date.maxDate).getFullYear(),new Date(this._date.maxDate).getMonth(),new Date(this._date.maxDate).getDate(), 23, 59, 59) : '';
    if(this._date.type === "date") {
      this.defaultDate = this._date.defaultValue ? new Date(new Date(this._date.defaultValue).getFullYear(),new Date(this._date.defaultValue).getMonth(),new Date(this._date.defaultValue).getDate(), 0, 0, 0) : '';
    } else {
      this.defaultDate = this._date.defaultValue;      
    }
    if (this._date && this._date.defaultValue && !(this.dataService.searchFilters.filters && this.dataService.searchFilters.filters.date)) {
      this.dateInput(new Date(this._date.defaultValue));
    }
    if (this.dataService.searchFilters.filters && this.dataService.searchFilters.filters.date) {
      this.dateInput(new Date(this.dataService.searchFilters.filters.date));
    }
  }

  dateInput (date) {
    if (this._date.dateRange) {
      if (date[ 0 ] && date[ 1 ]) {
        date[ 0 ] = this.dateFormat(date[ 0 ]).toString();
        date[ 1 ] = this.dateFormat(date[ 1 ]).toString();
        if (this._date.module && this._date.module === dateComponentActions.search) {
          this.settingChipValue(date);
        } else {
          this.form.controls[ this._date.key ].patchValue('');
        }
      }
    } else {
      if (this._date.targetIndex) {
        this._crud[ this._date.targetIndex ].minDate = date;
      }
      if (this._date.onlyTimer) {
        date = this.timeFormat(date).toString();
      } else {
        date = this.dateFormat(date).toString();
      }
      if (this._date.module && this._date.module === dateComponentActions.search) {
        this.settingChipValue(date);
      } else {
        this.dataService.dataToSendForDate[ this._date.key ] = date;
      }
    }
  }

  timeFormat (date: Date) {
    return moment(date).format("HH:mm")
  }

  dateFormat (date: Date) {
    if (date) {
      let month: any = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      const time = [ date.getHours() < 10 ? '0' + date.getHours() : date.getHours() ] +
        ':' + [ date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() ] +
        ':' + [ date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() ];
      const dateChange = date.getFullYear() + '-' + month + '-' + [ date.getDate() < 10 ? '0' + date.getDate() : date.getDate() ];
      if (this._date.datePickerType === dateComponentActions.calendar) {
        return dateChange;
      } else if (this._date.datePickerType === dateComponentActions.both) {
        return dateChange + ' ' + time;
      } else if (this._date.datePickerType === dateComponentActions.time) {
        return time;
      }
    } else {
      return '';
    }
  }

  settingChipValue (date) {
    if (this._date.dateRange) {
      this.dataService.setSearchData(this._date, date[ 0 ], this._date.key, date[ 0 ]);
      this.dataService.setSearchData(this._date, date[ 1 ], this._date.dateRangeKey, date[ 1 ]);
      this._date.chipOptions = {
        key: this._date.key,
        value: date[ 0 ] + ' ~ ' + date[ 1 ],
        uiKey: this._date.uiKey,
        apiKey: this._date.apiKey,
        target: this._date.dateRangeKey
      };
    } else {
      this.dataService.setSearchData(this._date, date, this._date.key, date);
      this._date.chipOptions = {
        key: this._date.key,
        value: date,
        uiKey: this._date.uiKey,
        apiKey: this._date.apiKey
      };
    }

    this.form.controls[ this._date.key ].patchValue('');
  }

  ngOnDestroy () {
    this._date.chipOptions = {};
    this._date.options = [];
  }
}
