import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { HttpService } from '../../services/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultiChipService {
  constructor(private authService: AuthService, private _http: HttpService) {}
  getAutoSuggestions(apiEndPoint, dataToSend): Observable<object> {
    dataToSend.hideLoader = true;
    return this._http.get(apiEndPoint, dataToSend);
  }
  getAllSuggestions(apiEndPoint, options): Observable<object> {
    options.hideLoader = true;
    return this._http.get(apiEndPoint, null, options);
  }
}
