<div [formGroup]="form" class="autocomplete-form">
  <mat-form-field appearance="{{ _appearance ? 'outline' : null }}" class="autocomplete-width" [hidden]="_autocomplete.hideField" [hintLabel]="_autocomplete.hintLabel">
    <mat-label>{{ _autocomplete.placeholder }}</mat-label>
    <mat-icon
      matSuffix
      *ngIf="_autocomplete.searchableDropDown && !form.controls[ _autocomplete.key ].disabled"
      (click)="triggerClick()"
      >arrow_drop_down</mat-icon
    >
    <input
      *ngIf="!_autocomplete.useTextArea;else text_area_content"
      matInput
      appTrim
      type="text"
      autocomplete="off"
      [formControlName]="_autocomplete.key"
      [matAutocomplete]="matAutoComplete"
      (keyup)="fetchAutoSuggestions($event)"
      (click)="triggerClick()"
      [required]="
        _autocomplete.validations && _autocomplete.validations.required
      "
    />
    <ng-template #text_area_content>
      <textarea
        matInput
        appTrim
        type="text"
        wrap="soft"
        autocomplete="off"
        [formControlName]="_autocomplete.key"
        [matAutocomplete]="matAutoComplete"
        (keyup)="fetchAutoSuggestions($event)"
        (click)="triggerClick()"
        [required]="
          _autocomplete.validations && _autocomplete.validations.required
        "
      ></textarea>
    </ng-template>
    <mat-autocomplete #matAutoComplete="matAutocomplete">
      <div *ngIf="!_autocomplete.searchableDropDown">
        <mat-option *ngFor="let option of _autocomplete.options" (onSelectionChange)="
            onAutoCompleteSelection(
              $event,
              _autocomplete,
              option,
              _autocomplete.key,
              option[_autocomplete.apiKey]
            )
          " [value]="
            _autocomplete.module !== 'search'
              ? option[_autocomplete.apiKey]
              : null
          ">
          {{ option | updateProperty: _autocomplete }}
        </mat-option>
        <mat-option *ngIf="checkEmpty">No Result Found</mat-option>
      </div>
      <div *ngIf="_autocomplete.searchableDropDown">
        <mat-option *ngFor="let option of _autocomplete.filteredOptions | async" [value]="option[_autocomplete.apiKey]" (onSelectionChange)="
            onAutoCompleteSelection(
              $event,
              _autocomplete,
              option,
              _autocomplete.key,
              option[_autocomplete.apiKey]
            )
          ">
          {{ option | updateProperty: _autocomplete }}
        </mat-option>
        <mat-option *ngIf="
            !_autocomplete.filteredOptions ||
            _autocomplete.filteredOptions.length === 0
          ">No Result Found</mat-option>
      </div>
    </mat-autocomplete>
  </mat-form-field>
</div>
<app-error [form]="form" [_error]="_autocomplete"></app-error>
<div [innerHtml]="
    dataService.selectedText || dataService.apiValueForForm['sendSmsText']
  "></div>
