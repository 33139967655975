<div class="select-form" [formGroup]="form" [hidden]="_select.hideField">
  <mat-form-field class="select-form-width mat-form-field-select">
    <mat-select #select [placeholder]="_select.placeholder" [formControlName]="_select.key" [required]="_select.validations && _select.validations.required"
      (selectionChange)="onSelection(_select,_select.key)">
      <mat-option [value]="option.value" *ngFor="let option of _select.options;">
        {{option.placeholder}}
        <span *ngIf="option.color" class="color-box" [style.background-color]="option.color"></span>
      </mat-option>
    </mat-select>
    <small *ngIf="_select.hintLabel" class="hint">{{ _select.hintLabel }}</small>
  </mat-form-field>
  <app-error [form]="form" [_error]="_select"></app-error>
</div>
