import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MultiChipService } from './multi-chip.service';
import { DataService } from '../../services/data/data.service';
import { moduleType as module } from '../../constants/shared-constant';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormService } from '../../services/form/form.service';


@Component({
  selector: 'app-multi-chip',
  templateUrl: './multi-chip.component.html',
  styleUrls: [ './multi-chip.component.scss' ]
})
export class MultiChipComponent implements OnInit, OnDestroy {
  @Input()
  _multichip;
  @Input()
  form: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  filteredOptions = [];
  selectedOptions = {};
  count = false;
  @ViewChild('multiChipInput', { static: false })
  multiChipInput: ElementRef;
  constructor (private multiChipService: MultiChipService, public dataService: DataService, private formService: FormService) { }

  ngOnInit () {
    const optionParams = this.dataService.globalFilter.apiValue;
    if (
      this._multichip.module !== module.search ||
      Object.keys(this.dataService[ this._multichip.module === module.search ? 'dataToSendForSearchMultiChip' : 'dataToSendForCrudMultiChip' ]).length === 0
    ) {
      this.dataService[ this._multichip.module === module.search ? 'dataToSendForSearchMultiChip' : 'dataToSendForCrudMultiChip' ][ this._multichip.key ] = [];
      this.dataService.initDataForMultiChip[ this._multichip.key ] = [];
    }
    let params = {};
    if (this._multichip.filterWithHubCity) {
      for (const key in optionParams) {
        if (key === this._multichip.filterWithHubCity) {
          params[ key ] = optionParams[ key ];
        }
      }
    } else {
      params = optionParams;
    }
    if (!this._multichip.autoComplete) {      
      if (this._multichip.apiEndPoint) {
        this.multiChipService.getAllSuggestions(this._multichip.apiEndPoint, params).subscribe(success => {
          this._multichip.options = success;
          this._multichip.filteredOptions = success;
          if (this._multichip.extraOptions) {
            this._multichip.options = [ ...this._multichip.extraOptions, ...this._multichip.options ];
            this._multichip.filteredOptions = this._multichip.options;
          }
          if (this._multichip.options) {
            this._multichip.filteredOptions = this.form.controls[ this._multichip.key ].valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value))
            );
          }
        });
      }
      else {
        //options
        this._multichip.filteredOptions = this._multichip.options;
        this._multichip.filteredOptions = this.form.controls[ this._multichip.key ].valueChanges.pipe(
          startWith(''),
          map(inputValue => this._filter(inputValue))
        );
      }
    } else {
      this.form.controls[ this._multichip.key ].valueChanges.subscribe(res => {
        if (res && res.length >= 3) {
          this.getAutoCompleteResults(res);
        }
      });
    }
  }

  getAutoCompleteResults (value: string) {
    const dataToSend = { text: value };
    if (this._multichip.hubIdRequired) {
      dataToSend[ 'hubId' ] = this.dataService.globalFilter.apiValue.hubId;
    }
    this.multiChipService.getAllSuggestions(this._multichip.apiEndPoint, dataToSend).subscribe(success => {
      if (success) {
      this._multichip.options = success;
      this._multichip.filteredOptions = success;
      if (this._multichip.extraOptions) {
        this._multichip.options = [ ...this._multichip.extraOptions, ...this._multichip.options ];
        this._multichip.filteredOptions = this._multichip.options;
      }
      if (this._multichip.options) {
        this._multichip.filteredOptions = this.form.controls[ this._multichip.key ].valueChanges.pipe(
          startWith(''),
          map(inputValue => this._filter(inputValue))
        );
      }
    }
    });
  }


  private _filter (value) {
    if (value === null || value === undefined) {
      value = '';
    }
    return this._multichip.options?.filter(option => option[ this._multichip.uiKey ].toLowerCase().includes(value.toString().toLowerCase()));
  }

  remove (chip: string): void {
    const index = this.dataService.initDataForMultiChip[ this._multichip.key ].indexOf(chip);
    if (index >= 0) {
      const restoredOption = this.selectedOptions[ chip ];
      if (restoredOption) {
        this._multichip.options.splice(restoredOption.index, 0, restoredOption.option);
      }
      this.dataService.initDataForMultiChip[ this._multichip.key ].splice(index, 1);
      this.dataService[ this._multichip.module === module.search ? 'dataToSendForSearchMultiChip' : 'dataToSendForCrudMultiChip' ][ this._multichip.key ].splice(index, 1);
      if (this._multichip.module === module.search) {
        this.dataService.setSearchData(
          this._multichip,
          this.dataService.initDataForMultiChip[ this._multichip.key ],
          this._multichip.key,
          this.dataService[ this._multichip.module === module.search ? 'dataToSendForSearchMultiChip' : 'dataToSendForCrudMultiChip' ][ this._multichip.key ]
        );
        this._multichip.chipOptions = {
          key: this._multichip.key,
          value: this.dataService.initDataForMultiChip[ this._multichip.key ],
          apiValue: this.dataService[ this._multichip.module === module.search ? 'dataToSendForSearchMultiChip' : 'dataToSendForCrudMultiChip' ][ this._multichip.key ]
        };
      }
    }
    if (this.dataService.initDataForMultiChip[ this._multichip.key ] && this.dataService.initDataForMultiChip[ this._multichip.key ].length === 0) {
      this.form.controls[ this._multichip.key ].patchValue('');
    } else if (this.dataService.initDataForMultiChip[ this._multichip.key ] && this.dataService.initDataForMultiChip[ this._multichip.key ].length > 0) {
      this.form.controls[ this._multichip.key ].patchValue(this.dataService.initDataForMultiChip[ this._multichip.key ]);
    }
  }

  onSelectionChangeMultiChip (option, uiValue, key, apiValue) {
    if (this._multichip.module === module.search) {
      this.count = false;
      if (Object.keys(this.dataService.dataToSendForSearchMultiChip).length === 0) {
        this.dataService.dataToSendForSearchMultiChip[ key ] = [];
      }
      this.dataService.dataToSendForSearchMultiChip[ key ].forEach(id => {
        if (id === apiValue) {
          this.count = true;
        }
      });
      if (!this.count) {
        if (!this.dataService.initDataForMultiChip?.[this._multichip.key]?.length){
            this.dataService.initDataForMultiChip[this._multichip.key] =[];
        }
        this.dataService.initDataForMultiChip[ this._multichip.key ].push(uiValue);
        this.dataService.dataToSendForSearchMultiChip[ key ].push(apiValue);
        this.dataService.setSearchData(
          this._multichip,
          this.dataService.initDataForMultiChip[ this._multichip.key ],
          key,
          this.dataService.dataToSendForSearchMultiChip[ key ]
        );
        this._multichip.chipOptions = {
          key: this._multichip.key,
          value: this.dataService.initDataForMultiChip[ this._multichip.key ],
          apiValue: this.dataService.dataToSendForSearchMultiChip[ key ]
        };
      }
    } else {
      const optionWithLocation = { index: this._multichip.options.indexOf(option), option: option };
      this.selectedOptions[ uiValue ] = optionWithLocation;
      // if user selects all hub option starts
      if(this._multichip.key === "hubId") {
        if(option && option.id === -1 ) {
          this.dataService.initDataForMultiChip = {
            "hubId" : [option.name]
          }
          this.dataService.dataToSendForCrudMultiChip = {
            "hubId" : [option.id]
          }
        } else if(this.dataService.dataToSendForCrudMultiChip[ this._multichip.key ].indexOf(-1) !== -1) {
          return;
        }
      } 
      // if user selects all hub option ends
      this._multichip.options.splice(optionWithLocation.index, 1);
      if (this.dataService.initDataForMultiChip[ this._multichip.key ].indexOf(uiValue) === -1) {
        this.dataService.initDataForMultiChip[ this._multichip.key ].push(uiValue);
      }
      if (this.dataService.dataToSendForCrudMultiChip[ key ].indexOf(apiValue) === -1) {
        this.dataService.dataToSendForCrudMultiChip[ key ].push(apiValue);
      }
    }
    this.multiChipInput.nativeElement.value = '';
    this.form.controls[ key ].patchValue('');
  }
  ngOnDestroy () {
    this.dataService[ this._multichip.module === module.search ? 'dataToSendForSearchMultiChip' : 'dataToSendForCrudMultiChip' ] = {};
    this._multichip.chipOptions = {};
    this._multichip.options = [];
  }
}