<div class="mat-card-sm">
  <div class="row more-vert-center">
    <div [class]="card && card.content ? 'col-md-6' : 'col-lg-3'" *ngFor="let card of _card">
      <mat-card class="mat-elevation-z border mb has-action-button" [class.disabled]="card.icon">
        <mat-card-header class="align-items-center" *ngIf="card.action && card.action.length > 0">
          <!-- <mat-card-subtitle>{{card.label}}</mat-card-subtitle>
          <mat-card-title class="mb-0">{{card.value}}</mat-card-title> -->
          <button class="action-button" mat-icon-button [matMenuTriggerFor]="action" [disabled]="card.icon">
            <mat-icon class="material-icons-outlined">more_vert</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content class="mat-card-lg">
          <div class="row">
            <div class="col col-left">
              <mat-card-header class="align-items-center">
                <mat-card-subtitle>{{ card.label }}</mat-card-subtitle>
                <mat-card-title class="mb-0 f-s-l"> <span matTooltipHideDelay="1000" matTooltip="{{
                card.value }}">{{
                    card.value }}</span></mat-card-title>

              </mat-card-header>
              <p class="text-danger mb-0" *ngIf="card.error">{{card.error}}</p>
            </div>
            <div class="col-big" *ngIf="card && card.content">
              <div class="col col-right bl-dashed">
                <div class="margin">
                  <div class="label-group" *ngFor="let content of card.content">
                    <span class="label">{{ content.label }}</span>
                    <div class="value">
                      <span>:</span> <strong>{{ content.value }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-icon class="icon-info" *ngIf="card && card.icon" (click)="iconClicked(card.confirmation)"> {{card.icon}} </mat-icon>
      <mat-menu #action="matMenu">
        <ng-container *ngFor="let action of card.action">
          <button mat-menu-item (click)="performOperation(action)" *ngIf="
              !action.privilege ||
              permissionsService.getPermission(action.privilege)
            ">
            {{ action.label }}
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</div>
