<div class="loginPage updated-password">
  <div class="row">
    <div class="col-lg-6 col-md-12 col-logo">
      <img
        class="img-rounded"
        style="opacity: 0.2;"
        src="assets/img/icon.png"
      />
    </div>
    <div class="col-lg-6 col-md-12 col-form">
      <form
        [formGroup]="setPasswordForm"
        *ngIf="userToken"
        (ngSubmit)="onSetSubmit()"
      >
        <div class="row">
          <div class="col-md-6 has-error-msg">
            <mat-form-field class="matFormField-full-width">
              <input
                name="new password"
                formControlName="password"
                [type]="hidePassword ? 'password' : 'text'"
                matInput
                placeholder="New password"
              />
              <mat-icon
                class="material-icons-outlined"
                matSuffix
                color="primary"
              >
                {{
                  setPasswordForm.controls['password'].errors
                    ? 'sentiment_very_dissatisfied'
                    : 'sentiment_very_satisfied'
                }}
              </mat-icon>
              <mat-hint>password must be minimum 4 characters</mat-hint>
            </mat-form-field>
          </div>
          <div class=" col-md-6 has-error-msg">
            <mat-form-field class="matFormField-full-width">
              <input
                name="confirmPassword"
                formControlName="confirmPassword"
                [type]="hidePassword ? 'password' : 'text'"
                matInput
                placeholder="Confirm Password"
              />
              <mat-icon
                matSuffix
                color="primary"
                class="pointer material-icons-outlined"
                (click)="hidePassword = !hidePassword"
              >
                {{ hidePassword ? 'visibility_off' : 'visibility' }}
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-lg-12">
            <button
              type="submit"
              mat-raised-button
              color="success"
              [disabled]="setPasswordForm.invalid"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="isPasswordChanged">
      <h1>Success! Your Details has been updated!</h1>
      <button
        type="button"
        mat-raised-button
        color="success"
        routerLink="/login"
      >
        Visit Login Page
      </button>
    </div>
  </div>
  <div *ngIf="!userToken">
    <h1>Token Missing!!!</h1>
    <h5>Hi, Token is required to set new password.</h5>
  </div>
</div>
