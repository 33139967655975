<div class="outer-div">
    <button type="button" class="close-button" mat-icon-button (click)="close()">
        <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
    <h4>Product Images Details</h4>
    <div class="image">
        <ng-container *ngIf="data?.pickedImage;else noImage">
            <img class="image-width" [src]="fileUrl + data.pickedImage" onError="this.src='../../../../../assets/img/no_image.jpg';" alt="..." />
        </ng-container>
        <ng-template #noImage>
            <img class="image-width" src="../../../../../assets/img/no_image.jpg" alt="..." />
        </ng-template>
    </div>
    <!-- images Details -->
    <div>
        <ng-container *ngIf="data?.customerId">
            <h4>CustomerId:{{data?.customerId}}</h4>
        </ng-container>
        <ng-container *ngIf="data?.orderId">
            <h4>OrderId:{{data?.orderId}}</h4>
        </ng-container>
        <ng-container *ngIf="data?.quantity">
            <h4>Quantity:{{data?.quantity}}</h4>
        </ng-container>
        <ng-container *ngIf="data?.acceptQty">
            <h4>Accept Quantity:{{data?.acceptQty}}</h4>
        </ng-container>
        <ng-container *ngIf="data?.date">
            <h4>Date:{{data?.date | date:'fullDate'}}</h4>
        </ng-container>
        <ng-container *ngIf="data?.issue">
            <h4>Issue:{{data?.issue}}</h4>
        </ng-container>
    </div>
</div>
