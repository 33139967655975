<div class="catalog-dialog" [ngSwitch]="data.why">
  <div class="bundle" *ngSwitchCase="'buttons'">
    <!-- <div class="close-button-holder">
    <button close-button mat-icon-button type="button"><mat-icon>close</mat-icon></button>
  </div> -->
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      <div class="button-holder">
        <button mat-stroked-button color="primary" class="text-uppercase" [mat-dialog-close]="'single'">
          <mat-icon class="material-icons-outlined">filter_vintage</mat-icon>
          Single Product
        </button>
        <button mat-stroked-button color="primary" class="text-uppercase" [mat-dialog-close]="'bundle'">
          <mat-icon class="material-icons-outlined">filter_vintage</mat-icon>
          Bundle Product
        </button>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'sortOrder'">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      <mat-form-field>
        <input matInput placeholder="Sort order" [(ngModel)]="sortOrder" />
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="primary" [mat-dialog-close]="false">No</button>
      <button mat-button color="warn" [mat-dialog-close]="sortOrder">
        Yes
      </button>
    </mat-dialog-actions>
  </div>
  <div *ngSwitchCase="'dataValue'">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      <div [ngSwitch]="getDataType(dataValue)">
        <div *ngSwitchCase="'object'">
          <div *ngFor="let dv of dataValue | keyvalue; let i = index; trackBy:trackByFn">
            <mat-form-field>
              <input matInput [placeholder]="'Data Value '+i" [(ngModel)]="dataValue[dv.key]" />
            </mat-form-field>
          </div>
        </div>
        <div *ngSwitchDefault>
          <mat-form-field>
            <input matInput placeholder="Data Value" [(ngModel)]="dataValue" />
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="primary" [mat-dialog-close]="false">No</button>
      <button mat-button color="warn" [mat-dialog-close]="dataValue">
        Yes
      </button>
    </mat-dialog-actions>
  </div>
  <div *ngSwitchCase="'singleInput'">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      <mat-form-field>
        <input matInput [placeholder]="data.placeholder" [(ngModel)]="initialInput" />
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="primary" [mat-dialog-close]="false">No</button>
      <button mat-button color="warn" [mat-dialog-close]="initialInput">
        Yes
      </button>
    </mat-dialog-actions>
  </div>
  <div *ngSwitchCase="'formUpdate'">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <!-- <mat-dialog-content *ngIf="data.crud.type === 'autocomplete'">
      <app-autocomplete [form]="data.form" [_autocomplete]="data.crud"> </app-autocomplete>
    </mat-dialog-content> -->
    <mat-dialog-content class="app-crud-popup">
      <app-crud [form]="data.form" [_crud]="data.crud"> </app-crud>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!data?.showButtons || (data?.showButtons && data?.showButtons !== 'hide')">
      <button mat-button color="primary" [mat-dialog-close]="false">
        Cancel
      </button>
      <button mat-button color="warn" [disabled]="data.form.invalid" [mat-dialog-close]="data.form">
        Proceed
      </button>
    </mat-dialog-actions>
  </div>
  <div *ngSwitchCase="'moreButton'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="false">
          No
        </button>
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="true">
          Yes
        </button>
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="1">
          delete and send message
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchCase="'update'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="false">
          No
        </button>
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="true">
          Yes
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchCase="'cancel_membership'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="false">
          Close
        </button>
        <button mat-button color="primary" class="text-uppercase" #btnRef [mat-dialog-close]="true">
          Cancel Membership
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchCase="'refund_membership'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="false">
          Close
        </button>
        <button mat-button color="primary" class="text-uppercase" #btnRef [mat-dialog-close]="true">
          Refund Membership
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchCase="'society'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" #btnRef [mat-dialog-close]="true">
          Ok
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchCase="'add'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="false">
          No
        </button>
        <button mat-button color="primary" class="text-uppercase" #btnRef [mat-dialog-close]="true">
          Yes
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchCase="'errorHandle'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content class="textColor">
        <li *ngFor="let item of data.message">
          {{ item }}
        </li>
      </mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="true">
          OK
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchCase="'icon-click-details'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>
        <ng-container *ngFor="let msg of data.message">
          <div>{{msg}}</div>
        </ng-container>
      </mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" #btnRef [mat-dialog-close]="true">
          Ok
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchCase="'society-deactivation'">
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="false">
          No, Cancel
        </button>
        <button mat-button color="primary" class="text-uppercase" #btnRef [mat-dialog-close]="true">
          Yes, Deactivate
        </button>
      </mat-dialog-actions>
    </div>
  </div>
  <div *ngSwitchDefault>
    <div class="mat-dialog-wrap">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button color="primary" class="text-uppercase" [mat-dialog-close]="false">
          Cancel
        </button>
        <button mat-button color="primary" class="text-uppercase" #btnRef [mat-dialog-close]="true">
          Delete
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
