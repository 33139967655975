import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http/http.service';
import { AppWebRoutes } from '../../constants/app-web-routes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdatePasswordService {
  constructor(private _http: HttpService) {}

  setNewPassword(requestData): Observable<object> {
    return this._http.post(AppWebRoutes.AUTH.setPassword, requestData);
  }

  ChangePassword(requestData): Observable<object> {
    return this._http.post(AppWebRoutes.AUTH.changePassword, requestData);
  }
  changeAdminName(url, requestData): Observable<object> {
    return this._http.patch(url, requestData);
  }
}
