import * as moment from "moment";
import { AppWebRoutes } from "src/app/core/constants/app-web-routes";
import { clusterGeofenceActionStatusEnum, mapMeta, serviceableAreaGeofenceActionStatusEnum, societyGeofenceActionStatusEnum } from "src/app/location/constants/map-constants";

export default class MapServiceUtils {

  static getApiToGetGeofence (geofenceFetchSteps) {
    let api = AppWebRoutes.MAP.serviceableAreaGeofenceAll;
    switch (geofenceFetchSteps) {
      case mapMeta.geofenceTypesEnum.SOCIETY:
        api = AppWebRoutes.MAP.societiesGeofenceAll;
        break;
      case mapMeta.geofenceTypesEnum.CLUSTER:
        api = AppWebRoutes.MAP.clusterGeofenceAll + mapMeta.apiResolve.PARENT_CLUSTER_NAME;
        break;
    }
    return api;
  }

  static isCacheExpired (dataService) {
    let _isCacheExpired = true;
    const _cacheExpirationDate = dataService.getLocalStorage(mapMeta.localStorageKeys.CACHE_EXPIRATION_DATE);
    if (_cacheExpirationDate) {
      const expirationDuration = moment(_cacheExpirationDate).diff(moment());
      if (expirationDuration && expirationDuration > 0) {
        _isCacheExpired = false;
      }
    }
    return _isCacheExpired;
  }

  static isDataPresentInCache (dataService) {
    let _isDataPresentInCache = false;
    if (dataService.getLocalStorage(mapMeta.localStorageKeys.GEOFENCE)) {
      _isDataPresentInCache = true;
    }
    return _isDataPresentInCache;
  }

  static isDropPointMappedToCluster (dataService, clusterId) {
    let _isDropPointMappedToCluster = false;
    const dropPointFeatures = dataService.getLocalStorage(mapMeta.localStorageKeys.DROP_POINT_PINS);
    if (dropPointFeatures && dropPointFeatures.length) {
      _isDropPointMappedToCluster = dropPointFeatures.some((dropPoint) => dropPoint.attributes.clusterId === clusterId);
    }
    return _isDropPointMappedToCluster;
  }

  static createMarkerArcGisJson (hubsFeatures, serviceableAreaPinFeatures, dropPointPinFeatures, societyPinFeatures) {
    const markerPinArcJson = JSON.parse(JSON.stringify(mapMeta.ARC_GIS_INITIAL_PARAMS));
    markerPinArcJson.geometryType = mapMeta.esriGeometry.ESRI_GEOMETRY_POINT;
    markerPinArcJson.features = [ ...hubsFeatures, ...serviceableAreaPinFeatures, ...dropPointPinFeatures, ...societyPinFeatures ];
    return markerPinArcJson;
  }

  static getMarkerFeatures (pins, type?) {
    let features = [];
    pins && pins.forEach(pin => {
      if (pin.latitude && pin.longitude) {
        features.push(MapServiceUtils.getMarkerFeatureFromJson(pin, type));
      }
    });
    return features;
  }

  static getMarkerFeatureFromJson(pin, type) {
    let feature = {};
    let geometry = { "x": pin.longitude, "y": pin.latitude, "spatialReference": { "wkid": 4326 }};
    switch (type) {
      case mapMeta.feature.HUB:
      case mapMeta.feature.DROP_POINT:
      case mapMeta.feature.SOCIETY_MARKER:
        feature = {
          'attributes': {
            "OBJECTID": type.toUpperCase() + mapMeta.objectId.PIN_OBJECTID + pin.id,
            'name': pin.name,
            'REGION': type,
            'TYPE': mapMeta.featureType.MARKER,
            'id': pin.id,
            'longitude': pin.longitude,
            'latitude': pin.latitude,
            'bucketSize': pin.bucketSize,
            'clusterId': pin.clusterId,
            'clusterName': pin.clusterName,
            'hubId': pin.hubId
          },
          'geometry': geometry,
        };
        break;
      case mapMeta.feature.SERVICEABLE_AREA_PIN:
        feature = {
          'attributes': {
            "OBJECTID": (pin.type === 1 ? mapMeta.feature.DC_POINT : mapMeta.feature.PLACEMARK).toUpperCase() + mapMeta.objectId.PIN_OBJECTID + pin.id,
            'name': pin.name,
            'REGION': pin.type === 1 ? mapMeta.feature.DC_POINT : mapMeta.feature.PLACEMARK,
            'TYPE': mapMeta.featureType.MARKER,
            'ado': pin.ado,
            'areaSize': pin.areaSize,
            'id': pin.id,
            'longitude': pin.longitude,
            'latitude': pin.latitude,
            'status': pin.status,
          },
          'geometry': geometry,
        };
        break;
      case mapMeta.feature.CUSTOMER:
        feature = {
          'attributes': {
            "OBJECTID": mapMeta.feature.CUSTOMER.toUpperCase() + mapMeta.objectId.PIN_OBJECTID + pin.id,
            'name': pin.id,
            'REGION': mapMeta.feature.CUSTOMER,
            'TYPE': mapMeta.featureType.MARKER,
            'id': pin.id,
            'longitude': pin.longitude,
            'latitude': pin.latitude
          },
          'geometry': geometry,
        };
        break;
    }
    return feature;
  }

 static getGeofenceFeatureFromJson(geofenceId, dataToSend, selectedGeofenceType) {
    let attributes;
    switch (selectedGeofenceType) {
      case mapMeta.geofenceTypesEnum.SERVICEABLE_AREA:
        attributes = {
          "OBJECTID": dataToSend.OBJECTID > -1 ? dataToSend.OBJECTID : mapMeta.objectId.SERVICEABLE_OBJECTID + geofenceId,
          'name': geofenceId,
          'REGION': mapMeta.geofenceTypesEnum.SERVICEABLE_AREA,
          'TYPE': mapMeta.featureType.GEOFENCE,
          'id': geofenceId,
          'status': mapMeta.serviceableAreaStatusEnum[dataToSend.status],
        };
        break;
      case mapMeta.geofenceTypesEnum.SOCIETY:
        attributes = {
          "OBJECTID": dataToSend.OBJECTID > -1 ? dataToSend.OBJECTID : mapMeta.objectId.SOCIETY_OBJECTID + geofenceId,
          'name': dataToSend.societyId,
          'REGION': mapMeta.geofenceTypesEnum.SOCIETY,
          'TYPE': mapMeta.featureType.GEOFENCE,
          'id': geofenceId,
          "societyId" : dataToSend.societyId,
          'status': mapMeta.societyStatusEnum[dataToSend.status],
        };
        break;
      case mapMeta.geofenceTypesEnum.CLUSTER:
        attributes = {
          "OBJECTID": mapMeta.objectId.CLUSTER_OBJECTID + geofenceId,
          'name': geofenceId,
          'REGION': mapMeta.geofenceTypesEnum.CLUSTER,
          'TYPE': mapMeta.featureType.GEOFENCE,
          'id': geofenceId,
          'clusterId': dataToSend.clusterId,
          'deliveryStartDate': dataToSend.deliveryStartDate,
          'hubId': dataToSend.hubId,
          'status': mapMeta.clusterStatusEnum[dataToSend.status],
        };
        break;
    }
    return { 'attributes': attributes, 'geometry': { 'rings': [ dataToSend.polygon ], "spatialReference": { "wkid": 4326 } }};
  }

  static createGeofenceArcGisJson (geofenceFeatures) {
    const geofenceArcJson = JSON.parse(JSON.stringify(mapMeta.ARC_GIS_INITIAL_PARAMS));
    geofenceArcJson.geometryType = mapMeta.esriGeometry.ESRI_GEOMETRY_POLYGON;
    geofenceArcJson.features = geofenceFeatures;
    return geofenceArcJson;
  }

  static getGeofenceFeatures (permissionsService, geofences, type) {
    let features = [];
    geofences && geofences.forEach(geofence => {
      if(geofence.polygon) {
        const arcGeofence = {
          'attributes': {
            'OBJECTID': mapMeta.objectId.SERVICEABLE_OBJECTID + geofence.id,
            'id': geofence.id, 'name': geofence.name,
            'status': mapMeta.serviceableAreaStatusEnum[geofence.status],
            'REGION': type,
            'TYPE': mapMeta.featureType.GEOFENCE },
          'geometry': { 'rings': [ geofence.polygon ], 'spatialReference': { 'wkid': 4326 } },
        };
        if(permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA_LIVE)) {
          (geofence.status === serviceableAreaGeofenceActionStatusEnum.DONE
            || geofence.status === serviceableAreaGeofenceActionStatusEnum.LIVE) && features.push(arcGeofence);
        } else if(permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA_DONE)) {
          geofence.status !== serviceableAreaGeofenceActionStatusEnum.DRAFT && features.push(arcGeofence);
        } else {
        features.push(arcGeofence);
        }
      }
    });
    return features;
  }

  static getClusterGeofenceFeatures (permissionsService, dataService, mapdata, geofences) {
    let features = [];
    let selectableParentCluster = [];
    geofences && geofences.forEach(geofence => {
      if(geofence.polygon) {
        const geometry = { 'rings': [ geofence.polygon ], 'spatialReference': { 'wkid': 4326 }};
        const arcGeofence = {
          'attributes': {
            'OBJECTID': mapMeta.objectId.CLUSTER_OBJECTID + geofence.id,
            'id': geofence.id,
            'name': geofence.name,
            'clusterId': geofence.clusterId,
            'deliveryStartDate': geofence.deliveryStartDate,
            'status': mapMeta.clusterStatusEnum[geofence.status],
            'REGION': mapMeta.geofenceTypesEnum.CLUSTER,
            'TYPE': mapMeta.featureType.GEOFENCE },
          'geometry': geometry,
        };
        if(geofence.status === clusterGeofenceActionStatusEnum.READY) {
          const existingParentIndex = selectableParentCluster.findIndex(parent => parent.resolve === geofence.clusterId);
          if(existingParentIndex > -1) {
            const counter = selectableParentCluster[existingParentIndex].gc + 1;
            selectableParentCluster[existingParentIndex] = {gc: counter, label: `${geofence.clusterName} (GF-${counter})`, resolve: geofence.clusterId};
          } else {
            selectableParentCluster.push({gc: 1, label: `${geofence.clusterName} (GF-${1})`, resolve: geofence.clusterId});
          }
        }
        if(permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA)) {
          geofence.status === clusterGeofenceActionStatusEnum.READY && features.push(arcGeofence);
        } else {
          features.push(arcGeofence);
        }
      }
    });
    selectableParentCluster.length && dataService.setLocalStorage(mapMeta.localStorageKeys.SELECTABLE_PARENT_CLUSTER, selectableParentCluster);
    mapdata.geofenceData.features = [ ...mapdata.geofenceData.features, ...features ];
  }

  static getClustersGeometry(dataService, OBJECTID, splitClusterId){
    let geofences = dataService.getLocalStorage(mapMeta.localStorageKeys.GEOFENCE);
    let featuresGeometry = [];
    geofences && geofences.forEach(geofence => {
      if(geofence.attributes.REGION === mapMeta.geofenceTypesEnum.CLUSTER &&
        geofence.attributes.OBJECTID !== OBJECTID &&
        ((splitClusterId && geofence.attributes.id !== splitClusterId) || !splitClusterId)) {
        featuresGeometry.push(geofence.geometry);
      }
    });
    return featuresGeometry;
  }

  static getSocietyGeofenceFeatures (permissionsService, mapdata, dataService, societies) {
    let geofenceFeatures = [];
    let markerFeatures = [];
    societies && societies.forEach(geofence => {
      if(geofence.polygon) {
        const arcGeofence = {
          'attributes': {
            'OBJECTID': mapMeta.objectId.SOCIETY_OBJECTID + geofence.geofenceId,
            'id': geofence.geofenceId,
            "societyId" : geofence.id,
            'name': geofence.name,
            'status': mapMeta.societyStatusEnum[geofence.status],
            'REGION': mapMeta.geofenceTypesEnum.SOCIETY,
            'TYPE': mapMeta.featureType.GEOFENCE },
          'geometry': { 'rings': [ geofence.polygon ], 'spatialReference': { 'wkid': 4326 } },
        };
        if(permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA_LIVE)) {
          geofence.status === societyGeofenceActionStatusEnum.LIVE && geofenceFeatures.push(arcGeofence);
        }  else if(permissionsService.getPermission(mapMeta.privilage.UPDATE_SERVICEABLE_AREA_DONE)) {
          geofence.status !== societyGeofenceActionStatusEnum.DRAFT && geofenceFeatures.push(arcGeofence);
        } else {
          geofenceFeatures.push(arcGeofence);
        }
      }
      markerFeatures.push(MapServiceUtils.getMarkerFeatureFromJson(geofence, mapMeta.feature.SOCIETY_MARKER));
    });
    dataService.setLocalStorage(mapMeta.localStorageKeys.SOCIETY_PINS, markerFeatures);
    mapdata.markerPinData.features = [...mapdata.markerPinData.features, ...markerFeatures];
    mapdata.geofenceData.features = [ ...mapdata.geofenceData.features, ...geofenceFeatures ];
  }

  static getFilteredMapData (changedViewAsList, changedSelectParentClusterList, markerPinData, geofenceData) {

    let isGrowthGeofenceDraftSelected: boolean = false;
    let isGrowthGeofenceInConsiderationSelected: boolean = false;
    let isGrowthGeofenceLiveSelected: boolean = false;
    let isSocietyDraftSelected: boolean = false;
    let isSocietyActiveSelected: boolean = false;
    let isClusterLiveSelected: boolean = false;
    let isClusterDeactivatedSelected: boolean = false;
    let isClusterDraftSelected: boolean = false;
    let isSentToGrowthManagerSelected: boolean = false;
    let isSentToOperationSelected: boolean = false;
    let isExistingHubSelected: boolean = false;
    let isServiceableAreaPinSelected: boolean = false;
    let isSocietyPinSelected: boolean = false;
    let isDropPointSelected: boolean = false;

    changedViewAsList.forEach(viewAs => {
      switch (viewAs) {
        case mapMeta.viewAsListLabels.GROWTH_GEOFENCE_DRAFT :
          isGrowthGeofenceDraftSelected = true;
          break;
        case mapMeta.viewAsListLabels.GROWTH_GEOFENCE_IN_CONSIDERATION :
          isGrowthGeofenceInConsiderationSelected = true;
          break;
        case mapMeta.viewAsListLabels.GROWTH_GEOFENCE_LIVE :
          isGrowthGeofenceLiveSelected = true;
          break;
        case mapMeta.viewAsListLabels.SOCIETY_GEOFENCE_DRAFT:
          isSocietyDraftSelected = true;
          break;
        case mapMeta.viewAsListLabels.SOCIETY_GEOFENCE_ACTIVE:
          isSocietyActiveSelected = true;
          break;
        case mapMeta.viewAsListLabels.OPS_GEOFENCE_LIVE:
          isClusterLiveSelected = true;
          break;
        // case mapMeta.viewAsListLabels.CHILD_CLUSTER_DEACTIVATED:
        //   isClusterDeactivatedSelected = true;
        //   break;
        case mapMeta.viewAsListLabels.OPS_GEOFENCE_DRAFT:
          isClusterDraftSelected = true;
          break;
        case mapMeta.viewAsListLabels.SENT_TO_GROWTH_MANAGER:
          isSentToGrowthManagerSelected = true;
          break;
        case mapMeta.viewAsListLabels.SENT_TO_OPERATION:
          isSentToOperationSelected = true;
          break;
        case mapMeta.viewAsListLabels.EXISTING_HUBS:
          isExistingHubSelected = true;
          break;
        case mapMeta.viewAsListLabels.OTHER_PINS:
          isServiceableAreaPinSelected = true;
          break;
        case mapMeta.viewAsListLabels.SOCIETY_PINS:
          isSocietyPinSelected = true;
          break;
        case mapMeta.viewAsListLabels.DROP_POINT_PINS:
          isDropPointSelected = true;
          break;
      }
    });

    let markerDataToFilter: any;
    if (markerPinData) {
      markerDataToFilter = JSON.parse(JSON.stringify(markerPinData));
      let filteredPinsFeatures = [];
      if (markerDataToFilter.features.length) {
        markerDataToFilter.features.forEach(feature => {
          if (isExistingHubSelected && feature.attributes.REGION === mapMeta.feature.HUB) {
            filteredPinsFeatures.push(feature);
          }
          if (isServiceableAreaPinSelected && (feature.attributes.REGION === mapMeta.feature.DC_POINT || feature.attributes.REGION === mapMeta.feature.PLACEMARK)) {
            filteredPinsFeatures.push(feature);
          }
          if (isSocietyPinSelected && feature.attributes.REGION === mapMeta.feature.SOCIETY_MARKER) {
            filteredPinsFeatures.push(feature);
          }
          if (isDropPointSelected && feature.attributes.REGION === mapMeta.feature.DROP_POINT) {
            if(!changedSelectParentClusterList.length || (changedSelectParentClusterList.length && changedSelectParentClusterList.includes(feature.attributes.clusterId))) {
              filteredPinsFeatures.push(feature);
            }
          }
        });
      }
      markerDataToFilter.features = filteredPinsFeatures;
    }

    let geofenceDataToFilter: any;
    if (markerPinData) {
      geofenceDataToFilter = JSON.parse(JSON.stringify(geofenceData));
      let filteredGeofenceFeatures = [];
      if (geofenceDataToFilter.features.length) {
        geofenceDataToFilter.features.forEach(feature => {
          if (isGrowthGeofenceDraftSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.DRAFT]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isGrowthGeofenceInConsiderationSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.DONE]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isGrowthGeofenceLiveSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.LIVE]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isSocietyDraftSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY &&
            feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.DRAFT]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isSocietyActiveSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY &&
            feature.attributes.status === mapMeta.societyStatusEnum[societyGeofenceActionStatusEnum.LIVE]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isClusterLiveSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.CLUSTER &&
            feature.attributes.status === mapMeta.clusterStatusEnum[clusterGeofenceActionStatusEnum.READY]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isClusterDeactivatedSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.CLUSTER &&
            feature.attributes.status === mapMeta.clusterStatusEnum[clusterGeofenceActionStatusEnum.DEACTIVATED]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isClusterDraftSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.CLUSTER &&
            feature.attributes.status === mapMeta.clusterStatusEnum[clusterGeofenceActionStatusEnum.DRAFT]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isSentToGrowthManagerSelected &&
            ( feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA ||
              feature.attributes.REGION === mapMeta.geofenceTypesEnum.SOCIETY ) &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.REVIEW]) {
            filteredGeofenceFeatures.push(feature);
          }
          if (isSentToOperationSelected &&
            feature.attributes.REGION === mapMeta.geofenceTypesEnum.SERVICEABLE_AREA &&
            feature.attributes.status === mapMeta.serviceableAreaStatusEnum[serviceableAreaGeofenceActionStatusEnum.DONE]) {
            filteredGeofenceFeatures.push(feature);
          }
        });
      }
      geofenceDataToFilter.features = filteredGeofenceFeatures;
    }

    const mapdata = {
      markerPinData: markerDataToFilter,
      geofenceData: geofenceDataToFilter
    }
    return mapdata;
  }
}
