import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http/http.service';
import { AppWebRoutes } from '../../constants/app-web-routes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private _http: HttpService) {}

  login(formValue): Observable<object> {
    return this._http.post(AppWebRoutes.LOGIN, formValue);
  }
  logOut(): Observable<object> {
    return this._http.get(AppWebRoutes.LOGOUT);
  }
  getUserProfile(): Observable<object> {
    return this._http.get(AppWebRoutes.AUTH.userProfile);
  }

  getMasterData(): Observable<object> {
    return this._http.get(AppWebRoutes.MASTERDATA.masterDataApi);
  }
}
