<div [formGroup]="form" (click)="dtPicker.open()" [hidden]="_date.hideField">
  <mat-form-field class="date-width mat-form-field-date" appearance="outline">
    <mat-label>{{ _date.placeholder }}</mat-label>
    <mat-icon matPrefix class="material-icons-outlined"
      >calendar_today</mat-icon
    >
    <mat-icon matSuffix class="material-icons-outlined"
      >arrow_drop_down</mat-icon
    >
    <input
      *ngIf="_date.dateRange"
      autocomplete="off"
      placeholder="yyyy-mm-dd"
      matInput
      [min]="min"
      [max]="max"
      [formControlName]="_date.key"
      [owlDateTimeTrigger]="dtPicker"
      (dateTimeChange)="dateInput($event.value)"
      [owlDateTime]="dtPicker"
      [selectMode]="'range'"
      [required]="_date.validations && _date.validations.required"
    />
    <input
      *ngIf="!_date.dateRange"
      autocomplete="off"
      placeholder="yyyy-mm-dd"
      matInput
      [max]="max"
      [min]="min"
      [formControlName]="_date.key"
      [owlDateTimeTrigger]="dtPicker"
      (dateTimeChange)="dateInput($event.value)"
      [owlDateTime]="dtPicker"
      [required]="_date.validations && _date.validations.required"
    />
    <owl-date-time
      [showSecondsTimer]="true"
      [pickerType]="_date.datePickerType"
      #dtPicker
    ></owl-date-time>
  </mat-form-field>
</div>
<app-error [form]="form" [_error]="_date"></app-error>
<mat-error *ngIf="
    form.controls[_date.key].touched ||
    form.controls[_date.key].dirty ||
    form.controls[_date.key].value
  ">
  <span *ngIf="
      form.controls[_date['key']].errors &&
      form.controls[_date['key']].errors['owlDateTimeMin'] &&
      form.controls[_date['key']].errors['owlDateTimeMin']['min']
    ">
    {{ _date.placeholder }} is not valid.
  </span>
</mat-error>
