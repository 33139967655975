import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http/http.service';
import { AppWebRoutes } from '../../constants/app-web-routes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  constructor(private _http: HttpService) {
  }

  forgotPassword(requestData): Observable<object> {
    return this._http.post(AppWebRoutes.AUTH.forgotPassword, requestData);
  }
}
