import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { environment } from '../../../../environments/environment';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-label-view',
  templateUrl: './label-view.component.html',
  styleUrls: [ './label-view.component.scss' ]
})
export class LabelViewComponent implements OnInit {
  public config;
  file_url = environment.file_url;
  imageUrl;
  constructor (private dataService: DataService, private loc: Location, private router: Router, private route: ActivatedRoute) { }
  @Input()
  _labelView;

  public options = {
    componentRestrictions: { country: 'in' }
  };
  @Output()
  actionsView: EventEmitter<any> = new EventEmitter<boolean>();
  @Output()
  previewImage: EventEmitter<any> = new EventEmitter<string>();

  ngOnInit () {
    if (this._labelView.url) {
      this.imageUrl = environment.file_url + this._labelView.url;
    }
  }
  actions (action) {
    const dialogRef = this.dataService.openDialog({
      message: 'Are you sure you want to have ' + action + ' request',
      title: 'Procurement ' + action + ' request',
      why: 'update'
    });
    dialogRef.subscribe(result => {
      if (result) {
        this.actionsView.emit(action);
      }
    });
  }
  previewFullImage () {
    this.previewImage.emit(environment.file_url + this._labelView.url);
  }
  goBack () {
    this.loc.back();
  }
  goToEdit () {
    if (this.route.snapshot.params.isBundle) {
      this.router.navigate([ this._labelView.page.path, 'bundle', 'edit', this.route.snapshot.params.id ]);
    } else if (this.route.snapshot.params.hubId && this.route.snapshot.params.productId) {
      if (this._labelView.isBundle) {
        this.router.navigate([ this._labelView.page.path, this.route.snapshot.params.hubId, 'bundle', this.route.snapshot.params.productId, 'edit' ]);
      } else {
        this.router.navigate([ this._labelView.page.path, this.route.snapshot.params.hubId, 'product', this.route.snapshot.params.productId, 'edit' ]);
      }
    } else {
      this.router.navigate([ this._labelView.page.path, 'edit', this.route.snapshot.params.id ]);
    }
  }
}
