import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'covertArrayToNumber'
})
export class CovertArrayToNumberPipe implements PipeTransform {

  transform (value: any): any {
    return Array(value).fill(value).map((x, i) => i + 1);
  }

}
