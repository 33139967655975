export class AppWebRoutes {
  public static LOGIN = 'auth/users/login';
  public static LOGOUT = 'auth/users/logout';
  public static USERS = {
    users: 'users',
    datalist: 'datalist/users',
    all: 'users/all',
    autocomplete: 'users/autocomplete',
    export: 'export/users',
    updategroup: 'users/update-group',
    getUserRoll: 'users/roles/all',
    updateUserRoll: 'users/roles',
    abscondingEmpUpload: 'auth/users/deactivation/upload',
    addCityHub: 'users/city',
    addMultipleCityHub: '/users/city/all',
    userIncentiveUpload: 'ppd/deliveries/user/summary/upload',
  };
  public static AUTH = {
    user: 'auth/users',
    authUserDatalist: 'datalist/users',
    updateGroup: 'update-group',
    userProfile: 'auth/users/session/profile',
    setPassword: 'auth/users/set-password',
    forgotPassword: 'auth/users/forgot-password',
    googleLogin: '/google/login',
    changePassword: 'auth/users/change-password',
    adminName: 'users',
  };
  public static IMAGE = {
    image: 'images',
    export: 'export/marketing/images',
    datalist: 'datalist/marketing/images',
    upload: 'images/upload',
  };
  public static CODEPUSH_MANAGEMENT = {
    create: 'segments/codepush',
  };
  public static COMMON = {
    export: 's3/export/csv?filePath=sample&fileName=',
    upload: 's3/upload/csv?filePath=sample',
  };
  public static PRIVILEGES = {
    privileges: 'privileges',
    all: 'privileges/all',
    autocomplete: 'privileges/autocomplete',
    datalist: 'datalist/privileges',
    export: 'export/privileges',
    privilegeModule: 'privileges/modules',
  };
  public static GROUPS = {
    groups: 'groups',
    all: 'groups/all',
    autocomplete: 'groups/autocomplete',
    datalist: 'datalist/groups',
    export: 'export/groups',
    userByGroup: 'auth/users/groups',
    userBygroupId: 'users/groups/',
    groupsByIds: 'groups/find/ids',
  };
  public static CLUSTERS = {
    clusters: 'clusters',
    reportClusters: 'reports/clusters',
    all: 'clusters/all',
    autocomplete: 'clusters/autocomplete',
    datalist: 'datalist/clusters',
    export: 'export/clusters',
    resolveWithHub: 'hub,society,droppoint',
    clusterCustomerExport: 'export/clusters/customers',
  };
  public static MAP = {
    serviceableAreaPin: 'locations/serviceable-area-pin',
    serviceableAreaPinAll: 'locations/serviceable-area-pin/all',
    serviceableAreaGeofenceAll: 'locations/serviceable-area/geofence',
    serviceableAreaGeofence: 'locations/serviceable-area',
    societiesGeofenceAll: 'societies/geofence',
    societies: 'societies',
    geofenceMap: 'geofence/map',
    clusterGeofenceAll: 'clusters/geofence/view',
    clusterGeofence: 'clusters/geofence',
    dropPoints: 'clusters/droppoint',
    kmlUpload: 'locations/geo-fence/kml/upload',
  };
  public static CITIES = {
    cities: 'cities',
    autocomplete: 'cities/autocomplete',
    all: 'cities/all',
    datalist: 'datalist/cities',
    export: 'export/cities',
    citiesUnmapped: 'reports/cities/unmapped',
    cityByIds: 'cities/find/ids',
    cityMeta: 'hubs/city/meta',
    hubCity: 'hubs/city',
    cityState: 'cities/states',
  };
  public static MARKETING = {
    deliveryType: 'orders/delivery-type',
    masterData: 'reports/masterdata?masterKey=DELIVERY_TYPES',
    campaign: 'marketing/campaign',
    all: 'marketing/campaign/all',
    datalist: 'datalist/marketing/campaign',
    export: 'export/marketing/campaign',
    validate: 'marketing/campaign/validities',
    campaignFor: 'marketing/campaign-for',
    datalistFor: 'datalist/marketing/campaign-for',
    exportFor: 'export/marketing/campaign-for',
    productsForHubQty: 'marketing/campaign/product-qty',
    campaignForDeleteMapping: 'marketing/campaign-for/cityid',
    flashDealDataList: 'datalist/flash-deal',
    flashDealCreate: 'marketing/flash-deal',
    getFlashDeal: 'marketing/flash-deal',
    flashDealUpdate: 'marketing/flash-deal',
    flashDealDelete: 'marketing/flash-deal',
    flashDealImageUpload: 'marketing/flash-deal/image/upload',
    differentialPricing: 'marketing/campaign',
  };
  public static LOCATION = {
    locations: 'locations',
    all: 'locations/all',
    autocomplete: 'locations/autocomplete',
    datalist: 'datalist/locations',
    export: 'export/locations',
    resolveWithCity: 'city',
    getAllHub: 'hubs/find/ids',
    activeHubsCities: 'hubs/hub-cities/active',
    getStateId: 'cities/states/autocomplete',
  };
  public static SOCIETIES = {
    societies: 'societies',
    datalist: 'datalist/societies',
    newSocietyDatalist: 'datalist/ppd/recce/request/growth',
    deletedDatalist: 'datalist/societies/deleted',
    allDatalist: 'datalist/societies/all',
    all: 'societies/all',
    autocomplete: 'societies/autocomplete',
    export: 'export/societies',
    exportSocietyDatalist: 'export/ppd/recce/request/growth',
    markNewSocietyComplete: 'ppd/recce/request/society/completed',
    imageupload: 'societies/images/upload',
    resolveWithLocationHub: 'location,hub',
    tower: 'societies/towers',
    societyDeactivate: 'societies/deactivate',
    referSociety: 'customers/societies/referral',
    societyByIds: 'societies/find/ids',
    allSocietyExport: 'export/societies/all',
    societyLatLongUpload: 'societies/lat-long/upload',
    societyHouseholdUpload: 'societies/house-hold/upload',
    duplicateSocieties: 'customers/society-update/upload',
    societyDeliveryStartDateUpload: 'societies/delivery-start-date/upload',
    societyPincodeUpload: 'societies/pincode/upload',
    locationUpload: 'locations/upload',
    societyCreationBUlkUpload: 'societies/upload',
    requestTowerDatalist: 'datalist/customers/towers/requests',
    requestTowerExport: 'export/customers/towers/requests',
    requestTowerAction: 'customers/tower/request/action',
    societyDeactivationRequest: 'societies/delivery-holiday',
    societyDeactivationDatalist: 'datalist/societies/delivery-holiday',
    societyDeactivationApproval: 'societies/delivery-holiday/status',
    societyDeactivationRequestDelete: 'societies/delivery-holiday',
  };
  public static HUBS = {
    hubs: 'hubs',
    datalist: 'datalist/hubs',
    all: 'hubs/all',
    autocomplete: 'hubs/autocomplete',
    autocompleteUnmapped: 'hubs/all/autocomplete',
    export: 'export/hubs',
    resolveWithCity: 'city',
    mappingHubCity: 'hubs/cities',
    getHubsByIds: 'hubs/find/ids',
    hubType: 'hubs/types/all',
    hubMeta: 'hubs/meta',
    hubData: 'hubs/data',
    allHubs: 'hubs/no-limit',
  };
  public static RECCE = {
    recce: 'ppd/recce/request',
    datalist: 'datalist/ppd/recce/request/ops',
    autocomplete: 'hubs/autocomplete',
    autocompleteUser: 'users/autocomplete/phone',
    export: 'export/ppd/recce/request/ops',
    assignTo: 'ppd/recce/request/assign',
    recceUpload: 'ppd/recce/request/upload',
  };
  public static TOWER = {
    tower: 'societies/towers',
    all: 'societies/towers/all',
    autocomplete: 'societies/towers/autocomplete',
    datalist: 'datalist/societies/towers',
    export: 'export/societies/towers',
  };
  public static LOCATIONMAPPING = {
    societiesByLocation: 'societies',
    societiesByCluster: 'societies/clusters',
    droppointByCluster: 'droppoints/clusters',
    societiesUnmapped: 'datalist/societies/clusters/unmapped',
    societyUpdateSort: 'societies/clusters/update-sort/',
    clustersByTeam: 'teams/clusters',
    clustersByTeamId: 'datalist/teams/clusters/byteam',
    clustersUnmapped: 'datalist/clusters/teams',
    towersBySociety: 'datalist/societies/towers',
    societyTower: 'societies/towers',
    societyAutocomplete: 'societies/autocomplete',
  };
  public static CUSTOMERS = {
    customers: 'customers',
    customer_details: 'customers/details',
    datalist: 'datalist/customers',
    all: 'customers/all',
    autocomplete: 'customers/autocomplete',
    autocompleteEmail: 'customers/autocomplete?type=email',
    autocompletePhone: 'customers/autocomplete?type=phone',
    export: 'export/customers',
    updategroup: 'customers/update-group',
    duplicate: 'datalist/customers/duplicate',
    exportDuplicate: 'export/customers/duplicate',
    summary: 'datalist/customers/summary',
    negativeBalance: 'datalist/customers/negative-balance',
    exportNegativeBalance: 'export/customers/negative-balance',
    wrongaddress: 'datalist/customers/wrong-address',
    exportWrongaddress: 'export/customers/wrong-address',
    holidays: 'holidays',
    createHolidays: 'holidays',
    createScheme: 'customers/schemes',
    createBaskets: 'baskets/customers/',
    scheme: 'schemes',
    baskets: 'baskets/details/customers',
    resolveWithSociety: 'societies',
    editAddress: 'update-address',
    editProfile: 'update-profile',
    customerDuplicate: 'duplicate',
    referList: 'datalist/customers/refered',
    exportReferList: 'export/customers/refered',
    referInfluencers: 'datalist/customers/influencers',
    exportReferInfluencers: 'export/customers/influencers',
    referAll: 'datalist/customers/all-refers',
    exportReferAll: 'export/customers/all-refers',
    rejectedReferrals: 'datalist/customers/no-benefit-refers',
    exportRejectedReferrals: 'export/customers/no-benefit-refers',
    updateCreditLimit: 'update-credit-limit',
    addCashback: 'transactions/customers',
    sendMessage: 'push-msg',
    sendSMS: 'communication/sms',
    sendSMSToCustomer: 'customers/communication/sms',
    statement: '',
    intercomMessage: 'intercom-msg',
    resetPassword: 'reset-password',
    requestCashColection: 'cash-collections/add',
    communication: 'communication',
    getDeliveryInstruction: 'delivery-instructions',
    createRecurringOrders: 'recurringorders/add',
    smsTemplates: 'message-templates',
    editPhoneNumber: 'update-phone',
    inviteCode: 'customers/invite-codes',
    todayOrder: 'datalist/customers/today-order',
    basketProduct: 'baskets/products',
    recurringorders: 'recurringorders/status',
    productOrder: 'datalist/orders/customer-product-ordered',
    exportOroductOrder: 'export/orders/customer-product-ordered',
    monthlySpends: 'datalist/customers/monthly-spend',
    barcodeMapping: 'barcode/customer/',
    membership: 'membership/user/current',
    cancelMmbership: 'membership/user/cancel',
    accountClosure: 'customers/account-closure/',
    invoice: 'ppd/invoice/membership',
    feedbackUpload: 'cims/feedback/upload',
    subcategoryFeedbackUpload: 'cims/subcategory/feedback/upload',
    referralUpload: 'customers/societies/referral/upload',
    membershipBulkUpload: 'membership/user/upload',
    currentMbPass: 'marketing/mb-pass/customer/current',
    expiredMbPass: 'marketing/mb-pass/customer/expired',
    customerStatement: 'export/customers/statements',
    auth: 'auth',
    logout: 'logout',
    validateAddress: 'consumer/user/validate-address',
    ValidateAddressServiceable: 'customers/validate-address/serviceable',
    customerLocations: 'customers/locations',
    refundMembership: 'cims/membership-refund',
    getCurrentMembership: 'cims/membership-details',
    deliveryImgUrl: 'ppd/app/deliveries/image',
    jioCashbackVisibility: 'transactions/rr/mahacashback/balance',
    duplicateHistory: 'customers/duplicate-history',
    accountSuspend: 'customers/account-suspension',
    walletFreeze: 'customers/wallet-freeze',
    accountSuspendBulkUpload: 'customers/account-suspension/bulk-upload',
    walletFreezeBulkUpload: 'customers/wallet-freeze/bulk-upload',
    accountsOfFreezedOrSuspendedAccounts: 'customers/suspension-reason',
    rcOffer: 'customers/rc-offer',
    societyReferralList: 'datalist/customers/society/referral',
    societyReferralExport: 'export/customers/society/referral',
    membershipBulkUploadTwo: 'membership/user/bulk/upload',
    refundReturn: 'cims/ticket/refund/datalist',
    deviceDetails: 'devices/find',
    unprocessCustomersInviteCodeBulkUpload:
      'customers/remove/invite-code/upload',
    allCustomerList: 'cims/customer/datalist',
    assignTicket: 'cims/ticket/retention-calling',
    customerRetentionExport: 'cims/customer/export',
  };
  public static TEAM = {
    team: 'teams',
    all: 'teams/all',
    autocomplete: 'teams/autocomplete',
    datalist: 'datalist/teams',
    export: 'export/teams',
    updateName: 'name',
    updateSheet: 'sheet',
    config: 'teams/config',
    teamClusterDatalist: 'datalist/teams/clusters/day',
    uploadTeamCluster: 'teams/clusters/upload',
    exportTeamCluster: 'export/teams/clusters',
  };
  public static INVITECODE = {
    invitecodes: 'invite-codes',
    all: 'invite-codes/all',
    autocomplete: 'invite-codes/autocomplete',
    datalist: 'datalist/marketing/invite-codes',
    export: 'export/marketing/invite-codes',
    validate: 'invite-codes/validities',
    running: 'invite-codes/running',
    imageupload: 'invite-codes/images/upload',
    bulkApply: 'customers/bulk_apply_invite_code',
  };
  public static RECURRING = {
    recurring: 'marketing/recurring/campaign',
    all: 'recurring/campaign/all',
    campaignDatalist: 'datalist/marketing/recurring/campaign',
    export: 'export/marketing/recurring/campaign',
    validate: 'marketing/recurring/campaign/validities',
    campaignFor: 'recurring/campaign-for',
    datalistFor: 'datalist/recurring/campaign-for',
    exportFor: 'export/recurring/campaign-for',
  };
  public static CAMPAIGN = {
    campaign: 'marketing/campaign',
    all: 'marketing/campaign/all',
    datalist: 'datalist/marketing/campaign',
    export: 'export/marketing/campaign',
    validate: 'marketing/campaign/validities',
    campaignFor: 'marketing/campaign-for',
    datalistFor: 'datalist/marketing/campaign-for',
    exportFor: 'export/marketing/campaign-for',
  };
  public static BANNER = {
    banners: 'banners',
    all: 'banners/all',
    autocomplete: 'banners/autocomplete',
    datalist: 'datalist/marketing/banners',
    export: 'export/marketing/banners',
    imageupload: 'banners/images/upload',
    mediaUpload: 'banners/media/upload',
    completed: 'datalist/marketing/banners/completed',
    running: 'datalist/marketing/banners/running',
    scheduled: 'datalist/marketing/banners/scheduled',
    stopped: 'datalist/marketing/banners/stopped',
    exportCompleted: 'export/marketing/banners/completed',
    exportRunning: 'export/marketing/banners/running',
    exportScheduled: 'export/marketing/banners/scheduled',
    exportStopped: 'export/marketing/banners/stopped',
    findBannersByIds: 'banners/find/ids',
    bannerValidate: 'banners/name/validate',
  };
  public static MB_PASS = {
    mbPass: 'marketing/mb-pass',
    datalist: 'datalist/marketing/mb-pass',
    export: 'export/marketing/mb-pass',
    uploadPassEligibility: 'marketing/pass-eligibility-segment/upload',
    bulkUploadMbPass: 'marketing/mb-pass/upload',
    mbPassAutocomplete: 'marketing/mb-pass/autocomplete',
    manualSelectSegment: 'marketing/pass-eligibility-segment',
    getManualSelection: 'marketing/pass-eligibility-segment/pass',
  };
  public static COUPON = {
    datalist: 'datalist/marketing/coupon',
    export: 'export/marketing/coupon',
    coupon: 'marketing/coupon',
    autocomplete: 'marketing/coupon/autocomplete',
    clone: 'marketing/coupon/clone',
    forceStop: 'marketing/coupon/force-stop',
    customerCoupon: 'datalist/marketing/coupon/customer',
    benefit_on: 'benefit-on',
    benefit_upload: 'benefit-on/upload',
  };
  public static AUTO_ADD_SAMPLE = {
    export: 'export/marketing/catalog/offer',
    datalist: 'datalist/marketing/catalog/offer',
    offerDetails: 'catalog/offer/details',
    offer: 'catalog/offer',
    sampleBenefit: 'catalog/sample-benefit',
    uploadReport: 'catalog/offer/customer/upload-report',
    bulkUpload: 'catalog/offer/customer/upload',
    offerSegment: 'catalog/offer/segment',
  };
  public static SCHEMES = {
    schemes: 'schemes',
    all: 'schemes/all',
    autocomplete: 'schemes/autocomplete',
    datalist: 'datalist/marketing/schemes',
    export: 'export/marketing/schemes',
    validate: 'invite-codes/validities',
    running: 'running',
  };
  public static MESSAGETEMPLATES = {
    messageTemplates: 'message-templates',
    all: 'message-templates/all',
    autocomplete: 'message-templates/autocomplete',
    datalist: 'datalist/message-templates',
    export: 'export/message-templates',
    stopped: 'datalist/message-templates/stopped',
  };
  public static BASKET = {
    basketDeliveryHoliday: 'deliveries/holidays',
    products: 'datalist/baskets/products',
    exportProducts: 'export/baskets/products',
    history: 'datalist/baskets/history',
    exportHistory: 'export/baskets/history',
    summary: 'datalist/baskets/summary',
    exportSummary: 'export/baskets/summary',
    procurement: 'datalist/baskets/procurement',
    exportProcurement: 'export/baskets/procurement',
    failing: 'datalist/baskets/failing',
    exportFailing: 'export/baskets/failing',
    find: 'datalist/baskets/products/find',
    exportFind: 'export/baskets/products/find',
    cluster: 'datalist/baskets/clusters',
    exportCluster: 'export/baskets/clusters',
    productFailing: 'datalist/baskets/products/failing',
    exportProductFailing: 'export/baskets/products/failing',
    details: 'baskets/details/customers',
    basketProcess: 'baskets/process/customer',
    removedProducts: 'datalist/baskets/removed-products',
    exportRemovedProducts: 'export/baskets/removed-products',
    basketRemovalUpload: 'baskets/process/orders/remove/upload',
  };
  public static VENDORS = {
    vendors: 'vendors',
    all: 'vendors/all',
    autocomplete: 'vendors/autocomplete',
    datalist: 'datalist/vendors',
    export: 'export/vendors',
    vendorHoliday: 'vendor-holidays',
    unmapped: 'reports/vendors/unmapped',
  };
  public static PRODUCTVARIANT = {
    datalist: 'datalist/products/variants',
    productVariants: 'products/variants/',
    deleteProductsVariant: 'products/variants/delete',
    deleteVariant: 'products/variants/delete/all',
    export: 'export/products/variants',
    bulk_upload: 'products/variants/upload',
  };
  public static VENDORSBYHUB = {
    datalist: 'datalist/vendors/hubs',
    vendors: 'vendors/hubs',
    autocomplete: 'vendors/hubs/autocomplete',
  };
  public static VENDORPROCUREMENTS = {
    vendorProcurements: 'vendor-procurements',
    datalist: 'datalist/vendor-procurements',
  };
  public static VENDORHOLIDAYS = {
    datalist: 'datalist/vendor-holidays',
  };
  public static BRANDS = {
    brands: 'brands',
    all: 'brands/all',
    autocomplete: 'brands/autocomplete',
    datalist: 'datalist/brands',
    export: 'export/brands',
    findByIds: 'brands/find/ids',
  };

  public static POPULAR_SUGGESTION = {
    suggestions: 'search/popular/suggestions',
    list: 'datalist/popular/suggestions',
    imageUpload: 'search/popular/suggestions/image/upload',
  };

  public static SUPER_CATEGORIES = {
    datalist: 'datalist/categories/super',
    export: 'export/categories/super',
    autocomplete: 'categories/autocomplete',
    categories: 'categories/super',
    unmapped: 'reports/categories/super/hubs/unmapped',
    all: 'categories/super',

    autocompleteHubSpecific: 'categories/hubs/autocomplete',
    hub: 'categories/hubs',
    activeCategoriesInHub: 'categories/hubs/all',
    imageupload: 'categories/images/upload',
    categoriesLineItems: 'categories/lineItem',
    categoriesRevenue: 'categories/hubs/revenue',
    categoriesAll: 'categories/find/ids',
    revenueDatalist: 'datalist/categories/hubs/revenue',
    updateCategories: 'categories/hubs/update/categories',
    updateLineItems: 'categories/hubs/update/line-item',
    lineItemsExport: 'export/categories/hubs/revenue',
    uploadLineItems: 'categories/hubs/revenue/upload',
    categoryOos: 'categories/force-oos',
    categoryOosAll: 'categories/hubs/all-categories',
  };
  public static CATEGORIES = {
    categories: 'categories',
    all: 'categories/all',
    autocomplete: 'categories/autocomplete',
    autocompleteHubSpecific: 'categories/hubs/autocomplete',
    datalist: 'datalist/categories',
    export: 'export/categories',
    hub: 'categories/hubs',
    activeCategoriesInHub: 'categories/hubs/all',
    imageupload: 'categories/images/upload',
    unmapped: 'reports/categories/hubs/unmapped',
    categoriesLineItems: 'categories/lineItem',
    categoriesRevenue: 'categories/hubs/revenue',
    categoriesAll: 'categories/find/ids',
    revenueDatalist: 'datalist/categories/hubs/revenue',
    updateCategories: 'categories/hubs/update/categories',
    updateLineItems: 'categories/hubs/update/line-item',
    lineItemsExport: 'export/categories/hubs/revenue',
    uploadLineItems: 'categories/hubs/revenue/upload',
    categoryOos: 'categories/force-oos',
    categoryOosAll: 'categories/hubs/all-categories',
  };
  public static CATEGORIESBYHUB = {
    categories: 'categories/hubs',
    datalist: 'datalist/categories/hubs',
  };
  public static SUPERCATEGORIESBYHUB = {
    categories: 'categories/super/hubs',
    datalist: 'datalist/categories/super/hubs',
  };
  public static MANUFACTURERS = {
    manufacturers: 'manufacturers',
    all: 'manufacturers/all',
    autocomplete: 'manufacturers/autocomplete',
    datalist: 'datalist/manufacturers',
    export: 'export/manufacturers',
    findByIds: 'manufacturers/find/ids',
  };
  public static SUBCATEGORYTYPES = {
    datalist: 'datalist/subcategories-type',
    subcategorytypes: 'subcategories-type',
    autocomplete: 'subcategories-type/autocomplete',
  };
  public static PTYPES = {
    datalist: 'datalist/subcategory/p-type',
    export: 'export/subcategory/p-type',
    subcategoriesPType: 'subcategories-type/ptype',
    autocomplete: 'subcategories-type/autocomplete',
    getAllPTypes: 'subcategories-type/find/ids',
  };
  public static FEEDBACK = {
    datalist: 'datalist/cims/feedback',
    export: 'export/cims/feedback',
  };
  public static SUBCATEGORYFEEDBACK = {
    datalist: 'datalist/cims/subcategory',
    export: 'export/cims/subcategory',
  };
  public static SUBCATEGORIES = {
    subcategories: 'subcategories',
    all: 'subcategories/all',
    autocomplete: 'subcategories/autocomplete',
    datalist: 'datalist/subcategories',
    export: 'export/subcategories',
    hub: 'subcategories/hubs',
    imageupload: 'subcategories/images/upload',
    findByIds: 'subcategories/find/ids',
  };

  public static SUBCATEGORIESBYHUB = {
    subcategories: 'subcategories/hubs',
    all: 'subcategories/all',
    autocomplete: 'subcategories/autocomplete',
    datalist: 'datalist/subcategories/hubs',
    export: 'export/subcategories',
    unmapped: 'reports/subcategories/hubs/unmapped',
    unmappedWithDropdown: 'reports/subcategories/hubs/unmapped?name=',
  };
  public static WELCOMEMESSAGES = {
    locations: 'welcome-messages',
    welcomemessages: 'welcome-messages',
    all: 'welcome-messages/all',
    autocomplete: 'welcome-messages/autocomplete',
    imageupload: 'welcome-messages/images/upload',
    datalist: 'datalist/marketing/welcome-messages',
    export: 'export/marketing/welcome-messages',
    running: 'datalist/marketing/welcome-messages/running',
    exportRunning: 'export/marketing/welcome-messages/running',
    scheduled: 'datalist/marketing/welcome-messages/scheduled',
    exportScheduled: 'export/marketing/welcome-messages/scheduled',
    completed: 'datalist/marketing/welcome-messages/completed',
    exportCompleted: 'export/marketing/welcome-messages/completed',
    stopped: 'datalist/marketing/welcome-messages/stopped',
    exportStopped: 'export/marketing/welcome-messages/stopped',
  };
  public static ORDER = {
    bundle: 'datalist/orders/bundle',
    exportBundle: 'export/orders/bundle',
    cancelled: 'datalist/orders/cancelled',
    exportCancelled: 'export/orders/cancelled',
    cluster: 'datalist/orders/cluster',
    exportCluster: 'export/orders/cluster',
    findProduct: 'datalist/orders/find-product',
    exportFindProduct: 'export/orders/find-product',
    gst: 'datalist/orders/gst',
    exportGst: 'export/orders/gst',
    uploadGst: 'reports/orders/upload/gst',
    gstTotal: 'datalist/orders/gst-total',
    exportGstTotal: 'export/orders/gst-total',
    history: 'datalist/orders/history',
    exportHistory: 'export/orders/history',
    product: 'datalist/orders/products',
    exportProduct: 'export/orders/products',
    refunded: 'datalist/orders/refunded',
    exportRefunded: 'export/orders/refunded',
    sales: 'datalist/orders/sales',
    uploadSales: 'reports/orders/upload/sales',
    exportSales: 'export/orders/sales',
    sample: 'datalist/orders/sample',
    addSample: 'orders/sample/product',
    exportSample: 'export/orders/sample',
    society: 'datalist/orders/society',
    exportSociety: 'export/orders/society',
    summary: 'datalist/orders/summary',
    exportSummary: 'export/orders/summary',
    productRefunded: 'datalist/orders/products/refunded',
    exportProductRefunded: 'export/orders/products/refunded',
    refundedPercent: 'datalist/orders/products/refunded-percent',
    exportRefundedPercent: 'export/orders/products/refunded-percent',
    recurring: 'datalist/recurringorders',
    exportRecurring: 'export/recurringorders',
    recurringSummary: 'datalist/recurringorders/summary',
    allProduct: 'orders/products',
    tomorrowBasket: 'baskets/details/customers',
    order: 'orders',
    customer: 'orders/customer',
    recurringOrder: 'datalist/recurringorders',
    resolveWithProductAddress: 'order_product,order_address,order_deliveries',
    refundedProduct: 'reports/orders/details/refunded-products',
    refund: 'orders/refund',
    resolveWithsocities: 'society',
    exportRecurringSummary: 'export/recurringorders/summary',
    orderSocietylist: 'orders/sample/product',
    orderDeliveryType: 'orders/delivery-type',
    extraFee: 'membership/extra-fee/all',
    extraFeeCity: 'membership/extra-fee-city/all',
    extraFeeDefault: 'membership/extra-fee-default/all',
    orderDeliveryTypeById: 'orders/delivery-type',
    invoice: 'ppd/invoice/order',
    remarkList: 'datalist/cims/spoiled',
    sampleUpload: 'orders/sample/priority/product-upload',
    sampleExport: 'export/orders/customer/sample-product',
    uploadReport: 'orders/customer/sample/upload-report',
    productBulkUpload: 'orders/customer/sample/product-upload',
    batch: 'batch/job/key',
    orderProductRefund: 'order-refunds/products',
    sampleproduct: 'datalist/orders/customer/sample-product',
    sampleOrderDatalist: 'datalist/orders/sample-product',
    sampleOrderExport: 'export/orders/sample-product',
  };

  public static PPD = {
    packingIssue: 'datalist/ppd/packing-issues',
    packingIssueExport: 'export/ppd/packing-issues',
    VehiclesExportLoading: 'export/vehicles/loading ',
    VehiclesLoading: 'datalist/vehicles/loading ',
    exportFindProduct: 'export/ppd/find-product',
    generateCluster: 'ppd/app/generate/cluster-stickers',
    packStatus: 'datalist/ppd/pack-status',
    packStatusExport: 'export/ppd/pack-status',
    pickIssueExport: 'export/ppd/pick-issues',
    pickIssue: 'datalist/ppd/pick-issues',
    vehicles: 'datalist/vehicles',
    clusterVehicles: 'datalist/vehicles/clusters',
    exportClusterVehicles: 'export/vehicles/clusters',
    exportClusterVehiclesMapping: 'export/vehicles/upload/clusters',
    exportVehicles: 'export/vehicles',
    uploadClusterVehicle: 'vehicles/upload',
    vehicleAutocomplete: 'vehicles/types',
    vehicle: 'vehicles',
    deliveriesReport: 'datalist/ppd/deliveries-report',
    exportDeliveriesReport: 'export/orders/cancelled/deliveries-report',
    delivery: 'datalist/ppd/delivery',
    exportInventory: 'export/inventory',
    inventoryList: 'datalist/inventory',
    inventoryProduct: 'ppd/inventory/sa/count',
    inventoryProductUpdate: 'ppd/inventory/sa/count/update',
    exportDelivery: 'export/ppd/delivery',
    deliverySociety: 'datalist/ppd/delivery-society',
    deliveryDroppoint: 'datalist/ppd/delivery-droppoint',
    exportDeliverySociety: 'export/ppd/delivery-society',
    exportDeliveryDroppoint: 'export/ppd/delivery-droppoint',
    deliveryTower: 'datalist/ppd/delivery-tower',
    deliveryBucket: 'datalist/ppd/delivery-bucket',
    exportDeliveryTower: 'export/ppd/delivery-tower',
    exportDeliveryBucket: 'export/ppd/delivery-bucket',
    findProduct: 'datalist/ppd/find-product',
    productReturnDatalist: 'datalist/ppd/order/product/hub/return',
    productReturnExport: 'export/ppd/order/product/hub/return',
    productReturn: 'ppd/order/product/hub/return/{id}',
    productReturnUpload: 'ppd/order/product/hub/return',
    manpowerUpload: 'ppd/manpower/cluster/upload',
    dailyIncentiveExport: 'export/ppd/manpower/daily-incentive',
    monthlyIncentiveExport: 'export/ppd/manpower/monthly-incentive',
    dailyIncentiveDatalist: 'datalist/ppd/manpower/daily-incentive',
    monthlyIncentiveDatalist: 'datalist/ppd/manpower/monthly-incentive',
    featureActivation: 'ppd/features/details',
    featureName: 'ppd/features',
    featureMetaData: 'ppd/features-meta',
    notifyLate: 'ppd/app/orders/notify-late',
    messageTemplate: 'ppd/app/message/templates',
    saveLate: 'ppd/app/orders/save-late',
    generateOtp: 'auth/users/group/otp/generate',
    getSaIterations: 'ppd/inventory/sa/iterations',
    clusterTargetUpload: 'ppd/cluster-milestone-targets/upload',
    clusterTargetExport: 'export/ppd/supplychain/cluster-milestone-target',
    clusterTargetDatalist: 'datalist/ppd/supplychain/cluster-milestone-target',
    getClusterTargetData: 'ppd/cluster-milestone-targets',
    milestoneDelayDatalist: 'datalist/ppd/supplychain/delay-logs/milestone',
    milestoneDelayExport: 'export/ppd/supplychain/delay-logs/milestone',
    milestoneDelaysToday: 'ppd/supplychain/delay-logs/milestone/today',
    milestoneDelaysTodayExport:
      'ppd/supplychain/delay-logs/milestone/today/export',
    createMilestoneDelay: 'ppd/supplychain/milestone-delay',
    clusterDelayDataList: 'datalist/ppd/supplychain/delay-logs/cluster',
    clusterDelayExport: 'export/ppd/supplychain/delay-logs/cluster',
    clusterDelayToday: 'ppd/supplychain/delay-logs/cluster/today',
    clusterDelayTodayExport: 'ppd/supplychain/delay-logs/cluster/today/export',
    milestonePerformance: 'ppd/supplychain/milestone-performance',
    deliveryTracking: 'ppd/supplychain/tracking/delivery',
    packingTracking: 'ppd/supplychain/tracking/packing',
    loadingTracking: 'ppd/supplychain/tracking/loading',
    anfList: 'datalist/ppd/anf/order',
    anfExport: 'export/ppd/anf/order',
    anfCallLogs: 'customers/call/logs',
    ppsStickerList: 'ppd/app/download/cluster-stickers',
    orderRouting: 'teams/autocomplete',
    triggerOrderRouting: 'ppd/app/team/routing',
    getAllfeatureProperties: 'ppd/features/properties/all',
    addProperties: 'ppd/features/properties',
    getMetaProperties: 'ppd/features-meta/properties/all',
    addMetaProperties: 'ppd/features-meta/properties/slug',
    vendorsList: 'datalist/ppd/transport/vendor',
    vendorNameAutocomplete: 'ppd/transport/vendor/autocomplete/name',
    vendorIdAutocomplete: 'ppd/transport/vendor/autocomplete/code',
    vendorDetails: 'ppd/transport/vendor',
    vendorVehicles: 'ppd/transport/vendor-vehicle',
    exportVendor: 'export/ppd/transport/vendor',
    dailyReport: 'datalist/ppd/transport/daily-report',
    editVechileInOutIndailyReport: 'ppd/app/transport-vehicle',
    exportDailyReport: 'export/ppd/transport/daily-report',
    uploadcConsolidatedReport: 'reports/ppd/transport/hub-report/upload',
    exportConsolidatedReport: 'export/ppd/transport/hub-report',
    uploadVendorReport: 'reports/ppd/transport/vendor-report/upload',
    exportVendorReport: 'export/ppd/transport/vendor-report',
    vendorHubAutocomplete: 'ppd/app/transport-vehicle/vendor',
    getPickingUser: 'ppd/app/team/sa/picker',
    removePicking: 'ppd/app/team/inventory',
    nonConsumableDelivery: 'ppd/app/download/crate-stickers',
    ewayDownload: 'ppd/eway-bill/download',
    productReturnImages: 'ppd/order/products/return',
    naDeliverySticker: 'ppd/app/download/crate-stickers',
    naDeliveryDownloadSticker: 'ppd/app/generate/cluster-crate-stickers',
    imageupload: 'ppd/promotions/banners/image/upload',
    datalist: 'datalist/ppd/promotions/banners',
    export: 'export/ppd/promotions/banners',
    getPpdBannerById: 'ppd/promotions/banners',
    addPpdBanner: 'ppd/promotions/banners',
    editPpdBanner: 'ppd/promotions/banners',
  };

  public static TRANSACTION = {
    locations: 'transactions',
    all: 'transactions/all',
    bulkCashback: 'transactions/bulk-cashback',
    membershipRefund: 'transactions/membership/refund',
    transactionDetail: 'datalist/payment/transactions/details',
    transactionRefund: 'transactions/refund',
    autocomplete: 'transactions/autocomplete',
    datalist: 'datalist/payment/transactions',
    export: 'export/payment/transactions',
    summaryCashback: 'datalist/payment/transactions/summary/cashback',
    cashCollection: 'datalist/payment/cash-collections',
    cashCollectionView: 'cash-collections',
    exportCashCollection: 'export/payment/cash-collections',
    listCollection: 'datalist/payment/cash-collections/collections',
    exportListCollection: 'export/payment/cash-collections/collections',
    milkCashback: 'datalist/payment/transactions/milk-cashback',
    exportMilkCashback: 'export/payment/transactions/milk-cashback',
    pg: 'datalist/payment/transactions/list/payment-gateway',
    summary: 'datalist/payment/transactions/summary',
    cashback: 'datalist/payment/transactions/cashback',
    exportCashback: 'export/payment/transactions/cashback',
    onlineSummary: 'datalist/payment/transactions/list/summary',
    exportOnlineSummary: 'export/payment/transactions/summary',
    notifyMissedCollection: 'cash-collections/missed',
    credit: 'datalist/payment/credits',
    cashCollectionReasons: 'cash-cancel-reasons',
    customerCredit: 'customer-net-credit',
    queryPG: 'transactions/query',
    superRechargeData: 'supercharge/wallet',
    cancelSuperRecharge: 'supercharge/wallet/cancel',
    walletRefund: 'cims/ticket/wallet-refund',
    deleteCard: 'payment/delete-card',
    getCustomerCards: 'payment/list-card',
    accountClose: 'cims/ticket/ac-close',
    accountCloseRefundBalance: 'credits/balance/account-closure',
    transactionS3Upload: 'export/payment/transactions/upload/s3',
  };
  public static PROCUREMENT = {
    datalist: 'datalist/procurements',
    export: 'export/procurements',
    actions: 'datalist/procurements/actions',
    staffVendorList: 'vendor-procurements/users',
    assignVendor: 'vendor-procurements/users/assign',
    deleteAssignVendor: 'vendor-procurements/users/delete-assign',
    assignVendorList: 'vendor-procurements/users',
    staff: 'vendor-procurements/vendors/users',
    exportActions: 'export/procurements/actions',
    changeRequests: 'datalist/procurements/change-requests',
    exportChangeRequests: 'export/procurements/change-requests',
    clusterReport: 'datalist/procurements/cluster-report',
    exportClusterReport: 'export/procurements/cluster-report',
    na: 'datalist/procurements/na',
    exportNa: 'export/procurements/na',
    return: 'datalist/procurements/return',
    exportReturn: 'export/procurements/return',
    societyReport: 'datalist/procurements/society-report',
    exportSocietyReport: 'export/procurements/society-report',
    suggestions: 'datalist/procurements/suggestions',
    exportSuggestions: 'export/procurements/suggestions',
    autocomplete: 'vendors/autocomplete',
    vendorProcurementAutocomplete: 'vendor-procurements/autocomplete',
    procurements: 'procurements',
    vendorProcurements: 'vendor-procurements',
    createReturn: 'procurements/return',
    cancel: 'procurements/cancel',
    decline: 'procurements/decline',
    procure: 'procurements/procure',
    request: 'procurements/requests',
  };
  public static APPLOGS = {
    applog: 'app-logs',
    datalist: 'datalist/app-logs',
    export: 'export/app-logs',
    summary: 'reports/app-logs/summary',
    settings: 'datalist/params',
    updateVersion: 'value',
    settingupdate: 'params',
  };
  public static BACKLOG = {
    backlog: 'backoffice-logs',
    datalist: 'datalist/backoffice-logs',
    export: 'export/backoffice-logs',
    summary: 'reports/backoffice-logs/summary',
    crons: 'datalist/scheduled-jobs',
    exportCrons: 'export/scheduled-jobs',
  };
  public static APPERROR = {
    apperror: 'apperror-logs',
    datalist: 'datalist/apperror-logs',
    export: 'export/apperror-logs',
    summary: 'reports/apperror-logs/summary',
  };
  public static APPERRORLOG = {
    applog: 'apperror-logs',
    datalist: 'datalist/apperror-logs',
    export: 'export/apperror-logs',
  };
  public static DBLOGS = {
    dbLogs: 'db-logs',
    datalist: 'datalist/db-logs',
    export: 'export/db-logs',
  };
  public static PRODUCTS = {
    productshub: 'products/hubs',
  };
  public static SEARCH_SIMULATOR = {
    search: 'search/product/simulator',
    searchSimulator: 'search/product/custom-simulator',
    export: 'search/product/export/simulator',
    exportSimulator: 'search/product/export/custom-simulator',
  };
  public static CATALOG = {
    masterAutocomplete: 'products/catalogs/autocomplete',
    productsForHub: 'products/hubs',
    productBundleHub: 'products/hubs/bundles',
    producthubs3up: 'export/products/hubs/upload',
    productsForHubAutocomplete: 'products/hubs/autocomplete',
    productsCatalogAutocomplete: 'products/catalogs/autocomplete',
    productMasterUnmapped: 'products/catalogs/unmapped',
    categoriesHub: 'categories/hubs',
    productsCatalogDatalist: 'datalist/products/catalogs',
    productHubDatalist: 'datalist/products/hubs',
    imageupload: 'products/catalogs/images/upload',
    bundleUpload: 'products/catalogs/bundles/images/upload',
    productCatalog: 'products/catalogs',
    productCatalogParent: 'products/catalogs?isParent=1',
    bundleCatalog: 'products/catalogs/bundles',
    productInfo: 'products/info',
    changeRequestApprove: 'products/change-requests',
    procurementVendors: 'products/procurement-vendors',
    aisle: 'products/aisle-sa',
    products: 'products',
    images: 'products/images',
    imagesUpload: 'products/images/upload',
    resolve: 'brand,category,subcategory,subcategory_type,p_type',
    resolveForHub: 'hub,vendor,productcatalog',
    scheduleDatalist: 'datalist/products/hubs/vendors/schedule',
    searchableDatalist: 'datalist/products/hubs/schedule/searchable',
    saLocationUpload: 'products/sa-location/upload',
    sortOrderUpload: 'products/hubs/sort-order/upload',
    productHubUpload: 'products/hubs/upload',
    productHubExport: 'export/products/hubs',
    productHubBundleExport: 'export/products/hubs/bundle',
    productMasterUpload: 'products/catalogs/upload',
    productMasterExport: 'export/products/catalogs',
    productAlerts: 'datalist/products/hubs/alerts',
    productQuantities: 'datalist/products/hubs/quantities',
    productLowStock: 'datalist/products/hubs/low-stock',
    productLowStockExport: 'export/products/hubs/low-stock',
    productDeleted: 'datalist/products/hubs/deleted-products',
    productStockPurchase: 'datalist/products/hubs/stock-purchase',
    productStockPurchaseExport: 'export/products/hubs/stock-purchase',
    productPriceUpload: 'products/hubs/schedule/price/upload',
    productPriceUploadNew: 'products/hubs/scheduled-price/upload',
    productSearchableUpload: 'products/hubs/schedule/searchable/upload',
    productPriceList: 'datalist/products/hubs/schedule/price',
    productPriceListNew: 'datalist/products/hubs/scheduled-price',
    productPriceListExport: 'export/products/hubs/schedule/price',
    productPriceListExportNew: 'export/products/hubs/scheduled-price',
    productOutOfStock: 'datalist/products/hubs/oos',
    productRequest: 'datalist/products/hubs/requests',
    stockRemaining: 'datalist/products/hubs/stocks',
    stockRemainingExport: 'export/products/hubs/stocks',
    synonymsDatalist: 'datalist/synonyms',
    synonyms: 'synonyms',
    elasticIndexing: 'batch',
    brandExport: 'export/brands',
    manufacturersExport: 'export/manufacturers',
    procurementVendorUnmapped: 'reports/products/procurement-vendors/unmapped',
    bundleStock: 'datalist/products/hubs/bundle-stock',
    membershipupdatePrice: 'products',
    membershipPrice: 'products/hubs/membership/price',
    membershipDelivery: 'products/hubs/delivery-type',
    membershipDeliveryBundle: 'bundles/hubs/delivery-type',
    membershipDeilveryUpdate: 'products/hub/delivery-type',
    stockUploadHub: 'products/hubs/stock/upload',
    impulseByupload: 'products/impulse/upload',
    feedbackUpload: 'cims/feedback/upload',
    subcategoryFeedbackUpload: 'cims/subcategory/feedback/upload',
    relatedProductUpload: 'products/related/upload',
    similarProductUpload: 'products/similar/upload',
    userProductRecommendationUpload: 'products/user/recommendations/upload',
    inActiveBulkUpload: 'products/inactive/upload',
    bestOfferDeal: 'products/offers',
    refillBulkUpload: 'products/hubs/tat/upload',
    productHubInfo: 'products/hubs/info',
    productHubInfoUpload: 'products/hubs/info/upload',
    productMasterInfoUpload: 'products/info/upload',
    allocateAllImagesToBundle: 'products/bundle/images/add',
    rrlMrpUpload: 'products/hubs/rrl/price/upload',
    rrStockUpload: 'products/hubs/rr/stock/upload',
    articleCodeMapping: 'products/catalogs/rr-product/upload',
    restrictBundleVisiblity: 'products/hubs/is-forced-inactive/upload',
    bundleUploadFile: 'products/hubs/bundle/upload',
    rrProductPriceUpload: 'products/hubs/rrl/schedule/price/upload',
    rrProductPriceUploadNew: 'products/hubs/rrl/scheduled-price/upload',
    uploadResetStock: 'products/hubs/reset/stock/upload',
    inventoryBulkUpload: 'products/hubs/dp/stock/upload',
    multiplierUpload: 'products/hubs/product-multiplier/upload',
    lmUpload: 'products/catalog/weight/upload',
    lmHubUpload: 'products/hub/weight/upload',
    offerTypeBulkUpload: 'products/hubs/offer-type/upload',
    otbBulkUpload: 'products/hubs/otb-flag/upload',
    fnvProductCsvBulkUpload: 'products/hubs/fnv-stickers/upload',
    fnvProductDownloadSticker: 'ppd/app/download/product-sticker',
  };
  public static SEARCH = {
    productSearch: 'consumer/products/search',
  };
  public static MASTERDATA = {
    masterdata: 'reports/masterdata',
    masterDataApi: 'internal/master-data',
  };
  public static SEGMENTS = {
    segmentCreate: 'segments',
    autocomplete: 'segments/autocomplete-segment',
    newAutoComplete: 'segments/autocomplete',
    all: 'segments/all',
    segmentAutocomplete: 'segments/autocomplete',
    segmentById: 'segments/find/ids',
    segmentExport: 'export/marketing/segments',
    segmentDatalist: 'datalist/marketing/segments',
    segmentTypeCreation: 'segments/type',
    segmentTypeDatalist: 'datalist/marketing/segments/type',
    segmentTypeExport: 'export/marketing/segments/type',
    segmentTypesAutoComplete: 'segments/type/autocomplete',
    segmentsTypeAll: 'segments/type/all',
    customerSegmentExport: 'export/customers/segments',
    customerSegmentDatalist: 'datalist/customers/segments',
    deleteCustomerSegmentUpload: 'customers/segments/delete/upload',
    getReportDeleteCustomerSegmentUpload:
      'customers/segments/delete/upload-report',
    segmentBulkUpload: 'segments/upload',
    customerSegmentUploadReport: 'customers/segments/upload-report',
    customerSegmentBulkUpload: 'customers/segments/upload',
    customerSegmentUploadCron: 'batch',
  };
  public static COLLECTION = {
    collectionProductList: 'list/datalist',
    collection: 'collection/datalist',
    productListPost: 'list',
    autocomplete: 'list/autocomplete',
    productListExport: 'list/export',
    collectionAutocomplete: 'collection/autocomplete',
    collectionExport: 'collection/export',
    listPatchUpload: 'list/products',
    collectionTemplate: 'templates/all',
    createCollection: 'collection',
    productListAll: 'list/all',
    templateById: 'templates',
    brand: 'collection/new-brands',
    brandAutocomplete: 'brands/autocomplete',
    brandLaunch: 'collection/brands/launch/type',
    brandLaunchList: 'collection/brands/launch/type/datalist',
    findByIds: 'collection/new-brands/find/ids',
    brandList: 'collection/new-brands/datalist',
    productLaunchUpload: 'products/launch/upload',
    productLaunchExport: 'export/products/launch/report',
    imagesUpload: 'collection/image/upload',
    videoLottieUpload: 'collection/video/upload',
    videoLottiePresign: 'collection/video/presigned-url',
    autocompleteCollectionName: 'collection/autocomplete',
    onePager: 'collection/location-type',
    onePagerList: 'collection/location-type/datalist',
    collectionAll: 'collection/all',
    collectionClone: 'collection/clone',
  };
  public static SPONSOR = {
    datalist: 'sponsor/type/datalist',
    export: 'sponsor/type/export',
    sponsorType: 'sponsor/type',
  };
  public static MEMBERSHIP = {
    all: 'membership/all',
    membership: 'membership',
    autocomplete: 'membership/autocomplete',
    datalist: 'datalist/marketing/membership',
    export: 'export/marketing/membership',
    cityMembership: 'membership/city',
    cityDatalist: 'datalist/marketing/membership/city',
    cityExport: 'export/marketing/membership/city',
    membershipExtension: 'marketing/offer-membership',
    extensionDatalist: 'datalist/marketing/offer-membership',
    extensionExport: 'export/marketing/offer-membership',
    expireMembership: '/membership/user/expire/upload',
  };
  public static WMS = {
    stockDatalist: 'datalist/wms/stock',
    updateStocks: 'wms/stock',
    stocksExport: 'export/wms/stock',
    generateEInvoice: 'wms/einvoice/outbound',
    getInvoiceOutboundHistory: 'datalist/wms/einvoice/outbound/history',
    getInvoiceOutboundHistoryDownload: 'export/wms/einvoice/outbound/history',
    triggerPushSales: 'wms/sales/history',
  };
  public static IMS = {
    addParentChild: 'ims/product-parent-child',
    getParentChild: 'ims/product-parent-child',
    datalist: 'datalist/ims/product-parent-child',
    export: 'export/ims/product-parent-child',
    productList: 'reports/ims/product/hub/child',
    parentProductList: 'ims/loose-product/all?isActive=1',
    dailyPackingTargetUpload: 'ims/daily-packing-target/upload',
    dailyPackingTargetDatalist: 'datalist/ims/daily-packing-target',
    dailyPackingTargetPending: 'datalist/ims/daily-packing-target/pending',
    dailyPackingTargetSummary: 'datalist/ims/daily-packing-target/summary',
    dailyPackingTargetExport: 'export/ims/daily-packing-target',
    dailyPackingTarget: 'ims/daily-packing-target',
    dailyProductTarget: 'ims/daily-product-target',
    dailyProductTargetDatalist: 'datalist/ims/daily-product-target',
    dailyProductTargetExport: 'export/ims/daily-product-target',
    dailyProductTargetUpload: 'ims/daily-product-target/upload',
    purchaseRequestGenerate: 'ims/purchase-request/generate',
    purchaseRequestCreate: 'ims/purchase-request',
    purchaseRequestDatalist: 'datalist/ims/purchase-request',
    exportPurchaseRequest: 'export/ims/purchase-request',
    looseProduct: 'ims/loose-product',
    looseProductDataList: 'ims/loose-product',
    getALllooseProductList: 'ims/loose-product/all',
    getUserList: 'datalist/ims/user',
    exportUserRequest: 'export/ims/user',
    userRequest: 'ims/user',
    dailyPackingAction: 'ims/daily-packing-target/action',
    getCratesDataList: 'datalist/ims/crates',
    exportCratesData: 'export/ims/crates',
    taxProductRequest: 'ims/tax-product',
    getAllTaxProductList: 'ims/tax-product/no-limit',
    getTaxProductList: 'datalist/ims/tax-product-type',
    getSupplierDataList: 'ims/supplier/datalist',
    supplierRequest: 'ims/supplier',
    purcaseOrderSupplierDataList: 'datalist/ims/purchase-order/supplier',
    purcaseOrderSupplierRequest: 'ims/purchase-order-product/supplier',
    purcaseOrderSupplierExport: 'export/ims/purchase-order/supplier',
    skuPurcaseOrderDatalist: 'datalist/ims/purchase-order/product',
    skuPurcaseOrderRequest: 'ims/purchase-order/product',
    skupurcaseOrderExport: 'export/ims/purchase-order/product',
    purcaseOrderRequest: 'ims/purchase-order/supplier',
    getPurcaseRequest: 'ims/purchase-request/product',
    syncedQuantity: 'ims/daily-packing-target/synced',
    recievePODatalist: 'datalist/ims/purchase-order/received',
    recievePOExport: 'export/ims/purchase-order/received',
    recivedPurcaseOrderRequest: 'ims/received-purchase-order',
    recivedPurcaseOrderProduct: 'ims/received-purchase-order/product',
    generateRecievedPO: 'ims/received-purchase-order',
    getBatchingDatalist: 'datalist/ims/batch/loose-product',
    batchOrderRequest: 'ims/batch/loose-product/sub-batch',
    getBatchRequest: 'ims/batch/loose-product/sku',
    batchingTargetExport: 'export/ims/batch/loose-product',
    getAreaDataList: 'datalist/ims/inventory/area',
    getAllAreaInventoryList: 'ims/inventory/area/no-limit',
    areaInventoryRequest: 'ims/inventory/area',
    getLocationDatalist: 'datalist/ims/inventory/location',
    getAllLocationInventoryList: 'ims/inventory/location/no-limit',
    locationInventoryRequest: 'ims/inventory/location',
    getCurrentInventoryDatalist: 'ims/batch/loose-product/sub-batch/inventory',
    getInventoryLogsDatalist: 'datalist/ims/inventory/movement/logs',
    batchLooseProductDataList: 'ims/batch/loose-product/area/batches',
    requestMovementPendingDataList: 'datalist/ims/inventory/movement/request',
    requestMovementTodaysDataList:
      'datalist/ims/inventory/movement/request/today',
    getLooseProductBatchList: 'ims/batch/loose-product/area/batches',
    getBatchListForMovement: 'ims/inventory/movement/request/batches',
    requestInventoryMovement: 'ims/inventory/movement/request/batches',
    cancelInventoryMovement: 'ims/inventory/movement/request/cancel',
    productParentChildUpload: 'ims/product-parent-child/upload',
    rebatchingRequest: 'ims/batch/loose-product/sub-batch/re-batching',
    getBatchNumber: 'ims/batch/loose-product/sub-batch/sequence',
    wastageExport: 'export/ims/wastage',
    wastageDataList: 'datalist/ims/wastage',
    getlooseProductWastage: 'ims/wastage/loose-product-details',
    postLooseProductWastage: 'ims/wastage/loose-product',
    getchildProductWastage: 'ims/wastage/product-details',
    postchildProductWastage: 'ims/wastage/product',
    getUnsoldInventoryDatalist: 'datalist/ims/inventory/unsold',
    exportUnsoldInventory: 'export/ims/inventory/unsold',
    unsoldTargetRequest: 'ims/inventory/unsold',
    getTotallUnsoldQuantity: 'ims/inventory/unsold/quantity',
    getAutocompleteProductList: 'ims/product-parent-child/autocomplete',
    cratingDataList: 'datalist/ims/packed-loose-product',
    getCratingPackets: 'ims/packed-loose-product/crate',
    createCratingPackets: 'ims/packed-loose-product/crating',
    getteamMaster: 'ims/team',
    getAllTeamMaster: 'ims/team/all',
    teamMasterDatalist: 'datalist/ims/team',
    teamMasterExport: 'export/ims/team',
    getPurchaseAllocationDatalist: 'datalist/ims/purchase-allocation',
    getAllActiveTeamList: 'ims/team/all?isActive=1',
    getAllocationDataForLooseProduct: 'ims/purchase-allocation/loose',
    deletePurchaseAllocation: 'ims/purchase-allocation',
    getLastDayMinimumPurchasePrice:
      'ims/purchase-allocation/min-purchase-price',
    createPurchaseAllocation: 'ims/purchase-allocation',
    getAllocationSummary: 'ims/purchase-allocation/summary',
    getSupplierAllocationRequest: 'ims/purchase-supplier-allocation/request',
    onlySupplierAllocationLoading:
      'ims/purchase-allocation/supplier/confirm/loading',
    downloadSupplierDocument: 'ims/supplier-document',
    getSupplierCommunication: 'ims/supplier-communication/list',
    notifyCommunication: 'ims/supplier-communication/notify',
    exportFnVGRNData: 'export/ims/fnv/grn',
    creatingFnVGRNDatalist: 'datalist/ims/fnv/grn',
    creatingFnVGRNUpload: 'ims/fnv/grn/upload',
    creatingFnVGRN: 'ims/fnv/grn',
    exportFnVWastageManagementData: 'export/ims/fnv/wastage',
    creatingFnVWastageManagementDatalist: 'datalist/ims/fnv/wastage',
    creatingFnVWastageManagementUpload: 'ims/fnv/wastage/upload',
    creatingFnVWastageManagement: 'ims/fnv/wastage',
    exportFnVUnpackWeightData: 'export/ims/fnv/unpack',
    creatingFnVUnpackWeightDatalist: 'datalist/ims/fnv/unpack',
    creatingFnVUnpackWeightUpload: 'ims/fnv/unpack/upload',
    creatingFnVUnpackWeight: 'ims/fnv/unpack',
    exportFnVActualWeightData: 'export/ims/fnv/packet',
    creatingFnVActualWeightDatalist: 'datalist/ims/fnv/packet',
    creatingFnVActualWeightUpload: 'ims/fnv/packet/upload',
    creatingFnVActualWeight: 'ims/fnv/packet',
    exportFnVDailyUnsoldData: 'export/ims/fnv/unsold',
    creatingFnVDailyUnsoldDatalist: 'datalist/ims/fnv/unsold',
    creatingFnVDailyUnsoldUpload: 'ims/fnv/unsold/upload',
    creatingFnVDailyUnsold: 'ims/fnv/unsold',
    updateParentChild:
      'ims/product-parent-child/{child_product_id}/update-parent',
  };

  public static SELFHELP = {
    getFaq: 'cims/faq',
    getFaqType: 'cims/faq-type',
  };
  public static LOYALTY = {
    loyaltyVoucherCityAutocomplete: 'loyalty/voucher/autocomplete',
    loyaltyVoucherDatalist: 'datalist/loyalty/point',
    loyaltyVoucherExport: 'export/loyalty/voucher',
    getLoyaltyVoucherDatalist: 'datalist/loyalty/voucher',
    loyaltyVoucherCreate: 'loyalty/voucher',
    loyaltyVoucherUpload: 'loyalty/voucher/upload',
    loyaltyVoucherCodeDatalist: 'datalist/loyalty/voucher-code',
    loyaltyVoucherCodeExport: 'export/loyalty/voucher/code',
    loyaltyVoucherCity: 'loyalty/voucher',
    loyaltyPoints: 'datalist/loyalty/point',
    loyaltyPointsPurchase: 'datalist/loyalty/voucher-purchase',
    loyaltyVoucherCodeGet: 'loyalty/voucher-code',
    loyaltyTransactionListDatalist: 'datalist/loyalty/transaction',
    loyaltyTransaction: 'loyalty/transaction',
    voucherCityDatalist: 'datalist/loyalty/voucher/city',
    voucherCityExport: 'export/loyalty/voucher/city',
    voucherPuchase: 'loyalty/voucher-purchase',
    voucherPurchaseExport: 'export/loyalty/voucher-purchase',
    loyaltyVoucherCodeUpload: 'loyalty/voucher-code/upload',
    loyaltyCampaignDatalist: 'datalist/loyalty/campaign',
    loyaltyCampaignCreate: 'loyalty/campaign',
    loyaltyCampaignExport: 'export/loyalty/campaign',
    loyalSegment: 'datalist/loyalty/segment',
    loyalSegmentExport: 'export/loyalty/segment',
  };

  public static configuration = {
    propertyDatalist: 'datalist/properties',
    propertyConfig: 'cache/reload-entity',
  };
  public static tagsHub = {
    tags: 'products/tags',
    tagsDatalist: 'datalist/products/tags',
    allTags: 'products/tags/all',
    productTag: 'products/hub/tags',
    productTagDatalist: 'datalist/products/hubs/tags',
    exportTags: 'export/products/tags',
    exportProductTags: 'export/products/hubs/tags',
    productTagUpload: 'products/hub/tags/upload',
    tagsSortOrderUpload: 'products/subcategories/tags/upload',
    tagsSortOrderPatch: 'products/Subcategory/tags/upload',
    tagsAutoComplete: 'products/tags/autocomplete',
  };
  public static PAYMENTS = {
    datalist: 'datalist/payment/offers',
    export: 'export/payment/offers',
    paymentOptions: 'payment/option',
    paymentOffer: 'payment/offer',
    autoDebit: 'payment/auto-debit/customer',
    autoDebitDeactivate: 'payment/auto-debit/deactivate',
    bankDetailsAutocomplete: 'payment/bank-details/autocomplete',
    bankDetailsFindByIds: 'payment/bank-details/find/ids',
    customerPaymentSummary: 'datalist/payment/customer/offer',
    customerPaymentSummaryExport: 'export/payment/customer/offer',
    autocomplete: 'payment/offer/autocomplete',
    paymentMethodDatalist: 'payment/methods/types',
  };

  public static BDTRACKER = {
    rwaDirectoryDatalist: 'datalist/marketing/bd/rwa-directory',
    rwaDirectoryExport: 'export/marketing/bd/rwa-directory',
    getUserDetail: 'users',
    datalist: 'datalist/marketing/bd/allocation',
    export: 'export/marketing/bd/allocation',
    singleAllocation: 'bd/allocation',
    createAllocation: 'bd/allocation',
    bulkAllocationUpload: 'bd/bulk-allocation/upload',
    bulkUnAllocationUpload: 'bd/bulk-unallocation/upload',
    approvalDataList: 'datalist/marketing/bd/pipeline/approval-log',
    approvalFormData: 'datalist/marketing/bd/pipeline/form-details',
    listViewDataList: 'datalist/marketing/bd/pipeline/list-view',
    pipelineExport: 'export/marketing/bd/pipeline/list-view',
    pipelineSummary: 'datalist/marketing/bd/pipeline/summary',
    activationHistoryList: 'datalist/marketing/bd/activation-history',
    activationHistorySummary:
      'datalist/marketing/bd/activation-history/summary',
    viewReportDatalist: 'datalist/marketing/bd/view-report',
    viewReportExport: 'export/marketing/bd/view-report',
    updateApprovalFrom: 'bd/pipeline/approve',
  };

  public static CASHBACK_BUCKET = {
    datalist: 'datalist/payment/cashback/reason',
    export: 'export/payment/cashback/reason',
    cashbackReasonAutocomplete: 'payment/cashback/reason/autocomplete',
    cashbackReason: 'payment/cashback/reason',
    approve: 'payment/cashback/reason/approved',
    disable: 'payment/cashback/reason/disable',
    reject: 'payment/cashback/reason/rejected',
    summary: 'payment/cashback/reason/summary',
  };

  public static CIMS = {
    subGroupMapping: 'cims/subcategory/group',
    SubGroupMappingDatalist: 'cims/subcategory/group/datalist',
    cimsSubcategory: 'cims/subcategory',
    cimsCategory: 'cims/category',
    cimsIssueType: 'cims/issuetype',
  };
  public static common = {
    hubMeta: 'hubs/meta',
    hubData: 'hubs/data',
    hubMetaDatalist: 'datalist/hubs/meta',
    hubDatalist: 'datalist/hubs/data',
    hubMetaExport: 'export/hubs/meta',
    hubDataExport: 'export/hubs/data',
  };

  public static PROPERTIES = {
    cityDataList: 'hubs/city/properties/all',
    cityProperty: 'hubs/city/properties',
    hubDataList: 'hubs/properties/all',
    hubProperty: 'hubs/properties',
  };

  public static OFFERS = {
    offer: 'marketing/offer',
    offers: 'offers',
    datalist: 'datalist/marketing/offer',
    export: 'export/marketing/offer',
    uploadTGCustomer: 'export/marketing/offer/customer',
    uploadS3: 'marketing/offer/customer/s3',
    uploadTG: 'marketing/offer/customer',
    offerAutocomplete: 'marketing/offer/autocomplete',
    customerOfferDataList: 'datalist/marketing/customer/offer',
    offerCashbackDetails: 'reports/marketing/offer/cashback/details',
    offerReload: 'marketing/offer/customer/segment',
    offerClone: 'marketing/offer/clone',
  };

  public static PRODUCT_STICKERS = {
    datalist: 'datalist/product/sticker',
    productStickerAutocomplete: 'products/sticker/list/autocomplete',
    prodStickerMappingUpload: 'products/sticker/upload',
    prodStickerPriorityUpload: 'products/sticker/priority/upload',
  };

  public static MONTHLYBACHAT = {
    bachatcampaign: 'marketing/monthly-bachat/campaign',
    monthlyBachat: 'marketing/monthly-bachat',
    campaigndatalist: 'marketing/monthly-bachat/datalist',
    exportdatalist: 'marketing/monthly-bachat/export',
  };

  public static REWARDS = {
    rewards: 'marketing/reward',
    createReward: 'marketing/reward/create',
    datalist: 'datalist/marketing/reward',
  };
}
