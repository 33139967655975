import { DataService } from 'src/app/shared/services/data/data.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = new BehaviorSubject<boolean>(false);
  redirectUrl: string;

  constructor (
    private router: Router,
    private dataService: DataService,
    private permissionsService: NgxPermissionsService
  ) {
    this.isLoggedIn.next(!!this.getAuthentication());
  }

  // Storing token and user info in localStorage

  setAuthentication (loginCredential) {
    localStorage.setItem(
      environment.localStorageKeys.USER,
      JSON.stringify(loginCredential)
    );
  }

  getAuthentication () {
    return JSON.parse(localStorage.getItem(environment.localStorageKeys.USER));
  }

  setUserProfile (userProfile, moveToHome?: boolean) {
    this.isLoggedIn.next(true);
    this.permissionsService.loadPermissions(userProfile.profile.privileges);
    const authenticatedUser = this.getAuthentication();
    userProfile.profile = {
      ...authenticatedUser.profile,
      ...userProfile.profile
    };
    localStorage.setItem(
      environment.localStorageKeys.USER,
      JSON.stringify({ ...authenticatedUser, ...userProfile })
    );
    if (moveToHome) {
      this.dataService.globalFilter = {
        apiValue: {
          cityId: authenticatedUser.profile.cityId,
          hubId: authenticatedUser.profile.hubId
        },
        uivalue: {
          cityId: authenticatedUser.profile.cityName,
          hubId: authenticatedUser.profile.hubName
        }
      };
      this.router.navigate([ '' ]);
    }
  }

  clearStorageData (key) {
    localStorage.removeItem(key);
  }

  logOut (): void {
    localStorage.clear();
    this.isLoggedIn.next(false);
    this.router.navigate([ 'login' ]);
  }
}