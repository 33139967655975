import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html'
})
export class ChipComponent implements OnInit {
  @Input()
  _chip;
  @Input()
  _search;
  @Input()
  search;
  form: FormGroup;
  constructor (private dataService: DataService) { }

  ngOnInit () {
  }

  toRemoveChip (index?) {
    if (this._chip[ 'dependentOn' ]) {
      this._search[ this._chip[ 'targetIndex' ] ][ 'chipOptions' ] = {};
      this.dataService.setSearchData(this._chip, '', this._search[ this._chip[ 'targetIndex' ] ].key, '');
    }
    if (this._chip && this._chip.target) {
      this.dataService.setSearchData({}, '', this._chip.target, '');
    }
    if (this.isArray(this._chip.apiValue)) {
      this.dataService.initDataForMultiChip[ this._chip.key ].splice(index, 1);
      this.dataService.dataToSendForSearchMultiChip[ this._chip.key ].splice(index, 1);
      this.dataService.setSearchData(
        this._chip,
        this.dataService.initDataForMultiChip[ this._chip.key ],
        this._chip.key,
        this.dataService.dataToSendForSearchMultiChip[ this._chip.key ]
      );
      this._chip.value = this.dataService.initDataForMultiChip[ this._chip.key ];
    } else {
      this.dataService.setSearchData(this._chip, '', this._chip.key, '');
      this._chip.value = '';
    }
    this.dataService.setLocalStorage(environment.localStorageKeys.FILTERS, this.dataService.searchFilters);
    this.dataService.refreshList.next(true);
  }

  isArray (obj) {
    return Array.isArray(obj);
  }
}
