<div class="error-template">
  <h1>
    Oops!
  </h1>
  <h2>
    404 Not Found
  </h2>
  <div class="error-details">
    <h3>This is not the web page you are looking for!</h3>
  </div>
  <div class="error-actions">
    <button
      mat-raised-button
      color="primary"
      (click)="dataService.goBack()"
      class="btn"
    >
      Take Me Home
    </button>
  </div>
</div>
