import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './containers/side-nav/side-nav.component';
import { HomeComponent } from './containers/home/home.component';
import { LoginComponent } from './containers/login/login.component';
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component';
import { AppRoutingModule } from './modules/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { GlobalFilterComponent } from './containers/global-filter/global-filter.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './containers/update-password/update-password.component';
import { SettingComponent } from './containers/app-settings/setting.component';
import { UserNameComponent } from './containers/user-name/user-name.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    SideNavComponent,
    HomeComponent,
    LoginComponent,
    PageNotFoundComponent,
    AppRoutingModule
  ],
  declarations: [
    SideNavComponent,
    HomeComponent,
    LoginComponent,
    PageNotFoundComponent,
    GlobalFilterComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    SettingComponent,
    UserNameComponent
  ],
  entryComponents: [ GlobalFilterComponent ]
})
export class CoreModule { }