<div class="mat-radio-group-wrap" [formGroup]="form">
  <label class="d-block">{{ _radio.placeholder }}</label>
  
  <mat-radio-group [formControlName]="_radio.key">
    <mat-radio-button
      color="primary"
      (change)="emitRadioChange($event)"
      [value]="radio.value"
      *ngFor="let radio of _radio.radios"
      >{{ radio.placeholder }}</mat-radio-button
    >
  </mat-radio-group>
</div>
