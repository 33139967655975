<mat-card-header class="header">
  <div class="rowOne w-100">
    <button (click)="goBack()" class="close-button" type="button" mat-icon-button *ngIf="_header.showBackButton">
      <mat-icon class="material-icons-outlined">arrow_back</mat-icon>
    </button>
    <div class="col p-0 header-title-holder">
      <span class="header-title" *ngIf="!_header.headline">{{ _header.header }}</span>
      <span class="header-title" *ngIf="_header.headline">{{ _header.headline }}</span>
    </div>
    <div class="refresh-holder">
      <div *ngIf="_header.showRefresh" class="refresh">
        <a href="javascript:void(0)" (click)="reloadFilteredData()" *ngIf="!_header.backPath">
          <mat-icon class="material-icons-outlined">refresh</mat-icon>
          <span>REFRESH</span>
        </a>
      </div>
      <button *ngIf="_header.showCreate && (!_header.createPrivilege || permissionsService.getPermission(_header.createPrivilege))" class="create-button" mat-raised-button (click)="changePage(_header.createRoute, _header.dialogBox)">
        <mat-icon class="material-icons-outlined">add</mat-icon>
        <span>{{ _header.btnText | uppercase }}</span>
      </button>
    </div>
  </div>
</mat-card-header>
