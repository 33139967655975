<div class="page-layout" [ngClass]="{ 'sidenav-open': isCollapsed, 'no-side-menu': !showMenu }">
  <!-- side menu -->
  <button type="button" class="d-block d-sm-none nav-m" mat-icon-button (click)="isCollapsed = !isCollapsed">
    <mat-icon class="material-icons-outlined">menu</mat-icon>
  </button>
  <mat-sidenav-container class="page-layout">
    <mat-sidenav (click)="removeDropdown()" class="sidenav-wrap" #sidenav mode="side" opened [ngClass]="{ collapseit: isCollapsed }" *ngIf="showMenu">
      <mat-toolbar class="toolbar-logo">
        <mat-toolbar-row>
          <button type="button" class="d-none d-sm-block" mat-icon-button (click)="isCollapsed = !isCollapsed">
            <mat-icon class="material-icons-outlined">menu</mat-icon>
          </button>
          <div class="brand-holder">
            <img class="logo-image" src="../../../../assets/img/logo.png" />
            <span class="brand-title">Milkbasket</span>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <mat-nav-list [ngClass]="{'scrolHide':hoverScroll ? true : ''}" class="nav-list ps scrollTarget hoverScroll" [perfectScrollbar]="config">
        <div id="set-scroll-margin"></div>
        <div class="mat-list-item-label-1" id="{{nav.name}}" (mouseover)="navScrollIn(nav)" (mouseout)="navScrollOut(nav)" *ngFor="let nav of navLinks; let $index = index"
          (click)="selectRouter(nav, $index)">
          <a mat-list-item [routerLink]="getRouterLink(nav)" routerLinkActive="active-navLink">
            <!-- <img class='navlink-image' src="../../../../assets/img/{{nav.icon}}.png"> -->
            <mat-icon class="icon-left-side" [ngClass]="{
                'material-icons-outlined': nav.icon !== 'location_on'
              }">{{ nav.icon }}</mat-icon>
            <span class="title">{{ nav.label }}</span>
            <mat-icon *ngIf="nav.submenu && nav.submenu.length" class="material-icons-outlined arrow-right">arrow_right</mat-icon>
          </a>
          <div *ngIf="nav.submenu" class="sub-menu ps" [routerLink]="getRouterLink(nav)" routerLinkActive="active-submenu" [perfectScrollbar]="config">
            <ng-container *ngFor="let subNav of nav.submenu">
              <ng-container *ngIf="!(groupCheck.length && subNav.label === 'Master Data')">
                <div class="mat-list-item-label-2" *ngIf="
                  !subNav.privilege ||
                  permissionsService.getPermission(subNav.privilege)
                " (click)="selectSubmenu($event, subNav)" [routerLink]="[subNav.path, subNav.params]" [ngClass]="getSublinkActiveClass(subNav, nav)">
                  <a mat-list-item>{{ subNav.label }}</a>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="main-content-wrap">
      <mat-toolbar class="toolbar-header" [ngClass]="!showMenu ? 'blue-theme' : ''">
        <div class="filter-list-holder">
          <div class="city-hub-labels" *ngIf="showMenu" [routerLink]="['/settings']">
            <span>
              <mat-icon>location_on</mat-icon>{{ dataService.globalFilter.uivalue['cityId'] }}
            </span>
            <span>
              <mat-icon class="material-icons-outlined">home</mat-icon>
              {{ dataService.globalFilter.uivalue['hubId'] }}
            </span>
          </div>
        </div>
        <div class="info-menu-wrap">
          <ul class="info-menu right-links list-inline list-unstyled">
            <li class="profile showopacity divider" [ngClass]="loginClick ? 'open' : ''">
              <a href="javascript:void(0)" data-toggle="dropdown" (click)="loginClick = !loginClick" aria-expanded="false">
                <div class="oval">
                  <span class="login">{{ extraName() }}</span>
                </div>
                <mat-icon class="material-icons-outlined">keyboard_arrow_down</mat-icon>
              </a>
              <ul class="dropdown-menu profile">
                <li class="divider">
                  <div class="logOut">
                    <span class="avatar">{{ extraName() }}</span>
                    <div class="full-name">
                      <strong>{{ dataService.userProfile.fullName }}</strong>
                      <div class="role">
                        {{ dataService.userProfile.groupName }}
                      </div>
                    </div>
                  </div>
                </li>
                <li class="divider" (click)="loginClick = !loginClick" [routerLink]="['/settings']">
                  <mat-icon class="material-icons-outlined">settings</mat-icon>
                  <span> Settings </span>
                </li>
                <li class="divider" (click)="loginClick = !loginClick" [routerLink]="['/user-name']">
                  <mat-icon class="material-icons-outlined">account_circle</mat-icon>
                  <span> Account </span>
                </li>
                <li (click)="logout()">
                  <mat-icon class="material-icons-outlined">power_settings_new</mat-icon>
                  <span class="" (click)="loginClick = !loginClick">
                    Logout
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </mat-toolbar>
      <main class="content-outer-container scroll-primary">
        <div class="content" [ngClass]="{ sidebar_shift: isCollapsed, sidebar_remove: !showMenu }" (click)="removeDropdown()">
          <div class="loader" *ngIf="showLoader">
            <div class="loaderBg"></div>
            <!-- <img src="assets/img/loader.svg"> -->
            <div class="loader-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <router-outlet></router-outlet>
        </div>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
