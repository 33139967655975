import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ERROR_MESSAGES } from '../constants/shared-constant';

@Component({
  selector: 'app-multivalued-input',
  templateUrl: './multivalued-input.component.html',
  styleUrls: [ './multivalued-input.component.scss' ]
})
export class MultivaluedInputComponent implements OnInit {
  @Input() isTextAllowed: boolean = false; // allow text in input
  @Input() maxlengthAllowed: number = 6; // Maximum one input size only allowed
  @Input() minlengthAllowed: number = 6; // Minimum one input size only allowed
  @Input() limit: number = 100; // Maximum Inputs Allowed
  @Input() values: Array<string> = []; // pre exist values if
  @Input() label: string = "Bin";
  @Input() placeholder: string = "Enter Bin Values";
  @Input() error_message: string = ERROR_MESSAGES.BIN_ERROR;
  @Input() hint: string;
  @Output() valueList = new EventEmitter<string[]>();
  showErrorMessage: boolean = false;

  readonly separatorKeysCodes = [ ENTER, COMMA ] as const;
  addOnBlur = false;
  value = "Enter";

  clearInput () {
    this.value = '';
  }
  constructor () { }

  ngOnInit (): void {
  }

  add (event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (
      value &&
      value.trim() &&
      value.length >= this.minlengthAllowed &&
      value.length <= this.maxlengthAllowed
    ) {
      this.values.push(value);
      this.value = '';
      this.valueList.emit(this.values);
      this.showErrorMessage = false;
    }
  }

  remove (value: string): void {
    const index = this.values.indexOf(value);
    if (index >= 0) {
      this.values.splice(index, 1);
    }
    this.valueList.emit(this.values);
  }

  selectValue (value: string) {
    this.value = value;
    this.remove(value);
  }

  checkValidation (event: any): any {
    if (
      event.key != '.' &&
      event.key != ',' &&
      event.key != 'Backspace' &&
      event.key != 'Enter'
    ) {
      if (!this.isTextAllowed && isNaN(event.key * 1)) {
        event.stopPropagation();
        event.preventDefault();
        return false;
      }
      if (event && event.target && event.target.value) {
        if (event.target.value.length >= this.maxlengthAllowed) {
          event.stopPropagation();
          event.preventDefault();
          return false;
        } else if (event.target.value.length + 1 === this.maxlengthAllowed) {
          this.showErrorMessage = false;
        } else {
          this.showErrorMessage = true;
        }
      }
      if (event && event.target && !event.target.value) {
        this.showErrorMessage = true;
      }
    } else if (event.key === 'Backspace') {
      if (event && event.target && event.target.value && event.target.value.length === 1) {
        // when no character are there No error should be visible
        this.showErrorMessage = false;
      } else if (event && event.target && !event.target.value) {
        // when no character are there No error should be visible if extra backspace pressed
        this.showErrorMessage = false;
      }
      else {
        this.showErrorMessage = true;
      }
    }
    // not allowed to enter full stop
    else if (event.key === '.') {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }
}
