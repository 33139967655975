import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http/http.service';
import { Observable } from 'rxjs';
import { DataService } from '../../shared/services/data/data.service';

@Injectable({
  providedIn: 'root'
})
export class SharedCollectionService {
  constructor (
    private httpService: HttpService,
    private dataService: DataService
  ) { }

  getData (url): Observable<object> {
    return this.httpService.post(url, { filterRequired: true });
  }
  patchDataOnParams (url, formValue): Observable<object> {
    return this.httpService.patch(url, null, formValue);
  }
  addData (url, formValue): Observable<object> {
    return this.httpService.post(url, formValue);
  }
  getAllList (url): Observable<object> {
    return this.httpService.get(url);
  }
  getDataById (url, basketId): Observable<object> {
    return this.httpService.get(url, basketId, null, true);
  }
  getDataWithOutId (url): Observable<object> {
    return this.httpService.get(url);
  }
  editData (url, formValue, listId): Observable<object> {
    return this.httpService.put(url, formValue, listId, true);
  }
  editDataWith (url, formValue, userId): Observable<object> {
    return this.httpService.put(url, formValue, userId, false);
  }
  uploadFile (url, formValue, option?): Observable<object> {
    return this.httpService.post(url, formValue, option);
  }
  getPresigningUrl(url, option?) {
    return this.httpService.get(url);
  }
  uploadLargeVideoFiles (url, data, option?): Observable<object> {
    return this.httpService.putWithHeaders(url, data, option, false, true);
  }
}
