<mat-error
  *ngIf="
    form.controls[_error.key]?.touched ||
    form.controls[_error.key]?.dirty ||
    form.controls[_error.key]?.value
  "
>
  <span *ngFor="let validation of validations; let i = index">
    <span
      *ngIf="
        form.controls[_error['key']].errors &&
        form.controls[_error['key']].errors[validation]
      "
    >
      {{ _error['validationMessages'][i] }}
    </span>
  </span>
</mat-error>
