<div class="row row-lg">
  <div class="col-md-6 p-0" [ngClass]="{ 'col-md-12': !_labelView.latitude }">
    <mat-card class="padding-bottom">
      <mat-card-header class="ml-0">
        <mat-card-title class="headline-holder">
          <button class="button-back" *ngIf="_labelView.page.path" (click)="goBack()" type="button" aria-label="Toggle sidenav" mat-icon-button>
            <mat-icon class="material-icons-outlined" aria-label="Side nav toggle icon" matTooltip="Back">keyboard_backspace
            </mat-icon>
          </button>
          <div class="title-holder" (click)="previewFullImage()">
            <div class="avatar mr" *ngIf="_labelView.url">
              <img class="img-uploaded" [src]="this.file_url + _labelView.url" alt="..." onError="this.src='../../../../assets/img/no_image.jpg';" />
            </div>
            <span class="head-title">{{ _labelView.page.label }}</span>
          </div>
          <button class="create-button" mat-raised-button (click)="goToEdit()" *ngIf="_labelView.showEdit">
            <mat-icon class="material-icons-outlined">create</mat-icon>
          </button>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="form-horizontal padding-top">
          <div class="scroll-primary">
            <div class="label-list ps" [perfectScrollbar]="config">
              <div [class]="
                  view.class ? view.class + ' label-list-tr' : 'label-list-tr'
                " *ngFor="let view of _labelView">
                <app-label [_label]="view"></app-label>
              </div>
            </div>
          </div>
          <div class="row mt-5" *ngIf="_labelView.actions">
            <div class="col-md-12 label-view-action">
              <span *ngFor="let action of _labelView.actions">
                <button mat-stroked-button color="primary" type="button" class="btn btn-actions text-uppercase" (click)="actions(action.action)">
                  {{ action.action }}
                </button>
              </span>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-6 p-0 map-section" *ngIf="_labelView.latitude || _labelView.longitude">
    <agm-map [latitude]="_labelView.latitude" [longitude]="_labelView.longitude" [scrollwheel]="false" [zoom]="12">
      <agm-marker [markerDraggable]="true" [latitude]="_labelView.latitude" [longitude]="_labelView.longitude"></agm-marker>
    </agm-map>
  </div>
</div>
