import { Component, Input, OnInit } from '@angular/core';
import { shared } from '../../constants/shared-constant';
import { Router } from '@angular/router';
import { DataService } from '../../services/data/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: [ './label.component.scss' ]
})
export class LabelComponent implements OnInit {
  @Input()
  _label;

  constructor (
    private router: Router,
    public dataService: DataService
  ) { }

  ngOnInit () {
    if (this._label.value === 0 || this._label.value) {
      this._label.value = this._label.value;
    } else {
      this._label.value = shared.NA;
    }
    if (!Array.isArray(this._label.value)) {
      this._label.value = [ this._label.value ];
    }
  }
  navigateTo () {
    if (this._label.navigateTo === 'CIMS') {
      return `${environment.issuePath}order/${this._label.linkId}`;
    } else if (
      this._label.placeHolder === 'Product' &&
      this._label.navigateTo === 'catalog/hub'
    ) {
      return `/${this._label.navigateTo}/${this.dataService.globalFilter.apiValue.hubId}/product/${this._label.linkId}/view`;
    } else if (this._label.navigateTo === 'basket') {
      return `/${this._label.navigateTo}/view/${this._label.linkId}/${this._label.value}`;
    } else if (this._label.navigateTo) {
      // this.router
      //   .navigateByUrl('/', { skipLocationChange: true })
      //   .then(() =>
      //     this.router.navigate([
      //       `${this._label.navigateTo}/${this._label.linkId}`
      //     ])
      //   );
      return `/${this._label.navigateTo}/${this._label.linkId}`;
    }
  }
}
