import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { globalFilter, appConstants } from '../../constants/app-constant';
import { FormService } from '../../../shared/services/form/form.service';
import { FormGroup } from '../../../../../node_modules/@angular/forms';
import { DataService } from '../../../shared/services/data/data.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SettingService } from './setting.service';
import { AppWebRoutes } from '../../constants/app-web-routes';
import { MapService } from 'src/app/location/containers/map/helper/map.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: [ './setting.component.scss' ]
})
export class SettingComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public globalFilter = globalFilter;
  @Output()
  afterSelect: EventEmitter<any> = new EventEmitter<any>();
  public cityHubSelectedWhileLogin = false;
  previousUrl;
  constructor (
    private formService: FormService,
    private dataService: DataService,
    public dialog: MatDialog,
    private authService: AuthService,
    private settingService: SettingService,
    private mapService: MapService,
    private loc: Location,
    private router: Router
  ) { }

  ngOnInit () {
    this.cityHubSelectedWhileLogin = !!(
      this.authService.getAuthentication().profile &&
      this.authService.getAuthentication().profile[ 'hubName' ]
    );
    this.form = this.formService.createFormGroup(this.globalFilter);
  }
  changeOperation () {
    if (typeof this.form.value[ 'hubId' ] === 'number') {
      const indexofHub = this.dataService.getPreviousUrl.indexOf(
        this.dataService.globalFilter.apiValue.hubId
      );
      this.dataService.globalFilter.apiValue[ 'cityId' ] = this.form.value[
        'cityId'
      ];
      this.dataService.globalFilter.uivalue[
        'cityId'
      ] = this.dataService.apiValueForForm[ 'cityId' ];
      this.dataService.globalFilter.apiValue[ 'hubId' ] = this.form.value[
        'hubId'
      ];
      this.dataService.globalFilter.uivalue[
        'hubId'
      ] = this.dataService.apiValueForForm[ 'hubId' ];
      const profileValue = this.authService.getAuthentication();
      profileValue.profile[
        'hubId'
      ] = this.dataService.globalFilter.apiValue.hubId;
      profileValue.profile[
        'cityId'
      ] = this.dataService.globalFilter.apiValue.cityId;
      profileValue.profile[
        'hubName'
      ] = this.dataService.globalFilter.uivalue.hubId;
      profileValue.profile[
        'cityName'
      ] = this.dataService.globalFilter.uivalue.cityId;
      this.authService.setAuthentication(profileValue);
      appConstants[ 'menu' ][ 'hubcatalog' ][ 'root' ][
        'submenu'
      ][ 3 ].path = `/catalog/hub/${this.dataService.globalFilter.apiValue.hubId
      }/product`;
      this.getCityData();
      this.mapService.getMapData(true);
      if (this.cityHubSelectedWhileLogin) {
        if (this.dataService.getPreviousUrl && this.dataService.getPreviousUrl.match('catalog\/hub\/d*') && this.dataService.getPreviousUrl.match('catalog\/hub\/d*').length) {
          const url = `${this.dataService.getPreviousUrl.substr(0, indexofHub - 1)}/${this.dataService.globalFilter.apiValue.hubId}/${this.dataService.getPreviousUrl.substr(indexofHub + 2)}`
          this.router.navigate([ url ]);
        } else {
          this.goBack();
        }
      } else {
        this.afterSelect.emit();
      }
    }
  }

  getCityData () {
    this.settingService.getDataById(AppWebRoutes.CITIES.cities, this.dataService.globalFilter.apiValue.cityId)
      .subscribe((success: Response) => {
        if (success && success[ 'latitude' ] && success[ 'longitude' ]) {
          this.dataService.mapCenter.latitude = success[ 'latitude' ];
          this.dataService.mapCenter.longitude = success[ 'longitude' ];
          this.dataService.mapCenter.zoom = success[ 'zoom' ];
          this.mapService.mapCenterZoomSubject.next(true);
        }
      });
  }

  goBack () {
    this.loc.back();
  }
  ngOnDestroy () {
    this.dataService.apiValueForForm = {};
  }
}
