import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: [ './image-detail.component.scss' ]
})
export class ImageDetailComponent {
  fileUrl = environment.file_url;
  constructor (
    public dialogRef: MatDialogRef<ImageDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  close () {
    this.dialogRef.close();
  }

}
