import { Component, OnInit, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: [ './counter.component.scss' ]
})
export class CounterComponent implements OnInit {
  @Input() element;
  @Input() row;
  @Input() disabled;
  given;
  required;
  @Output() change: EventEmitter<number> = new EventEmitter();
  // @Input() showTotal = false;
  // @Input() initial = 0;
  // @Input() leadingZero: true;
  constructor (private changeDetectRef: ChangeDetectorRef) { }

  ngOnInit () {
    this.given = this.element[ this.row.counter.given ] ? Number(this.element[ this.row.counter.given ]) : 0;
    this.element[ this.row.counter.updateName ] = this.given;
    this.required = Number(this.element[ this.row.counter.required ]);
  }
  increment () {
    this.given++;
    this.element[ this.row.counter.updateName ]++;
    // this.change.emit(this.count);
  }

  decrement () {
    this.given--;
    this.element[ this.row.counter.updateName ]--;
    // this.change.emit(this.count);
  }

}
