<br><br>
<div class="center" [ngClass]="{'hidden': schema.hideField === true }">
  <label> {{ schema.placeholder }} <span *ngIf="schema.validations && schema.validations.required">*</span></label>
  <ngx-file-drop *ngIf="!_url" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <span class='material-icons-outlined'>perm_media</span>
      &nbsp;&nbsp;
      <h4>Drop files here to upload</h4>
      &nbsp;&nbsp; <h4>or</h4> &nbsp;&nbsp;
      <button mat-stroked-button type="button" color="primary" mat-raised-button (click)="openFileSelector()">Browse Files</button>
    </ng-template>
  </ngx-file-drop>
  <div class="image-uploader-box" *ngIf="_url && format">
    <ng-container [ngSwitch]="format">
      <div *ngSwitchCase="mediaType.IMAGE">
        <img class="img-uploaded" [src]="_url" *ngIf="_url" alt="..." />
      </div>
      <div *ngSwitchCase="mediaType.VIDEO">
        <video [src]="_url" *ngIf="_url" height="250" width="100%" controls></video> <br />
      </div>
      <div *ngSwitchCase="mediaType.LOTTIE">
        <ng-lottie height="318px" [options]="{'path': _url}"></ng-lottie>
      </div>
      <div *ngSwitchDefault>
        {{files[0].relativePath}}
      </div>
      <button *ngIf="_url" type="button" class="close-button" mat-icon-button (click)="reset()">
        <mat-icon class="material-icons-outlined">close</mat-icon>
      </button>
    </ng-container>
  </div>
  <br>
  <h4>Note <span *ngIf="schema.fileMsg">( {{ schema.fileMsg }} )</span> : </h4>
  <h5 class="danger">- Only '{{ schema.acceptType }}' files are allowed.</h5>
</div>
