import { Component, OnInit } from '@angular/core';
import { appConstants } from '../../constants/app-constant';
import { DataService } from '../../../shared/services/data/data.service';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ForgotPasswordService } from './forgot-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public mailSent: boolean;

  constructor(private dataService: DataService, private formBuilder: FormBuilder, private forgotPasswordService: ForgotPasswordService) {
    this.dataService.sendSideNavData({ 'title': appConstants.pages.forgotPassword.label });
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(appConstants.emailPattern)])]
    });
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.forgotPasswordService.forgotPassword(this.forgotPasswordForm.value).subscribe((success: Response) => {
      if (success === null) {
        this.mailSent = true;
        formDirective.resetForm();
        this.forgotPasswordForm.reset();
      } else {
        this.mailSent = false;
      }
    });
  }
}
