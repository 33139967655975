import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: [ './html-editor.component.scss' ]
})
export class HtmlEditorComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [
      // optional
      {
        name: 'quote',
        class: 'quote'
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1'
      }
    ]
  };
  @Input()
  _htmlEditor;
  @Input()
  form: FormGroup;

  constructor () { }

  ngOnInit () {
    this.editorConfig.placeholder = this._htmlEditor.placeholder;
    if (this._htmlEditor?.isHideToolBar) {
      this.editorConfig.toolbarHiddenButtons = this._htmlEditor.toolBarHiddenButton;
    }
    if (this._htmlEditor?.editable) {
      this.editorConfig.editable = false;
    }
  }
}
