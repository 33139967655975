import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  @Input() _error;
  @Input() form: FormGroup;
  public validations;
  constructor () { }

  ngOnInit () {
    if (this._error.validationMessages && this._error.validationMessages.length) {
      this.validations = Object.keys(this._error[ 'validations' ]);
    }
  }
}
