import {
    Actions,
    Crud,
    ListInfo,
    MetaData,
    PageInfo,
    Search,
    View
} from '../../shared/interface/interface';
import { AppWebRoutes } from '../../core/constants/app-web-routes';
import { appConstants } from 'src/app/core/constants/app-constant';
import { MatTableDataSource } from '@angular/material/table';

export const cmUser = 'CM';

export const csvMessages = {
    success: {
        message: 'Bulk-allocation List uploaded successfully',
        class: 'success'
    },
    failed: {
        message: 'There are issues with CSV file',
        class: 'danger'
    }
};

export const hoCityFilter = {
    key: 'cityIds',
    placeholder: 'City',
    type: 'multichip',
    apiEndPoint: AppWebRoutes.CITIES.cities,
    chipOptions: {},
    chip: true,
    uiKey: 'name',
    apiKey: 'id',
    module: 'search',
    searchableDropDown: true,
    filterWithHubCity: 'noHubId',
}


export const cmCityFilter = {
    key: 'cityIds',
    placeholder: 'City',
    type: 'autocomplete',
    apiEndPoint: AppWebRoutes.CITIES.cities,
    event: 'blur',
    chipOptions: {},
    chip: true,
    uiKey: 'name',
    apiKey: 'id',
    module: 'search',
    searchableDropDown: true,
}

export const actions = {
    ActionsEnum: {
        Denied: 1,
        Approved: 2,
        Reschedule: 3
    }
}
interface BdConstant {
    pages: BdTrackerPage;
    allocateContainer: Container,
    bulkAllocateContainer: Container,
    bulkUnAllocateContainer: Container,
    approvalLogContainer: Container,
    listViewDataContainer: Container,
    pipelineSummary: Container,
    activationHistoryContainer: Container,
    activationSummary: Container,
    viewReportContainer: Container,
    rwaDirectoryContainer: Container
}

interface BdTrackerPage {
    addAllocation: PageInfo;
    editAllocation: PageInfo;
    uploadBulkAllocation: PageInfo;
    uploadBulkUnAllocation: PageInfo;
    pipeline: PageInfo;
    approvalForm: PageInfo;
    activationHistory: PageInfo;
    formDetails: PageInfo;
    contactDetails: PageInfo
}

export interface ApprovalFormView {
    placeHolder: string;
    value: string;
}

export interface ListViewData {
    metaData?: MetaData,
    tableHeaders?: ListInfo,
    listData?: MatTableDataSource<object>,
    actions?: Actions,
    search?: Search,
    total?: number
}

interface Container {
    tableHeader: ListInfo;
    metaData?: MetaData;
    search?: Search;
    crud?: Array<Crud>;
    actions?: Actions;
    view?: Array<View>;
}

export const BdTrackerConstants: BdConstant = {
    pages: {
        addAllocation: {
            label: 'Create New Allocation',
            path: '/bd-tracker',
            privilege: 'create_bd_allocation'
        },
        editAllocation: {
            label: 'Edit Allocation',
            path: '/bd-tracker',
            privilege: 'update_bd_allocation'
        },
        uploadBulkAllocation: {
            label: 'Upload Bulk Allocation',
            path: 'bd-tracker/allocation/bulk-allocation',
            privilege: 'create_bd_allocation'
        },
        uploadBulkUnAllocation: {
            label: 'Upload Bulk UnAllocation',
            path: 'bd-tracker/allocation/bulk-Unallocation',
            privilege: 'delete_bd_allocation'
        },
        pipeline: {
            label: 'pipeline',
            path: 'bd-tracker/pipeline',
            privilege: 'view_bd_allocation'
        },
        approvalForm: {
            label: 'Approval Form',
            path: 'bd-tracker/pipeline',
            privilege: 'view_bd_allocation'
        },
        activationHistory: {
            label: 'activation History',
            path: 'bd-tracker/activation',
            privilege: 'view_bd_allocation'
        },
        formDetails: {
            label: 'Form Details',
            path: 'bd-tracker/view-reports',
            privilege: 'view_bd_allocation'
        },
        contactDetails: {
            label: 'Contact Details',
            privilege: 'view_bd_allocation'
        },
    },
    allocateContainer: {
        tableHeader: [
            {
                header: 'Soc Id',
                key: 'societyId'
            },
            { header: 'Soc Name', key: 'societyName' },
            { header: 'city', key: 'cityName', sortBy: true },
            { header: 'User', key: 'userName' },
            { header: 'Email', key: 'email' },
            { header: 'Allocated Date', key: 'allocationDate' },
            { header: 'HH', key: 'household' },
        ],
        metaData: {
            header: 'Allocation List',
            btnText: 'Allocate',
            canEdit: true,
            canDelete: true,
            createRouteParams: { mode: 'create' },
            removeGlobalFilter: true,
            delete: {
                url: 'bd/allocation',
                message: 'Are you sure you want to delete allocation?',
                title: 'Delete Allocation',
            },
            confirmation: {
                message: 'Are you sure you want to do this?',
                title: 'Allocation Details',
                why: 'update'
            },
            editRoute: 'bd-tracker/allocation/edit',
            editRouteParams: { mode: 'edit' },
            createRoute: 'add',
            showRefresh: true,
            showCreate: true,
            createPrivilege: 'create_bd_allocation',
            viewPrivilege: 'view_bd_allocation',
            viewExport: AppWebRoutes.BDTRACKER.export
        },
        actions: [
            {
                icon: 'edit',
                label: 'Edit',
                color: 'primary',
                operation: { id: 'allocationId' },
                privilege: 'update_bd_allocation'
            },
            {
                icon: 'delete',
                label: 'DeleteAllocation',
                color: 'primary',
                privilege: 'delete_bd_allocation'
            }
        ],
        search: [
            {
                key: 'userId',
                placeholder: 'User Email',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.USERS.autocomplete,
                event: 'blur',
                chipOptions: {},
                chip: true,
                uiKey: 'email',
                apiKey: 'userId',
                module: 'search'
            },
            {
                key: 'startDate',
                placeholder: 'Date Range',
                type: 'date',
                chip: true,
                options: [],
                chipOptions: {},
                uiKey: 'startDate',
                apiKey: 'startDate',
                module: 'search',
                datePickerType: 'calendar',
                dateRange: true,
                dateRangeKey: 'endDate'
            }
        ],
        crud: [
            {
                key: 'cityId',
                placeholder: 'City',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.CITIES.cities,
                validations: { required: true },
                defaultValue: '',
                disabled: false,
                validationMessages: [ 'City is required' ],
                uiKey: 'name',
                apiKey: 'id',
                searchableDropDown: true,
                module: 'crud',
            },
            {
                key: 'societyId',
                placeholder: 'Select Society',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.SOCIETIES.autocomplete,
                validations: { required: true },
                validationMessages: [ 'SocietyId is required' ],
                chip: true,
                likeSearch: false,
                dependent: true,
                options: [],
                chipOptions: {},
                uiKey: 'name',
                apiKey: 'id',
                module: 'crud',
                searchableDropDown: false,
                localCityIdRequired: true,
            },
            {
                key: 'userId',
                placeholder: 'Select User',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.USERS.autocomplete,
                options: [],
                validations: { required: true },
                defaultValue: '',
                validationMessages: [ 'User is required' ],
                isReadOnly: false,
                uiKey: 'email',
                apiKey: 'userId',
                module: 'crud',
                searchableDropDown: false,
                chip: false,
            }
        ]
    },
    bulkAllocateContainer: {
        tableHeader: [
            {
                header: 'Society Id',
                key: 'societyId',
                subkey: 'value',
                whatToDo: 'subkey'
            },
            {
                header: 'User Id',
                key: 'userId',
                subkey: 'value',
                whatToDo: 'subkey'
            },
        ],
        crud: [
            {
                key: 'file',
                placeholder: 'File Upload',
                type: 'file',
                defaultValue: '',
                validations: { required: true },
                validationMessages: [ 'File is required' ],
                accept: '.csv',
                isReadOnly: false,
                isEditMode: false
            }
        ],
        metaData: {
            download: 'allocation-csv.csv',
            header: 'List of Bulk Allocation Data',
            hidePagination: true,
            hideSearch: true,
            hidelocalSearch: true
        }
    },
    bulkUnAllocateContainer: {
        tableHeader: [
            {
                header: 'Society Id',
                key: 'societyId',
                subkey: 'value',
                whatToDo: 'subkey'
            }
        ],
        crud: [
            {
                key: 'file',
                placeholder: 'File Upload',
                type: 'file',
                defaultValue: '',
                validations: { required: true },
                validationMessages: [ 'File is required' ],
                accept: '.csv',
                isReadOnly: false,
                isEditMode: false
            }
        ],
        metaData: {
            download: 'unallocation-csv.csv',
            header: 'List of Bulk UnAllocation Data',
            hidePagination: true,
            hideSearch: true,
            hidelocalSearch: true
        }
    },
    approvalLogContainer: {
        tableHeader: [
            {
                header: 'User Id',
                key: 'userId',
                sortBy: true
            },
            { header: 'Soc Id', key: 'societyId' },
            { header: 'Soc Name', key: 'societyName' },
            { header: 'HH', key: 'household' },
            { header: 'Reg', key: 'reg' },
            { header: 'Paid', key: 'cpaid' },
            { header: 'Active', key: 'active' },
            { header: 'DoA', key: 'allocationDate' },
            {
                header: 'Status',
                key: 'approvalStatus',
                whatToDo: 'navigate',
                linkId: 'societyId',
                navigateTo: 'bd-tracker/pipeline/approval',
                pipe: 'updateProperty',
                pipeConfig: {
                    CM_PENDING: 'CM Pending',
                    CM_DENIED: 'CM Denied',
                    HO_PENDING: 'HO Pending',
                    HO_DENIED: 'HO Denied',
                    ACTIVATION_ASSIGNED: 'Activation assigned',
                    RESCHEDULE: 'Reschedule Requested',
                    BD_DENIED: 'BD Denied',
                    RWA_DENIED: 'RWA Denied',
                    ACTIVITY_DONE: 'Activity Done'
                }
            },
        ],
        metaData: {
            header: 'Approval List',
            removeGlobalFilter: true,
            showRefresh: true,
            viewPrivilege: 'view_bd_allocation',

        },
        search: [
            {
                key: 'userId',
                placeholder: 'User Email',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.USERS.autocomplete,
                event: 'blur',
                chipOptions: {},
                chip: true,
                uiKey: 'email',
                apiKey: 'userId',
                module: 'search'
            },
            {
                key: 'startDate',
                placeholder: 'Date Range',
                type: 'date',
                chip: true,
                options: [],
                chipOptions: {},
                uiKey: 'startDate',
                apiKey: 'startDate',
                module: 'search',
                datePickerType: 'calendar',
                dateRange: true,
                dateRangeKey: 'endDate'
            }
        ],
        view: [
            { placeholder: 'Society Name', key: 'societyName' },
            { placeholder: 'Society Id', key: 'societyId' },
            { placeholder: 'User Id', key: 'userId' },
            { placeholder: 'Allocated User Email', key: 'allocatedUserEmail' },
            { placeholder: 'Activity Type', key: 'activityType' },
            { placeholder: 'Activation Cost', key: 'activationCost' },
            { placeholder: 'Bd Email', key: 'bdEmail' },
            { placeholder: 'City', key: 'city' },
            { placeholder: 'Cost Per Promoter', key: 'costPerPromotor' },
            { placeholder: 'Current Penetration', key: 'currentPenetration' },
            { placeholder: 'Date Of Activity', key: 'dateOfActivity' },
            { placeholder: 'Last Major Activity', key: 'lastMajorActivity' },
            { placeholder: 'Meeting Id', key: 'meetingId' },
            { placeholder: 'Meeting Mode', key: 'meetingMode' },
            { placeholder: 'Meeting Purpose', key: 'meetingPurpose' },
            { placeholder: 'No Of Promoters Required', key: 'noOfPromotors' },
            { placeholder: 'No Of Goodie Bag Required', key: 'noOfGoodieBag' },
            { placeholder: 'Other Cost', key: 'otherCost' },
            { placeholder: 'Other Remarks', key: 'otherRemarks' },
            { placeholder: 'Paid User Target Activity', key: 'paidUserTargerActivity' },
            { placeholder: 'Permission Cost', key: 'permissionCost' },
            { placeholder: 'Permission Cost Paid By', key: 'costPaidBy' },
            { placeholder: 'Requirement Needed', key: 'requirementNeeded' },
            { placeholder: 'Rwa Designation', key: 'rwaDesignation' },
            { placeholder: 'Rwa Name', key: 'rwaName' },
            { placeholder: 'Rwa Mobile', key: 'rwaMobile' },
            { placeholder: 'Rwa Email', key: 'rwaEmail' },
            { placeholder: 'Status', key: 'status' },
            { placeholder: 'Total Budget', key: 'totalBudget' },
            { placeholder: 'Approval Status', key: 'approvalStatus' },
        ],
        crud: [
            {
                key: 'status',
                placeholder: 'Actions',
                type: 'radio',
                radios: [
                    { value: actions.ActionsEnum.Approved, placeholder: 'Approve' },
                    { value: actions.ActionsEnum.Denied, placeholder: 'Denied' }
                ],
                validations: { required: true },
                defaultValue: '',
                validationMessages: [ 'Action is required' ],
                isReadOnly: false
            },
        ]
    },
    listViewDataContainer: {
        tableHeader: [
            { header: 'User Id', key: 'userId', sortBy: true },
            { header: 'City', key: 'cityName', sortBy: true },
            { header: 'Email', key: 'email' },
            { header: 'Permission', key: 'permission' },
            { header: 'Approved', key: 'approved' },
            { header: 'Pending', key: 'pending' },
            { header: 'Denied', key: 'denied' },
            { header: 'Budget', key: 'totalBudget' }
        ],
        metaData: {
            header: 'List View',
            viewPrivilege: 'view_bd_allocation',
            showRefresh: true,
            removeGlobalFilter: true,
            viewExport: AppWebRoutes.BDTRACKER.pipelineExport
        },
        search: [
            {
                key: 'userId',
                placeholder: 'User Email',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.USERS.autocomplete,
                event: 'blur',
                chipOptions: {},
                chip: true,
                uiKey: 'email',
                apiKey: 'userId',
                module: 'search'
            },
            {
                key: 'startDate',
                placeholder: 'Date Range',
                type: 'date',
                chip: true,
                options: [],
                chipOptions: {},
                uiKey: 'startDate',
                apiKey: 'startDate',
                module: 'search',
                datePickerType: 'calendar',
                dateRange: true,
                dateRangeKey: 'endDate'
            }
        ]
    },
    pipelineSummary: {
        tableHeader: [
            {
                header: 'Permissions',
                key: 'permission',
                whatToDo: 'addLabel',
                label: 'lastLogin',
            },
            { header: 'HO Approved', key: 'hoApproved' },
            { header: 'Pending', key: 'hoPending' },
            { header: 'Denied', key: 'denied' },
            { header: 'Budget', key: 'totalBudget' }
        ],
        metaData: {
            hideSearch: true,
            hidePagination: true,
        },
    },
    activationHistoryContainer: {
        tableHeader: [
            {
                header: 'User',
                key: 'userId',
                sortBy: true
            },
            { header: 'City', key: 'cityName', sortBy: true },
            { header: 'DoA', key: 'dateOfActivation' },
            { header: 'Budget', key: 'budget' },
            { header: 'D0 Paid', key: 'd0Paid' },
            { header: 'D3 Paid', key: 'd3Paid' },
            { header: 'D7 Paid', key: 'd7Paid' },
            { header: 'Soc Id', key: 'societyId' },
            { header: 'Soc Name', key: 'societyName' }
        ],
        metaData: {
            header: 'Activation History',
            removeGlobalFilter: true,
            showRefresh: true,
            viewPrivilege: 'view_bd_allocation'
        },
        search: [
            {
                key: 'userId',
                placeholder: 'User Email',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.USERS.autocomplete,
                event: 'blur',
                chipOptions: {},
                chip: true,
                uiKey: 'email',
                apiKey: 'userId',
                module: 'search'
            },
            {
                key: 'startDate',
                placeholder: 'Date Range',
                type: 'date',
                chip: true,
                options: [],
                chipOptions: {},
                uiKey: 'startDate',
                apiKey: 'startDate',
                module: 'search',
                datePickerType: 'calendar',
                dateRange: true,
                dateRangeKey: 'endDate'
            }
        ]
    },
    activationSummary: {
        tableHeader: [
            {
                header: 'Activation Done',
                key: 'activationDone',
            },
            { header: 'Cancelled', key: 'cancelled' },
            { header: 'Budget', key: 'budget' },
            { header: 'D0 Paid', key: 'd0Paid' },
            { header: 'D3 Paid', key: 'd3Paid' },
            { header: 'D7 Paid', key: 'd7Paid' }
        ],
        metaData: {
            hideSearch: true,
            hidePagination: true,
        },
    },
    viewReportContainer: {
        tableHeader: [
            { header: 'User Id', key: 'userId', sortBy: true },
            { header: 'City', key: 'cityName', sortBy: true },
            { header: 'Type', key: 'status' },
            { header: 'Society Id', key: 'societyId' },
            { header: 'Society Name', key: 'societyName' },
            { header: 'Location', key: 'locationName' },
            { header: 'Recee Date', key: 'submissionDate' },
            { header: 'Oc HH', key: 'household' },
            {
                header: 'Full Form Details',
                key: 'Details',
                whatToDo: 'formDetails',
                navigateTo: 'bd-tracker/view-reports/form-details',
            }
        ],
        metaData: {
            header: 'View Reports(Recce Form)',
            viewPrivilege: 'view_bd_allocation',
            removeGlobalFilter: true,
            showRefresh: true,
            viewExport: AppWebRoutes.BDTRACKER.viewReportExport
        },
        search: [
            {
                key: 'userId',
                placeholder: 'User Email',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.USERS.autocomplete,
                event: 'blur',
                chipOptions: {},
                chip: true,
                uiKey: 'email',
                apiKey: 'userId',
                module: 'search'
            },
            {
                key: 'type',
                placeholder: 'Select type',
                type: 'select',
                options: [
                    { value: 'New', placeholder: 'New' },
                    { value: 'Existing', placeholder: 'Existing' }
                ],
                defaultValue: '',
                event: 'blur',
                uiKey: 'status',
                apiKey: 'status',
                module: 'search'
            },
            {
                key: 'startDate',
                placeholder: 'Date Range',
                type: 'date',
                chip: true,
                options: [],
                chipOptions: {},
                uiKey: 'startDate',
                apiKey: 'startDate',
                module: 'search',
                datePickerType: 'calendar',
                dateRange: true,
                dateRangeKey: 'endDate'
            }
        ],
        view: [
            { placeholder: 'Society Name', key: 'societyName' },
            { placeholder: 'Society Id', key: 'societyId' },
            { placeholder: 'Society Info Id', key: 'societyInfoId' },
            { placeholder: 'Society Type', key: 'societyType' },
            { placeholder: 'User Id', key: 'userId' },
            { placeholder: 'Email', key: 'email' },
            { placeholder: 'Address', key: 'address' },
            { placeholder: 'City Id', key: 'cityId' },
            { placeholder: 'Pincode', key: 'pincode' },
            { placeholder: 'City', key: 'cityName' },
            { placeholder: 'Location', key: 'locationName' },
            { placeholder: 'Latitude', key: 'latitude' },
            { placeholder: 'Longitude', key: 'longitude' },
            { placeholder: 'Total Tower', key: 'totalTower' },
            { placeholder: 'Tower Names', key: 'towerNames' },
            { placeholder: 'Houser No', key: 'houserNo' },
            { placeholder: 'Household', key: 'household' },
            { placeholder: 'Household Occupation', key: 'householdOccupation' },
            { placeholder: 'Contact Name', key: 'contactName' },
            { placeholder: 'Contact Email', key: 'contactEmail' },
            { placeholder: 'Contact Mobile', key: 'contactMobile' },
            { placeholder: 'DeliveryStartTime', key: 'deliveryStartTime' },
            { placeholder: 'Marketing Permission', key: 'marketingPermission' },
            { placeholder: 'Other Marketing', key: 'otherMarkting' },
            { placeholder: 'OtherGrocery', key: 'otherGrocery' },
            { placeholder: 'NearByMarket', key: 'nearByMarket' },
            { placeholder: 'OtherEGroceries', key: 'otherEGroceries' },
            { placeholder: 'Remarks', key: 'remarks' },
            { placeholder: 'SubmittedBy', key: 'submittedBy' },
            { placeholder: 'Status', key: 'status' },
            { placeholder: 'StatusId', key: 'statusId' },
            { placeholder: 'EntryPass', key: 'entryPass' },
            { placeholder: 'EntryPassId', key: 'entryPassId' },
            { placeholder: 'SubmissionDate', key: 'submissionDate' },
        ]
    },
    rwaDirectoryContainer: {
        tableHeader: [
            { header: 'Society Id', key: 'societyId' },
            { header: 'Society Name', key: 'societyName' },
            { header: 'City', key: 'cityName', sortBy: true },
            { header: 'con1', key: 'con1', whatToDo: 'openModal', linkId: '0' },
            { header: 'con2', key: 'con2', whatToDo: 'openModal', linkId: '1' },
            { header: 'con3', key: 'con3', whatToDo: 'openModal', linkId: '2' },
            { header: 'con4', key: 'con4', whatToDo: 'openModal', linkId: '3' },
            { header: 'con5', key: 'con5', whatToDo: 'openModal', linkId: '4' },
            { header: 'Last Updated by', key: 'lastUpdatedBy' },
        ],
        metaData: {
            header: 'Rwa Directory',
            viewPrivilege: 'view_bd_allocation',
            removeGlobalFilter: true,
            showRefresh: true,
            viewExport: AppWebRoutes.BDTRACKER.rwaDirectoryExport
        },
        search: [
            {
                key: 'userId',
                placeholder: 'User Email',
                type: 'autocomplete',
                apiEndPoint: AppWebRoutes.USERS.autocomplete,
                event: 'blur',
                chipOptions: {},
                chip: true,
                uiKey: 'email',
                apiKey: 'userId',
                module: 'search'
            },
            {
                key: 'startDate',
                placeholder: 'Date Range',
                type: 'date',
                chip: true,
                options: [],
                chipOptions: {},
                uiKey: 'startDate',
                apiKey: 'startDate',
                module: 'search',
                datePickerType: 'calendar',
                dateRange: true,
                dateRangeKey: 'endDate'
            }
        ],
        view: [
            { placeholder: 'Name', key: 'name' },
            { placeholder: 'Designation', key: 'designation' },
            { placeholder: 'Mobile Number', key: 'mobile' },
            { placeholder: 'Email', key: 'email' },
            { placeholder: 'Last Updated By', key: 'lastUpdatedBy' },
            { placeholder: 'Last Updated By Id', key: 'lastUpdatedById' },
        ]
    }
}
