<div class="loginPage forgot-password">
  <div class="row">
    <div class="col-lg-6 col-md-12 col-logo">
      <img class="img-rounded" style="opacity: 0.2;" src="assets/img/icon.png" />
    </div>
    <div class="col-lg-6 col-md-12 col-form">
      <div *ngIf="!mailSent">
        <h1>Forgot your password?</h1>
        <h6>Hi, Tell us your email</h6>
      </div>
      <h1 *ngIf="mailSent">Hi, we have sent you an email with reset instructions.</h1>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit(formDirective)" #formDirective="ngForm">
        <div class="row">
          <div class="col-md-12 has-error-msg">
            <mat-form-field class="matFormField-full-width">
              <input name="email" formControlName="email" matInput placeholder="Email Address">
              <mat-icon class="material-icons-outlined" matSuffix color="primary">
                {{forgotPasswordForm.controls['email'].errors?'sentiment_very_dissatisfied':'sentiment_very_satisfied'}}
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-lg-12">
            <button type="submit" mat-raised-button color="success" [disabled]="forgotPasswordForm.invalid">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
