<div [class]="toggleCityHubSelector ? 'show-header loginPage' : 'loginPage'">
  <div class="loader" *ngIf="showLoaderlogin">
    <div class="loaderBg"></div>
    <!-- <img src="assets/img/loader.svg"> -->
    <div class="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12 col-logo">
      <img class="img-rounded img-fluid img-home-logo" src="assets/img/icon.png" />
    </div>
    <div class="col-lg-6 col-md-12 col-form">
      <ng-container *ngIf="!toggleCityHubSelector">
        <h1>Howdy, MBians!!</h1>
        <h4>
          The all-new Admin panel for you guys is here! Looks brighter, its
          better and easier to use.
        </h4>

        <form [formGroup]="loginForm" (ngSubmit)="signIn()" novalidate *ngIf="showManualOrNot !== true">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field class="matFormField-full-width">
                <input name="email" formControlName="username" matInput placeholder="Email address" />
                <mat-error *ngIf="loginForm.controls['username']?.hasError('required')">
                  Email is
                  <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-12">
              <mat-form-field class="matFormField-full-width">
                <input name="password" formControlName="password" [type]="hidePassword ? 'password' : 'text'" matInput placeholder="Password" />
                <mat-icon matSuffix color="primary" class="pointer material-icons-outlined" (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error>
                  Password is
                  <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 forgot-password">
              <a [routerLink]="['/forgot-password']">Forgot Password?</a>
            </div>
            <div class="col-lg-12">
              <button type="submit" mat-raised-button color="success" [disabled]="loginForm.invalid">
                Login
              </button>
            </div>
            <div class="col-lg-12 login-with-google">
              <button type="button" mat-raised-button class="w-100 mt-4" (click)="loginWithGoogle()">
                <img width="20px" alt='Google "G" Logo' src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                <span class="pl-3">Login With Google</span>
              </button>
            </div>
          </div>
        </form>
        <div class="col-lg-12 login-with-google" *ngIf="showManualOrNot === true">
          <button type="button" mat-raised-button class="w-100 mt-4" (click)="loginWithGoogle()">
            <img width="20px" alt='Google "G" Logo' src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
            <span class="pl-3">Login With Google</span>
          </button>
        </div>
        <div class="col-lg-12 fixed-bottom">
          <span> Made with ♥ in Gtown</span>
        </div>
      </ng-container>
      <ng-container *ngIf="toggleCityHubSelector">
        <app-setting (afterSelect)="setCityHub()"></app-setting>
      </ng-container>
    </div>
  </div>
</div>
