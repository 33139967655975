import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http/http.service';
import { Observable } from 'rxjs';
import { DataService } from '../../shared/services/data/data.service';

@Injectable({
  providedIn: 'root'
})
export class SharedLocationService {
  constructor (
    private httpService: HttpService,
    private dataService: DataService
  ) { }

  getData (url, removeGlobalFilter = false): Observable<object> {
    return this.httpService.post(url, { filterRequired: true, removeGlobalFilter: removeGlobalFilter });
  }
  getAllList (url): Observable<object> {
    return this.httpService.get(url);
  }
  addData (url, formValue): Observable<object> {
    return this.httpService.post(url, formValue);
  }
  getDataById (url, userId): Observable<object> {
    return this.httpService.get(url, userId, null, true);
  }
  editData (url, formValue, userId): Observable<object> {
    return this.httpService.put(url, formValue, userId, true);
  }
  uploadData (url, formValue, dataToSend): Observable<object> {
    return this.httpService.post(url, formValue, dataToSend);
  }
  patchDataOnParams (url, formValue): Observable<object> {
    return this.httpService.patch(url, null, formValue);
  }
  editDataWith (url, formValue, userId): Observable<object> {
    return this.httpService.put(url, formValue, userId, false);
  }
  updateAction (url, formValue): Observable<object> {
    return this.httpService.put(url, formValue);
  }
  deleteData (url, id): Observable<object> {
    return this.httpService.delete(url, id, true);
  }
  getAllData (url): Observable<object> {
    let filtersToSend = {};
    if (
      this.dataService.searchFilters.filters &&
      Object.keys(this.dataService.searchFilters.filters).length
    ) {
      filtersToSend = this.dataService.searchFilters.filters;
    }
    return this.httpService.get(url, null, filtersToSend);
  }
  uploadFile (url, formValue, options?): Observable<object> {
    return this.httpService.post(url, formValue, options);
  }
  getDataByIdWithResolve (
    url,
    optionsToUrl,
    optionsToHeader
  ): Observable<object> {
    return this.httpService.get(url, optionsToUrl, optionsToHeader, true);
  }
  getAllDatabyId (url, optionsToUrl): Observable<object> {
    return this.httpService.get(url, null, optionsToUrl);
  }
  patchData (url, formValue): Observable<object> {
    return this.httpService.patch(url, formValue, true);
  }
  exportCsv (url, fileName, hubName, params?, removeGlobalFilter?) {
    this.httpService.exportCsv(url, params, removeGlobalFilter).subscribe(
      response => {
        // download file
        fileName = `${fileName}:${hubName}_${new Date().getDate()}-${new Date().getMonth() +
          1}-${new Date().getFullYear()}`;
        this.downloadFile(response, `${fileName}.csv`);
        this.dataService.hideLoader();
      },
      error => {
        this.dataService.hideLoader();
        if (error[ 'status' ] >= 500) {
          this.dataService.openSnackBar(
            'Check your Internet Connection / Internal Server Error'
          );
        } else if (error[ 'status' ] === 400) {
          this.dataService.openSnackBar(
            'Maximum records limit reached, add filters to get lesser number of records'
          );
        } else {
          this.dataService.openSnackBar(
            'Something went wrong. Please try again later.'
          );
        }
      }
    );
  }
  downloadFile (data: any, fileName) {
    const parsedResponse = data;
    const blob = new Blob([ parsedResponse ], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
