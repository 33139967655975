<div class="container-md setting" [ngClass]="{'notLoggedIn': !cityHubSelectedWhileLogin}">

  <h1>{{cityHubSelectedWhileLogin?'Change':'Select'}} your Hub - </h1>
  <div class="row">
    <div class="col-md-6" *ngFor="let search of globalFilter">
      <app-autocomplete [form]="form" [_autocomplete]="search" [_search]="globalFilter" [_appearance]="cityHubSelectedWhileLogin"> </app-autocomplete>
    </div>
  </div>

  <div class="mt" [ngClass]="{'action': cityHubSelectedWhileLogin}">
    <button mat-stroked-button type="button" color="primary" (click)='goBack()' *ngIf="cityHubSelectedWhileLogin">Cancel</button>
    <button mat-raised-button mat-stroked-button color="{{cityHubSelectedWhileLogin?'primary':'success'}}" type="button" (click)="changeOperation()" [disabled]="form.invalid">
      {{cityHubSelectedWhileLogin?'Save Details':'Continue'}}
    </button>
  </div>
</div>
