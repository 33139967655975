<div class="container-md">
  <form [formGroup]="form">
    <app-crud [_crud]="adminCrud" [form]="form"></app-crud>
  </form>
  <div class="action">
    <button
      mat-raised-button
      mat-stroked-button
      type="button"
      color="primary"
      (click)="Submit()"
      [disabled]="form.invalid"
    >
      Save Details
    </button>
  </div>
</div>

<!-- <div class="container-md">
  <form [formGroup]="form">
    <app-crud [_crud]="adminPassowrdCrud" [form]="passwordform"></app-crud>
  </form>
  <div class="action">
    <button
      mat-raised-button
      mat-stroked-button
      type="button"
      color="primary"
      (click)="passwordSubmit()"
      [disabled]="form.invalid"
    >
      Save Details
    </button>
  </div>
</div> -->
