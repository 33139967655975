<div class="input-form">
  <mat-form-field appearance="outline" class="input_field" [hintLabel]="hint" [ngClass]="{ 'mat-form-field-invalid ng-invalid': showErrorMessage }">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list #chipList>
      <mat-chip selectViaInteraction *ngFor="let value of values" (removed)="remove(value)" (click)="selectValue(value)" (select)="selectValue(value)">
        {{ value }}
        <mat-icon class="material-icons-outlined" matChipRemove>close</mat-icon>
      </mat-chip>
      <input placeholder="{{ placeholder }}" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)"
        [type]="isTextAllowed ? 'text' : 'number'" [readonly]="values.length === limit" [(ngModel)]="value" (keydown)="checkValidation($event)" />
    </mat-chip-list>
    <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearInput()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-error *ngIf="showErrorMessage">
    <span>
      {{ error_message }}
    </span>
  </mat-error>

</div>
