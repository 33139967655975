<h2 mat-dialog-title>
  Change City And Hub</h2>
<mat-dialog-content class="mat-typography">
  <div *ngFor="let search of globalFilter;">
    <app-autocomplete [form]="form" [_autocomplete]="search" [_search]='globalFilter'>
    </app-autocomplete>
    <app-chip [_chip]="search.chipOptions"></app-chip>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close mat-dialog-close="cancel">Cancel</button>
  <button mat-button cdkFocusInitial (click)="performAction()">Apply</button>
</mat-dialog-actions>
