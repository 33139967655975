import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../interface/interface';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: [ './dialog.component.scss' ]
})
export class DialogComponent implements OnInit, AfterViewInit {
  sortOrder;
  dataValue;
  initialInput;

  constructor (
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }
  @ViewChild('btnRef', { static: false }) buttonRef: MatButton;
  ngOnInit () {
    this.sortOrder = this.data[ 'sortOrderValue' ];
    this.dataValue = this.data[ 'dataValue' ];
    this.initialInput = this.data[ 'initialInput' ];
  }

  getDataType (value): string { return (typeof value); }

  trackByFn (index: any, item: any) { return index; }

  ngAfterViewInit (): void {
    if (this.buttonRef) {
      this.buttonRef.focus();
    }
  }
}
