<mat-chip-list>
  <mat-chip *ngIf="_chip && _chip.value && !isArray(_chip.value)" (removed)="toRemoveChip()">
    {{search.placeholder}}: {{_chip.value}}
    <mat-icon class="material-icons-outlined" matChipRemove>close</mat-icon>
  </mat-chip>
  <ng-container *ngIf="_chip && _chip.value && isArray(_chip.value)">
    <mat-chip *ngFor="let value of _chip.value; let i = index" (removed)="toRemoveChip(i)">
      {{search.placeholder}}: {{value}}
      <mat-icon class="material-icons-outlined" matChipRemove>close</mat-icon>
    </mat-chip>
  </ng-container>
</mat-chip-list>
