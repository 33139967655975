import { Component, AfterViewInit, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html'
})
export class ChartComponent implements AfterViewInit {
  @Input() _chart;
  public chart = [];
  constructor () {
  }

  ngAfterViewInit () {
    if (this._chart && this._chart.id) {
      const ctx = document.getElementById(`${this._chart.id}`);
      const that = this;
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: that._chart.chart.label,
          datasets: [ {
            label: that._chart.chart.title,
            data: that._chart.chart.data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          } ]
        },
        options: {
          scales: {
            yAxes: [ {
              ticks: {
                beginAtZero: true
              }
            } ]
          },
          title: {
            display: true,
            text: that._chart.chart.title,
            Position: 'left',
            fontSize: 18,
            fontColor: '#000'
          },
          legend: {
            display: false
          }
        }
      });
    }

  }

}
