import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-paste-multiple-input',
  templateUrl: './paste-multiple-input.component.html',
  styleUrls: [ './paste-multiple-input.component.scss' ]
})
export class PasteMultipleInputComponent implements OnInit {
  @Input() isTextAllowed: boolean = true; // allow text in input
  @Input() maxlengthAllowed: number = 50; // Maximum one input size only allowed
  @Input() minlengthAllowed: number = 1; // Minimum one input size only allowed
  @Input() limit: number = 10000; // Maximum Inputs Allowed
  @Input() values: Array<string> = []; // pre exist values if
  @Input() label: string = "Critical Pids";
  @Input() maxOptionsAllowed: number = 10;
  @Input() placeholder: string = "Enter Critical Pids Values";
  @Input() error_message: string = "Only number allowed";
  @Input() hint: string;
  @Output() valueList = new EventEmitter<string[]>();
  showErrorMessage: boolean = false;
  emptyMsg: string = "Values Can't be empty";
  maxLimitCrossedError:string = "";


  readonly separatorKeysCodes = [ ENTER, COMMA, SPACE ] as const;
  addOnBlur = false;
  value = "";

  clearInput () {
    this.value = '';
  }
  constructor () { }

  ngOnInit (): void {
    if(this.values && this.values.length) {
      this.emptyMsg = "";
    } else {
      this.emptyMsg = "Values Can't be empty";
    }
  }

  add (event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (
      value &&
      value.trim() &&
      value.length >= this.minlengthAllowed &&
      value.length <= this.maxlengthAllowed
    ) {
      this.valueAdded(value);
    }
  }

  remove (value: string): void {
    this.maxLimitCrossedError = "";
    const index = this.values.indexOf(value);
    if (index >= 0) {
      this.values.splice(index, 1);
    }
    
    this.valueList.emit(this.values);
    if(this.values && this.values.length) {
      this.emptyMsg = "";
    } else {
      this.emptyMsg = "Values Can't be empty";
    }
  }

  selectValue (value: string) {
    this.value = value;
    this.remove(value);
  }

  valueAdded(event:string) {
    this.inputEntered(event);
    this.valueList.emit(this.values);
    if(this.values && this.values.length) {
      this.emptyMsg = "";
    } else {
      this.emptyMsg = "Values Can't be empty";
    }
    this.showErrorMessage = false;
    setTimeout(() => {
      this.clearInput();
    }, 0);
  }

  inputEntered(event:any) {
    if(event && event.trim()) {
      if(event.includes('\t')) {
        const value = event.split("\t");
        value.forEach((str)=>{
          this.inputEntered(str);
        });
      } else
      if(event.includes('\n')) {
        const value = event.split("\n");
        value.forEach((str)=>{
          this.inputEntered(str);
        })
      } else
      if(event.includes(',')) {
        const value = event.split(",");
        value.forEach((str)=>{
          this.inputEntered(str);
        })
      } else
      if(event.includes(' ')) {
        const value = event.split(" ");
        value.forEach((str)=>{
          this.inputEntered(str);
        })
      } else if(!!event && !!event.trim() && !isNaN(event.trim() *1)) {
          if(this.values.length === this.maxOptionsAllowed) {
            this.maxLimitCrossedError = `Maximum allowed limit is ${this.maxOptionsAllowed}`;
          } else {
            this.maxLimitCrossedError = "";
            this.values.push(event.trim());
          }
      }
    }
  }

  checkValidation (event: any): any {
    if (
      event.key != '.' &&
      event.key != ',' &&
      event.key != 'Backspace' &&
      event.key != 'Enter'
    ) {
      if (!this.isTextAllowed && isNaN(event.key * 1)) {
        event.stopPropagation();
        event.preventDefault();
        return false;
      }
      if (event && event.target && event.target.value) {
        if (event.target.value.length >= this.maxlengthAllowed) {
          event.stopPropagation();
          event.preventDefault();
          return false;
        } else if (event.target.value.length + 1 === this.maxlengthAllowed) {
          this.showErrorMessage = false;
        } else {
          this.showErrorMessage = true;
        }
      }
      if (event && event.target && !event.target.value) {
        this.showErrorMessage = true;
      }
    } else if (event.key === 'Backspace') {
      if (event && event.target && event.target.value && event.target.value.length === 1) {
        // when no character are there No error should be visible
        this.showErrorMessage = false;
      } else if (event && event.target && !event.target.value) {
        // when no character are there No error should be visible if extra backspace pressed
        this.showErrorMessage = false;
      }
      else {
        this.showErrorMessage = true;
      }
    }
    // not allowed to enter full stop
    else if (event.key === '.') {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData("text");
    this.valueAdded(pastedText);
  }
}
