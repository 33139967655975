import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../services/data/data.service';
import { ListViewService } from '../list-view/list-view.service';
import { AppWebRoutes } from '../../../core/constants/app-web-routes';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: [ './crud.component.scss' ]
})
export class CrudComponent implements OnInit {
  @Input()
  _crud;
  @Input()
  form: FormGroup;
  @Input()
  parentForm?: FormGroup;
  @Input()
  splitClass: string;
  @Input()
  bin: string;
  @Output() onSelection = new EventEmitter();
  @Output() binValues = new EventEmitter();
  constructor (private dataService: DataService, private router: Router,private listViewService: ListViewService) { }
  ngOnInit () {
  }
  renderClass () {
    if (this.splitClass) {
      return this.splitClass;
    }
    switch (this._crud.class) {
      case 'col-md-1':
        return 'col-md-1';
      case 'col-md-2':
        return 'col-md-2';
      case 'col-md-3':
        return 'col-md-3';
      case 'col-md-4':
        return 'col-md-4';
      case 'col-md-5':
        return 'col-md-5';
      default:
        return 'col-md-6';
    }
  }
  goBack () {
    if (this._crud && this._crud[ 'backUrl' ]) {
      this.router.navigateByUrl(this._crud[ 'backUrl' ], { replaceUrl: true });
    }
    else {
      this.dataService.goBack();
    }
  }

  updateSelection (eve) {
    this.onSelection.emit(eve);
  }

  getMultiValue (valueList) {
    this.binValues.emit(valueList);
  }

  downloadCSV(csvFileName) {
    this.listViewService.exportCsv(`${AppWebRoutes.COMMON.export}${csvFileName}`, csvFileName.split('.')[0], '', {}, true);
  }

}
