import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-expansion',
  templateUrl: './expansion.component.html'
})
export class ExpansionComponent implements OnInit {
  @Input() _expansion;
  @Input() form: FormGroup;
  name: any;
  data: any;
  constructor () { }

  ngOnInit () { }
}
