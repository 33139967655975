<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <!-- <mat-panel-title>
        {{_expansion.title}}
      </mat-panel-title> -->
      <mat-panel-title>
        Filters
      </mat-panel-title>
      <mat-panel-description>
        {{_expansion.description}} || {{name}}
      </mat-panel-description>
      <mat-panel-description>
        {{_expansion.title}} || {{data}}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field>
      <input matInput [placeholder]="_expansion.description" [(ngModel)]="name">
    </mat-form-field>
    <mat-form-field>
      <input matInput [placeholder]="_expansion.title" [(ngModel)]="data">
    </mat-form-field>
  </mat-expansion-panel>
</mat-accordion>
