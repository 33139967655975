<mat-grid-list cols="2" rowHeight="350px">
  <app-card [_card]="cardData"></app-card>
  <ng-container *ngIf="chartData && chartData.length > 0">
    <div class="row">
      <div *ngFor="let chart of chartData" class="col-md-6">
        <app-chart [_chart]="chart"></app-chart>
      </div>
    </div>
  </ng-container>
</mat-grid-list>
