import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { SharedPPDService } from 'src/app/ppd/services/shared-ppd.service';
import { environment } from 'src/environments/environment';
import { ImageDetailComponent } from '../image-detail/image-detail.component';

@Component({
  selector: 'app-product-return-images',
  templateUrl: './product-return-images.component.html',
  styleUrls: [ './product-return-images.component.scss' ]
})
export class ProductReturnImagesComponent implements OnInit {
  images = [];
  fileUrl = environment.file_url;
  constructor (
    public dialogRef: MatDialogRef<ProductReturnImagesComponent>,
    public dialog: MatDialog,
    private sharedPPDService: SharedPPDService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit (): void {
    this.getRetuenProductImages();
  }

  getRetuenProductImages () {
    this.sharedPPDService.getAllList(`${AppWebRoutes.PPD.productReturnImages}?hubId=${this.data.hubId}&clusterId=${this.data.clusterId}&productId=${this.data.productId}&date=${this.data.date}`).subscribe((res: any) => {
      this.images = res;
    })
  }

  close () {
    this.dialogRef.close();
  }

  imageDetail (imageDetails) {
    this.dialog.open(ImageDetailComponent, {
      width: 'auto',
      data: imageDetails
    });
  }

}
