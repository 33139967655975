import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from 'src/app/shared/services/data/data.service';
import { MatDialog } from '@angular/material/dialog';
import { MbPassLedgerComponent } from '../containers/mb-pass/ledger/mb-pass-ledger.component';

@Injectable({
  providedIn: 'root'
})
export class SharedCustomerService {
  public tabIndex: number;
  userMembershipDetails: object;
  getOfferId = new BehaviorSubject(null);
  orderData = new BehaviorSubject(null);
  constructor (
    private httpService: HttpService,
    private dataService: DataService,
    public dialog: MatDialog) {
    this.tabIndex = 0;
  }


  postData (url, formValue): Observable<object | number> {
    return this.httpService.post(url, formValue);
  }

  getData (url, hidePagination = false, removeGlobalFilter = true): Observable<object> {
    return this.httpService.post(url, { filterRequired: true, hidePagination: hidePagination, removeGlobalFilter: removeGlobalFilter });
  }

  addData (url, formValue): Observable<object | number> {
    return this.httpService.post(url, formValue);
  }
  uploadData (url, formValue, dataToSend): Observable<object> {
    return this.httpService.post(url, formValue, dataToSend);
  }
  getAllList (url): Observable<object> {
    return this.httpService.get(url);
  }

  getDataById (url, customerId): Observable<object> {
    return this.httpService.get(url, customerId, null, true);
  }

  getDataByBody (url, body): Observable<object> {
    return this.httpService.get(url, body, null, true);
  }

  getDataWithOutId (url): Observable<object> {
    return this.httpService.get(url, null, true);
  }

  editData (url, formValue, customerId = null): Observable<object> {
    return this.httpService.put(url, formValue, customerId, true);
  }
  editDataPartial (url, formValue): Observable<object> {
    return this.httpService.put(url, formValue);
  }
  patchDataOnParams (url, formValue): Observable<object> {
    return this.httpService.patch(url, null, formValue);
  }
  getDataByIdWithResolve (
    url,
    optionsToUrl,
    optionsToHeader
  ): Observable<object> {
    return this.httpService.get(url, optionsToUrl, optionsToHeader, true);
  }
  patchData (url, formValue): Observable<object> {
    return this.httpService.patch(url, formValue);
  }
  deleteData (url, id): Observable<object> {
    return this.httpService.delete(url, id, true);
  }
  downloadPdf (url, fileName, view = false) {
    this.httpService.downloadPdf(url).subscribe(
      response => {
        // download file
        this.downloadFile(response, `${fileName}.pdf`);
        this.dataService.hideLoader();
      },
      error => {
        this.dataService.hideLoader();
      }
    );
  }
  downloadFile (data: any, fileName) {
    const parsedResponse = data;
    const blob = new Blob([ parsedResponse ], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  openMbPassLedger (data) {
    const dialogRef = this.dialog.open(MbPassLedgerComponent, {
      width: 'auto',
      data: data,
      autoFocus: false,
      maxHeight: '85%',
      panelClass: 'mb-pass-ledger'
    });
    return dialogRef.afterClosed();
  }

  refundMembership (url: string, dataToUpdate) {
    return this.httpService.put(url, dataToUpdate);
  }
}

