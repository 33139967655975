<div class="row tr-row">
  <span class="col t-cell">
    {{ _label.placeHolder }}
  </span>
  <div [class]="_label.navigateTo ? 'hyper-link col t-cell' : 'col t-cell'">
    <span class="t-cell-item" *ngFor="let value of _label.value">
      <a
        [routerLink]="navigateTo()"
        class="anchorColorSet"
        *ngIf="_label.navigateTo && _label.navigateTo !== 'CIMS'"
        [innerHTML]="_label.key ? value[_label.key] : value"
      ></a>
      <a
        [href]="navigateTo()"
        *ngIf="_label.navigateTo === 'CIMS'"
        class="anchorColorSet"
        [innerHTML]="_label.key ? value[_label.key] : value"
      ></a>
      <strong
        *ngIf="!_label.navigateTo"
        [innerHTML]="_label.key ? value[_label.key] : value"
      ></strong>
    </span>
  </div>
</div>
