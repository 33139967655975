import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { environment } from '../../../../environments/environment';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  paginationData: any = {
    pageSize: this.getLocalStorage(environment.localStorageKeys.PAGESIZE) || 100,
    pageNo: this.getPageIndex(),
    sort: {
      modifiedOn: 'DESC',
      modifiedDate: 'DESC'
    }
  };
  pageName = this.getLocalStorage(environment.localStorageKeys.PAGENAME)
    ? this.getLocalStorage(environment.localStorageKeys.PAGENAME)
    : '';
  tableCols = [];
  navigateToTab;
  appendDataToSend = {};
  imageUrl = '';
  showQuickMenu = true;
  isListView = false;
  resetPagination = true;
  pageSizeOptions: Array<number> = [];
  isSubscriptionAlive = true;
  apiValueForForm = {};
  showMasterHubButton = false;
  public errorKeySet = {};
  public errorMessages = {};
  selectedText: any;
  subcategoryDisplayOrder: any;
  getPreviousUrl = '/user';
  firebaseKeys = {};
  userProfile =
    this.getLocalStorage(environment.localStorageKeys.USER) &&
      this.getLocalStorage(environment.localStorageKeys.USER).profile
      ? this.getLocalStorage(environment.localStorageKeys.USER).profile
      : {};
  globalFilter: any = {
    apiValue: {
      cityId: this.userProfile.cityId,
      hubId: this.userProfile.hubId
    },
    uivalue: {
      cityId: this.userProfile.cityName,
      hubId: this.userProfile.hubName
    }
  };
  mapCenter = { latitude: 28.4231878, longitude: 76.9197003, zoom: 10 };
  list = [
    { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
    { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
    { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
    { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
    { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
    { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
    { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
    { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
    { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
    { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
  ];
  list$: BehaviorSubject<any> = new BehaviorSubject(this.list);
  searchFilters = this.getLocalStorage(
    environment.localStorageKeys.FILTERS
  ) || {
    count: 0,
    metaData: {},
    filters: {},
    isFilterPreFilled: false
  };
  hubMasterCatalog = localStorage.getItem(environment.localStorageKeys.CATALOG)
    ? localStorage.getItem(environment.localStorageKeys.CATALOG)
    : 'hubCatalog';
  optionParams: object;
  listPageIndex: number;
  goToViewPage = false;
  detailsFromMap = {};
  dataToSendForCrudMultiChip: any = {};
  dataToSendForCrudMultiChipCheck = {};
  dataToSendForCrudMultiChipMultiple = {};
  dataToSendForSearchMultiChip = {};
  initDataForMultiChip:any = {};
  initDataForMultiChipMulti = [];
  dataToSendForDate = {};
  images = {};
  selectedAutocompleteValue: string;
  dataToSendForImageUpload: any = {};
  dataToSendForViewDetails = {};
  bulkUploadTable: MatTableDataSource<object> = new MatTableDataSource();
  public refreshList = new Subject<boolean>();
  masterHubCatalogToggleDisabled = false;
  selectedOption: object;
  selectedCampaignType: string;
  isEquivalent = (PObj: Object, NObj: Object) => {
    // Loop through properties in PObj
    for (const key in PObj) {
      // Check property exists on both objects
      if (PObj.hasOwnProperty(key)) {
        if (PObj.hasOwnProperty(key) !== NObj.hasOwnProperty(key)) {
          return false;
        }
        switch (typeof PObj[ key ]) {
          // Deep compare objects
          case 'object':
            if (!this.isEquivalent(PObj[ key ], NObj[ key ])) {
              return false;
            }
            break;
          // Compare function code
          // case 'function':
          //   if (typeof (obj2[ p ]) === 'undefined' || (p !== 'functionName' && obj1[ p ].toString() !== obj2[ p ].toString())) return false;
          //   break;
          // Compare values
          default:
            if (PObj[ key ] !== NObj[ key ]) {
              return false;
            }
        }
      }
    }
    // Check NObj for any extra properties
    for (const key in NObj) {
      if (NObj.hasOwnProperty(key)) {
        if (typeof PObj[ key ] === 'undefined') {
          return false;
        }
      }
    }
    return true;
  }

  deepClone = obj => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
    let temp;
    if (obj instanceof Date) {
      temp = new Date(obj);
    } else {
      temp = new obj.constructor();
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        temp[ key ] = this.deepClone(obj[ key ]);
      }
    }
    return temp;
  }

  private sideNavData = new BehaviorSubject<any>({});
  private loader = new Subject<boolean>();

  constructor (
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  resetGlobalFilter () {
    this.globalFilter = {
      apiValue: {
        cityId: this.userProfile.cityId,
        hubId: this.userProfile.hubId
      },
      uivalue: {
        cityId: this.userProfile.cityName,
        hubId: this.userProfile.hubName
      }
    };
  }

  removeEmptyValue (myObj) {
    Object.keys(myObj).forEach(
      key => (myObj[ key ] === null || myObj[ key ] === '') && delete myObj[ key ]
    );
    return myObj;
  }

  setDefaultPagination () {
    if (this.resetPagination) {
      this.paginationData = {
        pageSize:
          this.getLocalStorage(environment.localStorageKeys.PAGESIZE) || 100,
        pageNo: this.getPageIndex(),
        sort: {
          modifiedDate: 'DESC',
          modifiedOn: 'DESC'
        }
      };
    }
  }

  sendSideNavData (data: object) {
    this.sideNavData.next(data);
  }

  getSideNavData () {
    return this.sideNavData;
  }

  getSearchData () {
    // this.searchFilters = this.removeEmptyValue(this.searchFilters);
    return this.searchFilters;
  }

  setSearchData (obj, uiValue, key, value, newFilterValue = false) {
    if (newFilterValue && key in this.searchFilters.filters) {
      return;
    }
    if (!this.searchFilters.filters[ key ] && (value || value === 0)) {
      if (key === obj.key) {
        this.searchFilters.count++;
      }
    } else if (this.searchFilters.filters[ key ] && !value) {
      this.searchFilters.count--;
    }
    this.searchFilters.filters[ key ] = value;
    this.searchFilters.metaData[ key ] = {};
    this.searchFilters.metaData[ key ][ obj.apiKey ] = value;
    this.searchFilters.metaData[ key ][ obj.uiKey ] = uiValue;
    this.searchFilters.filters = this.removeEmptyValue(
      this.searchFilters.filters
    );
  }

  resetSearchData () {
    this.searchFilters = {
      count: 0,
      metaData: {},
      filters: {},
      isFilterPreFilled: false
    };
  }

  getUpdatedPaginationData (total) {
    if (total >= 0) {
      if (total <= 50) {
        this.pageSizeOptions = [ 20, 50 ];
      } else {
        this.pageSizeOptions = [ 20, 50, 100 ];
      }
    }
  }

  openSnackBar (message: string, className?) {
    className = 'snack-bar-component' + ' ' + className ? className : '';
    if (!this.snackBar._openedSnackBarRef) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: message,
        horizontalPosition: 'center',
        panelClass: className,
        duration: 5000
      });
    }
  }

  showLoader () {
    this.loader.next(true);
  }

  hideLoader () {
    this.loader.next(false);
  }

  getLoader () {
    return this.loader;
  }

  destroyObservable () {
    // terminating the existing subscriber
    this.isSubscriptionAlive = false;

    // Enabling it for new subscriber
    this.isSubscriptionAlive = true;
  }

  openDialog (data) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: 'auto',
      data: data,
      autoFocus: false
    });
    return dialogRef.afterClosed();
  }

  openDialogWithClass (data: any, classname: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: 'auto',
      data: data,
      autoFocus: false,
      panelClass: classname
    });
    return dialogRef.afterClosed();
  }

  setLocalStorage (whatToStore, toStore) {
    localStorage.setItem(whatToStore, JSON.stringify(toStore));
  }

  getLocalStorage (whatToGet) {
    if (localStorage.getItem(whatToGet)) {
      return JSON.parse(localStorage.getItem(whatToGet));
    }
  }

  removeFromLocalStorage (whatToRemove) {
    return localStorage.removeItem(whatToRemove);
  }

  removeWFAccountClosureA2BFreezeOption (walletFreeze) {
    const index = walletFreeze?.findIndex(item => item.value === "WF_ACCOUNT_CLOSURE_A2B_FREEZE");
    if (index >= 0) {
      walletFreeze?.splice(index, 1);
    }
  }

  dateFormat (date, dateReturn = true) {
    if (date) {
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      const time =
        [ date.getHours() < 10 ? '0' + date.getHours() : date.getHours() ] +
        ':' +
        [ date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() ] +
        ':' +
        [ date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() ];
      const dateChange =
        date.getFullYear() +
        '-' +
        month +
        '-' +
        [ date.getDate() < 10 ? '0' + date.getDate() : date.getDate() ];
      if (dateReturn) {
        return dateChange;
      } else {
        return time;
      }
    } else {
      return '';
    }
  }
  convertObjectToKeyValueString (object) {
    if (object && object instanceof Object) {
      return (
        Object.keys(object)
          .map(function (key) {
            if (object[ key ] instanceof Object) {
              object[ key ] = JSON.stringify(object[ key ]);
            }
            return key + ':' + object[ key ];
          })
          .join(', ') || object
      );
    }
  }
  formatDateForUI (date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      '  ' +
      strTime
    );
  }

  regexMatch (targetString, searchPattern) {
    return targetString.match(searchPattern);
  }

  goBack () {
    this._location.back();
  }

  dateComparison (date: Date, compareDate: Date) {
    return date.getDate() === compareDate.getDate() &&
      date.getMonth() === compareDate.getMonth() &&
      date.getFullYear === compareDate.getFullYear
      ? true
      : false;
  }

  dateGreaterThan (date: Date, compareDate: Date) {
    if (date.getDate() === compareDate.getDate() &&
      date.getMonth() === compareDate.getMonth() &&
      date.getFullYear === compareDate.getFullYear) {
      return true;
    }
    if (date > compareDate) {
      return true;
    }
    return false;
  }

  update (index, field, value) {
    this.list = this.list.map((e, i) => {
      if (index === i) {
        return {
          ...e,
          [ field ]: value
        };
      }
      return e;
    });
    this.list$.next(this.list);
  }

  /* Common Generic Bulk Upload Function which takes two parameter
  resp : Response From Bulk Upload API
  tableHeaderCrud: Header Crud with keys of the bulk upload table
  And Returns the cumulative bulkUploadTable data to show.
  */
  bulkUploadFunction (resp, tableHeaderCrud, showErrorDialog = true) {
    this.bulkUploadTable.data = [];
    const errorsToBeShownInDialog = [];
    if (resp.failedRows) {
      for (const row of resp.failedRows) {
        const errorRowData = {};
        this.errorKeySet = {};
        this.errorMessages = {};
        for (const err of row.errors) {
          if (!this.errorMessages[ err.field ]) {
            this.errorMessages[ err.field ] = err.message;
          }
          errorsToBeShownInDialog.push(err.message);
          tableHeaderCrud.map(errorFlag => {
            const errorFlagSet = errorFlag.header.replace(' ', '');
            if (err.field === errorFlag.key) {
              this.errorKeySet[ 'is' + errorFlagSet ] = 'failed';
            }
            errorRowData[ errorFlag.key ] = {
              value: row[ errorFlag.key ] || row[ errorFlag.key ] === 0 ? row[ errorFlag.key ] : this.errorMessages[ errorFlag.key ],
              cssClass: this.errorKeySet[ 'is' + errorFlagSet ]
            };
          });
        }
        this.bulkUploadTable.data.push(errorRowData);
      }
    }
    if (resp.successRows) {
      for (const row of resp.successRows) {
        const dataToSendSuccess = {};
        tableHeaderCrud.map(tableHeaderData => {
          dataToSendSuccess[ tableHeaderData.key ] = {
            value: row[ tableHeaderData.key ]
          };
        });
        this.bulkUploadTable.data.push(dataToSendSuccess);
      }
    }
    if (errorsToBeShownInDialog.length > 0) {
      const errorDialog = {
        message: errorsToBeShownInDialog,
        title: 'Error in File',
        why: 'errorHandle'
      };
      showErrorDialog && this.openDialog(errorDialog);
    }
    return this.bulkUploadTable.data;
  }

  setYesOrNo (keyValue) {
    return keyValue ? 'Yes' : 'No'
  }

  getPageIndex () {
    const params = window.location.href.split('?');
    const urlParams = new URLSearchParams(params.slice(1).join());
    return urlParams.get('pageIndex') || 1;
  }

  reloadSameRoute () {
    const url = this.router.url;
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate([ url ]);
      });
  }

  differenceBetweenDates (date1, date2) {
    const dateStr1: any = new Date(date1);
    const dateStr2: any = new Date(date2);
    const difference = dateStr1 - dateStr2;
    return Math.ceil(Math.abs(difference) / (1000 * 60 * 60 * 24));
  }
}