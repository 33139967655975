<div class="mat-accordion-holder bg">
  <mat-accordion class="mat-accordion-custom">
    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>Today’s Order</strong>
        </mat-panel-title>
        <mat-panel-description class="f-g-1">
          <div class="left-block">
            <span>3 Items</span>
            <span class="divider"></span>
            <span>Amount : 210</span>
          </div>
          <div class="right-block">
            <strong class="initiate-refund">INITIATE REFUND</strong>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="table-holder">Table content...</div>
    </mat-expansion-panel>
  </mat-accordion>
</div>