import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { appConstants } from '../../../core/constants/app-constant';
import { AutoCompeteService } from './autocomplete.service';
import { map, startWith } from 'rxjs/operators';
import { autoCompleteComponentActions } from '../../constants/shared-constant';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UpdatePropertyPipe } from '../../pipe/updateProperty.pipe';
import { FormService } from '../../services/form/form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: [ './autocomplete.component.scss' ]
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  _autocomplete;
  @Input()
  _search;
  @Input()
  _appearance = true;
  @Input()
  form: FormGroup;
  observable: any;
  checkEmpty = false;
  @ViewChild(MatAutocompleteTrigger, { static: false })
  trigger;
  inDebounce: any;
  public matAutoComplete: MatAutocomplete;
  searchString: any;
  @Output() onSelection = new EventEmitter();
  subscription: Subscription = new Subscription();
  constructor (
    private autoCompeteService: AutoCompeteService,
    public dataService: DataService,
    private updatePropertyPipe: UpdatePropertyPipe,
    private formService: FormService
  ) { }

  ngOnInit () {
    if (this._autocomplete.options && this._autocomplete.options.length) {
      this.filterOnSearchedKey(this._autocomplete);
    } else {
      this._autocomplete.options = [];
      if (this._autocomplete.searchableDropDown) {
        this.dataForSearchableDropDown(
          this._autocomplete,
          this._autocomplete.apiEndPoint,
          this.removeUndefinedKey(this._autocomplete.optionsParams),
          this.dataService.globalFilter.apiValue
        );
      }
    }
    this.dataService.refreshList.subscribe(data => {
      if (this._autocomplete.searchableDropDown) {
        this.dataForSearchableDropDown(
          this._autocomplete,
          this._autocomplete.apiEndPoint,
          this.removeUndefinedKey(this._autocomplete.optionsParams),
          this.dataService.globalFilter.apiValue,
          true
        );
      }
    });
  }

  ngAfterViewInit () {
    if (this.trigger) {
      this.trigger.panelClosingActions.subscribe(e => {
        if (!(e && e.source) && !('captureOnNoData' in this._autocomplete)) {
          let isValueHave = false;
          this._autocomplete.options.forEach(element => {
            if (
              this.form.controls[ this._autocomplete.key ].value ===
              element[ this._autocomplete.uiKey ] ||
              this.form.controls[ this._autocomplete.key ].value ===
              element[ this._autocomplete.apiKey ]
            ) {
              isValueHave = true;
            }
          });
          if (isValueHave === false) {
            if (!this._autocomplete.sustainInputValue) {
              this.form.controls[ this._autocomplete.key ].patchValue('');
            }
          }
          this.trigger.closePanel();
        } else if (this._autocomplete.key in this.dataService.apiValueForForm) {
          delete this.dataService.apiValueForForm[ this._autocomplete.key ];
        }
      });
    }
  }
  removeUndefinedKey (obj) {
    if (obj) {
      Object.keys(obj).forEach(key => {
        if (obj[ key ] === undefined) {
          delete obj[ key ];
        }
      });
      return obj;
    } else {
      return {};
    }
  }
  triggerClick () {
    if (this._autocomplete.searchableDropDown) {
      this.trigger.openPanel();
      this._autocomplete.filteredOptions = this.form.controls[
        this._autocomplete.key
      ].valueChanges.pipe(
        startWith(''),
        map(value =>
          this._filter(
            value,
            this._autocomplete.options,
            this._autocomplete.uiKey
          )
        )
      );
    }
  }

  fetchAutoSuggestions (event) {
    const self = this;
    if (this.inDebounce) {
      clearTimeout(this.inDebounce);
    }
    this.inDebounce = setTimeout(() => { self.fetchAutoSuggestionsApi(event); }, 1000);
  }
  fetchAutoSuggestionsApi (event) {
    this.searchString = { text: this.form.value[ this._autocomplete.key ] };
    if (this._autocomplete.hubIdRequired) {
      this.searchString.hubId = this.dataService.globalFilter.apiValue.hubId;
    }

    if (this._autocomplete.cityIdRequired) {
      this.searchString.cityId = this.dataService.globalFilter.apiValue.cityId;
    }
    if (this._autocomplete.localCityIdRequired) {
      this.searchString.cityId = this.dataService.apiValueForForm[ 'cityId' ];
    }
    if (
      appConstants[ 'keyCodeNotAllowedForSuggestions' ].indexOf(event.keyCode) ===
      -1 &&
      !this._autocomplete.likeSearch &&
      !this._autocomplete.searchableDropDown
    ) {
      if (
        this.form.value[ this._autocomplete.key ] &&
        this.form.value[ this._autocomplete.key ].length >= 2
      ) {
        this._autocomplete.options = [];
        if (this.observable) {
          this.observable.unsubscribe();
        }
        this.checkEmpty = false;
        if (this._autocomplete.optionsParams) {
          this.searchString = {
            ...this.searchString,
            ...this._autocomplete.optionsParams
          };
        }
        this.searchString.text = encodeURIComponent(this.searchString.text);
        this.observable = this.autoCompeteService
          .getAutoSuggestions(this._autocomplete.apiEndPoint, this.searchString)
          .subscribe(success => {
            if (success) {
              this._autocomplete.options = success;
              if (this._autocomplete.options.length === 0) {
                this.checkEmpty = true;
              }
              if (
                this._autocomplete.options.length &&
                this.form.value[ this._autocomplete.key ] ===
                this._autocomplete.options[ 0 ][ this._autocomplete.key ]
              ) {
                this._autocomplete[ 'likeSearch' ] = true;
                // this.triggerSearchOnBlur(this._autocomplete.key);
                this._autocomplete[ 'likeSearch' ] = false;
              }
            }
          });
      } else {
        this._autocomplete.options = [];
      }
    }
  }

  onAutoCompleteSelection (event, searchConstant, uiValue, keyToSend, apiValue) {
    this.dataService.selectedOption = this.dataService.deepClone(uiValue);
    this.onSelection.emit({ data: this.dataService.selectedOption, inputInfo: searchConstant });
    if (uiValue[ 'isBundle' ]) {
      this.dataService.apiValueForForm[ 'isBundle' ] = !!uiValue[ 'isBundle' ];
    }
    const tmpUiValue = uiValue;
    if (event.isUserInput) {
      if (uiValue.text) {
        this.dataService.selectedText = uiValue.text;
      }
      if (searchConstant && searchConstant.subKey) {
        uiValue = uiValue[ searchConstant.uiKey ][ searchConstant.subKey ];
      } else {
        uiValue = uiValue[ searchConstant.uiKey ];
      }
      searchConstant.needUIValue && (this.dataService.selectedAutocompleteValue = uiValue);
      this.dataService.apiValueForForm[ keyToSend ] = apiValue;
      if (this._search && this._search.length) {
        if (this._autocomplete.globalFilter) {
          this.dataService.apiValueForForm[ this._autocomplete.key ] = uiValue;
        }
        if (searchConstant.module === 'search') {
          this._autocomplete.chipOptions = {
            key: keyToSend,
            value: this.updatePropertyPipe.transform(
              tmpUiValue,
              this._autocomplete
            ),
            uiKey: searchConstant.uiKey,
            apiKey: searchConstant.apiKey,
            apiValue: apiValue,
            dependentOn: searchConstant.dependentOn,
            targetIndex: searchConstant.targetIndex
          };
          this.dataService.setSearchData(
            searchConstant,
            uiValue,
            keyToSend,
            apiValue
          );
          this.form.controls[ keyToSend ].patchValue('');
        }
        if (searchConstant.module !== 'search') {
          this.form.controls[ keyToSend ].patchValue(
            this.updatePropertyPipe.transform(tmpUiValue, this._autocomplete)
          );
        }
        if (
          this._autocomplete.action ===
          autoCompleteComponentActions.auto_update_dependent_list
        ) {
          let optionParams = {};
          optionParams[ keyToSend ] = apiValue;

          if (this._autocomplete.customDependentApiKey) {
            delete optionParams[ keyToSend ];
            optionParams[ this._autocomplete.customDependentApiKey ] = apiValue;
          }

          optionParams = {
            ...optionParams,
            ...this._search[ this._autocomplete.targetIndex ].optionsParams
          };
          optionParams = this.removeUndefinedKey(optionParams);
          const index = this._autocomplete.targetIndex;
          this._search[ index ].options = [];
          this._search[ index ][ 'dependent' ] = false;
          this._search[ index ].chipOptions = {};
          this.form.controls[ this._search[ index ].key ].patchValue('');
          // clear dependent filter
          if (this._autocomplete.mapping === 'city-hub') {
            this.dataService.dataToSendForCrudMultiChip = { "hubId": [] };
            this.dataService.initDataForMultiChip = { "hubId": [] };
          }
          this.dataForSearchableDropDown(
            this._search[ index ],
            this._search[ index ].apiEndPoint,
            optionParams,
            this.dataService.globalFilter.apiValue
          );
          this._search[ index ][ 'dependent' ] = true;
        }

        if (this._autocomplete.action ===
          autoCompleteComponentActions.auto_update_dependent_list_depend) {
          let optionParams = {};
          optionParams[ keyToSend ] = apiValue;
          optionParams = {
            ...optionParams,
            ...this._search[ this._autocomplete.targetIndex ].optionsParams
          };
          optionParams = this.removeUndefinedKey(optionParams);
          const index = this._autocomplete.targetIndex;
          this._search[ index ].optionsParams = { ...this._search[ index ].optionsParams, ...optionParams },
            this._search[ index ][ 'dependent' ] = true;
        }
        if (!this._autocomplete.searchableDropDown) {
          this._autocomplete.options = [];
        }
      } else {
        this._autocomplete.chipOptions = {
          key: keyToSend,
          value: uiValue,
          apiValue: apiValue
        };
        this.form.controls[ keyToSend ].patchValue(uiValue);
      }
    }
  }

  dataForSearchableDropDown (
    searchConstant,
    apiEndPoint,
    optionParams,
    optionHubCity?,
    reload = false
  ) {
    let dependentKey = null;
    if (optionParams && 'dependentKey' in optionParams) {
      dependentKey = optionParams[ 'dependentKey' ];
      delete optionParams[ 'dependentKey' ];
    }
    searchConstant.options = [];
    let params = {};
    if (searchConstant.filterWithHubCity) {
      for (const key in optionHubCity) {
        if (key === searchConstant.filterWithHubCity) {
          params[ key ] = optionHubCity[ key ];
        }
      }
    } else {
      params = optionParams;
    }
    params = { ...params, ...optionParams };
    this.checkEmpty = false;
    if (searchConstant.dependent && dependentKey) {
      if (searchConstant.key in (this.dataService.apiValueForForm)
        && typeof this.dataService.apiValueForForm[ searchConstant.key ] === 'object'
        && dependentKey in this.dataService.apiValueForForm[ searchConstant.key ]) {
        params[ dependentKey ] = this.dataService.apiValueForForm[ searchConstant.key ][ dependentKey ];
      }

      if (dependentKey in this.dataService.apiValueForForm) {
        params[ dependentKey ] = this.dataService.apiValueForForm[ dependentKey ];

      }
    }
    if (
      !searchConstant.dependent ||
      reload ||
      (dependentKey && (dependentKey in this.dataService.apiValueForForm
        || (searchConstant.key in (this.dataService.apiValueForForm) && dependentKey in this.dataService.apiValueForForm[ searchConstant.key ])))
    ) {
      this.autoCompeteService
        .getAllSuggestions(apiEndPoint, params)
        .subscribe((success: any) => {
          if (typeof success[ 0 ] === 'string') {
            success = success.map(a => {
              return { module: a };
            });
            searchConstant.options = success;
          } else {
            searchConstant.options = success;
            if (this._autocomplete.addAllOptionDropdown) {
              this._autocomplete.options.unshift({ id: 0, name: 'All' });
            }
          }
          if (searchConstant.options && searchConstant.options.length === 0) {
            this.checkEmpty = true;
          }
          searchConstant.filteredOptions = success;
          searchConstant.optionParams = {};
          if (searchConstant.options && searchConstant.options.length) {
            if (searchConstant.extraOptions && searchConstant.extraOptions.length) {
              searchConstant.options = [ ...searchConstant.extraOptions, ...searchConstant.options ];
              searchConstant.filteredOptions = searchConstant.options;
            }
          }
          this.filterOnSearchedKey(searchConstant);
        });
    }
  }

  ngOnDestroy () {
    this._autocomplete.chipOptions = {};
    this._autocomplete.options = [];
    this.subscription.unsubscribe();
  }

  private _filter (value, options?, uiKey?): string[] {
    return options.filter(
      option =>
        option &&
        option[ uiKey ] &&
        this.checkForSubKey(option, uiKey).includes(
          value && value.toString().toLowerCase()
        )
    );
  }

  filterOnSearchedKey (searchConstant) {
    if (this.form.controls[ searchConstant.key ]) {
      searchConstant.filteredOptions = this.form.controls[
        searchConstant.key
      ].valueChanges.pipe(
        startWith(''),
        map(value =>
          this._filter(
            value,
            searchConstant.options,
            searchConstant.uiKey
          )
        )
      );
    }
  }

  checkForSubKey (option, uiKey) {
    if (this._autocomplete && this._autocomplete.subKey) {
      return option[ uiKey ][ this._autocomplete.subKey ]
        ? option[ uiKey ][ this._autocomplete.subKey ].toString().toLowerCase()
        : '';
    } else {
      return option[ uiKey ] ? option[ uiKey ].toString().toLowerCase() : '';
    }
  }
}