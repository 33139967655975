import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() _checkbox;
  @Input() form: FormGroup;
  constructor(private dataService: DataService) { }

  ngOnInit() { }
  onCheckboxClick(event, key) {
    this._checkbox.checkbox = event.checked;
    this.dataService.setSearchData(this._checkbox, event.checked, key, event.checked);
  }
}
