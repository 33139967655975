import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('headerMenu', { static: false })
  _headerMenu;
  @Input()
  _header;
  @Output()
  refreshData: EventEmitter<any> = new EventEmitter<boolean>();
  @Output()
  successUpdate: EventEmitter<any> = new EventEmitter<boolean>();

  constructor (
    public dataService: DataService,
    private router: Router,
    public permissionsService: NgxPermissionsService
  ) { }

  ngOnInit () {
    this.dataService.refreshList.subscribe(data => {
      if (data) {
        this.reloadFilteredData();
      }
    });
  }

  reloadFilteredData () {
    this.dataService.listPageIndex = 0;
    this.dataService.paginationData.pageNo = 1;
    this.refreshData.emit();
  }

  goBack () {
    this.dataService.goBack();
  }

  changePage (route, dialog?) {
    if (!route) {
      route = 'add';
    }
    if (dialog) {
      const dialogRef = this.dataService.openDialog(this._header.dialogBox);
      dialogRef.subscribe(result => {
        if (result) {
          if (result === true && this._header.dialogBox && this._header.dialogBox.apiUrl) {
            this.successUpdate.emit({ apiUrl: this._header.dialogBox.apiUrl });
          } else if (result === 'single') {
            this.router.navigate([ this.router.url, route ]);
          } else if (result === 'bundle') {
            route = 'bundle';
            this.router.navigate([ this.router.url, route ]);
          } else if (result.value.productId) {
            if (this.dataService.apiValueForForm[ 'isBundle' ]) {
              this.router.navigate([
                'catalog/hub',
                this.dataService.globalFilter.apiValue.hubId,
                'bundle',
                result.value.productId,
                route
              ]);
              delete this.dataService.apiValueForForm[ 'isBundle' ];
            } else {
              this.router.navigate([
                'catalog/hub',
                this.dataService.globalFilter.apiValue.hubId,
                'product',
                result.value.productId,
                route
              ]);
            }
          } else {
            this.router.navigate([ this.router.url, route, result ]);
          }
        }
      });
    } else {
      let url: string = this.router.url;
      if (this.router.url.indexOf("?") > -1) {
        url = this.router.url.substring(0, this.router.url.indexOf("?"));
      }
      if (this._header.addNewCreateRoute) {
        this.router.navigate([ route ]);
      }
      else {
        this.router.navigate([ url, route ]);
      }
    }
  }

  ngOnDestroy () { }
}
