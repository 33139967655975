import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { HttpService } from '../../services/http/http.service';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data/data.service';

@Injectable({
  providedIn: 'root'
})
export class ListViewService {
  constructor (
    private authService: AuthService,
    private _http: HttpService,
    private dataService: DataService
  ) { }

  deleteData (url, id, optionParams = null): Observable<object> {
    return this._http.delete(url, id, true, optionParams);
  }

  putData (url, body = null, optionParams = null): Observable<object> {
    return this._http.put(url, body, optionParams);
  }

  exportCsv (url, fileName, hubName, params?, removeGlobalFilter?, isErrorShow = false) {
    this._http.exportCsv(url, params, removeGlobalFilter).subscribe(
      response => {
        // download file
        fileName = `${fileName}:${hubName}_${new Date().getDate()}-${new Date().getMonth() +
          1}-${new Date().getFullYear()}`;
        this.downloadFile(response, `${fileName}.csv`);
        this.dataService.hideLoader();
      },
      error => {
        this.dataService.hideLoader();
        if (error[ 'status' ] >= 500) {
          this.dataService.openSnackBar(
            'Check your Internet Connection / Internal Server Error'
          );
        } else if (error[ 'status' ] === 400) {
          if (isErrorShow) {
            if ('error' in error) {
              let errors = JSON.parse(error.error);
              this.dataService.openSnackBar(errors.errors[ 0 ].message);
            }
          } else {
            this.dataService.openSnackBar(
              'Maximum records limit reached, add filters to get lesser number of records'
            );
          }
        } else {
          this.dataService.openSnackBar(
            'Something went wrong. Please try again later.'
          );
        }
      }
    );
  }

  getData (url) {
    return this._http.get(url);
  }

  downloadPdf (url, fileName, view = false) {
    this._http.downloadPdf(url).subscribe(
      response => {
        // download file
        if (view) {
          this.viewFile(response);
        } else {
          this.downloadFile(response, `${fileName}.pdf`);
        }
        this.dataService.hideLoader();
      },
      error => {
        this.dataService.hideLoader();
      }
    );
  }

  viewFile (data: any) {
    const blob = new Blob([ data ], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(data);
    window.open(fileURL, '_blank');
  }

  downloadFile (data: any, fileName) {
    const parsedResponse = data;
    const blob = new Blob([ parsedResponse ], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  patchData (url, id): Observable<object> {
    return this._http.patch(url, id, true);
  }

  editData (url, formValue, id): Observable<object> {
    return this._http.put(url, formValue, id, false);
  }
  editDataPut (url, formValue, id): Observable<object> {
    return this._http.put(url, formValue, id, true);
  }

  postData (url, body) {
    return this._http.post(url, body);
  }
  getDataById (url, userId): Observable<object> {
    return this._http.get(url, userId, null, true);
  }
}
