import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/browser';
import 'firebase';

if (environment.production) {
  Sentry.init({
    dsn: 'https://5cb8fce84a03413a95acff90025c01d4@sentry.io/1379390'
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch();
