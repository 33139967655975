import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input() _radio;
  @Input() form: FormGroup;
  constructor() {}

  ngOnInit() {}
  emitRadioChange(event) {
    if (this._radio.event) {
      if (!event.value) {
        this.form.controls[this._radio.target].disable();
        this.form.controls[this._radio.target].patchValue('');
        this.form.controls[this._radio.target].setErrors({});
      } else {
        this.form.controls[this._radio.target].enable();
      }
    }
  }
}
