<div class="google-form" [formGroup]="form" *ngIf="!loadMapOnly; else loadMap">
  <mat-form-field [hintLabel]="_google.hintLabel" appearance="outline">
    <mat-label>{{ _google.placeholder
      }}<span *ngIf="_google.validations && _google.validations.required"><i class="fa fa-asterisk as-design"></i></span></mat-label>
    <input matInput appTrim [placeholder]="_google.placeholder" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search [formControlName]="_google.key" />
  </mat-form-field>
  <app-error [form]="form" [_error]="_google"></app-error>
  <agm-map *ngIf="!_google.hideMap" (mapReady)="mapReadyHandler($event)" [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom">
    <agm-marker [markerDraggable]="draggable" (dragEnd)="markerDragEnd($event)" [latitude]="latitude" [longitude]="longitude"></agm-marker>
  </agm-map>
</div>

<ng-template #loadMap>
  <agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom">
    <ng-container *ngFor="let mapData of mapList">
      <agm-marker [iconUrl]="mapData.icon" [markerDraggable]="mapData.draggable" (dragEnd)="triggermarkerDragEnd($event)" [latitude]="mapData.latitude" [longitude]="mapData.longitude"></agm-marker>
    </ng-container>
  </agm-map>
</ng-template>
