import { Component, OnInit } from '@angular/core';
import { appConstants } from '../../constants/app-constant';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/services/form/form.service';
import { AppWebRoutes } from '../../constants/app-web-routes';
import { DataService } from 'src/app/shared/services/data/data.service';
import { UpdatePasswordService } from '../update-password/update-password.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-name',
  templateUrl: './user-name.component.html'
})
export class UserNameComponent implements OnInit {
  public adminCrud = appConstants.userContainer.crud;
  public adminPassowrdCrud = appConstants.passwordContainer.crud;

  public form: FormGroup;
  public passwordform: FormGroup;
  public userId: any;

  constructor (
    private formService: FormService,
    private dataService: DataService,
    private updatePasswordService: UpdatePasswordService,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit () {
    this.form = this.formService.createFormGroup(this.adminCrud);
    this.passwordform = this.formService.createFormGroup(
      this.adminPassowrdCrud
    );
    this.userId = this.dataService.getLocalStorage(
      environment.localStorageKeys.USER
    );

    this.form.patchValue({
      firstName: this.userId.profile.firstName,
      lastName: this.userId.profile.lastName
    });
  }

  Submit () {
    const requestData = {
      ...this.form.value
    };
    const apiUrl = `${AppWebRoutes.AUTH.adminName}/${this.userId.profile.id}`;
    this.updatePasswordService
      .changeAdminName(apiUrl, requestData)
      .subscribe((success: Response) => {
        if (success === null) {
          let userProfile = {};
          userProfile = this.dataService.userProfile;
          userProfile[ 'firstName' ] = this.form.value.firstName;
          userProfile[ 'lastName' ] = this.form.value.lastName;
          userProfile[ 'fullName' ] =
            this.form.value.firstName + '      ' + this.form.value.lastName;
          this.dataService.userProfile = userProfile;
          this.auth.setUserProfile({ profile: userProfile });
          this.router.navigate([ '/home' ]);
        }
      });
  }

  // passwordSubmit() {
  //   if (this.passwordform.invalid) {
  //     return;
  //   }
  //   const requestData = {
  //     ...this.passwordform.value
  //   };

  //   this.updatePasswordService
  //     .ChangePassword(requestData)
  //     .subscribe((success: Response) => {
  //       if (success === null) {
  //       }
  //     });
  // }
}
